import React, { useEffect } from "react";
import PropTypes from "prop-types";
import _, { isEmpty } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { Media, Col, Row } from "reactstrap";
import { truncate, dateFormat } from "../../../../_shared/functions/utils";
import ShipmentTrackingForm from "../../_shared/Forms/ShipmentTrackingForm";
import TrackingMap from "./TrackingMap";
import ProgressBar from "../../../../_shared/components/ProgressBar";
import img from "../../../../assets/img/svg/icons/check-mark.svg";
import { useHistory } from "react-router-dom";
import {
  trackMyOrders,
  checkIfMyOrdersIsAlreadyTracked,
} from "../../../../redux/actions";
import "./ShipmentTracking.scss";

const propTypes = {
  isCheckIfMyOrdersIsAlreadyTracked: PropTypes.bool,
};

const defaultProps = {
  isCheckIfMyOrdersIsAlreadyTracked: true,
};

const ShipmentTracking = ({
  deal,
  checkIfMyOrdersIsAlreadyTracked,
  isCheckIfMyOrdersIsAlreadyTracked,
  trackMyOrders,
  tracking_info,
  location,
}) => {
  const { push } = useHistory();

  const id = location.state.dealId;
  const { product, _id } = deal || {};
  const { name, photos } = product || {};
  const {
    carrier,
    tracking_code,
    est_delivery_date,
    status,
    carrier_detail,
    tracking_details,
  } = tracking_info || {};
  const { destination_location, origin_location } = carrier_detail || {};

  useEffect(() => {
    window.scrollTo(0, 0);
    const payload = {
      _id: id,
    };

    checkIfMyOrdersIsAlreadyTracked(payload);
  }, []);

  const submit = values => {
    const { carrier, tracking_code } = values;
    const payload = {
      carrier: carrier.value,
      tracking_code: tracking_code,
      deal: id,
    };

    trackMyOrders(payload);
  };

  return (
    <main className="order-tracking">
      {isCheckIfMyOrdersIsAlreadyTracked ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : (
        <React.Fragment>
          <div className="deals-detail__header">
            <Media className="deals-detail__header__media">
              <Media className="deals-detail__header__media__image" left>
                {!_.isEmpty(photos) && photos[0] && (
                  <img key={photos[0]._id} src={photos[0].cdn_link} />
                )}
              </Media>
              <Media className="deals-detail__header__media__body body" body>
                <Media className="body__heading" heading>
                  {name ? truncate(name, 50) : "---"}
                </Media>
              </Media>
            </Media>
            <div className="deals-detail__header__chat">
              <p
                onClick={() =>
                  push({
                    search: `?chat=true`,
                    state: {
                      dealId: _id,
                    },
                  })
                }
                className="deals-detail__header__chat__cta"
              >
                <img
                  className="chat-icon"
                  src="/images/svg/icons/chat.svg"
                  alt="chat icon"
                />
                Chat Room
              </p>
            </div>
          </div>
          {isEmpty(tracking_info) ? (
            <div className="order-tracking__input-group">
              <ShipmentTrackingForm onSubmit={submit} />
            </div>
          ) : null}
          <div className="order-tracking__map">
            <TrackingMap />
          </div>
          {isEmpty(tracking_info) ? (
            <React.Fragment>
              <p className="d-flex justify-content-center p-5">
                <em> You have no tracking on this order</em>
              </p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="order-details">
                <ul>
                  <li>
                    <p className="label">tracking number</p>
                    <p>{tracking_code}</p>
                  </li>
                  <li>
                    <p className="label">carrier</p>
                    <p>{carrier}</p>
                  </li>
                  <li>
                    <p className="label">Status</p>
                    <p>{status}</p>
                  </li>
                  <li>
                    <p className="label">Estimated Delivery date</p>
                    <p>{dateFormat(est_delivery_date)}</p>
                  </li>
                  <li>
                    <p className="label">shipped on</p>
                    <p>{origin_location}</p>
                  </li>
                  <li>
                    <p className="label">destination</p>
                    <p>{destination_location}</p>
                  </li>
                </ul>

                <Row className="tracking-detail">
                  <Col md={4} sm={4}>
                    <p className="label">tracking details</p>
                  </Col>
                  <Col md={8} sm={4}>
                    {tracking_details &&
                      tracking_details.map((tracking_detail, index) => (
                        <div key={index}>
                          <p className="more-detail">
                            <span>
                              {moment(tracking_detail.datetime).format(
                                "D MMM YYYY  hh: mm"
                              )}
                            </span>
                            {""}
                            {tracking_detail.message}
                          </p>
                        </div>
                      ))}
                  </Col>
                </Row>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </main>
  );
};

ShipmentTracking.propTypes = propTypes;
ShipmentTracking.defaultProps = defaultProps;

const mapStateToProps = state => ({
  tracking_info:
    state.orders.myTrackedOrder.track || state.orders.isTracked.track,
  location: state.router.location,
  isCheckIfMyOrdersIsAlreadyTracked:
    state.ui.loading.checkIfMyOrdersIsAlreadyTracked,
});

const mapDispatchToProps = {
  trackMyOrders,
  checkIfMyOrdersIsAlreadyTracked,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentTracking);

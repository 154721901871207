import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import qs from "query-string";
import { useHistory } from "react-router-dom";
import { fetchTenderOffers } from "../../../../../../redux/actions";
import ProductCard from "../../../../_shared/components/ProductCard";
import ProgressBar from "../../../../../../_shared/components/ProgressBar";

const OfferedTender = props => {
  const history = useHistory();
  const { tab } = qs.parse(history.location.search);
  const { offersList, fetchTenderOffers, isFetchingTenderOffers } = props;
  const { data } = offersList;

  useEffect(() => {
    const params = {
      offset: 0,
      count: 10,
    };

    fetchTenderOffers(params);
  }, []);

  const handleRedirectView = (e, id) => {
    history.push({
      search: `tab=${tab}&tenderOffer=${id}`,
    });
  };

  return (
    <Fragment>
      {isFetchingTenderOffers ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : isEmpty(data) ? (
        <div className="d-flex justify-content-center p-5">
          <img src="/images/svg/icons/no-data.svg" alt="No data icon" />
        </div>
      ) : (
        <div className="product__list">
          {data.map(item => (
            <ProductCard
              key={item._id}
              order={{ ...item.order, _id: item._id }}
              // setProductId={setProductId}
              action={handleRedirectView}
              // showMoreBtn={true}
            />
          ))}
        </div>
      )}
    </Fragment>
  );
};

OfferedTender.propTypes = {};

const mapStateToProps = state => ({
  offersList: state.tender.offersList,
  isFetchingTenderOffers: state.ui.loading.fetchTenderOffers,
});

const mapDispatchToProps = {
  fetchTenderOffers,
};

export default connect(mapStateToProps, mapDispatchToProps)(OfferedTender);

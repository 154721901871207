import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import CustomButton from "../../../../../_shared/components/Button";
import TrackingValFunc from "./TrackingValFunc";
import {
  TextInputField,
  SelectInputField,
} from "../../../../../_shared/components/ReduxFormFields/index";
import { carrier } from "../../../../../_shared/defs/carriers";

const propTypes = {
  isLoading: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  submit: PropTypes.func,
};

const defaultProps = {
  isLoading: false,
};

const ShipmentTrackingForm = ({
  handleSubmit,
  submitting,
  invalid,
  pristine,
  isLoading,
  submit,
}) => {
  const carrierDropdown = () => {
    return carrier.map(({ name, code }) => ({
      label: name,
      value: code,
    }));
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Row>
        <Col md={4}>
          <Field
            name="carrier"
            label="carrier"
            id="carrier"
            options={carrierDropdown()}
            component={SelectInputField}
            placeholder="Carrier"
            isSearchable={true}
          />
        </Col>
        <Col md={5}>
          <Field
            name="tracking_code"
            label="Tracking code"
            id="Tracking code"
            placeholder="Tracking code"
            component={TextInputField}
            type="text"
          />
        </Col>
        <Col md={2}>
          <CustomButton
            name="search"
            type="submit"
            loading={isLoading}
            disabled={submitting || pristine || invalid || isLoading}
          />
        </Col>
      </Row>
    </form>
  );
};

ShipmentTrackingForm.propTypes = propTypes;
ShipmentTrackingForm.defaultProps = defaultProps;

const mapStateToProps = state => ({
  isLoading: state.ui.loading.trackMyOrders,
});

const formName = "ShipmentTrackingForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  validate: TrackingValFunc,
})(ShipmentTrackingForm);

export default connect(mapStateToProps, null)(connectedForm);

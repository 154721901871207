import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import { inviteLogisticPartner } from "../../../../redux/actions"
import InviteLogisticForm from "../../_shared/Forms/InviteLogisticForm"
import "./LogisticInvite.scss"

const propTypes = {
  location: PropTypes.object,
  inviteLogisticPartner: PropTypes.func,
};

const LogisticInvite = ({ location, inviteLogisticPartner }) => {
  const { state } = location;
  const [email, setEmail] = useState("");

  const [selected, setSelected] = useState("");
  const handleSelect = (email, selected) => {
    setEmail(email);
    setSelected(selected);
  };
  const link = "https://www.easypost.com/";

  const handleSubmit = values => {
    const payload = {
      email: values.email,
      deal: state && state.dealId,
    };
    inviteLogisticPartner(payload);
  };

  const formatInitialValues = {
    email,
  };

  const opnDeliveryEmail = process.env.OPN_DELIVERY_EMAIL;

  return (
    <main className="logistic-invite">
      <Container>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <div className="logistic-invite__inner">
              <p className="logistic-invite__caption">
                Select <strong>POLIMEX DELIVERY</strong> - <br /> trust us to handle the
                shipping.
              </p>
              <div className="logistic-invite__tag-wrap">
                <div
                  className={`${
                    selected === "opn" ? "selected" : ""
                  } logistic-invite__tag-wrap__item`}
                  onClick={() => handleSelect(`${opnDeliveryEmail}`, "opn")}
                >
                  <img src="/images/logo_opn.png" alt="opn" />
                </div>
              </div>
              <div className="divider-both">OR</div>
              <p className="logistic-invite__caption">
                Select other OPN logistic partners prepared special <br /> conditions
                for you
              </p>
              <div className="logistic-invite__tag-wrap">
                
                <div
                  className={`${
                    selected === "evg" ? "selected" : ""
                  } logistic-invite__tag-wrap__item`}
                  onClick={() =>
                    handleSelect("Evgeni.Mitev@saloodo.com", "evg")
                  }
                >
                  <img src="/images/partners/saloodo.png" alt="saloodo" />
                </div>
                <div
                  className={`${
                    selected === "ship" ? "selected" : ""
                  } logistic-invite__tag-wrap__item `}
                  onClick={() => handleSelect("ship@freightos.com", "ship")}
                >
                  <img src="/images/partners/freightos.png" alt="freightos" />
                </div>

                <div
                  className={`${
                    selected === "easy" ? "selected" : ""
                  } logistic-invite__tag-wrap__item `}
                  onClick={() => handleSelect("", "easy")}
                >
                  <a href={link} target="_blank">
                    <img src="/images/partners/easy-post.png" alt="easy-post" />
                  </a>
                </div>
              </div>
              <div className="divider-both">OR</div>
              <div
                className="logistic-invite__email-wrap"
                onChange={() => handleSelect("", "")}
              >
                <p>Send cargo details to your logistic provider</p>

                <InviteLogisticForm
                  onSubmit={handleSubmit}
                  initialValues={formatInitialValues}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

LogisticInvite.propTypes = propTypes;

const mapStateToProps = state => ({
  location: state.router.location,
});

const mapDispatchToProps = {
  inviteLogisticPartner,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogisticInvite);

import "bootstrap/dist/css/bootstrap.css"
import { ConnectedRouter } from "connected-react-router"
import React from "react"
import * as ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { LastLocationProvider } from "react-router-last-location"
import "react-tabs/style/react-tabs.css"
// import io from "socket.io-client"
import App from "./App"
// import { connectSocketClient} "./redux/actions"
import { fetchClientId } from "./redux/actions"
import store, { history } from "./redux/store"
import "./styles/index.scss"


const { auth } = store.getState();
console.log("auth :", auth);

if (!auth.clientId) store.dispatch(fetchClientId());

// const isDev =
//   /dev.opnplatform/.test(window.location.host) ||
//   /localhost/.test(window.location.host);
// const baseUrl = isDev ? process.env.ENDPOINT_URL_DEV : process.env.ENDPOINT_URL;
// const access_token = (auth.accessToken || {}).token;

// const socket = io(baseUrl, {
//   path: "/opn_socket",
//   transports: ["polling"],
// });

// if (socket) {
//   store.dispatch(connectSocketClient(socket));
// }

// socket.emit("start", { access_token });

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <LastLocationProvider>
        <App />
      </LastLocationProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

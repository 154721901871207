import {
  createActionType,
  createActionString,
} from "../../../_shared/functions/utils";

export const CREATE_ORDER = createActionType("CREATE_ORDER", "orders");
export const FETCH_ORDERS = createActionType("FETCH_ORDERS", "orders");
export const FETCH_ORDER = createActionType("FETCH_ORDER", "orders");
export const FETCH_MY_ORDERS = createActionType("FETCH_MY_ORDERS", "orders");
export const SEARCH_ORDERS = createActionType("SEARCH_ORDERS", "orders");
export const SEARCH_MY_ORDERS = createActionString(
  "SEARCH_MY_ORDERS",
  "orders"
);
export const SEARCH_ALL_ORDERS = createActionString(
  "SEARCH_ALL_ORDERS",
  "orders"
);

export const UPLOAD_MEDIA = createActionType("UPLOAD_MEDIA", "upload");
export const TRACK_MY_ORDERS = createActionType("TRACK_MY_ORDERS", "orders");
export const CHECK_IF_MY_ORDERS_IS_ALREADY_TRACKED = createActionType(
  "CHECK_IF_MY_ORDERS_IS_ALREADY_TRACKED",
  "orders"
);

export const DRAFT_MY_ORDER = createActionType("DRAFT_MY_ORDER", "orders");
export const PUBLISH_MY__DRAFT_ORDER = createActionType(
  "PUBLISH_MY__DRAFT_ORDER",
  "orders"
);
export const FETCH_MY_DRAFT_ORDERS = createActionType(
  "FETCH_MY_DRAFT_ORDERS",
  "orders"
);

export const DELETE_MY_DRAFT_ORDER = createActionType(
  "DELETE_MY_DRAFT_ORDER",
  "orders"
);

export const UPDATE_MY_DRAFT_ORDER = createActionType(
  "UPDATE_MY_DRAFT_ORDER",
  "orders"
);

export const createOrder = (payload, nextStep) => ({
  type: CREATE_ORDER.START,
  meta: { payload, nextStep },
});

export const fetchOrders = (payload, key) => ({
  type: FETCH_ORDERS.START,
  meta: { payload, key },
});

export const fetchOrder = productId => ({
  type: FETCH_ORDER.START,
  productId,
});

export const fetchMyOrders = (payload, key) => ({
  type: FETCH_MY_ORDERS.START,
  meta: { payload, key },
});

export const searchOrders = payload => ({
  type: SEARCH_ORDERS.START,
  payload,
});

export const uploadMedia = (payload, nextAction, key) => ({
  type: UPLOAD_MEDIA.START,
  meta: { payload, nextAction, key },
});

export const trackMyOrders = payload => ({
  type: TRACK_MY_ORDERS.START,
  payload,
});

export const checkIfMyOrdersIsAlreadyTracked = payload => ({
  type: CHECK_IF_MY_ORDERS_IS_ALREADY_TRACKED.START,
  payload,
});

export const draftMyOrder = productId => ({
  type: DRAFT_MY_ORDER.START,
  productId,
});

export const publishMyDraftOrder = productId => ({
  type: PUBLISH_MY__DRAFT_ORDER.START,
  productId,
});

export const fetchMyDraftOrders = payload => ({
  type: FETCH_MY_DRAFT_ORDERS.START,
  payload,
});

export const deleteMyDraftOrder = (productId, purpose) => ({
  type: DELETE_MY_DRAFT_ORDER.START,
  meta: { productId, purpose },
});

export const updateMyDraftOrder = (payload, productId) => ({
  type: UPDATE_MY_DRAFT_ORDER.START,
  meta: { payload, productId },
});

import React from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import { FormGroup, Col } from "reactstrap";
import { isEmpty } from "lodash";
import {
  SelectInputField,
  TextInputField,
} from "../../../../../../../_shared/components/ReduxFormFields";
import {
  countries,
  deliveryMethods,
  units,
} from "../../../../../../../_shared/defs";
import {
  formatCategory,
  formatProduct,
} from "../../../../../../../_shared/functions/utils";
import "./AddProductForm.scss";

const propTypes = {
  category: PropTypes.object,
  categories: PropTypes.array,
  purpose: PropTypes.string,
  change: PropTypes.func,
};

const AddProductForm = ({ category, categories, purpose, change }) => {
  const countryDropdown = () => {
    return countries.map(({ name, code }) => ({ label: name, value: code }));
  };

  const variantDropdown = () => {
    return deliveryMethods.map(({ name, code }) => ({
      label: name,
      value: code,
    }));
  };

  const unitsDropdown = () => {
    return units.map(({ name, code }) => ({
      label: name,
      value: code,
    }));
  };

  const termsDropdown = () => [
    {
      label: "immediately",
      value: "immediately",
    },
    {
      label: "30 days",
      value: "30days",
    },
    {
      label: "60 days",
      value: "60days",
    },
    {
      label: "90 days",
      value: "90days",
    },
  ];

  const currencyDropdown = () => [
    {
      label: "USD",
      value: "USD",
    },
  ];

  const handleCategoryChange = () => {
    change("product", "");
  };

  return (
    <Col md={{ size: 10, offset: 1 }}>
      <FormGroup>
        <Field
          name="category"
          label="Category"
          id="category"
          options={formatCategory(categories)}
          onChange={handleCategoryChange}
          component={SelectInputField}
          placeholder="Category"
        />
      </FormGroup>
      <FormGroup>
        <Field
          name="product"
          label="Product Name"
          id="product"
          disabled={isEmpty(category)}
          options={formatProduct(
            categories,
            category && category.label.props.children
          )}
          component={SelectInputField}
          isSearchable={true}
          placeholder="Product Name"
        />
      </FormGroup>
      <FormGroup>
        <Field
          name="description"
          label="Description"
          id="description"
          type="textarea"
          className="textarea"
          component={TextInputField}
          placeholder="Description"
        />
      </FormGroup>
      <FormGroup>
        <Field
          name="location"
          label="Location"
          id="location"
          options={countryDropdown()}
          className="textarea"
          component={SelectInputField}
          isSearchable={true}
          placeholder="Location"
        />
      </FormGroup>
      {purpose === "BUY" && (
        <FormGroup>
          <Field
            name="destination"
            label="Destination"
            id="destination"
            type="text"
            component={TextInputField}
            placeholder="Destination"
          />
        </FormGroup>
      )}
      <FormGroup>
        <Field
          name="variant"
          label="Delivery Variant"
          id="variant"
          options={variantDropdown()}
          className="textarea"
          component={SelectInputField}
          isSearchable={true}
          placeholder="Delivery Variant"
        />
      </FormGroup>
      <FormGroup>
        <Field
          name="term"
          label="Delivery Terms"
          id="term"
          options={termsDropdown()}
          className="textarea"
          component={SelectInputField}
          isSearchable={true}
          placeholder="Delivery Terms"
        />
      </FormGroup>
      {purpose === "BUY" && (
        <FormGroup>
          <Field
            name="quantity"
            label="Quantity"
            id="quantity"
            type="number"
            component={TextInputField}
            placeholder="Quantity"
          />
        </FormGroup>
      )}
      <div className="price-unit">
        <FormGroup className="price-form-control">
          <Field
            name="currency"
            label="currency"
            id="currency"
            disabled
            options={currencyDropdown()}
            component={SelectInputField}
            placeholder="currency"
            isClearable={false}
          />
          <Field
            name="price"
            label="Price"
            id="price"
            type="text"
            className="amount"
            component={TextInputField}
            placeholder="Price"
          />
        </FormGroup>
        <FormGroup className="unit">
          <span>Per</span>
          <Field
            name="unit"
            label="unit"
            id="unit"
            options={unitsDropdown()}
            component={SelectInputField}
            placeholder="unit"
            isClearable={false}
          />
        </FormGroup>
      </div>
    </Col>
  );
};

AddProductForm.propTypes = propTypes;

export default AddProductForm;

import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import ProgressBar from "../../../../../../_shared/components/ProgressBar";
import { fetchPurchasingOrder } from "../../../../../../redux/actions";
import "./PurchasingOrder.scss";

const propTypes = {
  isFetchingPurchasingOrder: PropTypes.bool,
  purchasingList: PropTypes.object,
  fetchPurchasingOrder: PropTypes.func,
};

const defaultProps = {
  isFetchingPurchasingOrder: true,
};

const PurchasingOrder = ({
  fetchPurchasingOrder,
  isFetchingPurchasingOrder,
  purchasingList,
}) => {
  const { data } = purchasingList;
  console.log("purchasingList :", data);
  useEffect(() => {
    const params = {
      offset: 0,
      count: 10,
    };

    fetchPurchasingOrder(params);
  }, []);

  const renderTitle = () => (
    <Row className="p-order__inner__title-container align-items-center">
      <Col sm={2} md={2}>
        <p>Date</p>
      </Col>
      <Col sm={2} md={2}>
        <p>Code</p>
      </Col>
      <Col sm={6} md={6}>
        <p>Product name</p>
      </Col>
      <Col sm={1} md={1} />
    </Row>
  );

  return (
    <main className="p-order">
      {isFetchingPurchasingOrder ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : isEmpty(data) ? (
        <React.Fragment>
          <div className="no-data d-flex justify-content-center p-5">
            <img src="/images/svg/icons/no-data.svg" alt="No data icon" />
          </div>
        </React.Fragment>
      ) : (
        <div className="p-order__inner">
          {renderTitle()}
          {data &&
            data.map((offer, index) => (
              <div key={index} className="p-order__inner__item">
                <Row>
                  <Col md={2} sm={2} className="over-right">
                    <p>{moment(offer.created_at).format("DD/MM/YYYY")}</p>
                  </Col>
                  <Col md={2} sm={2}>
                    <p>{offer.order.tender.code}</p>
                  </Col>
                  <Col md={7} sm={7}>
                    <p>{offer.order.name}</p>
                  </Col>
                  <Col md={1} sm={1} className="text-right">
                    <i className="fas fa-ellipsis-v" />
                  </Col>
                </Row>
              </div>
            ))}
        </div>
      )}
    </main>
  );
};

PurchasingOrder.propTypes = propTypes;
PurchasingOrder.defaultProps = defaultProps;

const mapStateToProps = state => ({
  purchasingList: state.tender.purchasingList,
  isFetchingPurchasingOrder: state.ui.loading.fetchPurchasingOrder,
});

export default connect(mapStateToProps, { fetchPurchasingOrder })(
  PurchasingOrder
);

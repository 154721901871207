import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Container, Col, Row } from "reactstrap";
import "./Register.scss";
import {
  RegisterProgress,
  CompanyGoal,
  ChooseProduct,
  AddProduct,
  Success,
} from "../_shared/forms/Onboarding";
import SignUp from "./SignUp";
import {
  fetchCategories,
  incrementStep,
  decrementStep,
  createOrder,
  toggleInitializingOnboarding,
  navigateTo,
  toggleFromRegister,
} from "../../../redux/actions";
import { stringify } from "../../../_shared/functions/utils";
import authService from "../../../services/auth";

const propTypes = {
  stepCount: PropTypes.number,
  isCreatingOrder: PropTypes.bool,
  decrementStep: PropTypes.func,
  fetchCategories: PropTypes.func,
  incrementStep: PropTypes.func,
  createOrder: PropTypes.func,
  navigateTo: PropTypes.func,
  toggleInitializingOnboarding: PropTypes.func,
  categoryList: PropTypes.array,
  initializeOnboarding: PropTypes.bool,
};

const Register = props => {
  const isLoggedIn = authService.isLoggedIn();
  const {
    fetchCategories,
    stepCount,
    incrementStep,
    decrementStep,
    categoryList,
    createOrder,
    isCreatingOrder,
    toggleInitializingOnboarding,
    navigateTo,
    toggleFromRegister,
    initializeOnboarding,
  } = props;
  const [purpose, setPurpose] = useState("");
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedCategory, setSelectedCategory] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCategories();
    toggleFromRegister();

    if (isLoggedIn) {
      if (!initializeOnboarding) navigateTo(`/market`);
    }

    const selectedCategoryStroage = localStorage.getItem("selectedCategory");
    if (selectedCategoryStroage)
      setSelectedCategory(JSON.parse(selectedCategoryStroage));

    const purposeStroage = localStorage.getItem("purpose");
    if (purposeStroage) setPurpose(purposeStroage);
    const selectedProductStroage = localStorage.getItem("selectedProduct");

    if (selectedProductStroage)
      setSelectedProduct(JSON.parse(selectedProductStroage));

    return () => {
      toggleFromRegister();

      if (initializeOnboarding) {
        toggleInitializingOnboarding();
        localStorage.removeItem("purpose");

        localStorage.removeItem("selectedCategory");

        localStorage.removeItem("selectedProduct");
      }
    };
  }, [initializeOnboarding]);

  const handleSelectProduct = children => {
    for (let index = 0; index < categoryList.length; index++) {
      const eachCategory = categoryList[index];

      if (children.parent === eachCategory._id) {
        setSelectedCategory(eachCategory);
        localStorage.setItem("selectedCategory", JSON.stringify(eachCategory));
      }
    }

    setSelectedProduct(children);
    localStorage.setItem("selectedProduct", JSON.stringify(children));
  };

  const handleAddNewProduct = categoryId => {
    const selected = categoryList.filter(
      category => categoryId === category._id
    );


    setSelectedCategory(selected[0]);
    localStorage.setItem("selectedCategory", JSON.stringify(selected[0]));
    incrementStep();
  };

  const handleSelectPurpose = purpose => {
    setPurpose(purpose);
    localStorage.setItem("purpose", purpose);
  };

  const formatInitialValues = {
    category: {
      value: !isEmpty(selectedCategory) ? selectedCategory._id : "",
      label: <p>{!isEmpty(selectedCategory) ? selectedCategory.name : ""}</p>,
    },
    product: {
      value: !isEmpty(selectedCategory) && selectedProduct.name,
      label: <p>{!isEmpty(selectedProduct) && selectedProduct.name}</p>,
    },
    currency: { label: "USD", value: "USD" },
  };

  const handleSubmit = values => {
    const {
      category,
      product,
      price,
      images = [],
      docs = [],
      description,
      term,
      variant,
      location,
      currency,
      destination,
      quantity,
      unit,
    } = values;

    const imagesId = images.map(image => image._id);
    const docsId = docs.map(doc => doc._id);

    const payload = {
      title: stringify(product.value),
      price: Number(price),
      photos: imagesId,
      documents: docsId,
      purpose: purpose || "SELL",
      description,
      product: category.value,
      id: category.value,
      delivery: { term: term.value, variant: variant.value },
      country: location.value,
      currency: currency.value,
      destination,
      quantity: Number(quantity),
      amount: {
        number: 1,
        unit: unit.value,
      },
      status:"DRAFT"
    };

    createOrder(payload, incrementStep);
  };

  const renderContent = step => {
    switch (step) {
      case 1:
        return (
          <Col md={{ size: 4, offset: 4 }}>
            <h5 className="login-form__title text-center">Let's go!</h5>
            <SignUp nextStep={incrementStep} />
          </Col>
        );
      case 2:
        return (
          <Col md={{ size: 8, offset: 2 }}>
            <CompanyGoal
              nextStep={incrementStep}
              setPurpose={handleSelectPurpose}
              purpose={purpose}
            />
          </Col>
        );
      case 3:
        return (
          <Col md={{ size: 8, offset: 2 }}>
            <ChooseProduct
              nextStep={incrementStep}
              previousStep={decrementStep}
              categories={categoryList}
              purpose={purpose}
              handleSelectProduct={handleSelectProduct}
              handleAddNewProduct={handleAddNewProduct}
              selectedProduct={selectedProduct._id || ""}
            />
          </Col>
        );
      case 4:
        return (
          <Col md={{ size: 6, offset: 3 }}>
            <AddProduct
              purpose={purpose}
              nextStep={incrementStep}
              previousStep={decrementStep}
              formLoading={isCreatingOrder}
              categories={categoryList}
              onSubmit={handleSubmit}
              initialValues={formatInitialValues}
            />
          </Col>
        );
      case 5:
        return (
          <Col md={{ size: 10, offset: 1 }}>
            <Success />
          </Col>
        );

      default:
        break;
    }
  };

  return (
    <main className="register">
      <Container>
        <Row>
          {stepCount !== 1 && (
            <Col md="12">
              <div className="progress-logo">
                <img className="logo" src="/images/logo_opn.svg" />
              </div>
            </Col>
          )}
          {stepCount !== 5 && (
            <Col md={{ size: 8, offset: 2 }}>
              <RegisterProgress stepCount={stepCount} />
            </Col>
          )}
        </Row>
        <Row>{renderContent(stepCount)}</Row>
      </Container>
    </main>
  );
};

Register.propTypes = propTypes;

const mapStateToProps = state => ({
  stepCount: state.ui.step,
  categoryList: state.categories.list,
  isCreatingOrder: state.ui.loading.createOrder,
  initializeOnboarding: state.ui.initializeOnboarding,
});

const mapDispatchToProps = {
  fetchCategories,
  incrementStep,
  decrementStep,
  createOrder,
  toggleInitializingOnboarding,
  navigateTo,
  toggleFromRegister,
};

export default connect(mapStateToProps, mapDispatchToProps)(Register);

import { createActionType } from "../../../_shared/functions/utils";

export const FETCH_ALL_SUBSCRIPTION = createActionType(
  "FETCH_ALL_SUBSCRIPTION",
  "office"
);

export const FETCH_INTEGRATION_KEY = createActionType(
  "FETCH_INTEGRATION_KEY",
  "office"
);

export const UPLOAD_MY_DOCUMENT = createActionType(
  "UPLOAD_MY_DOCUMENT",
  "office"
);

export const FETCH_MY_DOCUMENTS = createActionType(
  "FETCH_MY_DOCUMENTS",
  "office"
);

export const DELETE_MY_DOCUMENT = createActionType(
  "DELETE_MY_DOCUMENT",
  "office"
);

export const FETCH_ECO_IMPACT_STATS = createActionType(
  "FETCH_ECO_IMPACT_STATS",
  "office"
);

export const FETCH_ANALYTICS = createActionType("FETCH_ANALYTICS", "office");

export const fetchAllSubscription = (payload) => ({
  type: FETCH_ALL_SUBSCRIPTION.START,
  payload,
});

export const fetchIntegrationKey = () => ({
  type: FETCH_INTEGRATION_KEY.START,
});

export const uploadMydocument = (payload, nextAction) => ({
  type: UPLOAD_MY_DOCUMENT.START,
  meta: { payload, nextAction },
});

export const fetchMydocuments = (params) => ({
  type: FETCH_MY_DOCUMENTS.START,
  params,
});

export const deleteMydocument = (payload, nextAction) => ({
  type: DELETE_MY_DOCUMENT.START,
  meta: { payload, nextAction },
});

export const fetchEcoImpactStats = () => ({
  type: FETCH_ECO_IMPACT_STATS.START,
});

export const fetchAnalytics = () => ({
  type: FETCH_ANALYTICS.START,
});

export const OurValuesData = [
  {
    img: "icon1",
    heading: "We put our users first",
    text:
      "We work hard to deliver a great experience for our customers and earn their loyalty.",
  },
  {
    img: "icon2",
    heading: "We move fast",
    text: "We deliver by focusing on speed without compromising on quality.",
  },
  {
    img: "icon3",
    heading: "We work together",
    text: "Every success is a team success and we celebrate every win together",
  },
  {
    img: "icon4",
    heading: "We are honest and transparent",
    text:
      "We communicate clearly and openly and connect with others in a genuine way",
  },
  {
    img: "icon5",
    heading: "We learn by doing",
    text:
      "We challenge ourselves every day and never shy away from experimenting. ",
  },
  {
    img: "icon6",
    heading: "We are independent",
    text:
      "There is no corporate-backing or other market control pulling our strings. We’re 100% tech for good!",
  },
];

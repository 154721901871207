import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { change, Field, getFormValues, reduxForm, reset } from "redux-form";
import _, { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import qs from "query-string";
import Classnames from "classnames";
import { connect } from "react-redux";
import ReactCountryFlag from "react-country-flag";
import { getName } from "country-list";
import {
  Media,
  Container,
  Row,
  Col,
  Alert,
  Modal,
  ModalBody,
} from "reactstrap";
import DealsForm from "../../_shared/Forms/DealsForm";
import CustomButton from "../../../../_shared/components/Button";
import {
  acceptDeal,
  preAcceptDeal,
  updateDeal,
  uiToggleModal,
  cancelDeal,
  updateDealPrice,
  fetchTenderBid,
  acceptTenderBid,
  rejectTenderBid,
  createInventory,
} from "../../../../redux/actions";
import ProgressBar from "../../../../_shared/components/ProgressBar";
import { TextInputField } from "../../../../_shared/components/ReduxFormFields/index";
import {
  truncate,
  priceFormat,
  dateFormat,
} from "../../../../_shared/functions/utils";
import "./DealsDetails.scss";

const propTypes = {
  updateDeal: PropTypes.func,
  uiToggleModal: PropTypes.func,
  preAcceptDeal: PropTypes.func,
  acceptDeal: PropTypes.func,
  cancelDeal: PropTypes.func,
  initialize: PropTypes.func,
  fetchTenderBid: PropTypes.func,
  updateDealPrice: PropTypes.func,
  rejectTenderBid: PropTypes.func,
  createInventory: PropTypes.func,
  acceptTenderBid: PropTypes.func,
  isFetchingDeal: PropTypes.bool,
  status: PropTypes.string,
  buyer: PropTypes.bool,
  isEdit: PropTypes.bool,
  deal: PropTypes.object,
  fields: PropTypes.object,
  tenderBid: PropTypes.object,
  from: PropTypes.object,
};

const defaultProps = {};

const DealsDetails = (props) => {
  const { push, location } = useHistory();
  const { tenderOffer } = qs.parse(location.search);
  const {
    updateDeal,
    uiToggleModal,
    isEdit,
    isFetchingDeal,
    deal,
    preAcceptDeal,
    cancelDeal,
    acceptDeal,
    updateDealPrice,
    initialize,
    fields,
    fetchTenderBid,
    tenderBid,
    acceptTenderBid,
    rejectTenderBid,
    createInventory,
  } = props;

  const order = tenderOffer ? tenderBid : deal;

  const {
    _id,
    product,
    accepted_by_seller,
    accepted_by_buyer,
    quantity,
    destination,
    due_date,
    buyer,
    isBuyer,
    isSeller,
    paid,
    created_at,
    amount,
    totalamount,
    status,
    originOffer,
    price,
    type,
    finalizestate,
    location: dest,
    from,
  } = order;
  const { company, name, country, delivery, photos, tender } =
    product || tenderBid.order || {};
  const { profile } = from || {};
  const isAcceptBtnClickable = (isSeller || isBuyer) && !isEdit;
  const isShowConfirmBtn = accepted_by_buyer && accepted_by_seller;
  const isMakePaymentBtn = status === "ACCEPTED";
  const isConfirmed = status === "PENDING";
  const isClosed = status === "CLOSED";
  const isCanceled = status === "CANCELLED";
  const isOfferOrigin = type === "BID" ? false : isEmpty(originOffer);
  const [isEditPrice, setIsEditPrice] = useState(false);
  const [showInventoryDialog, setShowInventoryDialog] = useState(isClosed);
  const [inventoryValue, setInventoryValue] = useState("");

  useEffect(() => {
    if (tenderOffer) fetchTenderBid(tenderOffer);
  }, []);

  useEffect(() => {
    if (isEdit) uiToggleModal();
    setIsEditPrice(false);

    setShowInventoryDialog(isClosed && isBuyer && !finalizestate);

    initialize({
      price: price / 100,
    });
  }, [order]);

  const handleSubmit = (values) => {
    const payload = {
      id: _id,
      ...values,
      quantity: Number(values.quantity),
    };

    updateDeal(payload);
  };

  const handleChangePrice = () => {
    const payload = {
      id: _id,
      price: Number(fields.price),
    };

    updateDealPrice(payload, setIsEditPrice);
  };

  const handleCancel = () => {
    initialize({
      price: price / 100,
    });

    setIsEditPrice(false);
  };

  const formatInitial = () => ({
    quantity,
    destination,
    due_date,
    price: originOffer && originOffer.price / 100,
  });

  const handleAcceptDeal = () => {
    acceptDeal({ id: _id });
  };

  const handlePreAcceptDeal = () => {
    if (type === "BID")
      return acceptTenderBid({ tender_id: tender, offer_id: tenderOffer });

    preAcceptDeal({ id: _id });
  };

  const handleCancelDeal = () => {
    if (type === "BID")
      return rejectTenderBid({ tender_id: tender, offer_id: tenderOffer });

    cancelDeal({ id: _id });
  };

  const redirectToCheckout = () => {
    if (type === "BID") {
      push(`/deals/checkout/${tenderOffer}?bid=/`);
    } else {
      push(`/deals/checkout/${_id}`);
    }
  };

  let warningMessage = "";

  if (isMakePaymentBtn && isSeller && !paid) {
    warningMessage = "You are waiting buyer to make payment";

    if (isConfirmed) {
      warningMessage = "";
    }
  } else if (isShowConfirmBtn && isConfirmed) {
    if (!isOfferOrigin && isSeller) {
      warningMessage = "You are waiting the buyer to confirm the deal.";
    } else if (isOfferOrigin && isBuyer) {
      warningMessage = "You are waiting the seller to confirm the deal.";
    }
  } else if (!accepted_by_buyer && accepted_by_seller && isSeller) {
    warningMessage = "You are waiting the buyer to accept the deal.";
  } else if (!accepted_by_seller && isBuyer && type !== "BID") {
    warningMessage = "You are waiting the seller to accept the deal.";
  } else if (type === "BID" && isSeller) {
    warningMessage = "You are waiting the buyer to accept the offer.";
  }

  const handleVirtualInventoryModal = () => {
    setInventoryValue("");
    setShowInventoryDialog((prevState) => !prevState);
  };

  const handleVirtualOnChange = (event) => {
    setInventoryValue(event.target.value);
  };

  const handleInventoryConfirmation = () => {
    if (inventoryValue === "virtual inventory") {
      const payload = { deal: _id };
      createInventory(payload, handleVirtualInventoryModal);
    }

    if (inventoryValue === "shipment") {
      handleVirtualInventoryModal();
      push({
        pathname: `/logistic-invite`,
        state: {
          dealId: _id,
        },
      });
    }
  };

  const renderVirtualInventoryModal = () => {
    return (
      <Modal
        isOpen={showInventoryDialog}
        toggle={handleVirtualInventoryModal}
        className="virtual-dialog"
      >
        <ModalBody>
          <div className="dialog__inner">
            <i className="fas fa-times" onClick={handleVirtualInventoryModal} />
            <h4 className="sm-title">
              Put your product on virtual inventory or <br /> ship it
              immediately
            </h4>

            <div className="virtual-cta">
              <label
                htmlFor="inventory"
                className={Classnames("virtual-btn", {
                  "virtual-btn__active": inventoryValue === "virtual inventory",
                })}
              >
                <input
                  className="d-none"
                  type="radio"
                  id="inventory"
                  name="virtual"
                  value="virtual inventory"
                  onChange={handleVirtualOnChange}
                />
                virtual inventory
              </label>
              <label
                htmlFor="shipment"
                className={Classnames("virtual-btn", {
                  "virtual-btn__active": inventoryValue === "shipment",
                })}
              >
                <input
                  className="d-none"
                  type="radio"
                  id="shipment"
                  name="virtual"
                  value="shipment"
                  onChange={handleVirtualOnChange}
                />
                shipment
              </label>
            </div>

            <div>
              <CustomButton
                action={handleVirtualInventoryModal}
                name="cancel"
                btnType="no-border"
              />
              <CustomButton
                action={handleInventoryConfirmation}
                name="Confirm"
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <Fragment>
      {isFetchingDeal ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : isEmpty(deal) && isEmpty(tenderBid) ? (
        <div className="no-deal">
          <p>
            <em>No deal to display</em>
          </p>
        </div>
      ) : (
        <div className="deals-detail">
          {renderVirtualInventoryModal()}
          <Container>
            <Row>
              <Col>
                <div className="deals-detail__header">
                  <Media className="deals-detail__header__media">
                    <Media className="deals-detail__header__media__image" left>
                      {!_.isEmpty(photos) && photos[0] && (
                        <img key={photos[0]._id} src={photos[0].cdn_link} />
                      )}
                    </Media>
                    <Media
                      className="deals-detail__header__media__body body"
                      body
                    >
                      <Media className="body__heading" heading>
                        {name ? truncate(name, 50) : "---"}
                      </Media>
                      <p className="body__content">
                        {company && company.verified && (
                          <img
                            className="body__content__verified"
                            src="/images/svg/icons/verify.svg"
                            alt="chat icon"
                          />
                        )}
                        <span className="body__content__company">
                          {company && company.profile && company.profile.name
                            ? company.profile.name
                            : "---"}
                        </span>
                        <span className="body__content__rating">
                          <i className="fas fa-star deals-sidebar__notification__card__status--active" />
                          <i className="fas fa-star deals-sidebar__notification__card__status--active" />
                          <i className="fas fa-star deals-sidebar__notification__card__status--active" />
                          <i className="fas fa-star deals-sidebar__notification__card__status--active" />
                          <i className="fas fa-star deals-sidebar__notification__card__status--active" />
                        </span>
                      </p>
                    </Media>
                  </Media>
                  <div className="deals-detail__header__chat">
                    <p
                      onClick={() =>
                        push({
                          search: `?chat=true`,
                          state: {
                            dealId: tenderOffer ? tenderOffer : _id,
                          },
                        })
                      }
                      className="deals-detail__header__chat__cta"
                    >
                      <img
                        className="chat-icon"
                        src="/images/svg/icons/chat.svg"
                        alt="chat icon"
                      />
                      Chat Room
                    </p>
                  </div>
                </div>
                <div className="product-details">
                  <ul>
                    {tenderOffer && (
                      <li>
                        <p className="label">Offered by</p>
                        <p className="ordered">
                          {from && from.verified && (
                            <img
                              className="body__content__verified"
                              src="/images/svg/icons/verify.svg"
                              alt="chat icon"
                            />
                          )}
                          <span className="body__content__company">
                            {profile && profile.name ? profile.name : "---"}
                          </span>
                        </p>
                      </li>
                    )}
                    <li>
                      <p className="label">Price Per Unit</p>
                      <div className="edit-price">
                        {!isEditPrice ? (
                          <p className="price">
                            {price ? priceFormat(price) : "---"}
                          </p>
                        ) : (
                          <Field
                            name="price"
                            label="Price"
                            type="number"
                            id="price"
                            component={TextInputField}
                            placeholder="Price"
                          />
                        )}

                        {isEditPrice ? (
                          <Fragment>
                            <i
                              className="fas fa-check"
                              onClick={handleChangePrice}
                            />
                            <i
                              className="fas fa-times"
                              onClick={handleCancel}
                            />
                          </Fragment>
                        ) : (
                          isConfirmed &&
                          isSeller && (
                            <i
                              className="fas fa-pencil-alt"
                              onClick={() => setIsEditPrice(true)}
                            />
                          )
                        )}
                      </div>
                    </li>

                    <li>
                      <p className="label">Total price</p>
                      {country && <p>{amount ? priceFormat(amount) : "---"}</p>}
                    </li>

                    <li>
                      <p className="label">Location</p>
                      {country && (
                        <p>
                          <ReactCountryFlag
                            countryCode={country}
                            className="product-card__body__country__flag"
                            svg
                          />
                          <span className="product-card__body__country__name">
                            {getName(country)}
                          </span>
                        </p>
                      )}
                    </li>
                    <li>
                      <p className="label">Delivery variant</p>
                      <p>
                        {delivery && delivery.variant
                          ? delivery.variant
                          : "---"}
                      </p>
                    </li>
                    {isOfferOrigin && (
                      <Fragment>
                        <li>
                          <p className="label">Delivery terms (days)</p>
                          <p>
                            {delivery && delivery.term ? delivery.term : "---"}
                          </p>
                        </li>
                        <li>
                          <p className="label">Estimated time of arrival</p>
                          <p>{dateFormat(created_at)}</p>
                        </li>
                      </Fragment>
                    )}
                    <li>
                      <p className="label">Status</p>
                      <div className="status">
                        <p
                          className={Classnames("pending", {
                            ["closed"]: isClosed,
                            ["accepted"]:
                              accepted_by_seller ||
                              (type === "BID" && isMakePaymentBtn),
                            ["canceled"]: isCanceled,
                          })}
                        >
                          {isClosed
                            ? "CLOSED"
                            : accepted_by_seller ||
                              (type === "BID" && isMakePaymentBtn)
                            ? "ACCEPTED"
                            : isCanceled
                            ? "CANCELLED"
                            : "PENDING"}
                        </p>
                      </div>
                    </li>

                    {/* <li>
                      <p className="label">ready for shipment</p>
                      {country && <p>{amount ? priceFormat(amount) : "---"}</p>}
                    </li> */}
                  </ul>
                </div>
              </Col>
            </Row>

            <hr className="mt-5" />

            {warningMessage.length !== 0 && (
              <Row>
                <Col>
                  <Alert color="warning">
                    <img src="/images/svg/icons/warning.svg" />
                    {warningMessage}
                  </Alert>
                </Col>
              </Row>
            )}

            <DealsForm
              setIsEdit={uiToggleModal}
              isBuyer={isBuyer}
              isEdit={isEdit}
              isClosed={isClosed}
              isConfirmed={isConfirmed}
              onSubmit={handleSubmit}
              initialValues={formatInitial()}
              acceptedByBuyer={accepted_by_buyer}
              acceptedBySeller={accepted_by_seller}
              company={buyer || company}
              amount={priceFormat(amount || totalamount)}
              price={priceFormat(price)}
              isOfferOrigin={isOfferOrigin}
              type={type === "BID"}
              location={dest}
              isCanceled={isCanceled}
            />

            {(isMakePaymentBtn || isClosed) && (
              <div className="logistic">
                <CustomButton
                  imgSrc="/images/svg/icons/logistics.png"
                  action={() =>
                    push({
                      pathname: `/logistic-invite`,
                      state: {
                        dealId: _id,
                      },
                    })
                  }
                  name="Invite Logistics"
                  btnType="no-border"
                />

                <CustomButton
                  action={() =>
                    push({
                      search: `?orderTracking=true`,
                      state: {
                        dealId: _id,
                      },
                    })
                  }
                  name="order Tracking"
                  btnType="no-border"
                />
              </div>
            )}

            {!isCanceled && (
              <Row>
                <Col md="12" className="deals-detail__cta">
                  {((!isShowConfirmBtn && !isMakePaymentBtn) || paid) && (
                    <CustomButton
                      action={handleCancelDeal}
                      disabled={type === "BID" ? false : !isAcceptBtnClickable}
                      name={`${paid ? "Check invoice" : "Reject"}`}
                      btnType="no-border"
                    />
                  )}
                  {warningMessage.length === 0 &&
                    (!isMakePaymentBtn && !paid ? (
                      <CustomButton
                        imgSrc={
                          isShowConfirmBtn
                            ? "/images/svg/icons/double-check-mark.svg"
                            : "/images/svg/icons/check-mark.svg"
                        }
                        action={
                          isShowConfirmBtn
                            ? handleAcceptDeal
                            : handlePreAcceptDeal
                        }
                        disabled={
                          type === "BID"
                            ? false
                            : !isAcceptBtnClickable ||
                              (isBuyer && !accepted_by_seller)
                        }
                        name={isShowConfirmBtn ? "Confirm Deal" : "Accept"}
                      />
                    ) : (
                      !paid &&
                      isBuyer && (
                        <CustomButton
                          action={redirectToCheckout}
                          name="Checkout"
                        />
                      )
                    ))}
                </Col>
              </Row>
            )}
          </Container>
        </div>
      )}
    </Fragment>
  );
};

DealsDetails.propTypes = propTypes;
DealsDetails.defaultProps = defaultProps;

const formName = "dealsPrice";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  // validate: dealValidationFunc,
})(DealsDetails);

const mapStateToProps = (state) => ({
  isFetchingDeal: state.ui.loading.fetchDeal || state.ui.loading.fetchTenderBid,
  isEdit: state.ui.modal.isOpen,
  fields: getFormValues(formName)(state),
  tenderBid: state.deals.current,
});

const mapDispatchToProps = {
  updateDeal,
  uiToggleModal,
  preAcceptDeal,
  acceptDeal,
  cancelDeal,
  change,
  reset,
  updateDealPrice,
  fetchTenderBid,
  acceptTenderBid,
  rejectTenderBid,
  createInventory,
};

export default connect(mapStateToProps, mapDispatchToProps)(connectedForm);

import {
  FETCH_USE_CASE,
  FETCH_USE_CASES,
  CLEAR_USE_CASE_STATE,
} from "../../actions";

const defaultState = {
  list: [],
  current: {},
};

const useCaseReducer = (state = defaultState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_USE_CASE.SUCCESS:
      return Object.assign({}, state, { current: payload });
    case FETCH_USE_CASES.SUCCESS:
      return Object.assign({}, state, { list: payload.data });
    case CLEAR_USE_CASE_STATE.SUCCESS:
      return Object.assign({}, state, defaultState);
    default:
      return state;
  }
};

export default useCaseReducer;

import {
  FETCH_ARTICLE,
  FETCH_ARTICLES,
  FETCH_RELATED_ARTICLES,
  apiRequest,
  GET,
  POST,
  LOGIN,
  navigateTo,
} from "../../actions";
import { API } from "../../../_shared/defs";

const fetchArticle = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_ARTICLE.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.BLOG}/article/${payload}`,
        key: "fetchArticle",
        onSuccess: FETCH_ARTICLE.SUCCESS,
      })
    );
  }
};

const fetchArticles = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_ARTICLES.START) {
    const { params } = action;
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.BLOG}/articles/all`,
        key: "fetchArticles",
        onSuccess: FETCH_ARTICLES.SUCCESS,
        params,
      })
    );
  }
};

const fetchRelatedArticles = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_RELATED_ARTICLES.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.BLOG}/articles/related?tags=${payload}`,
        key: "fetchRelatedArticles",
        onSuccess: FETCH_RELATED_ARTICLES.SUCCESS,
      })
    );
  }
};

export default [fetchArticle, fetchArticles, fetchRelatedArticles];

export const PATHS = {
  MAIN: "/",
  LOGIN: "/login",
  LOGOUT: "/logout",
  REGISTER: "/register",
  REFERRAL: "/referral",
  ADD_ON: "/add-on",
  // OPERATOR: "/operator",
  CASE_STUDIES: "/case-studies",
  CASE_STUDIES_VIEW: "/case-studies/:slug",
  EXPO: "/i-pack-expo",
  PAYMENT_OPTION: "/payment-option",
  RESET_PASSWORD: "/reset-password",
  CHANGE_PASSWORD: "/reset_password/:id",
  COMPANY_PROFILE: "/company_profile",
  SETTINGS: "/settings",
  NOTIFICATIONS: "/notifications",
  ACCOUNT_SETTINGS: "/account_settings",
  CO_WORKER_INVITED: "/invites/:id",
  RECOVERY_PASSWORD: "/recovery_password",
  TARIFF_PLANS: "/tariff_plans",
  TARIFF_PLANS_PROFILE: "/tariff_plans_profile",
  MAIN_PROFILE: "/main_profile",
  MAKE_ORDER: "/make_order",
  CREATE_ORDER: "/create_order/:id",
  CONFIRM_ORDER: "/confirm_order",
  ORDERS_HISTORY: "/orders_history",
  ORDER_PAYMENT: "/payment_order/:id",
  HOME: "/home",
  STRIPE: "/transaction",
  STRIPE_RESPONSE: "/stripe-response",
  CRYPTO: "/crypto",
  // PARTNERS: "/partners",
  ABOUT_US: "/about",
  BLOG: "/blog",
  BLOG_VIEW: "/blog/:slug",
  PRICING: "/pricing",
  PRICING_CHECKOUT: "/pricing/checkout",
  COMPANY: "/company",
  PRODUCTS: "/products",
  PRODUCT: "/product",
  ALL_CATEGORIES: "/categories",
  CATEGORY: "/category/:id",
  CATEGORY_PRODUCT: "/category-product/:id",
  WORKSPACE: "/workspace",
  LOGISTIC: "/logistic",
  CONTACT_US: "/contacts",
  SITEMAP: "/sitemap",
  ADVERTISEMENT: "/advertisement",
  FAQ: "/faq",
  MAGIC: "/magic",
  NEWS: "/news",
  SEARCH: "/search",
  REGISTER_COMPANY: "/register_company",
  MARKET: "/market",
  MARKET_VIEW: `/market/:productName/:productId/view`,
  MARKET_CREATE: "/market/create",
  PRE_ORDER: "/market/:productId/pre-order",
  DEALS: "/deals",
  DEAL_VIEW: "/deals/:dealId",
  DEAL_CHECKOUT: "/deals/checkout/:dealId",
  LOGISTIC_INVITE: "/logistic-invite",
  ACCOUNT: "/my-account",
  OFFICE: "/my-office",
  EDIT_DRAFT_ORDER: "/office/draft/:slug",
  INVITE: "/logistic/accept/:id",
  EMAIL_CONFIRMED: "/user/confirm_email/:id",
  ROBOTS: "/robots.txt",
  SITE_MAP: "/sitemap.xml",

  // technical works
  UNDER_DEVELOPMENT: "/under_development",
};

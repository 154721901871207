import React from "react";
import PropTypes from "prop-types";
import { Col, Row, Table } from "reactstrap";
import "./FeaturesTable.scss";

const FeaturesTable = props => {
  return (
    <Row className="table-section">
      <Col sm="12" lg="12">
        <Table striped className="pricing-table">
          <thead>
            <tr>
              <th />
              <th>Enterprise</th>
              <th>Business</th>
              <th>Basic</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Orders and/or Requests/mo</td>
              <td>Unlimited </td>
              <td>15</td>
              <td>1</td>
            </tr>
            <tr>
              <td>Company Verification</td>
              <td>
                <i className="fas fa-check" />
              </td>
              <td>
                <i className="fas fa-check" />
              </td>
              <td>
                <i className="fas fa-times" />
              </td>
            </tr>
            <tr>
              <td>Document storage, GB</td>
              <td>Unlimited </td>
              <td>5</td>
              <td>1</td>
            </tr>
            <tr>
              <td>Highlight Product in the Market</td>
              <td>
                <i className="fas fa-check" />{" "}
              </td>
              <td>
                <i className="fas fa-times" />
              </td>
              <td>
                <i className="fas fa-times" />
              </td>
            </tr>
            <tr>
              <td>Display Company Logo in the Market</td>
              <td>
                <i className="fas fa-check" />{" "}
              </td>
              <td>
                <i className="fas fa-times" />
              </td>
              <td>
                <i className="fas fa-times" />
              </td>
            </tr>
            <tr>
              <td>Premium Product Listing</td>
              <td>
                <i className="fas fa-check" />{" "}
              </td>
              <td>
                <i className="fas fa-times" />
              </td>
              <td>
                <i className="fas fa-times" />
              </td>
            </tr>
            <tr>
              <td>
                ERP API Calls Free/mo{" "}
                <span className="text-danger font-weight-bold">*</span>
              </td>
              <td>10000 </td>
              <td>1000</td>
              <td>
                <i className="fas fa-times" />
              </td>
            </tr>
            <tr>
              <td>
                Market Analytic Reports/mo{" "}
                <span className="text-danger font-weight-bold">*</span>
              </td>
              <td>Unlimited </td>
              <td>2</td>
              <td>
                <i className="fas fa-times" />
              </td>
            </tr>
            {/* <tr>
              <td>Automation Tools access</td>
              <td>Unlimited</td>
              <td>1</td>
              <td>
                <i className="fas fa-times" />
              </td>
            </tr> */}
            <tr>
              <td>Mention in Recommended Companies</td>
              <td>
                <i className="fas fa-check" />
              </td>
              <td>
                <i className="fas fa-times" />
              </td>
              <td>
                <i className="fas fa-times" />
              </td>
            </tr>
            <tr>
              <td>Display Logo in Partners Ecosystem </td>
              <td>
                <i className="fas fa-check" />
              </td>
              <td>
                <i className="fas fa-times" />
              </td>
              <td>
                <i className="fas fa-times" />
              </td>
            </tr>
            <tr>
              <td>Tech Support</td>
              <td>Online Chat & Email</td>
              <td>Email </td>
              <td>Email</td>
            </tr>
            <tr>
              <td>Users </td>
              <td>Unlimited </td>
              <td>1-5</td>
              <td>1</td>
            </tr>
            <tr>
              <td>
                Trending Feeds{" "}
                <span className="text-danger font-weight-bold">*</span>
              </td>
              <td>Unlimited </td>
              <td>5</td>
              <td>
                <i className="fas fa-times" />
              </td>
            </tr>
            <tr>
              <td>
                Price Alerts{" "}
                <span className="text-danger font-weight-bold">*</span>
              </td>
              <td>Unlimited </td>
              <td>5</td>
              <td>
                <i className="fas fa-times" />
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

FeaturesTable.propTypes = {};

export default FeaturesTable;

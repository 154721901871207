import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Collapse } from "reactstrap";
import { connect } from "react-redux";
import { change, Field, getFormValues, reduxForm } from "redux-form";
import { searchOrders } from "../../../../../../redux/actions";
import "./Filter.scss";
import { CheckBoxField } from "../../../../../../_shared/components/ReduxFormFields";
import { useHistory } from "react-router-dom";
//import { useEffect } from "react/cjs/react.production.min";

const propTypes = {
  categories: PropTypes.array,
  countries: PropTypes.array,
};

const Filter = props => {
  const {
    categories,
    countries,
    change,
    filters,
    setFilters,
    productList,
    purpose,
    searchOrders,
  } = props;

  const [isOpenCategories, setIsOpenCategories] = useState(true);
  const [isOpenCountries, setIsOpenCountries] = useState(true);
  const [categoryChecked, setCategoryChecked] = useState([]);
  const [countryChecked, setCountryChecked] = useState([]);
  const toggleCategories = () => setIsOpenCategories(!isOpenCategories);
  const toggleCountries = () => setIsOpenCountries(!isOpenCountries);
  const history = useHistory();

  const handleCheckBoxOnChangeCategory = category => {
    const currentIndex = categoryChecked.indexOf(category._id);
    const newCategoryChecked = [...categoryChecked];

    if (currentIndex === -1) {
      newCategoryChecked.push(category._id);
      change(`item_${category._id}`, true);
    } else {
      newCategoryChecked.splice(currentIndex, 1);
      change(`item_${category._id}`, false);
    }

    const payload = {
      count: 20,
      offset: 0,
      purpose,
      productList: productList || "all",
      filter: newCategoryChecked.length > 0 ? newCategoryChecked : null,
      country: countryChecked.length > 0 ? 
        countryChecked.reduce((accumulator, currentValue) => accumulator + "|" + currentValue)
        : 
        null,
    };

    searchOrders(payload);
    
    history.push({
      search: 
        `?countries=${countryChecked}&filters=${newCategoryChecked}`,
    });

    setCategoryChecked(newCategoryChecked);
    selectFilters(category._id, category.name);
  };

  const handleCheckBoxOnChangeCountry = country => {
    const currentIndex = countryChecked.indexOf(country.code);
    const newCountryChecked = [...countryChecked];
    
    if (currentIndex === -1) {
      newCountryChecked.push(country.code);
      change(`item_${country.code}`, true);
    } else {
      newCountryChecked.splice(currentIndex, 1);
      change(`item_${country.code}`, false);
    }
    
    //console.log('qs', qs.parse(history.location.search));

    setCountryChecked(newCountryChecked);
    console.log("newCountryChecked", newCountryChecked)

    const payload = {
      count: 20,
      offset: 0,
      purpose,
      productList: productList || "all",
      filter: categoryChecked.length > 0 ? categoryChecked : null,
      country: newCountryChecked.length > 0 ? newCountryChecked.reduce((accumulator, currentValue) => accumulator + "|" + currentValue) : null,
    };
    
    searchOrders(payload);

    history.push({
      search: 
        `?countries=${newCountryChecked}&filters=${categoryChecked}
        `,
    });
    //selectFilters(category._id, category.name);
  };

  const selectFilters = (id, name) => {
    // add first selected item from filters
    if (filters.length <= 0) {
      return setFilters([{ id, name }]);
    }

    // filter item from exist array
    const remainingFilters = filters.filter(item => item.id !== id);

    // add new item to exist array
    if (remainingFilters.length === filters.length) {
      const newFilters = [...filters, { id, name }];
      setFilters(newFilters);
    } else {
      setFilters(remainingFilters);
    }
  };

  const renderFilters = (categories, countries) => {   
    return (
      <div className="filter-dropdown">
        <h4 className="filter-dropdown__title">
          <span>Country</span>
          <i
            className={`fas fa-angle-${isOpenCountries ? "up" : "down"}`}
            onClick={toggleCountries}
          />
        </h4>
        {countries.map((country, i) => (
          <Collapse isOpen={isOpenCountries} key={i}>
            <Field
              name={`item_${country.name}`}
              onChange={() => handleCheckBoxOnChangeCountry(country)}
              label={country.name}
              type="checkbox"
              color="blue"
              key={country.code}
              id={country.code}
              className="filter-dropdown__item"
              component={CheckBoxField}
            />
          </Collapse>
        ))}

        <h4 className="filter-dropdown__title">
          <span>Category</span>
          <i
            className={`fas fa-angle-${isOpenCategories ? "up" : "down"}`}
            onClick={toggleCategories}
          />
        </h4>
        {categories.map((category, i) => (
          <Collapse isOpen={isOpenCategories} key={i}>
            <Field
              name={`item_${category._id}`}
              onChange={() => handleCheckBoxOnChangeCategory(category)}
              label={category.name.toLowerCase()}
              type="checkbox"
              color="blue"
              key={category._id}
              id={category._id}
              className="filter-dropdown__item"
              component={CheckBoxField}
            />
          </Collapse>
        ))}
      </div>
    );
  };
  return (
    <div className="search-filter">
      {categories && countries && renderFilters(categories, countries)}
    </div>
  );
};

Filter.propTypes = propTypes;

const formName = "filterForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  // @ts-ignore
  // validate: validateFunc,
})(Filter);

// export default connectedForm;
export default connect(
  state => ({
    formData: getFormValues(formName)(state),
  }),
  {
    searchOrders,
    change,
  }
)(connectedForm);

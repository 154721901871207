import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reset } from "redux-form";
import Classnames from "classnames";
import { InputGroup } from "reactstrap";

import "./CompanyField.scss";

const CompanyField = props => {
  const { label, onclick, reset, formType, ...rest  } = props;
  const [isEditable, setIsEditable] = useState(false);

  const handleOnClick = () => {
    onclick();
    setIsEditable(false);
    reset(formType);
  };



  const handleOnClose = () => {
    setIsEditable(false);
    reset(formType);
  };

  return (
    <div className={Classnames("company-field", { active: isEditable })}>
      <div className="company-field__label">{label}</div>
      <InputGroup className="company-field__input">
        <Field
          {...rest}
          disabled={!isEditable}

        />
        {!isEditable && (
          <i className="fas fa-pen" onClick={() => setIsEditable(true)} />
        )}
      </InputGroup>
      {isEditable && (
        <div className="company-field__cta">
          <i className="fas fa-check icons" onClick={handleOnClick} />
          <i className="fas fa-times icons" onClick={handleOnClose} />
        </div>
      )}
    </div>
  );
};

CompanyField.propTypes = {};

const mapStateToProps = state => ({
  location: state.router.location,
});

const mapDispatchToProps = { reset };

export default connect(mapStateToProps, mapDispatchToProps)(CompanyField);

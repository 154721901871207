import {
  FETCH_ARTICLE,
  FETCH_ARTICLES,
  FETCH_RELATED_ARTICLES,
  CLEAR_BLOG_STATE,
} from "../../actions";

const defaultState = {
  list: [],
  current: {},
  relatedList: [],
};

const blogReducer = (state = defaultState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_ARTICLE.SUCCESS:
      return Object.assign({}, state, { current: payload });
    case FETCH_ARTICLES.SUCCESS:
      return Object.assign({}, state, { list: payload.data });
    case FETCH_RELATED_ARTICLES.SUCCESS:
      return Object.assign({}, state, { relatedList: payload });
    case CLEAR_BLOG_STATE.SUCCESS:
      return Object.assign({}, state, defaultState);
    default:
      return state;
  }
};

export default blogReducer;

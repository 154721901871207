import {
  FETCH_CLIENT_ID,
  SUBSCRIPTION_STATUS,
  UPDATE_USER,
  apiRequest,
  GET,
  POST,
  PUT,
  REGISTER,
  LOGIN,
  SOCIAL,
  LOGOUT,
  navigateTo,
  RESET_PASSWORD,
  CHANGE_PASSWORD,
  toggleResetPassword,
  uiToggleShowSignupModal,
  toggleInitializingOnboarding,
  fetchCompanyInfo,
  confirmCompanyInfo,
} from "../actions";
import { API, PATHS } from "../../_shared/defs";
import connectServices from "../../services/connectServices";
import authService from "../../services/auth";

const disallowRoute = ["/login", "/register"];
let referrer = localStorage.getItem("locationReferrer");
if (!referrer) referrer = "/market";

const fetchClientId = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_CLIENT_ID.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.CLIENT}/id`,
        key: "fetchClientId",
        onSuccess: FETCH_CLIENT_ID.SUCCESS,
        requestType: "public",
        noSuccessToast: true,
        ...action.meta,
      })
    );
  }
};

const register = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === REGISTER.START) {
    const { payload, nextStep } = action.meta;
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.AUTH}`,
        key: "register",
        onSuccess: () => {
          dispatch({
            type: LOGIN.START,
            meta: { payload, nextStep },
          });
        },
        requestType: "public",
        payload,
      })
    );
  }
};

const social = ({ dispatch, getState }) => next => action => {
  const { router } = getState();
  next(action);
  if (action.type === SOCIAL.START) {
    const { payload, nextStep, key } = action.meta;
    let apiUrl;

    if (key === "googleAuth") apiUrl = `${API.AUTH}/oauth/callback`;
    if (key === "lookup") apiUrl = `${API.AUTH}/lookup`;
    dispatch(
      apiRequest({
        method: POST,
        url: apiUrl,
        key,
        onSuccess: data => {
          dispatch({
            type: SOCIAL.SUCCESS,
            payload: data,
          });

          connectServices(data);

          if (nextStep) {
            dispatch(toggleInitializingOnboarding());
            nextStep();
            if (key === "lookup") {
              dispatch(navigateTo("/register"));
              return;
            }
            return;
          }

          dispatch(fetchCompanyInfo());
          localStorage.removeItem("locationReferrer");

          if (disallowRoute.includes(router.location.pathname))
            return dispatch(navigateTo(`${referrer}`));

          dispatch(uiToggleShowSignupModal());
        },
        requestType: "public",
        payload,
      })
    );
  }
};

const login = ({ dispatch, getState }) => next => action => {
  const { router } = getState();
  next(action);
  if (action.type === LOGIN.START) {
    const { payload, nextStep } = action.meta;
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.AUTH}/login`,
        key: "login",
        successMessage: "Login successful",
        onSuccess: data => {
          dispatch({
            type: LOGIN.SUCCESS,
            payload: data,
          });

          connectServices(data);
          if (nextStep) {
            dispatch(toggleInitializingOnboarding());
            nextStep();
            return;
          }

          dispatch(fetchCompanyInfo());
          localStorage.removeItem("locationReferrer");

          if (disallowRoute.includes(router.location.pathname))
            return dispatch(navigateTo(`${referrer}`));

          dispatch(uiToggleShowSignupModal());
          dispatch(confirmCompanyInfo());
        },
        requestType: "public",
        payload,
      })
    );
  }
};

const fetchSubscriptionStatus = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === SUBSCRIPTION_STATUS.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.AUTH}/subscription_status`,
        key: "fetchSubscriptionStatus",
        onSuccess: SUBSCRIPTION_STATUS.SUCCESS,
        headerParams: false,
      })
    );
  }
};

const resetPassword = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === RESET_PASSWORD.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.AUTH}/reset_password`,
        key: "resetPassword",
        onSuccess: () => {
          dispatch(toggleResetPassword(true));
        },
        headerParams: false,
        payload,
        requestType: "public",
      })
    );
  }
};

const changePassword = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === CHANGE_PASSWORD.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.AUTH}/reset_password/${payload.id}`,
        key: "changePassword",
        onSuccess: () => {
          dispatch(navigateTo("/login"));
        },
        successMessage: "Password reset successful",
        headerParams: false,
        payload,
        requestType: "public",
      })
    );
  }
};

const updateUser = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === UPDATE_USER.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: PUT,
        url: `${API.AUTH}`,
        key: "updateUser",
        onSuccess: () => {
          dispatch(fetchCompanyInfo());
        },
        successMessage: "User profile updated successful",
        payload,
      })
    );
  }
};

const logout = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === LOGOUT.START) {
    authService.logoutUser();
    dispatch({ type: LOGOUT.SUCCESS });
  }
  if (action.type === LOGOUT.SUCCESS) {
    dispatch(navigateTo(PATHS.LOGIN));

    setTimeout(() => {
      localStorage.removeItem("locationReferrer");
      localStorage.removeItem("opn:persist");
    }, 2000);
  }
};

export default [
  social,
  resetPassword,
  changePassword,
  updateUser,
  fetchClientId,
  register,
  login,
  logout,
  fetchSubscriptionStatus,
];

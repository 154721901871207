export default data => {
  if (window.location.host === "opnplatform.com") {
    analytics.identify(data._id, {
      name: data.name,
      email: data.mail.id,
    });
  }

  window.Intercom("boot", {
    app_id: "ulueqf5y",
    name: data.name, // Full name
    email: data.mail.id, // Email address
    created_at: data.created_at, // Signup date as a Unix timestamp
  });
};

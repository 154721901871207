import React from "react";
import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";
import "./Pagination.scss";

const propTypes = {
  purpose: PropTypes.string,
  fetchData: PropTypes.func,
  pagination: PropTypes.object,
};

const Pagination = props => {
  const { fetchData, pagination, purpose } = props;
  const { totalpage, offset, count } = pagination || {};
  const currentPage = Math.ceil(offset / count - 1);
  const renderIcon = favSrc => <i className={`fas ${favSrc}`} />;

  const handlePageClick = e => {
    const selectedPage = e.selected;
    const payload = {
      count: 20,
      offset: count * selectedPage,
      purpose,
    };

    fetchData(payload, "fetchMoreOrder");
  };

  return (
    <ReactPaginate
      previousLabel={renderIcon("fa-chevron-left")}
      nextLabel={renderIcon("fa-chevron-right")}
      pageCount={totalpage}
      pageRangeDisplayed={2}
      marginPagesDisplayed={1}
      forcePage={currentPage}
      onPageChange={handlePageClick}
      containerClassName="pagination"
      subContainerClassName={"pagination__inner"}
    />
  );
};

Pagination.propTypes = propTypes;

export default Pagination;

import {
  createActionType,
  createActionString,
} from "../../../_shared/functions/utils";

export const FETCH_STATISTICS = createActionType(
  "FETCH_STATISTICS",
  "category"
);
export const SOCKET_STATISTICS = createActionString(
  "FETCH_STATISTICS",
  "category"
);

export const fetchStatistics = () => ({
  type: FETCH_STATISTICS.START,
});
export const fetchSocketStatistics = payload => ({
  type: SOCKET_STATISTICS,
  payload,
});

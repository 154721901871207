import { createActionType } from "../../../_shared/functions/utils";

export const FETCH_ARTICLE = createActionType("FETCH_ARTICLE", "blog");
export const FETCH_ARTICLES = createActionType("FETCH_ARTICLES", "blog");
export const FETCH_RELATED_ARTICLES = createActionType(
  "FETCH_RELATED_ARTICLES",
  "blog"
);
export const CLEAR_BLOG_STATE = createActionType(
  "CLEAR_BLOG_STATE",
  "blog"
);

export const fetchArticle = payload => ({
  type: FETCH_ARTICLE.START,
  payload,
});

export const fetchArticles = params => ({
  type: FETCH_ARTICLES.START,
  params,
});

export const fetchRelatedArticles = payload => ({
  type: FETCH_RELATED_ARTICLES.START,
  payload,
});

export const clearBlogState = payload => ({
  type: CLEAR_BLOG_STATE.START,
  payload,
});

import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { withLastLocation } from "react-router-last-location";
import { formatMessagesFromError } from "../../../_shared/functions/utils";
import Toaster from "../../../_shared/components/Toaster";
import authService from "../../../services/auth";
import routes from "../../../routes";

const propTypes = {
  component: PropTypes.func,
  isPrivate: PropTypes.bool,
  lastLocation: PropTypes.object,
  location: PropTypes.object,
  isMandatorySubscription: PropTypes.bool
};

const CustomRoute = ({
  component: Component,
  isPrivate,
  lastLocation,
  isMandatorySubscription,
  ...rest
}) => {
  const isLoggedIn = authService.isLoggedIn();
  const lastPath = lastLocation && lastLocation.pathname;
  const hasValidSubscription = authService.getStripeSubscriptionId()
  console.log('STRIIIPE', hasValidSubscription)

  const noRefRoutes = routes
    .filter(route => route.noRef)
    .map(route => route.path);

  return (
    <Route
      {...rest}
      render={props => {
        if (!isLoggedIn && lastPath && !noRefRoutes.includes(lastPath)) {
          localStorage.setItem("locationReferrer", lastPath);
        }

        if (isPrivate && !isLoggedIn) {
          Toaster.dismiss();
          Toaster.error(
            formatMessagesFromError("You need to login to continue")
          );

          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location.pathname },
              }}
            />
          );
        }

        if (isMandatorySubscription && !hasValidSubscription) {
          Toaster.dismiss();
          Toaster.error(
            formatMessagesFromError(
              "In order to continue you need to activate your plan"
            )
          );

          return (
            <Redirect
              to={{
                pathname: "/pricing",
                state: { from: props.location.pathname },
              }}
            />
          );
        }
        
          return <Component {...props} />;
      }}
    />
  );
};

CustomRoute.propTypes = propTypes;

export default withLastLocation(CustomRoute);

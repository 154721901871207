import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Row, Col } from "reactstrap";
import { reduxForm, reset, Field } from "redux-form";
import { Link } from "react-router-dom";
import {
  TextInputField,
  SelectInputField,
} from "../../../../_shared/components/ReduxFormFields";
import CustomButton from "../../../../_shared/components/Button";
import validateFunc from "../../../Authentication/_shared/forms/SignUpForm/registerValidation";
import {
  inviteCoWorker,
  fetchAllInvitedCoWorker,
  uiToggleModal,
  deleteCoWorkerInvite,
  changeInvitedCoWorkerAccess,
  fetchCompanyInfo,
  deleteCoWorker,
} from "../../../../redux/actions";
import ProgressBar from "../../../../_shared/components/ProgressBar";
import DeleteDialog from "../../../../_shared/components/partials/DeleteDialog";
import CoWorker from "./CoWorker";
import "./InviteCoWorkers.scss";

const propTypes = {
  isLoading: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  isInvitingCoWorker: PropTypes.func,
  fetchAllInvitedCoWorker: PropTypes.func,
  uiToggleModal: PropTypes.func,
  isFetchAllInvitedCoWorker: PropTypes.bool,
  deleteCoWorkerInvite: PropTypes.func,
  all_co_worker_invite: PropTypes.arrayOf(PropTypes.object),
  changeInvitedCoWorkerAccess: PropTypes.func,
  fetchCompanyInfo: PropTypes.func,
};

const defaultProps = {
  isLoading: false,
};

const PointsOfContact = props => {
  const {
    handleSubmit,
    isLoading,
    submitting,
    pristine,
    invalid,
    inviteCoWorker,
    reset,
    fetchAllInvitedCoWorker,
    uiToggleModal,
    all_co_worker_invite,
    isFetchAllInvitedCoWorker,
    deleteCoWorkerInvite,
    changeInvitedCoWorkerAccess,
    user_info,
    fetchCompanyInfo,
    deleteCoWorker,
  } = props;
  const { employees } = user_info || {};

  const [id, seCoWorkertId] = useState("");
  const [isCoWorker, seIsCoWorker] = useState(false);

  useEffect(() => {
    fetchAllInvitedCoWorker();
  }, []);

  const termsDropdown = () => [
    {
      label: "Primary contact",
      value: "Primary contact",
    },
    {
      label: "Restricted access",
      value: "Restricted",
    },
    {
      label: "Full access",
      value: "Full",
    },
  ];

  const onFormSubmit = values => {
    const payload = {
      mail: values.email,
      permission: values.access.value,
    };
    inviteCoWorker(payload, reset, fetchAllInvitedCoWorker);
  };

  const callToAction = () => {
    if (isCoWorker) {
      deleteCoWorker(id, fetchCompanyInfo);
      return seIsCoWorker(false);
    }
    return deleteCoWorkerInvite(id, fetchAllInvitedCoWorker);
  };

  const handleDeleteInvite = (e, id) => {
    e.stopPropagation();
    uiToggleModal();
    seCoWorkertId(id);
  };

  return (
    <main className="points-of-contacts">
      <p className="points-of-contacts__info">
        Depending on your permission settings, you can assign users to
        designated roles. Different roles will receive messaging and updates
        from OPN related to usage, billing and renewals.
      </p>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Row>
          <Col md={5}>
            <Field
              name="email"
              label="Email"
              id="email"
              type="email"
              component={TextInputField}
              placeholder="Business email address"
            />
          </Col>
          <Col md={5}>
            <Field
              name="access"
              label="access"
              id="access"
              options={termsDropdown()}
              placeholder="restricted access"
              component={SelectInputField}
              isSearchable={true}
              type="text"
            />
          </Col>
          <Col md={2}>
            <CustomButton
              name="invite"
              type="submit"
              classes="regular"
              loading={isLoading}
              disabled={submitting || pristine || invalid}
            />
          </Col>
        </Row>
      </form>
      <hr />
      <h3 className="points-of-contacts__text">Co-workers</h3>
      <React.Fragment>
        {isFetchAllInvitedCoWorker ? (
          <div className="d-flex justify-content-center p-5">
            <ProgressBar />
          </div>
        ) : isEmpty(all_co_worker_invite && employees) ? (
          <div className="d-flex justify-content-center p-5">
            <em>You don't have any co-worker invited yet</em>
          </div>
        ) : (
          <React.Fragment>
            {employees.map(employee => (
              <CoWorker
                key={employee._id}
                uiToggleModal={uiToggleModal}
                employee={employee}
                seCoWorkertId={seCoWorkertId}
                changeInvitedCoWorkerAccess={changeInvitedCoWorkerAccess}
                fetchCompanyInfo={fetchCompanyInfo}
                seIsCoWorker={seIsCoWorker}
              />
            ))}
            {all_co_worker_invite.map(worker => (
              <div className="points-of-contacts__employee" key={worker._id}>
                <div>
                  <p className="name">{worker.mail}</p>
                  <p className="position">{worker.company_permission}</p>
                  <p className="position">pending</p>
                </div>{" "}
                <i
                  className="fas fa-trash"
                  onClick={e => handleDeleteInvite(e, worker._id)}
                />
              </div>
            ))}
          </React.Fragment>
        )}
        <DeleteDialog
          title="Delete Co-worker"
          actionName="Delete"
          message="Are you sure you want to remove this worker from your co-worker "
          action={() => callToAction()}
        />
      </React.Fragment>
    </main>
  );
};

PointsOfContact.propTypes = propTypes;
PointsOfContact.defaultProps = defaultProps;

const formName = "PointsOfContact";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  validate: validateFunc,
})(PointsOfContact);

export default connect(
  state => ({
    isLoading: state.ui.loading.inviteCoWorker,
    all_co_worker_invite: state.settings.list,
    isFetchAllInvitedCoWorker: state.ui.loading.fetchAllInvitedCoWorker,
    user_info: state.settings.companyInfo.userInfo,
  }),
  {
    uiToggleModal,
    reset,
    inviteCoWorker,
    fetchAllInvitedCoWorker,
    deleteCoWorkerInvite,
    changeInvitedCoWorkerAccess,
    fetchCompanyInfo,
    deleteCoWorker,
  }
)(connectedForm);

import React from "react";
import HubSpotForm from "react-hubspot-form";
import { Row, Col } from "reactstrap";
import CustomButton from "../../../../_shared/components/Button";

const Exhibitor = () => {
  return (
    <div className="expo-tab-panel">
      <p className="expo-tab-panel__title">
        Find all the information you need about your trade show participation.
      </p>
      <div className="text-center">
        <a
          href="https://expo.opnplatform.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <CustomButton name="buy access" />
        </a>
      </div>
      <Row className="features-def row-eq-height">
        <Col sm={12} md={6} className="features-def__left">
          <div>
            <h5 className="md-title">Why exhibit?</h5>
            <p>
              Expose your business to thousands of decision-makers across the
              globe and expand your network with the verified buyers you would
              normally connect with at traditional trade shows:
            </p>
          </div>
        </Col>
        <Col sm={12} md={6} className="features-def__right">
          <img className="img-fluid" src="images/svg/cards/exhibit_screen.png" />
        </Col>
      </Row>
      <Row className="features-list">
        <Col md="6">
          <ul className="custom-list">
            <li>
              <strong>Non stop online trade show 365/24/7</strong>
            </li>
            <li>
              <strong>Virtual Company Booth:</strong> exhibitor video, instant
              video calls with lead scoring, live chat with sales staff, inbound
              meetings, showcase products, live stream product demos with Q&A
            </li>
            <li>
              <strong>Exhibitor Center:</strong> company profile, dashboard to
              manage your 1:1 and group meetings
            </li>
            <li>
              Sponsor or organize <strong>Video Sessions</strong> include
              webinars, product launches, workshops,and demos.
            </li>
            <li>
              <strong>Promote</strong> to specific groups of attendees with
              targeted ads.
            </li>
          </ul>
        </Col>
        <Col md="6">
          <ul className="custom-list">
            <li>
              <strong>Track your ROI</strong> with advanced analytics in real
              time.
            </li>
            <li>
              <strong>Obtain instant feedback</strong> using real-time
              interactive features like polling and Q&As.
            </li>
            <li>
              <strong>Networking opportunities:</strong> AI recommended people
              to meet.
            </li>
            <li>
              <strong>Environment friendly:</strong> eliminate the use of
              brochures or any supplementary material that causes paper waste
              and instead makes them available in the form of online resources.
            </li>
            <li>
              <strong>Display your product gallery:</strong> suggested products
              to buy, advanced filters, multiple product categories, save
              favorite products.
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="features-cap">
        <Col md={{ size: 10, offset: 1 }}>
          <div className="hub-spot-container">
            <HubSpotForm
              portalId="5004461"
              formId="23f09aab-b423-4c3a-a592-23f03ed11798"
              onSubmit={() => console.log("Submit!")}
              onReady={() => console.log("Form ready!")}
              loading={<div style={{ textAlign: "center" }}>Loading...</div>}
            />
          </div>
          <p className="expo-tab-panel__title">
            Exposure will also decrease your business’s environmental impact.
            Virtual trade shows eliminate the use of paper and printed marketing
            material and cancel the carbon footprint of local and international
            travel. By exhibit, your business is showing its commitment to
            global sustainable change.
          </p>
        </Col>
        <Col sm={12} className="text-center ticket">
          <a
            href="https://expo.opnplatform.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="img-fluid" src="images/svg/cards/ticket.svg" />
          </a>
        </Col>
      </Row>
    </div>
  );
};

export default Exhibitor;

import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { fetchActiveTender } from "../../../../../../redux/actions";
import ProductCard from "../../../../_shared/components/ProductCard";
import ProgressBar from "../../../../../../_shared/components/ProgressBar";

const Active = props => {
  const history = useHistory();
  const { activeList, fetchActiveTender, isFetchingActiveTender } = props;
  const { data } = activeList;
  console.log("data :", data);

  useEffect(() => {
    const params = {
      offset: 0,
      count: 10,
    };

    fetchActiveTender(params);
  }, []);

  const handleRedirectView = (e, id) => {
    history.push({
      search: `${history.location.search}&view=${id}`,
    });
  };

  return (
    <Fragment>
      {isFetchingActiveTender ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : isEmpty(data) ? (
        <div className="d-flex justify-content-center p-5">
          <img src="/images/svg/icons/no-data.svg" alt="No data icon" />
        </div>
      ) : (
        <div className="product__list">
          {data.map(item => (
            <ProductCard
              key={item._id}
              order={{ ...item.order, _id: item._id }}
              // setProductId={setProductId}
              action={handleRedirectView}
              // showMoreBtn={true}
            />
          ))}
        </div>
      )}
    </Fragment>
  );
};

Active.propTypes = {};

const mapStateToProps = state => ({
  activeList: state.tender.activeList,
  isFetchingActiveTender: state.ui.loading.fetchActiveTender,
});

const mapDispatchToProps = {
  fetchActiveTender,
};

export default connect(mapStateToProps, mapDispatchToProps)(Active);

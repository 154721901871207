import React from "react";
import { change, Field, getFormValues, reduxForm, reset } from "redux-form";
import { connect } from "react-redux";
import Classnames from "classnames";
import PropTypes from "prop-types";
import CustomButton from "../../../../../_shared/components/Button";
import { Card, CardBody, Col, Form, FormGroup, Row } from "reactstrap";
import { TextInputField } from "../../../../../_shared/components/ReduxFormFields/index";
import DatePickr from "../../../../../_shared/components/ReduxFormFields/ReactDatePicker";
import dealValidationFunc from "./dealValidation";
import "./DealsForm.scss";

const propTypes = {
  formLoading: PropTypes.bool,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  isClosed: PropTypes.bool,
  isEdit: PropTypes.bool,
  acceptedByBuyer: PropTypes.bool,
  isBuyer: PropTypes.bool,
  isOfferOrigin: PropTypes.bool,
  isConfirmed: PropTypes.bool,
  reset: PropTypes.func,
  setIsEdit: PropTypes.func,
  company: PropTypes.object,
  amount: PropTypes.object,
  type: PropTypes.bool,
  isCanceled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  formLoading: false,
};

const DealsForm = (props) => {
  const {
    handleSubmit,
    formLoading,
    submitting,
    pristine,
    invalid,
    reset,
    acceptedByBuyer,
    isClosed,
    company,
    isEdit,
    setIsEdit,
    isBuyer,
    amount,
    isConfirmed,
    isOfferOrigin,
    price,
    type,
    location,
    isCanceled,
  } = props;

  const handleEdit = () => {
    setIsEdit(!isEdit);
    reset();
  };

  return (
    <Card className="border-0">
      <CardBody className="p-0">
        <Form
          className={`deals-form ${!isEdit ? "edit" : ""}`}
          onSubmit={handleSubmit}
        >
          <Row>
            {isBuyer && isConfirmed && (
              <Col md="12" className="deals-form__cta">
                <CustomButton
                  action={handleEdit}
                  name={isEdit ? "Cancel" : "Edit"}
                  btnType="no-border"
                  classes="deals-form__cta__btn"
                />
                {isEdit && (
                  <CustomButton
                    name={formLoading ? "Loading ..." : "Save"}
                    type="submit"
                    btnType={isEdit ? "regular" : "no-border"}
                    classes="deals-form__cta__btn"
                    disabled={
                      isEdit &&
                      (pristine || invalid || formLoading || submitting)
                    }
                  />
                )}
              </Col>
            )}
          </Row>
          <Row>
            <Col md="12">
              <FormGroup>
                <label>{isOfferOrigin ? "Ordered by" : "Offered by"}</label>
                <p className="ordered">
                  {company && company.verified && (
                    <img
                      className="body__content__verified"
                      src="/images/svg/icons/verify.svg"
                      alt="chat icon"
                    />
                  )}
                  <span className="body__content__company">
                    {company && company.profile.name
                      ? company.profile.name
                      : "---"}
                  </span>
                </p>
              </FormGroup>

              <FormGroup>
                <label>Quantity</label>
                <Field
                  name="quantity"
                  label="Quantity"
                  id="quantity"
                  type="number"
                  disabled={!isEdit}
                  component={TextInputField}
                  placeholder="Quantity"
                />
              </FormGroup>

              <FormGroup>
                <label>Price Per Unit</label>
                <p className="ordered">{price ? price : "---"}</p>
              </FormGroup>
              <FormGroup>
                <label>{isOfferOrigin ? "Amount" : "Total price"}</label>
                <div className="amount">
                  <h4>{amount}</h4>
                </div>
              </FormGroup>
              <FormGroup>
                <label>Destination</label>
                {type ? (
                  <p>{location}</p>
                ) : (
                  <Field
                    name="destination"
                    label="Destination"
                    id="destination"
                    type="text"
                    disabled={!isEdit}
                    component={TextInputField}
                    placeholder="Destination"
                  />
                )}
              </FormGroup>
              {isOfferOrigin && (
                <FormGroup>
                  <label>Due date</label>
                  <Field
                    name="due_date"
                    label="Due date"
                    type="text"
                    id="due_date"
                    disabled={!isEdit}
                    component={DatePickr}
                    placeholder="Due date"
                  />
                </FormGroup>
              )}
              <FormGroup>
                <label>Status</label>
                <div className="status">
                  <p
                    className={Classnames("pending", {
                      ["closed"]: isClosed,
                      ["accepted"]: acceptedByBuyer || type,
                      ["canceled"]: isCanceled,
                    })}
                  >
                    {isClosed
                      ? "CLOSED"
                      : acceptedByBuyer || type
                      ? "ACCEPTED"
                      : isCanceled
                      ? "CANCELLED"
                      : "PENDING"}
                  </p>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

DealsForm.propTypes = propTypes;
DealsForm.defaultProps = defaultProps;

const formName = "dealsForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  validate: dealValidationFunc,
})(DealsForm);

// export default connectedForm;
export default connect(
  (state) => ({
    fields: getFormValues(formName)(state),
  }),
  {
    change,
    reset,
  }
)(connectedForm);

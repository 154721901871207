import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import {
  disconnectStripe, uiToggleShowDialog
} from "../../../../../../redux/actions"
import CustomButton from "../../../../../../_shared/components/Button"
import "./Stripe.scss"

const propTypes = {
  customAccountId: PropTypes.string,
  hasCompanyInfo: PropTypes.bool,
  uiToggleShowDialog: PropTypes.func,
  disconnectStripe: PropTypes.func,
  isCustomAccountVerified: PropTypes.bool
};

const Stripe = ({
  customAccountId,
  hasCompanyInfo,
  uiToggleShowDialog,
  disconnectStripe,
  isCustomAccountVerified,
}) => {
  let apiKey = "";
  let redirectUri = `${process.env.BASE_URL}/stripe-response`;
  // if (window.location.host === "localhost:3000") {
  //   apiKey = process.env.TEST_CLIENT_ID;
  //   redirectUri = `${process.env.BASE_URL}/stripe-response`;
  // } else if (window.location.host === "dev.opnplatform.com") {
  //   apiKey = process.env.TEST_CLIENT_ID;
  //   redirectUri = `${process.env.BASE_URL}/stripe-response`;
  // } else if (window.location.host === "opnplatform.com") {
  //   apiKey = process.env.PROD_CLIENT_ID;
  //   redirectUri = `${process.env.BASE_URL}/stripe-response`;
  // }
  // const stripeUrl =
  //   "https://connect.stripe.com/oauth/authorize?response_type=code&client_id=" +
  //   apiKey +
  //   "&scope=read_write&redirect_uri=" +
  //   redirectUri;

  //connect.stripe.com/oauth/authorize?redirect_uri=https://connect.stripe.com/hosted/oauth&client_id=ca_LgI2Iln5NpeJhKvdRp6kEOfeIRId7hwf&state=onbrd_LiuEzLGUq3smRBouiGQuIwTol5&response_type=code&scope=read_write&stripe_user[country]=IT

  https: return (
           <div className="stripe">
             <div className="stripe__header">
               <h4 className="caption">
                 What is Stripe?
                 <span
                   className={`status ${
                     customAccountId && isCustomAccountVerified
                       ? "connected"
                       : "disconnected"
                   }`}
                 >
                   {customAccountId && isCustomAccountVerified
                     ? "Connected"
                     : "Disconnected"}
                 </span>
               </h4>
               {customAccountId && isCustomAccountVerified ? (
                 <CustomButton
                   classes="disconnect-btn"
                   name="Disconnect"
                   btnType="no-border"
                   action={disconnectStripe}
                 />
               ) : hasCompanyInfo ? (
                 <a
                   href={redirectUri}
                   className="connect-btn"
                   target="_blank"
                   rel="noopener noreferrer"
                 >
                   Connect
                 </a>
               ) : (
                 <CustomButton
                   classes="connect-btn"
                   name="Connect"
                   btnType="no-border"
                   action={uiToggleShowDialog}
                 />
               )}
             </div>

             {/* {!isCustomAccountVerified && customAccountId ? (
               <div className="stripe__not-verified">
                 <p className="stripe__not-verified__content">
                   Your account is not enabled for accept payments. Complete the
                   "On board process" to enable your connect account.
                 </p>
                 <a
                   href={redirectUri}
                   className="connect-btn"
                   target="_blank"
                   rel="noopener noreferrer"
                 >
                   ENABLE
                 </a>
               </div>
             ) : (
               ""
             )} */}

             <p className="stripe__content">
               Stripe is a technology company that builds economic
               infrastructure for the internet. Businesses of every size—from
               new startups to public companies—use Stripe to provide payments
               worldwide daily.
             </p>
             <a
               href="http://stripe.com"
               target="_blank"
               rel="noopener noreferrer"
             >
               stripe.com
             </a>
           </div>
         );
};

Stripe.propTypes = propTypes;

const mapStateToProps = state => ({
  hasCompanyInfo: state.settings.hasCompanyInfo,
});

const mapDispatchToProps = {
  uiToggleShowDialog,
  disconnectStripe,
};

export default connect(mapStateToProps, mapDispatchToProps)(Stripe);

import React, { useEffect } from "react";
import { Row } from "reactstrap";
import PriceCard from "../../../../../Statics/Pricing/PriceCard"; 
import { tariffPlans } from "../../../../../Statics/Pricing/fixtures";
import "./Success.scss";

const Success = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="success" >
      <h5 className="form-title">Choose your Plan</h5>
      <p className="success-p" >
        Switch plans or cancel anytime
      </p>
      <Row className="row-eq-height pricing__card">
          {tariffPlans.map(tariff => (
            <PriceCard
              key={tariff.id}
              id={tariff.id}
              title={tariff.titleMain}
              content={tariff.content}
              bonus={tariff.bonus}
              plan={tariff.plan}
              monthPrice={tariff.monthPrice}
              yearPrice={tariff.yearPrice}
              totalPrice={tariff.totalPrice}
            />
          ))}
        </Row>
    </div>
  );
};


export default Success;


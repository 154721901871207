import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { isEmpty, debounce } from "lodash";
import { Spring, config } from "react-spring/renderprops";
import OutsideClickHandler from "react-outside-click-handler";
import {
  Container,
  Row,
  Col,
  InputGroup,
  Input,
  InputGroupAddon,
  Media,
} from "reactstrap";
import "./Main.scss";
import CustomButton from "../../../_shared/components/Button";
import PartnerLogoSection from "../_shared/components/PartnerLogoSection";
import {
  fetchStatistics,
  fetchSocketStatistics,
  searchOrders,
  navigateTo,
} from "../../../redux/actions";
import { truncate, priceFormat } from "../../../_shared/functions/utils";
import {
  trustedData,
  meterCardData,
  integrateData,
  solutionCardData,
  enterpriseData,
} from "./fixtures";
import ProgressBar from "../../../_shared/components/ProgressBar";

const propTypes = {
  navigateTo: PropTypes.func,
  fetchStatistics: PropTypes.func,
  fetchSocketStatistics: PropTypes.func,
  searchOrders: PropTypes.func,
  Socket: PropTypes.func,
  history: PropTypes.object,
  statistics: PropTypes.object,
  orderList: PropTypes.array,
  isSearching: PropTypes.bool,
  number: PropTypes.number,
};

const Home = ({
  history,
  fetchStatistics,
  fetchSocketStatistics,
  statistics,
  searchOrders,
  orderList,
  isSearching,
  navigateTo,
  Socket,
}) => {
  const OPNPlayBtn = useRef(null);
  const OPNVideoBox = useRef(null);

  // console.log("statistics :===>>>>", statistics);

  const searchOrdersDebounce = debounce(searchOrders, 750, { maxWait: 1500 });
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);

  useEffect(() => {
    fetchStatistics();

    if (Socket) {
      Socket().on("connect", function() {
        console.log("Socket.io CONNECTED");
      });

      Socket().on("stats", function(data) {
        fetchSocketStatistics(data);
      });
    }
  }, [Socket]);

  const enterpriseHeader = {
    title: "Enterprise Software Connector",
    subTitle: "Automate your sales and procurement workflow in few clicks.",
  };
  const integrateHeader = {
    title: "Integrate sales channels",
  };
  const trustedHeader = {
    title: "Recommended by",
  };

  const renderMeterCard = (item, index) => {
    const { count, label, link, btnName } = item;

    return (
      <Col md="4" key={index}>
        <div className="rate-card text-center">
          <img
            className="meter"
            src={`${process.env.IMAGE_URL}/assets/images/svg/meter${index}.svg`}
          />
          <div className="rate-card__wrap">
            <Spring
              from={{ number: 0 }}
              to={{ number: statistics[count] }}
              delay="1000"
              config={config.molasses}
            >
              {props => (
                <h1 className="rate-card__count">{props.number.toFixed()}</h1>
              )}
            </Spring>
            <p className="rate-card__label">{label}</p>
            {btnName === "Join community" ? (
              <a href={link} target="_blank" rel="noopener noreferrer">
                <CustomButton name={btnName} btnType="no-border" />
              </a>
            ) : (
              <CustomButton
                action={redirectToSignup}
                name={btnName}
                btnType="no-border"
              />
            )}
          </div>
        </div>
      </Col>
    );
  };

  const renderSolutionCard = (data, index) => {
    const { title, content, imgName } = data;
    return (
      <Col md="4" key={index} className="basic-card">
        <div className="basic-card__item">
          <img
            src={`${process.env.IMAGE_URL}/assets/images/svg/${imgName}.svg`}
            alt="money"
          />

          <h4>{title}</h4>
          <p>{content}</p>
        </div>
      </Col>
    );
  };

  const handleInputChange = event => {
    const name = event.target.value;
    const payload = {
      name,
      count: 250,
      offset: 0,
      purpose: "any",
      productList: "all",
    };

    searchOrdersDebounce(payload);
  };

  const redirectToViewProduct = (id, name, purpose) => {
    const previousLabel =
      purpose === "SELL" ? "all offers" : purpose === "BUY" && "all requests";
    navigateTo({
      pathname: `/market/${name}/${id}/view`,
      state: { previousLabel },
    });
  };

  const renderDropdownItem = order => {
    const { _id, photos, company, name, price, purpose } = order;

    return (
      <Media
        key={_id}
        className="dropdown-container__item"
        onClick={() => redirectToViewProduct(_id, name, purpose)}
      >
        <Media className="dropdown-container__item__image" left>
          {!isEmpty(photos) && photos[0] && <img src={photos[0].cdn_link} />}
        </Media>
        <Media className="dropdown-container__item__body" body>
          <p className="dropdown-container__item__body__company">
            {company && company.verified && (
              <img
                className="dropdown-container__item__body__company__verified"
                src={`${process.env.IMAGE_URL}/assets/images/svg/icons/verify.svg`}
                alt="chat icon"
              />
            )}
            <span>
              {company && company.profile.name ? company.profile.name : "---"}
            </span>
          </p>
          <Media className="dropdown-container__item__body__title" heading>
            {name ? truncate(name, 50) : "---"}
          </Media>
          <p className="dropdown-container__item__body__price">
            {priceFormat(price)}
          </p>
        </Media>
      </Media>
    );
  };

  const renderDropdown = () => (
    <div className="dropdown-container">
      {isSearching ? (
        <div className="d-flex justify-content-center p-3">
          <ProgressBar spinType="tiny" />
        </div>
      ) : !isEmpty(orderList) ? (
        orderList.map(order => renderDropdownItem(order))
      ) : (
        <p className="no-result">No matching product found.</p>
      )}
    </div>
  );

  const redirectToSignup = () => history.push(`/register`);

  const togglePlayPause = (event, videoElement, controlElement) => {
    event.preventDefault();
    event.stopPropagation();
    // const videoElement = OPNVideoBox.current;
    // const controlElement = OPNPlayBtn.current;

    if (videoElement.paused) {
      controlElement.className = "button hide";
      videoElement.setAttribute("controls", true);
      videoElement.play();
    } else {
      controlElement.className = "button show";
      videoElement.removeAttribute("controls");
      videoElement.pause();
    }
  };

  return (
    <main>
      <Helmet>
        <meta
          property="og:type"
          content="online platform for global packaging industry"
        />
        <meta
          property="og:title"
          content="Open Packaging Network Platform - Where sustainable packaging is born"
        />
        <meta
          property="og:image"
          content="https://opnplatform.com/images/video-poster.png"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />
        <meta property="og:site_name" content="OPN Platform" />
        <meta
          property="og:description"
          content="Open packaging network is a sustainable packaging platform for packaging industry. OPN b2b platform enhances your sell in global packaging industry rapidly. Join us today!"
        />
        <meta property="og:url" content="https://opnplatform.com/" />
        <link rel="canonical" href="https://opnplatform.com/" />
      </Helmet>
      <div className="main-hero">
        <img className="hero-bg" src="/images/svg/hero_bg.svg" />
        <Container>
          <Row>
            <Col className="d-flex justify-content-center align-items-center hero__col">
              <div className="hero__col__inner">
                <h1 className="hero__header">Open Packaging Network</h1>
                <p className="hero__sub-herder">
                  Data-driven B2B independent digital platform. Everything for
                  Packaging Fast and Easy.
                </p>
                <div className="hero__input-wrap">
                  <OutsideClickHandler
                    onOutsideClick={() => setIsSearchInputFocused(false)}
                  >
                    <InputGroup className="hero__input">
                      <Input
                        placeholder="Find your product"
                        onChange={handleInputChange}
                        onFocus={() => setIsSearchInputFocused(true)}
                      />
                      <InputGroupAddon addonType="append">
                        <img src="/images/svg/search.svg" />
                      </InputGroupAddon>
                      {isSearchInputFocused && renderDropdown()}
                    </InputGroup>
                  </OutsideClickHandler>
                </div>
                <CustomButton action={redirectToSignup} name="Get Started" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="main-growth">
        <Container>
          <Row>
            <Col md="12" className="text-center main__title">
              <h4>Our growth</h4>
            </Col>
            {meterCardData.map((item, index) => renderMeterCard(item, index))}
            <Col md="12" className="text-center  main-growth__btn">
              <CustomButton
                action={() => history.push(`/referral`)}
                name="Invite & Earn"
              />
            </Col>
          </Row>
        </Container>
      </div>

      <img
        src={`${process.env.IMAGE_URL}/assets/images/partners/sectionBorder.svg`}
        style={{ width: "100%" }}
      />

      <div className="main-solution">
        <Container>
          <Row>
            <Col md="12" className="main__title">
              <h4>Solutions for your Sustainable Packaging Business</h4>
              <p className="hero__sub-herder">
                Drive sales and procurement at every stage of your pipeline with
                powerful and flexible digital solutions by OPN - Sustainable
                packaging industry online platform
              </p>
            </Col>
            <Col md={{ size: 10, offset: 1 }} className="main-solution__video">
              <div
                className="video__box"
                onClick={event =>
                  togglePlayPause(
                    event,
                    OPNVideoBox.current,
                    OPNPlayBtn.current
                  )
                }
              >
                <video
                  className="video"
                  poster="https://opn.sfo2.digitaloceanspaces.com/assets/video-poster.png"
                  preload="none"
                  src="https://opn.sfo2.digitaloceanspaces.com/assets/OPN-How%20to%20Join.mp4"
                  ref={OPNVideoBox}
                />
                <div
                  className="button show"
                  ref={OPNPlayBtn}
                  onClick={event =>
                    togglePlayPause(
                      event,
                      OPNVideoBox.current,
                      OPNPlayBtn.current
                    )
                  }
                >
                  <button
                    id="play-pause"
                    aria-label="Play button"
                    className="paused"
                  />
                </div>
              </div>
            </Col>
            <Row className="justify-content-center row-eq-height main-solution__cards">
              {solutionCardData.map((data, index) =>
                renderSolutionCard(data, index)
              )}
            </Row>
          </Row>
        </Container>
      </div>

      <img
        src={`${process.env.IMAGE_URL}/assets/images/partners/sectionBorder.svg`}
        style={{ width: "100%" }}
      />

      <div className="main-partners">
        <Container>
          <Row>
            <Col md="12" className="">
              <PartnerLogoSection
                header={enterpriseHeader}
                button="Try Now"
                partnersLogos={enterpriseData}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <img
        src={`${process.env.IMAGE_URL}/assets/images/partners/sectionBorder.svg`}
        style={{ width: "100%" }}
      />

      <div className="main-partners">
        <Container>
          <Row>
            <Col md="12" className="">
              <PartnerLogoSection
                header={trustedHeader}
                button="Join now"
                partnersLogos={trustedData}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </main>
  );
};

Home.propTypes = propTypes;

const mapStateToProps = state => ({
  location: state.router.location,
  isSearching: state.ui.loading.searchOrders,
  statistics: state.home.statistics,
  Socket: state.auth.socketClient,
  orderList: state.orders.anyList,
});

const mapDispatchToProps = {
  fetchStatistics,
  fetchSocketStatistics,
  searchOrders,
  navigateTo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Chart } from "react-google-charts";
import { connect } from "react-redux";
import Classnames from "classnames";
import { fetchAnalytics } from "../../../../../redux/actions";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import "./Analytics.scss";

const propTypes = {
  analytics: PropTypes.object,
  fetchAnalytics: PropTypes.func,
};

function Analytics({ fetchAnalytics, analytics }) {
  const {
    total_close_deal,
    total_pending_deal,
    total_product_views,
    total_sales,
  } = analytics;
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    fetchAnalytics();
  }, []);

  const statData = [
    { label: "Total Sales", value: total_sales },
    { label: "Product Views", value: total_product_views },
    { label: "Pending Deals", value: total_pending_deal },
    { label: "Closed Deals", value: total_close_deal },
  ];

  const topCard = (data, idx) => (
    <div key={idx} className="top-card wrapper">
      <div className="top-card__label">{data.label}</div>
      <div
        className={Classnames("top-card__value", {
          blue: idx === 0,
          yellow: idx === 1,
          green: idx === 2,
          orange: idx === 3,
        })}
      >
        {data.value}
      </div>
    </div>
  );

  return (
    <div className="analytics">
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle caret>Dropdown</DropdownToggle>
        <DropdownMenu>
          <DropdownItem>Some Action</DropdownItem>
        </DropdownMenu>
      </Dropdown>

      <div className="top-card-container">
        {statData.map((data, idx) => topCard(data, idx))}
      </div>
      <div className="chart-container">
        <div className="sales wrapper">
          <div className="sales__title">Sales Summary</div>
          <Chart
            className="sales__chart"
            width={"100%"}
            height={"300px"}
            chartType="ScatterChart"
            loader={<div>Loading Chart</div>}
            data={[
              [
                "Generation",
                "Descendants",
                { role: "tooltip", type: "string", p: { html: true } },
              ],
              [0, 1, `<div class="chart-tooltip">$26374 • Thu 05/07</div>`],
              [1, 33, `<div class="chart-tooltip">$26374 • Thu 05/07</div>`],
              [2, 269, `<div class="chart-tooltip">$26374 • Thu 05/07</div>`],
              [3, 2013, `<div class="chart-tooltip">$26374 • Thu 05/07</div>`],
              [4, 2013, `<div class="chart-tooltip">$26374 • Thu 05/07</div>`],
              [5, 2013, `<div class="chart-tooltip">$26374 • Thu 05/07</div>`],
              [6, 2013, `<div class="chart-tooltip">$26374 • Thu 05/07</div>`],
            ]}
            options={{
              //   title: "Age vs. Weight comparison",
              legend: "none",
              crosshair: {
                trigger: "both",
                orientation: "vertical",
                type: "dashed",
                color: "#21212161",
              },
              trendlines: {
                0: {
                  type: "polynomial",
                  degree: 6,
                  lineWidth: 3,
                  color: "#3D7EFD",
                  tooltip: false,
                  opacity: 1,
                },
              },
              tooltip: { isHtml: true, trigger: "visible" },
              chartArea: { width: "85%" },
              vAxis: { baselineColor: "#ccc", gridlineColor: "#ccc" },
              hAxis: {
                baselineColor: "#ccc",
                gridline: { color: "#ccc", interval: [0, 1, 2, 3, 4, 5, 6] },
              },
            }}
            rootProps={{ "data-testid": "6" }}
          />
        </div>
        <div className="products wrapper">
          <div className="products__title">Top Products</div>
          <div className="products__table table">
            <div className="table__header title-in-btw">
              <div>product name</div>
              <div>conversion rate</div>
            </div>
            <div className="table__item">
              <div className="table__item__title title-in-btw">
                <div>Kraft Paper Roll 30'' X 1800'' (150ft)</div>
                <div>20%</div>
              </div>
              <div className="bar">
                <div className="bar__inner" style={{ width: "20%" }}></div>
              </div>
            </div>
            <div className="table__item">
              <div className="table__item__title title-in-btw">
                <div>Kraft Paper Roll 30'' X 1800'' (150ft)</div>
                <div>70%</div>
              </div>
              <div className="bar">
                <div className="bar__inner" style={{ width: "70%" }}></div>
              </div>
            </div>
            <div className="table__item">
              <div className="table__item__title title-in-btw">
                <div>Kraft Paper Roll 30'' X 1800'' (150ft)</div>
                <div>40%</div>
              </div>
              <div className="bar">
                <div className="bar__inner" style={{ width: "40%" }}></div>
              </div>
            </div>
            <div className="table__item">
              <div className="table__item__title title-in-btw">
                <div>Kraft Paper Roll 30'' X 1800'' (150ft)</div>
                <div>33%</div>
              </div>
              <div className="bar">
                <div className="bar__inner" style={{ width: "33%" }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Analytics.propTypes = propTypes;

const mapStateToProps = (state) => ({
  analytics: state.office.analytics,
});

const mapDispatchToProps = { fetchAnalytics };

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);

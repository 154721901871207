import { FETCH_STATISTICS, SOCKET_STATISTICS } from "../../actions";

const defaultState = {
  statistics: {},
};

const homeReducer = (state = defaultState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_STATISTICS.SUCCESS:
    case SOCKET_STATISTICS:
      return Object.assign({}, state, { statistics: payload });
    default:
      return state;
  }
};

export default homeReducer;

import {
  CardElement,
  IbanElement, useElements, useStripe
} from "@stripe/react-stripe-js"
import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import qs from "query-string"
import React, { useState } from "react"
import { connect } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import { Col, Form, FormGroup, Row } from "reactstrap"
import { change, Field, getFormValues, reduxForm } from "redux-form"
import { dealPayment } from "../../../../../redux/actions"
import CustomButton from "../../../../../_shared/components/Button"
import { TextInputField } from "../../../../../_shared/components/ReduxFormFields"
import "./CheckoutForm.scss"

const propTypes = {
  submitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  tenderId: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  dealPayment: PropTypes.func.isRequired,
};

const defaultProps = {};

const CheckoutForm = props => {
  const {
    handleSubmit,
    dealPayment,
    submitting,
    pristine,
    invalid,
    isLoading,
    tenderId,
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const { dealId } = useParams();
  const { goBack } = useHistory();
  const { bid } = qs.parse(location.search);
  const STRIPE_TABS = ["Card", "Bank Account"];
  const DEFAULT_TAB_IDX = 0;
  const [currentTabIdx, setTabIndex] = useState(DEFAULT_TAB_IDX)

  // useEffect(() => {
     
  //   return tabChangeHandler(DEFAULT_TAB_IDX, elements)
  // });
 



  const handleOnsubmit = async values => {
    try {
      let selectedPaymentMeth = {};


      console.log('VALORI FORM', values)

       switch (currentTabIdx) {
         case 0:
           selectedPaymentMeth = {
             type: "card",
             card: elements.getElement(CardElement),
             billing_details: {
               // Include any additional collected billing details.
               name: values.name,
             },
           };
           break;
         case 1:
           selectedPaymentMeth = {
             type: "sepa_debit",
             sepa_debit: elements.getElement(IbanElement),
             billing_details: {
               // Include any additional collected billing details.
               name: values.name,
               email: values.email
             },
           };
           break;
       }
      let { paymentMethod } = await stripe.createPaymentMethod({
        ...selectedPaymentMeth,
        
      });

      if (paymentMethod) {
        const payload = {
          payment_method_id: paymentMethod.id,
        };

        if (bid) {
          payload.id = tenderId;
          dealPayment(payload, "tender");
        } else {
          payload.id = dealId;
          dealPayment(payload);
        }
      }
    } catch (error) {
      console.log("error :", error);
    }
  };

  return (
    <Form className="form">
      <Row>
        <Col className="mb-5">
          <div className=""><strong>Payment:</strong>&nbsp; <a href="https://b2b.securetrade.pro/dashboard" target="_blank" title="Buy and Sell online with Secure Trade Pro">Click here to proceed</a> with the payment on our <a href="https://b2b.securetrade.pro" target="_blank" title="Buy and Sell online with Secure Trade Pro">secured payment platform.</a></div>
          <div className="mt-5"><strong>Shipment:</strong>&nbsp; <a href="/logistic-invite" target="_self" title="Select the logistic partner of your choice">Select the shipment partner of your choice</a>.</div>
        </Col>
        <Col md="12">
          
        </Col>
        <Col>
          <p className="stripe-note">
            OPM takes your data safety seriously and take all measures to
            protect sensitive personal data. We do not store any financial data
            of users such as the credit card information on our website for
            security purposes. All transactions you conduct with the help of our
            service are processed by the Mangopay service.
          </p>
        </Col>
        <Col md="12" className="stripe-form__cta">
          <CustomButton
            action={() => goBack()}
            name="Back"
            btnType="no-border"
          />
          <a href="https://b2b.securetrade.pro/dashboard" title="Pay on Secure Trade pro" target="_blank"><CustomButton
            name="Pay Now"
            loading={isLoading}
            type="button"
            disabled={
              isLoading || submitting
            }
          /></a>
        </Col>
      </Row>
    </Form>
  );
};

CheckoutForm.propTypes = propTypes;
CheckoutForm.defaultProps = defaultProps;

const formName = "checkoutForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  //   validate: PreOrderValidationFunc,
})(CheckoutForm);

// export default connectedForm;
export default connect(
  state => ({
    formData: getFormValues(formName)(state),
    dealId: state.deals.current._id,
    isLoading: state.ui.loading.dealPayment,
  }),
  {
    change,
    dealPayment,
  }
)(connectedForm);

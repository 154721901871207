import PropTypes from "prop-types"
import React, { Fragment, useState } from "react"
import Reaptcha from "react-google-recaptcha"
import SignUpForm from "../../_shared/forms/SignUpForm"
import SocialAuthForm from "../../_shared/forms/SocialAuthForm"
import "../Register.scss"

const propTypes = {
  nextStep: PropTypes.func,
};

const RECAPTCHA_SITE_KEY = process.env.RECAPTCHA_KEY;

const SignUp = ({ nextStep }) => {
  const [isVerified, setIsVerified] = useState(false);

  const TERMS_CONDITION =
    "https://app.termly.io/document/terms-of-use-for-online-marketplace/e651829b-f5ee-4b31-afff-92404c8d31b2";
  const PRIVACY_POLICY =
    "https://app.termly.io/document/privacy-policy/67deeb2e-996c-4c94-af4c-ee901117e246";
  const TIMES_OF_SERVICE =
    "https://app.termly.io/document/terms-of-use-for-online-marketplace/e651829b-f5ee-4b31-afff-92404c8d31b2";

  const reCaptchaLoaded = () => {
    console.log("loaded sucessful");
  };

  const verifyCallback = response => {
    if (response) setIsVerified(true);
  };

  return (
    <Fragment>
      <SignUpForm nextStep={nextStep} isVerified={isVerified} />
      <SocialAuthForm nextStep={nextStep} />
      <p className="text-center">
        By clicking “Confirm” you agree to our
        <a href={TERMS_CONDITION} target="_blank" rel="noopener noreferrer">
          {""} terms and conditions
        </a>
        {""} and {""}
        <a href={PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
          privacy policy.
        </a>
      </p>
      <p className="text-center">
        This site is protected by reCAPTCHA and the Google
        <a href={TERMS_CONDITION} target="_blank" rel="noopener noreferrer">
          {""} privacy policy
        </a>
        {""} and {""}
        <a href={TIMES_OF_SERVICE} target="_blank" rel="noopener noreferrer">
          {""} terms of service {""}
        </a>
        apply.
      </p>
      <div className="d-flex justify-content-center captcha-container">
        <Reaptcha
          sitekey={RECAPTCHA_SITE_KEY}
          badge="inline"
          onChange={verifyCallback}
        />
      </div>
    </Fragment>
  );
};

SignUp.propTypes = propTypes;

export default SignUp;

import React from "react";
import { Helmet } from "react-helmet";
import "./SocialShare.scss";

const SocialShare = ({ article, url }) => {
  const { description, title, main_image, tags, content } = article;

  //URL patterns for Social media sites share functionalities
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
  const twitterUrl = `https://twitter.com/intent/tweet?url=${url}`;
  const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;

  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={main_image} />
        <meta property="og:site_name" content="OPN Platform" />

        <meta name="keywords" content={tags ? tags : ""} />
      </Helmet>

      <ul>
        <li>
          <a
            href={facebookUrl}
            className="facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-facebook-f fa-2x" />
          </a>
        </li>
        <li>
          <a
            href={twitterUrl}
            className="twitter"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-twitter fa-2x" />
          </a>
        </li>
        <li>
          <a
            href={linkedinUrl}
            className="linkedin"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fab fa-linkedin fa-2x" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SocialShare;

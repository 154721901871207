import { isValid } from "date-fns"
import { debounce, isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { CardElement, injectStripe } from "react-stripe-elements"
import { Col, Form, FormGroup, Row } from "reactstrap"
import { change, Field, getFormValues, reduxForm } from "redux-form"
import {
  clearPromoCodeState,
  subscribeUser,
  uiToggleShowSignupModal, updateSubscribedUser, validatePromoCode
} from "../../../../../redux/actions"
import CustomButton from "../../../../../_shared/components/Button"
import ProgressBar from "../../../../../_shared/components/ProgressBar"
import {
  SelectInputField, TextInputField
} from "../../../../../_shared/components/ReduxFormFields"
import "./SubscriptionForm.scss"

const propTypes = {
  submitting: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isValidatingPromoCode: PropTypes.bool,
  invalid: PropTypes.bool,
  isValidPromoCode: PropTypes.object,
  formData: PropTypes.object,
  companyEmail: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  validatePromoCode: PropTypes.func,
  change: PropTypes.func,
  clearPromoCodeState: PropTypes.func,
  uiToggleShowSignupModal: PropTypes.func.isRequired,
  subscribeUser: PropTypes.func.isRequired,
  updateSubscribedUser: PropTypes.func.isRequired,
  stripe: PropTypes.object.isRequired,
  subscriptionId: PropTypes.string
};

const defaultProps = {
  formData: {},
  isValidatingPromoCode: false,
};

const SubscriptionForm = props => {
  const {
    formData,
    handleSubmit,
    subscribeUser,
    updateSubscribedUser,
    isLoggedIn,
    uiToggleShowSignupModal,
    submitting,
    invalid,
    isLoading,
    stripe,
    change,
    companyEmail,
    validatePromoCode,
    isValidPromoCode,
    isValidatingPromoCode,
    clearPromoCodeState,
    location,
    subscriptionId
  } = props;
  const {state} = location || {} ;
  const {tariff} = state || {} 
  const { goBack } = useHistory();
  const { payment_duration } = formData;
  const [showCoupon, setShowCoupon] = useState(false);
  const [searchQuery, setSearchQuery] = useState({});

  const handleOnsubmit = async values => {
    if (!isLoggedIn) return uiToggleShowSignupModal();
    const { payment_duration, promo_code } = values;
    try {
      let { token } = await stripe.createToken({
        email: companyEmail,
      });
      console.log('token', token);
      const payload = {
        token: token.id,
        //token: "tok_visa",
        quantity: 1,
        payment_duration: payment_duration.value,
        promo_code,
        tariff: tariff.toUpperCase() || "ENTERPRISE",
        //payment_method_types: [ "card"]
      };

      if (subscriptionId) {
        console.log('update', payload)
        updateSubscribedUser(payload)

       } else {
        console.log('subscribe', payload)
        subscribeUser(payload)
      }
    } catch (error) {
      console.log("error :", error);
    }
  };

  const monthlyPrice = {
    monthly: "290",
    yearly: "232",
  }; 

  const basicPlan = {
    monthly: "19",
    yearly: "15",
  }

  const enterPricePlan = {
    monthly: "990",
    yearly: "792",
  }

  const subscriptionDropdown = () => [
    {
      label: "12 months (-20% Off)",
      value: "yearly",
    },
    {
      label: "Monthly",
      value: "monthly",
    },
  ];

  const validatePromoCodeDebounce = () => {
    debounce(
      validatePromoCode, 750, {
        maxWait: 1500,
      }
    );
    isValidPromoCode.percent_off = 0;
    isValidPromoCode.message = "";
    isValidPromoCode.status = false;
  }

  const handlePromoCodeChange = value => {
    const validatePromoCodeDebounce = debounce(validatePromoCode, 750, {
      maxWait: 1500,
    });

    setSearchQuery(prevSearch => {
      if (prevSearch.cancel) {
        prevSearch.cancel();
      }

      return validatePromoCodeDebounce;
    });

    if (value.length > 0) return validatePromoCodeDebounce({ code: value });

    validatePromoCodeDebounce.cancel();
    
    clearPromoCodeState();
  };
  
  return (
    <Form className="stripe-form" onSubmit={handleSubmit(handleOnsubmit)}>
      <Row>
        <Col className="mb-5">
          <Row>
            <Col className="mb-5" md="12">
              <div className="stripe-form__title">
                <p className="label">Checkout</p>
                <p>
                  {tariff ? tariff : "Enterprise"}{" "}
                  {tariff === "Basic" ? (
                    <span>
                      ${basicPlan[payment_duration && payment_duration.value]}
                      /month
                    </span>
                  ) : tariff === "Business" ? (
                    <span>
                      $
                      {monthlyPrice[payment_duration && payment_duration.value]}
                      /month
                    </span>
                  ) : (
                    <span>
                      $
                      {
                        enterPricePlan[
                          payment_duration && payment_duration.value
                        ]
                      }
                      /month
                    </span>
                  )}
                </p>
              </div>
            </Col>
            <Col md="12">
              <div className="sub-plan">
                <FormGroup className="w-50">
                  <Field
                    name="payment_duration"
                    label="payment_duration"
                    id="payment_duration"
                    options={subscriptionDropdown()}
                    component={SelectInputField}
                    placeholder="Payment Duration"
                  />
                </FormGroup>
                <p className="total">
                  Total:{" "}
                  {tariff === "Basic" ? (
                    <span>
                      $
                      {
                        //basicPlan[payment_duration && payment_duration.value] - (basicPlan[payment_duration && payment_duration.value]*(isValidPromoCode.percent_off/100))
                      payment_duration && payment_duration.value === "monthly"
                        ? //basicPlan[payment_duration && payment_duration.value]
                        basicPlan[payment_duration && payment_duration.value] - (basicPlan[payment_duration && payment_duration.value]*(isValidPromoCode.percent_off > 0 ? isValidPromoCode.percent_off/100 : 0))
                        : //(basicPlan[payment_duration && payment_duration.value]*12)
                        (basicPlan[payment_duration && payment_duration.value] * 12) - 
                        ((basicPlan[payment_duration && payment_duration.value] * 12)*(isValidPromoCode.percent_off > 0 ? isValidPromoCode.percent_off/100 : 0))
                      }
                    </span>
                  ) : tariff === "Business" ? (
                    <span>
                      $
                      {
                        payment_duration && payment_duration.value === "monthly"
                        ? //monthlyPrice[payment_duration && payment_duration.value]
                        (monthlyPrice[payment_duration && payment_duration.value]) -
                        (monthlyPrice[payment_duration && payment_duration.value]*(isValidPromoCode.percent_off > 0 ? isValidPromoCode.percent_off/100 : 0))
                        : //monthlyPrice[payment_duration && payment_duration.value]*12
                        (monthlyPrice[payment_duration && payment_duration.value]*12) - 
                        ((monthlyPrice[payment_duration && payment_duration.value]*12)*(isValidPromoCode.percent_off > 0 ? isValidPromoCode.percent_off/100 : 0))
                      }
                    </span>
                  ) : (
                    <span>
                      $
                      {
                      payment_duration && payment_duration.value === "monthly"
                        ? //enterPricePlan[payment_duration && payment_duration.value]
                        (enterPricePlan[payment_duration && payment_duration.value]) - 
                        (enterPricePlan[payment_duration && payment_duration.value]*(isValidPromoCode.percent_off > 0 ? isValidPromoCode.percent_off/100 : 0))
                        : //enterPricePlan[payment_duration && payment_duration.value] * 12
                        (enterPricePlan[payment_duration && payment_duration.value]*12) -
                        ((enterPricePlan[payment_duration && payment_duration.value]*12)*(isValidPromoCode.percent_off > 0 ? isValidPromoCode.percent_off/100 : 0))
                      }
                    </span>
                  )}
                </p>
              </div>
            </Col>
            <Col>
            { !showCoupon ? validatePromoCodeDebounce({code: ""}) : true}
            { console.log('state', isValidPromoCode)}
              {!showCoupon && (
                <div className="mb-3">
                  <CustomButton
                    action={() => setShowCoupon(true)}
                    btnType="no-border"
                    name="Add a Coupon Code"
                    classes="p-0"
                    faName="fa-plus"
                  />
                </div>
              )}
              {showCoupon && (
                <FormGroup className="w-50 promo-code ">
                  <div className="error">
                    {isValidPromoCode.status ? (
                      <img
                        className="error__icon"
                        src="/images/svg/icons/valid.svg"
                        alt=""
                      />
                    ) : isEmpty(isValidPromoCode.message) ? (
                      ""
                    ) : (
                      <img
                        className="error__icon"
                        src="/images/svg/icons/invalid.svg"
                        alt=""
                      />
                    )}
                    <Field
                      name="promo_code"
                      label="Add a Coupon"
                      id="coupon"
                      type="text"
                      onChange={e => handlePromoCodeChange(e)}
                      component={TextInputField}
                      placeholder="Add a Coupon"
                      autoComplete="off"
                    />
                    {isValidatingPromoCode && <ProgressBar spinType="tiny" />}
                  </div>
                  {!isValidPromoCode.status &&
                    !isEmpty(isValidPromoCode.message) && (
                      <div className="invalid-feedback">
                        {isValidPromoCode.message}
                      </div>
                    )}
                  {isValidPromoCode.status && (
                    <div className="remove-cta">
                      <CustomButton
                        action={() => {
                          change("promo_code", "");
                          clearPromoCodeState();
                        }}
                        btnType="no-border"
                        name="Remove"
                        classes="p-0 remove"
                      />
                    </div>
                  )}
                </FormGroup>
              )}
            </Col>
          </Row>
        </Col>

        <Col md="12" className="mb-5 pt-5">
          <div className="divider-right">credit card information</div>
        </Col>
        <Col md="12">
          <FormGroup className="stripe-input-box">
            <CardElement className="stripe__wrap" />
            <p className="powered">
              powered by: <i className="fab fa-stripe" />
            </p>
          </FormGroup>
        </Col>
        <Col>
          <p className="stripe-note">
            OPN takes your data safety seriously and take all measures to
            protect sensitive personal data. We do not store any financial data
            of users such as the credit card information on our website for
            security purposes. All transactions you conduct with the help of our
            service are processed by the Stripe service.
          </p>
        </Col>
        <Col md="12" className="stripe-form__cta">
          <CustomButton
            action={() => goBack()}
            name="Back"
            btnType="no-border"
          />
          <CustomButton
            name="Pay Now"
            loading={isLoading}
            type="submit"
            disabled={invalid || submitting || isLoading || isEmpty(stripe)}
          />
        </Col>
      </Row>
    </Form>
  );
};

SubscriptionForm.propTypes = propTypes;
SubscriptionForm.defaultProps = defaultProps;

const formName = "subscriptionForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  // validate,
})(injectStripe(SubscriptionForm));

const allPlan = {
  yearly: {
    label: "12 months (-20% Off)",
    value: "yearly",
  },
  monthly: {
    label: "Monthly",
    value: "monthly",
  },
};

// export default connectedForm;
export default connect(
  (state, ownProps) => ({
    initialValues: {
      payment_duration: allPlan[ownProps.plan || "monthly"],
    },
    formData: getFormValues(formName)(state),
    isLoading: state.ui.loading.subscribeUser,
    isValidatingPromoCode: state.ui.loading.validatePromoCode,
    isLoggedIn: state.auth.isLoggedIn,
    companyEmail: state.auth.user && state.auth.user.mail.id,
    subscriptionId: state.auth.user && state.auth.user.stripe_subscription_id,
    isValidPromoCode: state.settings.isValidPromoCode,
    location : state.router.location,
  }),
  {
    change,
    subscribeUser,
    updateSubscribedUser,
    uiToggleShowSignupModal,
    validatePromoCode,
    clearPromoCodeState,
  }
)(connectedForm);

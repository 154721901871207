import { createActionType } from "../../../_shared/functions/utils";

export const FETCH_ACTIVE_TENDER = createActionType(
  "FETCH_ACTIVE_TENDER",
  "tender"
);
export const CREATE_BID = createActionType("CREATE_BID", "tender");
export const FETCH_BUYER_BIDS = createActionType("FETCH_BUYER_BIDS", "tender");
export const FETCH_SELLER_BIDS = createActionType(
  "FETCH_SELLER_BIDS",
  "tender"
);
export const FETCH_TENDER_ID = createActionType("FETCH_TENDER_ID", "tender");
export const FETCH_TENDER_BID = createActionType("FETCH_TENDER_BID", "tender");
export const REJECT_TENDER_BID = createActionType(
  "REJECT_TENDER_BID",
  "tender"
);
export const ACCEPT_TENDER_BID = createActionType(
  "ACCEPT_TENDER_BID",
  "tender"
);
export const FETCH_ARCHIVED_TENDER = createActionType(
  "FETCH_ARCHIVED_TENDER",
  "tender"
);
export const FETCH_TENDER_OFFERS = createActionType(
  "FETCH_TENDER_OFFERS",
  "tender"
);
export const FETCH_PURCHASING_ORDER = createActionType(
  "FETCH_PURCHASING_ORDER",
  "tender"
);

export const createBid = payload => ({
  type: CREATE_BID.START,
  payload,
});

export const fetchActiveTender = params => ({
  type: FETCH_ACTIVE_TENDER.START,
  params,
});

export const fetchTenderId = id => ({
  type: FETCH_TENDER_ID.START,
  id,
});

export const fetchTenderBid = id => ({
  type: FETCH_TENDER_BID.START,
  id,
});

export const acceptTenderBid = payload => ({
  type: ACCEPT_TENDER_BID.START,
  payload,
});

export const rejectTenderBid = payload => ({
  type: REJECT_TENDER_BID.START,
  payload,
});

export const fetchArchivedTender = params => ({
  type: FETCH_ARCHIVED_TENDER.START,
  params,
});

export const fetchTenderOffers = params => ({
  type: FETCH_TENDER_OFFERS.START,
  params,
});

export const fetchPurchasingOrder = params => ({
  type: FETCH_PURCHASING_ORDER.START,
  params,
});

import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import CustomButton from "../../../../../../_shared/components/Button";
import { InputGroup, Input, InputGroupAddon } from "reactstrap";
import "./ChooseProduct.scss";

const propTypes = {
  categories: PropTypes.array,
  setPurpose: PropTypes.func,
  nextStep: PropTypes.func,
  handleSelectProduct: PropTypes.func,
  handleAddNewProduct: PropTypes.func,
  selectedProduct: PropTypes.string,
  previousStep: PropTypes.func,
};

const ChooseProduct = props => {
  const {
    categories,
    handleSelectProduct,
    selectedProduct,
    previousStep,
    nextStep,
    handleAddNewProduct,
  } = props;
  const [searchedResult, setSearchedResult] = useState("");

  const handleChange = e => {
    const { value } = e.target;
    setSearchedResult(value.toUpperCase());
  };

  const result = categories
    .map(item => ({
      ...item,
      children: item.children.filter(
        child => child.name.indexOf(searchedResult) !== -1
      ),
    }))
    .filter(item => item.children.length > 0);

  return (
    <div className="choose-product">
      {categories == "" ? (
        <div className="">
          <div className="spinner" />
        </div>
      ) : (
        <div>
          <h5 className="form-title text-center">
            Choose Company Main Product
          </h5>
          <InputGroup className="search-bar">
            <Input
              className="search-bar__field"
              onChange={handleChange}
              placeholder="Search Product"
            />
            <InputGroupAddon addonType="append">
              <img src="/images/svg/search.svg" />
            </InputGroupAddon>
          </InputGroup>

          <div className="choose-product__categories">
            {result.map(children => (
              <div
                key={children._id}
                className="choose-product__categories__inner"
              >
                <Fragment>
                  <h4 className="choose-product__parent-category">
                    {children.name}
                  </h4>
                  <div>
                    {children.children.map(child => (
                      <CustomButton
                        key={child._id}
                        action={() => handleSelectProduct(child)}
                        name={child.name}
                        btnType="outlined"
                        classes={`${
                          selectedProduct === child._id ? "selected" : ""
                        }`}
                      />
                    ))}
                    <CustomButton
                      action={() => handleAddNewProduct(children._id)}
                      name="ADD PRODUCT +"
                      btnType="outlined"
                      classes="cta"
                    />
                  </div>
                </Fragment>
              </div>
            ))}
          </div>

          <div className="choose-product__cta">
            <CustomButton
              name="PREVIOUS"
              type="submit"
              btnType="no-border"
              action={() => previousStep()}
            />
            <CustomButton
              name="CONTINUE"
              type="submit"
              action={nextStep}
              disabled={isEmpty(selectedProduct)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

ChooseProduct.propTypes = propTypes;

export default ChooseProduct;

import React from "react";
import { change, Field, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import validateFunc from "../SignUpForm/registerValidation";
import { TextInputField } from "../../../../../_shared/components/ReduxFormFields/index";
import CustomButton from "../../../../../_shared/components/Button";
import "./ResetPassForm.scss";

const propTypes = {
  isLoading: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  isLoading: false,
};

const ResetPassForm = ({
  handleSubmit,
  isLoading,
  submitting,
  pristine,
  invalid,
}) => {
  return (
    <div className="reset_password-wrapper">
      <h5 className="reset_password-wrapper_title">Reset Password</h5>
      <form onSubmit={handleSubmit}>
        <Field
          name="email"
          label="Business email address"
          id="email"
          placeholder="Email"
          component={TextInputField}
          type="email"
        />

        <CustomButton
          name="CONFIRM"
          type="submit"
          classes="w-100"
          loading={isLoading}
          disabled={submitting || pristine || invalid}
        />
      </form>
    </div>
  );
};

ResetPassForm.propTypes = propTypes;
ResetPassForm.defaultProps = defaultProps;

const mapStateToProps = state => ({
  fields: getFormValues(formName)(state),
  isLoading: state.ui.loading.resetPassword,
});

const formName = "ResetPasswordForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  validate: validateFunc,
})(ResetPassForm);

// export default connectedForm;
export default connect(mapStateToProps, null)(connectedForm);

import React, { useEffect } from "react";
import { connect } from "react-redux";
import ResetPassForm from "../_shared/forms/ResetPassForm";
import { resetPassword, toggleResetPassword } from "../../../redux/actions";
import "./ResetPassword.scss";

const ResetPassword = ({
  toggleResetPassword,
  resetPassword,
  successfulReset,
}) => {
  useEffect(() => {
    return () => {
      toggleResetPassword(false);
    };
  }, []);

  const submit = values => {
    const payload = {
      email: values.email,
    };
    resetPassword(payload);
  };

  return (
    <main className="reset-password">
      {successfulReset ? (
        <div className="pup-up-message">
          <div className="pup-up-message_img">
            <img src="/images/confirm_email.png" alt="Confirm email" />
          </div>
          <h5 className="pup-up-message_title">Confirm your Email</h5>
          <p className="pup-up-message_mess">
            Please check your email for the confirmation link. You should be
            receiving it shortly.
          </p>
        </div>
      ) : (
        <ResetPassForm onSubmit={submit} />
      )}
    </main>
  );
};

const mapStateToProps = state => ({
  successfulReset: state.auth.successfulReset,
});

const mapDispatchToProps = {
  resetPassword,
  toggleResetPassword,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);

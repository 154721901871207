import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import { fetchCaseStudy, clearCaseStudyState } from "../../../../redux/actions";
import ProgressBar from "../../../../_shared/components/ProgressBar";
import "../../Blog/View/View.scss";

const propTypes = {
  isFetchCaseStudy: PropTypes.bool,
};

const defaultProps = {
  isFetchCaseStudy: true,
};

const View = ({
  fetchCaseStudy,
  case_study,
  isFetchCaseStudy,
  clearCaseStudyState,
}) => {
  const { slug } = useParams();
  const { description, title, main_image, tags, content } = case_study;
  const getId = url => {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return "error";
    }
  };
  const renderVideo = HTMLSTRING => {
    if (!HTMLSTRING) return "";
    if (HTMLSTRING.indexOf("</oembed>") == -1) return HTMLSTRING;
    let size = HTMLSTRING.split("</oembed>").length;

    for (let i = 0; i < size; i++) {
      let re = /<oembed.+?url=[\'"]([^\'"]+)[\'"].*?>/i;
      let results = re.exec(HTMLSTRING);
      if (!results) break;

      let videoId = getId(results[1]);
      let iframeMarkup =
        '<iframe width="560" height="315" src="//www.youtube.com/embed/' +
        videoId +
        '" frameborder="0" allowfullscreen></iframe>';
      HTMLSTRING = HTMLSTRING.replace(results[0], iframeMarkup);
      HTMLSTRING = HTMLSTRING.replace("</oembed>", "");
    }
    return HTMLSTRING;
  };

  useEffect(() => {
    fetchCaseStudy(slug);

    return () => {
      clearCaseStudyState();
    };
  }, []);

  return (
    <main>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={main_image} />
        <meta property="og:site_name" content="OPN Platform" />

        <meta name="keywords" content={tags ? tags : ""} />
      </Helmet>

      {isFetchCaseStudy ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : (
        <Container>
          <h2 className="blog-inner-page-header">{title}</h2>
          <Row>
            <div className="blog">{ReactHtmlParser(renderVideo(content))}</div>
          </Row>
        </Container>
      )}
    </main>
  );
};

View.propTypes = propTypes;
View.defaultProps = defaultProps;

const mapStateToProps = state => ({
  case_study: state.useCase.current,
  isFetchCaseStudy: state.ui.loading.fetchCaseStudy,
});

const mapDispatchToProps = {
  fetchCaseStudy,
  clearCaseStudyState,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);

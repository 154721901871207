import React, { useEffect } from "react";
import CustomButton from "../../../_shared/components/Button";
import { Col, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { AddOn } from "./AddOn";
import "./AddOn.scss";

const Addon = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const history = useHistory();

  return (
    <main className="add-on">
      <h1>Add On</h1>
      <Row>
        {AddOn.map((item, index) => {
          const { title, text1, text2, hasButton, Img } = item;
          return (
            <Col lg={6} md={6} xl={6} key={index}>
              <div className="add-on__card">
                <h5>{title}</h5>
                <p>{text1}</p>
                <p>{text2}</p>
                {hasButton && (
                    <CustomButton name="GET NOW"  action={() => history.push({
                      pathname: `/pricing/checkout`,
                      state: { 
                      plan:  "monthly",
                      tariff: "Enterprise"
                     },
                    })}/>
                )}
                {!isEmpty(Img) && (
                  <img src={`images/aboutUs/${Img}.png`} alt="card image" />
                )}
              </div>
            </Col>
          );
        })}
      </Row>
    </main>
  );
};

export default Addon;

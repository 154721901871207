import { createActionType } from "../../../_shared/functions/utils";

export const CREATE_INVENTORY = createActionType(
  "CREATE_INVENTORY",
  "inventory"
);
export const FETCH_INVENTORY = createActionType("FETCH_INVENTORY", "inventory");
export const FETCH_INVENTORY_BY_ID = createActionType(
  "FETCH_INVENTORY_BY_ID",
  "inventory"
);

export const createInventory = (payload, callBack) => ({
  type: CREATE_INVENTORY.START,
  meta: { payload, callBack },
});

export const fetchInventory = params => ({
  type: FETCH_INVENTORY.START,
  params,
});

export const fetchInventoryById = id => ({
  type: FETCH_INVENTORY_BY_ID.START,
  id,
});

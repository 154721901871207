import React from "react";
import { change, Field, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Form } from "reactstrap";
import PropTypes from "prop-types";
import { register } from "../../../../../redux/actions";
import { formatMessagesFromError } from "../../../../../_shared/functions/utils";
import Toaster from "../../../../../_shared/components/Toaster";
import validateFunc from "./registerValidation";
import { TextInputField } from "../../../../../_shared/components/ReduxFormFields";
import CustomButton from "../../../../../_shared/components/Button";

const propTypes = {
  isLoading: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  nextStep: PropTypes.func,
  register: PropTypes.func.isRequired,
  isVerified: PropTypes.bool,
};

const defaultProps = {
  isLoading: false,
};

const SignUpForm = props => {
  const {
    handleSubmit,
    isLoading,
    submitting,
    pristine,
    invalid,
    register,
    nextStep,
    isVerified,
  } = props;

  const onFormSubmit = values => {
    const payload = {
      email: values.email,
      password: values.password,
      c_password: values.confirmPassword,
      company_name: values.companyFullName,
    };

    if (isVerified) {
      register(payload, nextStep);
    } else {
      window.scrollTo(0, 230);
      Toaster.dismiss();
      Toaster.error(
        formatMessagesFromError(
          "Please verify that you are a human, and try again."
        )
      );
    }
  };

  return (
    <div className="login-form_wrapper">
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Field
          name="companyFullName"
          label="Company Name"
          id="companyFullName"
          placeholder="Company Name"
          component={TextInputField}
          type="text"
        />
        <Field
          name="email"
          label="Business email address"
          id="email"
          placeholder="Business email address"
          component={TextInputField}
          type="email"
        />
        <Field
          name="password"
          label="Password"
          placeholder="Password"
          id="password"
          component={TextInputField}
          type="password"
        />
        <Field
          name="confirmPassword"
          label="Confirm password"
          id="confirmPassword"
          placeholder="Confirm password"
          component={TextInputField}
          type="password"
        />
        <CustomButton
          name="CONFIRM"
          type="submit"
          classes="w-100"
          loading={isLoading}
          disabled={submitting || pristine || invalid || isLoading}
        />
      </Form>
      <div className="divider-both login-form_wrapper mt-4">OR</div>
    </div>
  );
};

SignUpForm.propTypes = propTypes;
SignUpForm.defaultProps = defaultProps;

const mapStateToProps = state => ({
  fields: getFormValues(formName)(state),
  isLoading: state.ui.loading.register,
});

const mapDispatchToProps = {
  change,
  register,
};

const formName = "signUp";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  validate: validateFunc,
})(SignUpForm);

// export default connectedForm;
export default connect(mapStateToProps, mapDispatchToProps)(connectedForm);

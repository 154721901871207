import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Col, CardImg, Card, CardBody } from "reactstrap";
import CustomButton from "../../../../_shared/components/Button";
import { truncate } from "../../../../_shared/functions/utils";

const CaseStudiesCard = ({ eachCase }) => {
  const { title, description, main_image, title_link } = eachCase || {};
  return (
    <Col lg={6} md={6} xl={6} style={{ marginBottom: "52px" }}>
      <Card style={{ border: "0px", borderRadius: "8px" }}>
        <CardImg
          top
          src={main_image}
          width="100%"
          alt="Card image"
          className="blog-card-image"
        />
        <CardBody>
          <h5 className="card-text">{truncate(title, 60)}</h5>
          <p className="card-text_p">{truncate(description, 110)}</p>
          <Link to={`/case-studies/${title_link}`}>
            <CustomButton btnType="no-border" name="READ MORE" />
          </Link>
        </CardBody>
      </Card>
    </Col>
  );
};

CaseStudiesCard.propTypes = {};

export default CaseStudiesCard;

import { FETCH_INVENTORY_BY_ID, FETCH_INVENTORY } from "../../actions";

const defaultState = {
  list: {},
  current: {},
};

const virtualInventoryReducer = (state = defaultState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_INVENTORY_BY_ID.SUCCESS:
      return Object.assign({}, state, { current: payload });
    case FETCH_INVENTORY.SUCCESS:
      return Object.assign({}, state, { list: payload });
    default:
      return state;
  }
};

export default virtualInventoryReducer;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Container, Row, Col } from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import {
  fetchArticle,
  fetchRelatedArticles,
  clearBlogState,
} from "../../../../redux/actions";
import ProgressBar from "../../../../_shared/components/ProgressBar";
import "./View.scss";
import BlogCard from "../BlogCard";
import SocialShare from "./SocialShare";

const propTypes = {
  isFetchingArticle: PropTypes.bool,
};

const defaultProps = {
  isFetchingArticle: true,
};

const View = ({
  fetchArticle,
  article,
  isFetchingArticle,
  fetchRelatedArticles,
  relatedArticle,
  clearBlogState,
}) => {
  const { slug } = useParams();
  const { description, title, main_image, tags, content, title_link } = article;
  const isDev =
    /dev.opnplatform/.test(window.location.host) ||
    /localhost/.test(window.location.host);
  const url = isDev
    ? `https://dev.opnplatform.com/blog/${slug}`
    : `https://opnplatform.com/blog/${slug}`;

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchArticle(slug);
    fetchRelatedArticles(tags);

    return () => {
      clearBlogState();
    };
  }, []);

  const getId = url => {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return "error";
    }
  };

  const renderVideo = HTMLSTRING => {
    if (!HTMLSTRING) return "";
    if (HTMLSTRING.indexOf("</oembed>") == -1) return HTMLSTRING;
    let size = HTMLSTRING.split("</oembed>").length;

    for (let i = 0; i < size; i++) {
      let re = /<oembed.+?url=[\'"]([^\'"]+)[\'"].*?>/i;
      let results = re.exec(HTMLSTRING);
      if (!results) break;

      let videoId = getId(results[1]);
      let iframeMarkup =
        '<iframe width="560" height="315" src="//www.youtube.com/embed/' +
        videoId +
        '" frameborder="0" allowfullscreen></iframe>';
      HTMLSTRING = HTMLSTRING.replace(results[0], iframeMarkup);
      HTMLSTRING = HTMLSTRING.replace("</oembed>", "");
    }
    return HTMLSTRING;
  };
  const SocialMediaFollowers = [
    {
      followers: "1,165 Followers",
      img: "linkedin",
      link: "https://www.linkedin.com/company/28997241/",
    },
    {
      followers: "2,144 Followers",
      img: "twiter",
      link: "https://twitter.com/OpenPackaginNet",
    },
    {
      followers: "120 Followers",
      img: "medium",
      link: "https://medium.com/@openpackagingnetwork",
    },
    {
      followers: "925 Followers",
      img: "youtube",
      link: "https://www.youtube.com/channel/UC0L8kz1iaNb_sYMTSl6XekQ",
    },
    {
      followers: "56 Fans",
      img: "facebook",
      link: "https://www.facebook.com/Open-Packaging-Network-115006196873598",
    },
    {
      followers: "7,224 Followers",
      img: "instagram",
      link: "https://www.instagram.com/opn_platform",
    },
  ];

  return (
    <main>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />
        <meta name="keywords" content={tags ? tags : ""} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={main_image} />
        <meta property="og:site_name" content="OPN Platform" />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={url} />
        <meta property="article:tag" content={tags ? tags : ""} />
        <meta property="twitter:card" content={title} />
        <meta property="twitter:image" content={main_image} />
      </Helmet>

      {isFetchingArticle ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : (
        <Container>
          <div className="social-share">
            <SocialShare article={article} url={url} />
          </div>
          <h2 className="blog-inner-page-header">{title}</h2>
          <Row>
            <div className="blog">{ReactHtmlParser(renderVideo(content))}</div>
          </Row>
          <React.Fragment>
            <h3 className="related-blog-post-header">Follow us on</h3>
            <ul className="social-datas">
              {SocialMediaFollowers.map((SocialMediaFollower, index) => {
                const { img, followers, link } = SocialMediaFollower;
                return (
                  <a
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                  >
                    <li>
                      <img src={`/images/social-icons/${img}.png`} />
                      {""} {followers}
                    </li>
                  </a>
                );
              })}
            </ul>
          </React.Fragment>
          {!isEmpty(relatedArticle) && (
            <Row>
              <Col md="12">
                <h3 className="related-blog-post-header">Related Blog Posts</h3>
              </Col>
              {relatedArticle.map(item => (
                <BlogCard key={item._id} article={item} />
              ))}
            </Row>
          )}
        </Container>
      )}
    </main>
  );
};

View.propTypes = propTypes;
View.defaultProps = defaultProps;

const mapStateToProps = state => ({
  article: state.blogs.current,
  relatedArticle: state.blogs.relatedList,
  isFetchingArticle: state.ui.loading.fetchArticle,
});

const mapDispatchToProps = {
  fetchArticle,
  fetchRelatedArticles,
  clearBlogState,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);

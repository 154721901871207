import { createActionType } from "../../../_shared/functions/utils"

export const FETCH_CO_WORKER_EMAIL = createActionType(
  "FETCH_CO_WORKER_EMAIL",
  "settings"
);
export const INVITE_CO_WORKER = createActionType(
  "INVITE_CO_WORKER",
  "settings"
);

export const CHANGE_INVITED_CO_WORKER_ACCESS = createActionType(
  "CHANGE_INVITED_CO_WORKER_ACCESS",
  "settings"
);

export const FETCH_ALL_INVITED_CO_WORKER = createActionType(
  "FETCH_ALL_INVITED_CO_WORKER",
  "settings"
);

export const DELETE_CO_WORKER_INVITE = createActionType(
  "DELETE_CO_WORKER_INVITE",
  "settings"
);

export const DELETE_CO_WORKER = createActionType(
  "DELETE_CO_WORKER",
  "settings"
);

export const FETCH_COMPANY_INFO = createActionType(
  "FETCH_COMPANY_INFO",
  "settings"
);

export const CONFIRM_COMPANY_INFO = createActionType(
  "CONFIRM_COMPANY_INFO",
  "settings"
);

export const CONFIRM_ORDER_LIMIT = createActionType(
  "CONFIRM_ORDER_LIMIT",
  "settings"
);

export const UPDATE_COMPANY_INFO = createActionType(
  "UPDATE_COMPANY_INFO",
  "settings"
);

export const UPLOAD_COMPANY_LOGO = createActionType(
  "UPLOAD_COMPANY_LOGO",
  "settings"
);

export const SET_IBAN_FOR_BANK = createActionType(
  "SET_IBAN_FOR_BANK",
  "settings"
);
export const CONNECT_STRIPE = createActionType("CONNECT_STRIPE", "settings");
export const DISCONNECT_STRIPE = createActionType(
  "DISCONNECT_STRIPE",
  "settings"
);
export const CHECK_STRIPE_ENABLED = createActionType("CHECK_STRIPE_ENABLED", "settings");

export const SUBSCRIBE_USER = createActionType("SUBSCRIBE_USER", "settings");
export const UPDATE_SUBSCRIBED_USER = createActionType("UPDATE_SUBSCRIBED_USER", "settings");

export const VALIDATE_PROMO_CODE = createActionType(
  "VALIDATE_PROMO_CODE",
  "settings"
);

export const CLEAR_PROMO_CODE_STATE = createActionType(
  "CLEAR_PROMO_CODE_STATE",
  "settings"
);

export const VERIFY_USER_BUSINESS = createActionType(
  "VERIFY_USER_BUSINESS",
  "settings"
);

export const fetchCoWorkerEmail = payload => ({
  type: FETCH_CO_WORKER_EMAIL.START,
  payload,
});

export const inviteCoWorker = (payload, reset, nextAction) => ({
  type: INVITE_CO_WORKER.START,
  meta: { payload, reset, nextAction },
});

export const changeInvitedCoWorkerAccess = (payload, nextAction) => ({
  type: CHANGE_INVITED_CO_WORKER_ACCESS.START,
  meta: { payload, nextAction },
});

export const fetchAllInvitedCoWorker = () => ({
  type: FETCH_ALL_INVITED_CO_WORKER.START,
});

export const deleteCoWorkerInvite = (id, nextAction) => ({
  type: DELETE_CO_WORKER_INVITE.START,
  meta: { id, nextAction },
});

export const deleteCoWorker = (id, nextAction) => ({
  type: DELETE_CO_WORKER.START,
  meta: { id, nextAction },
});

export const confirmCompanyInfo = () => ({
  type: CONFIRM_COMPANY_INFO.START,
});

export const confirmOrderLimit = () => ({
  type: CONFIRM_ORDER_LIMIT.START,
});

export const fetchCompanyInfo = () => ({
  type: FETCH_COMPANY_INFO.START,
});

export const updateCompanyInfo = payload => ({
  type: UPDATE_COMPANY_INFO.START,
  payload,
});

export const uploadCompanyLogo = payload => ({
  type: UPLOAD_COMPANY_LOGO.START,
  payload,
});

export const connectStripe = payload => ({
  type: CONNECT_STRIPE.START,
  payload,
});

export const checkStripeEnabled = () => ({
  type: CHECK_STRIPE_ENABLED.START,
});

export const setIbanForBank = payload => ({
  type: SET_IBAN_FOR_BANK.START,
  payload,
});

export const disconnectStripe = payload => ({
  type: DISCONNECT_STRIPE.START,
  payload,
});

export const subscribeUser = payload => ({
  type: SUBSCRIBE_USER.START,
  payload,
});

export const updateSubscribedUser = payload => ({
  type: UPDATE_SUBSCRIBED_USER.START,
  payload,
});


export const validatePromoCode = payload => ({
  type: VALIDATE_PROMO_CODE.START,
  payload,
});

export const clearPromoCodeState = () => ({
  type: CLEAR_PROMO_CODE_STATE,
});

export const verifyUserBusiness = payload => ({
  type: VERIFY_USER_BUSINESS.START,
  payload,
});

import React from "react";
import { connect } from "react-redux";
import { Link, Route } from "react-router-dom";
import "./Page404.scss";
import { Container, Row, Col } from "reactstrap";

const Page404 = ({ auth }) => {
  return (
    <main className="not-found">
      <Container>
        <Row>
          <Col sm="12" md={{ size: 6, offset: 3 }} className="not-found__inner">
            <p className="not-found__title">Something went wrong</p>
            <img src="/images/404.png" alt="404" />

            {auth && (
              <React.Fragment>
                <p className="not-found__subtitle">
                  Maybe you are interested in the next sections
                </p>
                <ul className="not-found__nav">
                  <li>
                    <Link to="/market?market=offers">Offers</Link>
                  </li>
                  <li>
                    <Link to="/market?market=requests">Requests</Link>
                  </li>
                </ul>
              </React.Fragment>
            )}
          </Col>
        </Row>
      </Container>
    </main>
  );
};

const mapStateToProps = state => ({
  auth: state.auth.accessToken,
});
export default connect(mapStateToProps)(Page404);

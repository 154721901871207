import {
  createActionType,
  createActionString,
} from "../../_shared/functions/utils";

export const API_REQUEST = createActionType("API_REQUEST", "API");
export const CONNECT_SOCKET_CLIENT = createActionString(
  "CONNECT_SOCKET_CLIENT",
  "socket"
);

export const apiRequest = meta => ({
  type: API_REQUEST.START,
  meta,
});

export const connectSocketClient = socket => ({
  type: CONNECT_SOCKET_CLIENT,
  payload: socket,
});

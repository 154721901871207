import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CustomButton from "../../../../../_shared/components/Button";
import validateFunc from "../SignUpForm/registerValidation";
import { TextInputField } from "../../../../../_shared/components/ReduxFormFields/index";
import "./InviteForm.scss";

const propTypes = {
  isLoading: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  isLoading: false,
};

const InviteForm = ({
  handleSubmit,
  submitting,
  invalid,
  pristine,
  isLoading,
  submit,
}) => {
  return (
    <React.Fragment>
      <div className="invited-form">
        <h5 className="">Welcome To OPN</h5>

        <form onSubmit={handleSubmit(submit)}>
          <Field
            name="email"
            label="Business email address"
            id="email"
            placeholder="Email"
            component={TextInputField}
            type="email"
            disabled
          />
          <Field
            name="password"
            label="Password"
            placeholder="Password"
            id="password"
            component={TextInputField}
            type="password"
          />
          <Field
            name="confirmPassword"
            label="Confirm password"
            id="confirmPassword"
            placeholder="Confirm password"
            component={TextInputField}
            type="password"
          />
          <CustomButton
            name="CONFIRM"
            type="submit"
            classes="w-100"
            loading={isLoading}
            disabled={submitting || pristine || invalid || isLoading}
          />
        </form>
      </div>
    </React.Fragment>
  );
};

InviteForm.propTypes = propTypes;
InviteForm.defaultProps = defaultProps;

const mapStateToProps = state => ({
  isLoading: state.ui.loading.register,
  initialValues: {
    email: state.settings.email.mail,
  },
});

const mapDispatchToProps = {
  // change,
  // register,
};

const formName = "InviteFormForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  validate: validateFunc,
})(InviteForm);

export default connect(mapStateToProps, mapDispatchToProps)(connectedForm);

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { fetchAllDeal } from "../../../../../redux/actions";
import AllDeals from "./AllDeals";
import Subscription from "./Subscription";
import OpnCommission from "./OpnCommission";

const propTypes = {
  fetchAllDeal: PropTypes.func,
};

const BillingContent = ({ fetchAllDeal }) => {
  useEffect(() => {
    const params = {
      count: 10,
      offset: 0,
    };
    fetchAllDeal(params);
  }, []);

  const renderTab = (title, disabled) => <Tab disabled={disabled}>{title}</Tab>;

  const renderTabPanel = ContentPanel => (
    <TabPanel className="billing__tabs__panel">
      <ContentPanel />
    </TabPanel>
  );

  return (
    <div className="office-content-panel">
      <Tabs className="tabs billing__tabs">
        <TabList>
          {renderTab("Deals")}
          {renderTab("OPN Commission")}
          {renderTab("Subscription")}
          {renderTab("Credits", true)}
        </TabList>

        <div>
          {renderTabPanel(AllDeals)}
          {renderTabPanel(OpnCommission)}
          {renderTabPanel(Subscription)}
          {renderTabPanel("BillingList")}
        </div>
      </Tabs>
    </div>
  );
};

BillingContent.propTypes = propTypes;

const mapStateToProps = state => ({});

const mapDispatchToProps = { fetchAllDeal };

export default connect(mapStateToProps, mapDispatchToProps)(BillingContent);

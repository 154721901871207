import { routerMiddleware } from "connected-react-router"
// import { createLogger } from "redux-logger";
import { createBrowserHistory } from "history"
import { applyMiddleware, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import thunk from "redux-thunk"
import applicationMiddleware from "./middleware"
import createRootReducer from "./reducers"


export const history = createBrowserHistory();

export const getPersistedStore = () => {
  let cache = {};
  if (localStorage.hasOwnProperty("opn:persist")) {
    const localStorageItem = localStorage.getItem("opn:persist");
    cache = JSON.parse(localStorageItem);
  }

  return cache;
};

export const persistStore = object => {
  if (!(object instanceof Object)) {
    throw new Error("persistStore requires an Object as param");
  }
  const currentCache = getPersistedStore();
  const objectToString = JSON.stringify(
    Object.assign({}, currentCache, object)
  );

  localStorage.setItem("opn:persist", objectToString);
};

const parseMiddleware = () => {
  const middleware = [
    thunk,
    routerMiddleware(history),
    ...applicationMiddleware,
  ];

  if (process.env.NODE_ENV === "production") {
    return applyMiddleware(...middleware);
  } else {
    // Enable additional logging in non-production environments.
    // middleware.push(createLogger());
    return applyMiddleware(...middleware);
  }
};

const store = createStore(
  createRootReducer(history),
  getPersistedStore(),
  composeWithDevTools(parseMiddleware())
);

// Do on state change
store.subscribe(() => {
  const { auth, ui } = store.getState();

  const cache = { auth, ui };
  // STORE BASIC DATA NEEDED
  persistStore(cache);
});

export default store;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { debounce } from "lodash";
import { change, reset } from "redux-form";
import ReactTooltip from "react-tooltip";
import {
  Form,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Collapse,
  Tooltip,
} from "reactstrap";
import { fetchCategories, fetchCountries, searchOrders } from "../../../../../redux/actions";
import Filter from "./Filter";
import Tags from "./Tags";
import "./Search.scss";

const propTypes = {
  fetchCategories: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  categoryList: PropTypes.array,
  countryList: PropTypes.array,
  reset: PropTypes.func,
  change: PropTypes.func,
  searchOrders: PropTypes.func,
  location: PropTypes.object,
};

const Search = (props) => {
  const {
    fetchCategories,
    fetchCountries,
    categoryList,
    countryList,
    change,
    reset,
    searchOrders,
    location,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [filters, setFilters] = useState([]);
  const { query } = location;
  const { market, productList } = query;
  const queryValue = market || "offers";
  const purpose =
    queryValue === "offers" ? "SELL" : queryValue === "requests" ? "BUY" : "";
  const toggle = () => setIsOpen(!isOpen);
  const searchOrdersDebounce = debounce(searchOrders, 1000, { maxWait: 1500 });

  useEffect(() => {
    fetchCategories();
    fetchCountries();
  }, []);

  const handleDelete = (itemId) => {
    let result = filters.filter((it) => itemId !== it.id);
    setFilters(result);
    change("filterForm", `item_${itemId}`, false);
  };

  const handleClearFilter = () => {
    setFilters([]);
    reset("filterForm");
  };

  const handleInputChange = (event) => {
    const name = event.target.value;
    const payload = {
      name,
      count: 20,
      offset: 0,
      purpose,
      productList: productList || "all",
    };

    searchOrdersDebounce(payload);
  };

  return (
    <div className="search">
      <Form className="search-form">
        <InputGroup>
          <Input placeholder="Type to search" onChange={handleInputChange} />
          <InputGroupAddon addonType="append" id="AutoHideFilterTip">
            <InputGroupText>
              <ReactTooltip id="filter" place="top" multiline={true} />
              <div
                data-for="filter"
                data-tip="Filter products by categories"
                data-iscapture="true"
                className="label"
                onClick={toggle}
              >
                <img
                  className="filter-icon"
                  src="/images/svg/icons/filter.svg"
                  alt=""
                />
                <span className="filter-text">Filter</span>
              </div>
              <Collapse isOpen={isOpen}>
                <Filter
                  categories={categoryList}
                  countries={countryList}
                  setFilters={setFilters}
                  filters={filters}
                  purpose={purpose}
                  productList={productList}
                />
              </Collapse>
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
        <Tags
          deleteFilter={handleDelete}
          clearFilter={handleClearFilter}
          filters={filters}
        />
      </Form>
    </div>
  );
};

Search.propTypes = propTypes;

const mapDispatchToProps = { fetchCategories, fetchCountries, change, reset, searchOrders };

const mapStateToProps = (state) => ({
  categoryList: state.categories.list,
  countryList: state.countries.list,
  location: state.router.location,
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);

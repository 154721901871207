import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { auth } from "../../../../../api/firebase";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import "./SocialAuthForm.scss";
import { social } from "../../../../../redux/actions";

const propTypes = {
  nextStep: PropTypes.func,
  social: PropTypes.func,
  from_register: PropTypes.bool,
};

const SocialAuthForm = props => {
  const { nextStep, social, location, from_register } = props;
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const search = location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const access_token = params.get("access_token"); // bar
    const refresh_token = params.get("refresh_token"); // bar
    const error = params.get("error"); // bar

    if (error) {
      setErrorMessage(error);
    } else if (from_register) {
      if (access_token && refresh_token) {
        const payload = { access_token };
        social(payload, nextStep, "lookup");
        return;
      }
    } else if (access_token && refresh_token) {
      const payload = { access_token };
      social(payload, "", "lookup");
    }
  });

  const handleClick = provider => {
    setErrorMessage(null);
    auth
      .getAuth()
      .signInWithPopup(provider)
      .then(data => {
        const payload = { auth: data };
        if (from_register) {
          social(payload, nextStep, "googleAuth");
        } else {
          social(payload, "", "googleAuth");
        }
      })
      .catch(err => {
        setErrorMessage(err.message);
      });
  };

  const linkedInOAuth = () => {
    let isDev =
      /dev.opnplatform/.test(window.location.host) ||
      /localhost/.test(window.location.host);
    const apiBase = isDev ? process.env.API_URL_DEV : process.env.API_URL;
    window.location.href = `${apiBase}/user/oauth/linkedin`;
  };

  return (
    <div>
      <div className="social-auth">
        <div
          className="social-auth__google"
          onClick={() => handleClick(auth.googleOAuth())}
        >
          <img src="/images/svg/icons/google.svg" alt="Google logo" />
          Google
        </div>
        <div className="social-auth__linked-in" onClick={linkedInOAuth}>
          <img src="/images/svg/icons/linked-in.svg" alt="LinkedIn logo" />
          LinkedIn
        </div>
      </div>
      {errorMessage ? (
        <p className="text-danger py-5">{errorMessage}</p>
      ) : (
        <Fragment />
      )}
    </div>
  );
};

SocialAuthForm.propTypes = propTypes;

const mapStateToProps = state => ({
  location: state.router.location,
  from_register: state.auth.fromRegister,
});

const mapDispatchToProps = { social };

export default connect(mapStateToProps, mapDispatchToProps)(SocialAuthForm);

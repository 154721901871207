import {
  FETCH_ALL_SUBSCRIPTION,
  FETCH_INTEGRATION_KEY,
  FETCH_MY_DOCUMENTS,
  FETCH_ECO_IMPACT_STATS,
  FETCH_ANALYTICS,
} from "../../actions";

const defaultState = {
  list: [],
  apiKey: {},
  docs: [],
  stats: {},
  analytics: {},
};

const officeReducer = (state = defaultState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_ALL_SUBSCRIPTION.SUCCESS:
      return Object.assign({}, state, { list: payload });
    case FETCH_ECO_IMPACT_STATS.SUCCESS:
      return Object.assign({}, state, { stats: payload });
    case FETCH_INTEGRATION_KEY.SUCCESS:
      return Object.assign({}, state, { apiKey: payload });
    case FETCH_MY_DOCUMENTS.SUCCESS:
      return Object.assign({}, state, { docs: payload.data });
    case FETCH_ANALYTICS.SUCCESS:
      return Object.assign({}, state, { analytics: payload });
    default:
      return state;
  }
};

export default officeReducer;

import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import Editor from "react-medium-editor";
import ChatItem from "./ChatItem";
import { InputGroup } from "reactstrap";
import CustomButton from "../../../../_shared/components/Button";
import {
  fetchDealChatId,
  sendChatMessage,
  uploadMedia,
} from "../../../../redux/actions";
import {
  formatMessagesFromError,
  truncate,
} from "../../../../_shared/functions/utils";
import Toaster from "../../../../_shared/components/Toaster";
import "medium-editor/dist/css/medium-editor.css";
import "medium-editor/dist/css/themes/default.css";
import "./Chat.scss";
import ProgressBar from "../../../../_shared/components/ProgressBar";

const propTypes = {
  fetchDealChatId: PropTypes.func,
  sendChatMessage: PropTypes.func,
  uploadMedia: PropTypes.func,
  isFetchingChatThread: PropTypes.bool,
  isUploadingFile: PropTypes.bool,
  chat: PropTypes.object,
  location: PropTypes.object,
};

const defaultProps = {
  isFetchingChatThread: true,
};

const Chat = (props) => {
  let scrollbar = useRef();
  const { goBack } = useHistory();
  const {
    fetchDealChatId,
    location,
    sendChatMessage,
    chat,
    isFetchingChatThread,
    uploadMedia,
    isUploadingFile,
  } = props;
  const { state } = location;
  const { _id, thread } = chat;

  useEffect(() => {
    const payload = {
      id: state.dealId,
    };
    fetchDealChatId(payload);
  }, []);

  useEffect(() => {
    handleScrollBottom();
  }, [thread]);

  const handleScrollBottom = () => {
    if (!scrollbar || !scrollbar.current) {
      return;
    }

    setTimeout(() => scrollbar.current.scrollToBottom(), 500);
  };

  const [inputText, setInputText] = useState("");
  const [attachment, setAttachment] = useState([]);

  const handleChange = (text) => {
    setInputText(text);
  };

  const handleSubmit = () => {
    const payload = {
      chat: _id,
      text: inputText,
    };

    if (!isEmpty(attachment)) {
      console.log("attachment :", attachment);
      const attachFiles = attachment.map((file) => file._id);
      payload.attachments = attachFiles;
    }

    sendChatMessage(payload);
    setInputText(null);
    setAttachment([]);
  };

  const setAttachmentHandler = (data) => {
    setAttachment((currentState) => [...currentState, data.data]);
  };

  const handleUpload = (event) => {
    const file = event.target.files;
    let mediaType = "img";
    if (file[0].type.split("/")[0] === "application") mediaType = "doc";

    if (
      file[0].type.split("/")[0] !== "application" &&
      file[0].type.split("/")[0] !== "image"
    ) {
      Toaster.dismiss();
      Toaster.error(
        formatMessagesFromError(
          "This file type is not supported, check and try again."
        )
      );

      return;
    }

    const payload = {
      files: file,
      uploadFrom: "chat",
      mediaType,
    };

    uploadMedia(payload, setAttachmentHandler, "uploadMedia");
  };

  return (
    <div className="chat">
      <header className="chat__header">
        <CustomButton
          btnType="no-border"
          action={() => goBack()}
          faName="fa-angle-left"
          name="Back to details"
        />
      </header>
      <Scrollbars ref={scrollbar} style={{ height: "65vh" }}>
        {isFetchingChatThread ? (
          <div className="d-flex justify-content-center p-5">
            <ProgressBar />
          </div>
        ) : (
          <section className="chat__thread">
            {thread &&
              thread.data.map((data, index) => {
                let isSameTime = true;
                const prevItem = thread.data[index - 1];
                if (!isEmpty(prevItem)) {
                  isSameTime = !moment(data.created_at).isSame(
                    prevItem.created_at,
                    "day"
                  );
                }

                return (
                  <ChatItem
                    key={data._id}
                    data={data}
                    isSameTime={isSameTime}
                  />
                );
              })}
          </section>
        )}
      </Scrollbars>
      {/* cdn_link */}
      <div className="chat__attachment-box">
        {attachment.map((file, i) => (
          <div className="chat__attachment-box__item" key={i}>
            {file.mimetype.split("/")[0] === "image" ? (
              <img src={file.cdn_link} alt="image" />
            ) : file.name.split(".")[1] === "pdf" ? (
              <img src="/images/svg/icons/pdf.svg" alt="pdf" />
            ) : (
              <img src="/images/svg/icons/doc.svg" alt="doc" />
            )}
            {truncate(file.name, 20)}
          </div>
        ))}
      </div>
      <section className="chat__input">
        <InputGroup>
          {isUploadingFile ? (
            <div className="loading">
              <ProgressBar spinType="tiny" />
            </div>
          ) : (
            <label htmlFor="file" className="icon-wrap">
              <i className="fas fa-paperclip" />

              <input
                className="d-none"
                type="file"
                id="file"
                accept=".doc,.pdf,image/*"
                onChange={(event) => handleUpload(event)}
              />
            </label>
          )}
          <div className="editor-wrap">
            <Editor text={inputText} onChange={handleChange} />
          </div>
        </InputGroup>
        <CustomButton
          disabled={isEmpty(inputText)}
          action={handleSubmit}
          name="Send"
        />
      </section>
    </div>
  );
};

Chat.propTypes = propTypes;
Chat.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  chat: state.deals.chat,
  isFetchingChatThread:
    state.ui.loading.fetchChatThread || state.ui.loading.fetchDealChatId,
  isUploadingFile: state.ui.loading.uploadMedia,
  location: state.router.location,
});

const mapDispatchToProps = {
  fetchDealChatId,
  sendChatMessage,
  uploadMedia,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);

import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Row, Col } from "reactstrap";

const Managing = props => {
  return (
    <Row>
      <Col md="12" className="faq">
        <Accordion allowZeroExpanded={true} preExpanded={["open"]}>
          <AccordionItem uuid="open">
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                Can I place multiple orders?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Yes, it’s recommended that you do in order to enjoy the full
                benefits of this feature.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                How many products can I trade?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                You can trade your whole product line or integrate your software
                automatically when producing or searching for new products.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                Can I place recurring orders?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                OPN currently processes spot orders in real time and you will be
                able to manage recurring orders very soon. We will keep you
                updated.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                How do I check supplier's feedback made by other buyers?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                You can view the supplier's Rating & Reviews made by other
                buyers on their product card.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                How do I leave feedback for my order?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                You can rank vendors any time once a product has been shipped.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                How can I buy products via the OPN platform?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                You select an Offer from the Market and click on it, and then
                follow the next simple steps.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                How can I sell products via the OPN platform?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Place your Product in the Market, then the buyer who suits your
                conditions will confirm the Deal.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </Col>
    </Row>
  );
};

Managing.propTypes = {};

export default Managing;

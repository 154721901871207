import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tooltip } from "reactstrap";
import { isEmpty } from "lodash";
import { truncate } from "../../../../../../_shared/functions/utils";
import ProgressBar from "../../../../../../_shared/components/ProgressBar";
import DeleteDialog from "../../../../../../_shared/components/partials/DeleteDialog";
import {
  uiToggleModal,
  deleteMydocument,
  fetchMydocuments,
} from "../../../../../../redux/actions";
import "./AllDocuments.scss";

const propTypes = {
  isFetchMydocuments: PropTypes.bool,
  uiToggleModal: PropTypes.func,
  deleteMydocument: PropTypes.func,
  documents: PropTypes.array,
  fetchMydocuments: PropTypes.func,
};

const defaultProps = {
  isFetchMydocuments: true,
};

const AllDocuments = ({
  documents,
  isFetchMydocuments,
  uiToggleModal,
  deleteMydocument,
  fetchMydocuments,
}) => {
  // const [tooltipOpen, setTooltipOpen] = useState(false);
  // const toggle = () => setTooltipOpen(!tooltipOpen);
  const [id, setId] = useState("");

  const calToAction = () => {
    const payload = {
      id: id,
    };

    deleteMydocument(payload, fetchMydocuments);
  };

  return (
    <main className="all-documents">
      {isFetchMydocuments ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : isEmpty(documents) ? (
        <React.Fragment>
          <p>
            <em className="d-flex justify-content-center p-5">
              No document to display
            </em>
          </p>
        </React.Fragment>
      ) : (
        documents.map((doc, index) => (
          <div key={index} className="all-documents__each-document">
            <div className="all-documents__each-document__left">
              {doc.file.name.split(".")[1] === "pdf" ? (
                <img src="/images/svg/icons/pdf.svg" alt="pdf" />
              ) : doc.file.name.split(".")[1] === "docx" ? (
                <img src="/images/svg/icons/doc.svg" alt="doc" />
              ) : doc.file.name.split(".")[1] === "pptx" ? (
                <img src="/images/power_point.png" alt="pptx" />
              ) : (
                <img src="/images/excel.png" alt="excel" />
              )}
              <a
                href={doc.file.cdn_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p>{truncate(doc.file.name, 20)}</p>
              </a>
            </div>
            {/* <Tooltip
              placement="top"
              isOpen={tooltipOpen}
              autohide={false}
              target="DisabledAutoHide"
              toggle={toggle}
            >
              Delete
            </Tooltip>
           */}
            <i
              className="fas fa-trash"
              //id="DisabledAutoHide"
              onClick={() => {
                uiToggleModal();
                setId(doc._id);
              }}
            />
          </div>
        ))
      )}
      <DeleteDialog
        title="Delete Document"
        actionName="Delete"
        message="Are you sure you want to delete this Document?"
        action={() => calToAction()}
      />
    </main>
  );
};

AllDocuments.propTypes = propTypes;
AllDocuments.defaultProps = defaultProps;

const mapStateToProps = state => ({
  documents: state.office.docs,
  isFetchMydocuments: state.ui.loading.fetchMydocuments,
});
const mapDispatchToProps = {
  uiToggleModal,
  deleteMydocument,
  fetchMydocuments,
};

export default connect(mapStateToProps, mapDispatchToProps)(AllDocuments);

import React from "react";
import { change, Field, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import VerificationVal from "./VerificationVal";
import { TextInputField } from "../../../../../../_shared/components/ReduxFormFields";
import CustomButton from "../../../../../../_shared/components/Button";

const propTypes = {
  isLoading: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  isLoading: false,
};

const VerificationForm = ({
  handleSubmit,
  isLoading,
  submitting,
  pristine,
  invalid,
}) => {
  return (
    <div>
      <h5 className="reset_password-wrapper_title">Verify your company</h5>
      <form onSubmit={handleSubmit}>
        <Field
          name="reqistration_number"
          label="Reqistration number"
          id="reqistration_number"
          placeholder="Business reqistration number"
          component={TextInputField}
          type="text"
        />
        <Field
          name="jurisdiction"
          label="Jurisdiction"
          id="jurisdiction"
          placeholder="Jurisdiction of Incorporation"
          component={TextInputField}
          type="text"
        />
        <h6>Date of Incorporation calender</h6>
        <Field
          name="date"
          label="Date"
          id="date"
          placeholder="Date of Incorporation"
          component={TextInputField}
          type="date"
        />
        <CustomButton
          name="verify"
          type="submit"
          classes="w-100"
          loading={isLoading}
          disabled={submitting || pristine || invalid}
        />
      </form>
    </div>
  );
};

VerificationForm.propTypes = propTypes;
VerificationForm.defaultProps = defaultProps;

const mapStateToProps = state => ({
  fields: getFormValues(formName)(state),
  isLoading: state.ui.loading.verifyUserBusiness,
});

const formName = "VerificationForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  validate: VerificationVal,
})(VerificationForm);

// export default connectedForm;
export default connect(mapStateToProps, null)(connectedForm);

import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Row, Col } from "reactstrap";

const Support = () => {
  return (
    <Row>
      <Col md="12" className="faq-accordion">
        <Accordion allowZeroExpanded={true} preExpanded={["open"]}>
          <AccordionItem uuid="open">
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                How do I return products to the supplier?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                You will need to contact your supplier directly to negotiate
                returning goods, including shipping costs and methods.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                What can I do if I fail to track the shipment?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                If you failed to track the shipment with the tracking number or
                other delivery information provided by your supplier or shipping
                company, please contact the supplier or the logistic company
                directly for further assistance.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                What can I do if I have been scammed?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                If there is a trade dispute, please contact the supplier for a
                solution first. If this does not work, you can contact the OPN
                Team to negotiate with the supplier further.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </Col>
    </Row>
  );
};

Support.propTypes = {};

export default Support;

import categories from "./categories";
import countries from "./countries";
import orders from "./orders";
import deals from "./deals";
import settings from "./settings";
import office from "./office";
import tender from "./tender";
import notifications from "./notifications";
import inventory from "./virtualInventory";

export default {
  categories,
  countries,
  orders,
  deals,
  settings,
  office,
  tender,
  notifications,
  inventory,
};

import {
  createActionString,
  createActionType,
} from "../../_shared/functions/utils";

export const UI_LOADING = createActionType("UI_LOADING", "UI");
export const UI_ERROR = createActionString("UI_ERROR", "UI");
export const UI_NAVIGATE = createActionString("UI_NAVIGATE", "UI");

export const UI_MODAL_TOGGLE = createActionString("UI_MODAL_TOGGLE", "UI");
export const UI_MODAL_CUSTOM_TOGGLE = createActionString(
  "UI_MODAL_CUSTOM_TOGGLE",
  "UI"
);

export const UI_PAYMENT_SUCCESS_TOGGLE = createActionString(
  "UI_PAYMENT_SUCCESS_TOGGLE",
  "UI"
);
export const UI_DIALOG_TOGGLE = createActionString("UI_DIALOG_TOGGLE", "UI");
export const UI_SIGNUP_MODAL_TOGGLE = createActionString(
  "UI_SIGNUP_MODAL_TOGGLE",
  "UI"
);
export const UI_INCREMENT_STEP = createActionString("UI_INCREMENT_STEP", "UI");
export const UI_DECREMENT_STEP = createActionString("UI_DECREMENT_STEP", "UI");
export const UI_INITIALIZING_ONBOARDING = createActionString(
  "UI_INITIALIZING_ONBOARDING",
  "UI"
);

export const SET_TAB_INDEX = createActionString("SET_TAB_INDEX", "UI");

export const startUILoading = key => ({
  type: UI_LOADING.START,
  key,
});

export const stopUILoading = key => ({
  type: UI_LOADING.END,
  key,
});

export const updateUIError = (key, value) => ({
  type: UI_ERROR,
  key,
  value,
});

export const navigateTo = path => ({
  type: UI_NAVIGATE,
  payload: path,
});

export const uiToggleModal = () => ({
  type: UI_MODAL_TOGGLE,
});

export const uiToggleModalCustom = () => ({
  type: UI_MODAL_CUSTOM_TOGGLE,
});

export const uiTogglePaymentSuccess = () => ({
  type: UI_PAYMENT_SUCCESS_TOGGLE,
});

export const uiToggleShowDialog = () => ({
  type: UI_DIALOG_TOGGLE,
});

export const uiToggleShowSignupModal = () => ({
  type: UI_SIGNUP_MODAL_TOGGLE,
});

export const incrementStep = () => ({
  type: UI_INCREMENT_STEP,
});

export const decrementStep = () => ({
  type: UI_DECREMENT_STEP,
});

export const toggleInitializingOnboarding = () => ({
  type: UI_INITIALIZING_ONBOARDING,
});

export const setTabIndex = value => ({
  type: SET_TAB_INDEX,
  value,
});

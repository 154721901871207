import React, { useState, useRef, forwardRef, Fragment } from "react";
import { Input, InputGroup, FormFeedback } from "reactstrap";
import classNames from "classnames";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import en from "date-fns/locale/en-GB";
import "react-datepicker/dist/react-datepicker.css";
import "./ReactDatePicker.scss";

registerLocale("en", en);

const DatePickr = ({
  className,
  disabled = false,
  input,
  name,
  label,
  id,
  placeholder,
  minDate,
  maxDate,
  value,
  onChange,
  selectedDate,
  disabledKeyboardNavigation,
  fixedHeight,
  useYear,
  useFilterOptions,
  yearRange,
  openToDate,
  when,
  minAge,
  withTime = false,
  meta: { touched, error, warning },
  ...rest
}) => {
  const [startDate, setStartDate] = useState(
    when.previous
      ? new Date(
          Object.prototype.toString.call(selectedDate) === "[object Date]"
            ? selectedDate
            : new Date()
        )
      : new Date(
          moment(
            Object.prototype.toString.call(selectedDate) === "[object Date]"
              ? selectedDate
              : new Date()
          )
            .add(1, "days")
            .valueOf()
        )
  );
  const [year, setYear] = useState(
    new Date(
      new Date(new Date().setFullYear(new Date().getFullYear() - minAge))
    ).getFullYear()
  );
  const datepicker = useRef(null);
  const inputRef = useRef(null);

  const handleChange = date => {
    setStartDate(new Date(date.valueOf()));

    input
      ? input.onChange(new Date(date.valueOf()))
      : onChange(new Date(date.valueOf()));
    // datepicker.current.setOpen(false);
  };

  function getYear(date) {
    return date.getFullYear();
  }

  function getMonth(date) {
    return date.getMonth();
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let DateFormat = "LL";

  if (withTime) DateFormat = "LL | h:mm a";

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className="text-input-container">
      <InputGroup
        onClick={!disabled ? onClick : undefined}
        className="float-label"
      >
        <Input
          disabled={disabled}
          id={id}
          onClick={onClick}
          placeholder={placeholder}
          className={classNames("filter-datepicker", className, {
            "is-invalid": touched && error,
            "is-valid": !error && input.value,
          })}
          value={
            input && input.value
              ? moment(new Date(input.value).toISOString()).format(DateFormat)
              : "Click to select a date."
          }
          name={name}
          readOnly={true}
          ref={ref}
        />
        {label && <label htmlFor={id}>{label}</label>}
      </InputGroup>
      {touched && error && (
        <FormFeedback className="d-block">{error}</FormFeedback>
      )}
    </div>
  ));

  const time_convert = num => {
    if (num.indexOf(":") > -1) {
      return num;
    }

    if (isNaN(num)) return;

    return `0${num}:00`;
  };

  const CustomTimeInput = ({ value, onChange }) => (
    <input
      value={value}
      onChange={e => onChange(time_convert(e.target.value) || "0:00")}
      style={{ border: "solid 1px pink" }}
    />
  );

  return (
    <DatePicker
      className="react-datepicker-container"
      ref={datepicker}
      selected={selectedDate ? new Date(selectedDate.valueOf()) : startDate}
      dateFormat={DateFormat}
      showTimeInput={withTime}
      customTimeInput={<CustomTimeInput />}
      onChange={date => {
        handleChange(new Date(date));
      }}
      useWeekdaysShort={true}
      locale="en"
      placeholderText="Weeks start on Monday"
      customInput={<CustomInput ref={inputRef} />}
      disabledKeyboardNavigation={disabledKeyboardNavigation}
      minDate={
        minDate
          ? Object.prototype.toString.call(minDate) === "[object Date]"
            ? minDate
            : null
          : moment().toDate()
      }
      maxDate={
        maxDate
          ? Object.prototype.toString.call(maxDate) === "[object Date]"
            ? maxDate
            : null
          : null
      }
      renderCustomHeader={({
        date,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
        changeYear,
      }) => {
        return (
          <Fragment>
            <div className={"custom-datepicker-nav"}>
              <button
                onClick={() => {
                  setYear(getYear(date));
                  decreaseMonth();
                }}
                disabled={prevMonthButtonDisabled}
                className={"custom-dp-btn"}
                type={"button"}
              >
                <i className={"fa fa-chevron-left"} />
              </button>
              <span>
                <strong>
                  {months[getMonth(date)]} {getYear(date)}
                </strong>
              </span>
              <button
                onClick={() => {
                  increaseMonth();
                  setYear(getYear(date));
                }}
                disabled={nextMonthButtonDisabled}
                type={"button"}
                className={"custom-dp-btn"}
              >
                <i className={"fa fa-chevron-right"} />
              </button>
            </div>

            {useYear && (
              <div className={"yearpicker"}>
                <Input
                  type="select"
                  name="select"
                  id="datepicker-select"
                  onChange={e => {
                    const year = Number(e.target.value);
                    changeYear(year);
                    setYear(year);
                  }}
                  defaultValue={year}
                >
                  {Array.from(
                    Array(
                      typeof yearRange === "number" ? Number(yearRange) : 60
                    ).keys()
                  ).map(value => {
                    const currentYear = new Date(
                      new Date().setFullYear(new Date().getFullYear() - minAge)
                    ).getFullYear();
                    return (
                      <option
                        value={currentYear - value}
                        key={currentYear - value}
                      >
                        {currentYear - value}
                      </option>
                    );
                  })}
                </Input>
              </div>
            )}
          </Fragment>
        );
      }}
      {...rest}
    />
  );
};

const datePickrDefaultProps = {
  when: {
    previous: true,
  },
  fixedHeight: false,
  disabledKeyboardNavigation: true,
  useYear: false,
  useFilterOptions: true,
  yearRange: 60,
  minAge: 18,
};

DatePickr.defaultProps = datePickrDefaultProps;

export default DatePickr;

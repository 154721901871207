import React from "react";
import { Modal } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import VerificationForm from "../../forms/VerificationForm";
import {
  uiToggleModal,
  verifyUserBusiness,
} from "../../../../../../redux/actions";
import "./BusinessVerificationModal.scss";

const propTypes = {
  uiToggleModal: PropTypes.func,
  isOpen: PropTypes.bool,
  profile: PropTypes.object,
  verifyUserBusiness: PropTypes.func,
};

const BusinessVerificationModal = ({
  isOpen,
  uiToggleModal,
  profile,
  verifyUserBusiness,
}) => {
  const { name, location } = profile || {};
  const { country } = location || {};
  const submit = values => {
    const { date, jurisdiction, reqistration_number } = values;
    const dateOfIncorporation = date.split("-");

    const year = Number(dateOfIncorporation[0]);
    const month = Number(dateOfIncorporation[1]);
    const day = Number(dateOfIncorporation[2]);
    const payload = {
      CountryCode: country,
      BusinessName: name,
      BusinessRegistrationNumber: reqistration_number,
      JurisdictionOfIncorporation: jurisdiction.toUpperCase(),
      DayOfIncorporation: day,
      MonthOfIncorporation: month,
      YearOfIncorporation: year,
    };

    verifyUserBusiness(payload);
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        uiToggleModal();
      }}
    >
      <div className="modal-content__success">
        <VerificationForm onSubmit={submit} />
      </div>
    </Modal>
  );
};

BusinessVerificationModal.propTypes = propTypes;

const mapStateToProps = state => ({
  isOpen: state.ui.modal.isOpen,
  profile: state.settings.companyInfo.profile,
});

const mapDispatchToProps = {
  uiToggleModal,
  verifyUserBusiness,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessVerificationModal);

import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import "./DealList.scss";
import ProgressBar from "../../../../_shared/components/ProgressBar";
import "./DealList.scss";
import CustomButton from "../../../../_shared/components/Button";
import DealCard from "./DealCard";

const propTypes = {
  setDeal: PropTypes.func,
  isLoading: PropTypes.bool,
  currentId: PropTypes.string,
  deals: PropTypes.array,
  label: PropTypes.string,
};

const DealList = props => {
  const { deals, label, currentId, setDeal, isLoading } = props;
  const { push } = useHistory();
  return isLoading ? (
    <div className="d-flex justify-content-center py-5">
      <ProgressBar spinType="tiny" />
    </div>
  ) : (
    <div className="deals-sidebar__notification">
      {!isEmpty(deals) ? (
        deals.map(deal => (
          <DealCard
            key={deal._id}
            deal={deal}
            active={currentId === deal._id}
            action={setDeal}
          />
        ))
      ) : (
        <div className="no-data">
          <p>
            You have no{" "}
            {label ? (
              label
            ) : (
              <span>
                <strong>open</strong> or <strong>close</strong>
              </span>
            )}{" "}
            conversations yet. Please find an offer or request you are
            interested in and start the deal process.
          </p>
          <CustomButton
            action={() => push(`/market`)}
            name="Let's go find one"
            btnType="no-border"
            imgSrc="/images/svg/icons/search-blue.svg"
          />
        </div>
      )}
    </div>
  );
};

DealList.propTypes = propTypes;

export default DealList;

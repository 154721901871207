import { createActionType } from "../../../_shared/functions/utils";

export const FETCH_UNREAD_NOTIFICATIONS_COUNT = createActionType(
  "FETCH_UNREAD_NOTIFICATIONS_COUNT",
  "notifications"
);

export const FETCH_CURRENT_NOTIFICATIONS = createActionType(
  "FETCH_CURRENT_NOTIFICATIONS",
  "notifications"
);

export const FETCH_ALL_NOTIFICATIONS = createActionType(
  "FETCH_ALL_NOTIFICATIONS",
  "notifications"
);

export const MARK_AS_VIEWED_NOTIFICATION = createActionType(
  "MARK_AS_VIEWED_NOTIFICATION",
  "notifications"
);

export const fetchUnreadNotificationsCount = () => ({
  type: FETCH_UNREAD_NOTIFICATIONS_COUNT.START,
});

export const fetchCurrentNotifications = params => ({
  type: FETCH_CURRENT_NOTIFICATIONS.START,
  params,
});

export const fetchAllNotifications = params => ({
  type: FETCH_ALL_NOTIFICATIONS.START,
  params,
});

export const markAsViewedNotification = Id => ({
  type: MARK_AS_VIEWED_NOTIFICATION.START,
  Id,
});

import {
  createActionType,
  createActionString,
} from "../../_shared/functions/utils";

// Users

export const SUBSCRIPTION_STATUS = createActionType(
  "SUBSCRIPTION_STATUS",
  "auth"
);

export const FETCH_CLIENT_ID = createActionType("FETCH_CLIENT_ID", "auth");
export const SOCIAL = createActionType("SOCIAL", "auth");
export const LOGIN = createActionType("LOGIN", "auth");
export const REGISTER = createActionType("REGISTER", "auth");
export const RESET_PASSWORD = createActionType("RESET_PASSWORD", "auth");
export const UPDATE_PASSWORD = createActionType("UPDATE_PASSWORD", "auth");
export const UPDATE_USER = createActionType("UPDATE_USER", "auth");
export const VERIFY = createActionType("VERIFY", "auth");
export const VERIFY_LINK = createActionType("VERIFY_LINK", "auth");
export const RESEND_VERIFY = createActionType("RESEND_VERIFY", "auth");
export const LOGOUT = createActionType("LOGOUT", "auth");
export const CHANGE_PASSWORD = createActionType("CHANGE_PASSWORD", "auth");
export const CLEAR_AUTH_STATE = createActionString("CLEAR_AUTH_STATE", "auth");
export const TOGGLE_RESET_PASSWORD = createActionType(
  "TOGGLE_RESET_PASSWORD",
  "auth"
);
export const TOGGLE_FROM_REGISTER = createActionString(
  "TOGGLE_FROM_REGISTER",
  "auth"
);

export const fetchSubscriptionStatus = () => ({
  type: SUBSCRIPTION_STATUS.START,
});

export const fetchClientId = () => ({
  type: FETCH_CLIENT_ID.START,
});

export const login = (payload, nextStep) => ({
  type: LOGIN.START,
  meta: { payload, nextStep },
});

export const social = (payload, nextStep, key) => ({
  type: SOCIAL.START,
  meta: { payload, nextStep, key },
});

export const register = (payload, nextStep) => ({
  type: REGISTER.START,
  meta: { payload, nextStep },
});

export const resetPassword = payload => ({
  type: RESET_PASSWORD.START,
  payload,
});

export const toggleResetPassword = payload => ({
  type: TOGGLE_RESET_PASSWORD.START,
  payload,
});

export const updatePassword = payload => ({
  type: UPDATE_PASSWORD.START,
  payload,
});

export const updateUser = payload => ({
  type: UPDATE_USER.START,
  payload,
});

export const changePassword = payload => ({
  type: CHANGE_PASSWORD.START,
  payload,
});

export const verify = payload => ({
  type: VERIFY.START,
  payload,
});

export const verifyLink = payload => ({
  type: VERIFY_LINK.START,
  payload,
});

export const resendVerify = payload => ({
  type: RESEND_VERIFY.START,
  payload,
});

export const logout = () => ({
  type: LOGOUT.START,
});

export const clearAuthState = () => ({
  type: CLEAR_AUTH_STATE,
});

export const toggleFromRegister = () => ({
  type: TOGGLE_FROM_REGISTER,
});

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import ProductCard from "../../../../_shared/components/ProductCard";
import {
  fetchMyOrders,
  draftMyOrder,
  publishMyDraftOrder,
} from "../../../../../../redux/actions";
import ProgressBar from "../../../../../../_shared/components/ProgressBar";
import DeleteDialog from "../../../../../../_shared/components/partials/DeleteDialog";

const propTypes = {
  fetchMyOrders: PropTypes.func,
  draftMyOrder: PropTypes.func,
  publishMyDraftOrder: PropTypes.func,
  isFetchingMyOrders: PropTypes.bool,
};

const Published = ({
  myOrders,
  fetchMyOrders,
  isFetchingMyOrders,
  draftMyOrder,
  location,
}) => {
  const history = useHistory();

  const { query } = location;
  const { tab } = query;
  const queryValue = tab || "offers";
  const purpose =
    queryValue === "offers" ? "SELL" : queryValue === "requests" && "BUY";
  const previousLabel =
    queryValue === "offers"
      ? "my offers"
      : queryValue === "requests" && "my requests";
  const [productId, setProductId] = useState("");

  const handleRedirectView = (e, id, name) => {
    const productName = name.split(" ").join("-");
    history.push({
      pathname: `market/${productName}/${id}/view`,
      state: { previousLabel },
    });
  };

  const callToAction = () => {
    draftMyOrder(productId);
  };

  useEffect(() => {
    const payload = {
      count: 200,
      offset: 0,
      purpose: purpose,
    };

    fetchMyOrders(payload);
  }, []);

  return (
    <React.Fragment>
      {isFetchingMyOrders ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : isEmpty(myOrders.data) ? (
        <div className="d-flex justify-content-center p-5">
          <img src="/images/svg/icons/no-data.svg" alt="No data icon" />
        </div>
      ) : (
        <div className="product__list">
          {myOrders.data &&
            myOrders.data.map(order => (
              <ProductCard
                key={order._id}
                order={order}
                setProductId={setProductId}
                action={handleRedirectView}
                showSwitchBtn={true}
              />
            ))}
        </div>
      )}
      <DeleteDialog
        title="Unpublish Product"
        actionName="unpublish"
        message="Are you sure you what to unpublish this Product from the market"
        action={callToAction}
      />
    </React.Fragment>
  );
};
Published.propTypes = propTypes;

const mapStateToProps = state => ({
  location: state.router.location,
  myOrders: state.orders.myList,
  isFetchingMyOrders:
    state.ui.loading.fetchMyOrders || state.ui.loading.searchOrders,
});

const mapDispatchToProps = {
  fetchMyOrders,
  draftMyOrder,
  publishMyDraftOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(Published);

import React from 'react';
import { withRouter } from 'react-router';
import './PartnerLogoSection.scss';
import CustomButton from '../../../../../_shared/components/Button';

const PartnerLogoSection = ({ button, header, title, partnersLogos, history }) => {
  const redirectToSignup = () => history.push(`/register`);
  return (
    <section className="partner text-center">
      {title && <div className="partner_subtitle">{title}</div>}
      {header && (
        <div className="main__title">
          <h4>{header.title}</h4>
          {header.subTitle && <p className="hero__sub-herder">{header.subTitle}</p>}
        </div>
      )}
      <ul className="partner_list">
        {partnersLogos.map((imageName, id) => (
          <li className="partner_list_items" key={id}>
            <img src={`images/partners/${imageName}.png`} alt="Packaging Partenrs " />
          </li>
        ))}
      </ul>
      <div className="partner_link">
        {
          button ? <CustomButton action={redirectToSignup} name={button} /> :
          <a target="_blank" href="mailto:partners@opnplatform.com">
            <CustomButton action={redirectToSignup} name="Become a partner" />
          </a>}
      </div>
    </section>
  );
};

export default withRouter(PartnerLogoSection);

import React from "react";
import PropTypes from "prop-types";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Container, Row, Col } from "reactstrap";
import "./PaymentOption.scss";

const row1 = [
  { name: "1. Choose your Provider", imgName: "providers" },
  { name: "2. Account Set Up", imgName: "setup" },
  { name: "3. Instant Payouts", imgName: "payout" },
];

const row2 = [
  { name: "1. Risk Engine", imgName: "engine-risk" },
  { name: "2. Notification Engine", imgName: "engine-notification" },
  { name: "3. ReportingEngine", imgName: "engine-reporting" },
];

const renderDealsCard = ({ name, imgName }) => (
  <div className="deal-card border-0">
    <h4>{name}</h4>
    <img src={`/images/svg/cards/${imgName}.png`} className="img-fluid" />
  </div>
);

const PaymentOption = props => {
  return (
    <main className="payment-option">
      <Container>
        <Row>
          <Col>
            <h1 className="title">Payment Options</h1>
            <Tabs className="payment-option__tabs">
              <TabList>
                <Tab>subcriptions</Tab>
                <Tab>deals</Tab>
              </TabList>

              <TabPanel className="subscription">
                <Row>
                  <Col md="6">
                    <h4 className="sub-title">Partner:</h4>
                    <div className="partner-logo">
                      <img src="/images/svg/cards/stripe.svg" alt="stripe" />
                    </div>
                  </Col>
                  <Col md="6">
                    <h4 className="sub-title">Cards:</h4>
                    <div className="cards-logo">
                      <img src="/images/svg/cards/visa.svg" alt="visa" />
                      <img src="/images/svg/cards/master.svg" alt="master" />
                      <img src="/images/svg/cards/maestro.svg" alt="maestro" />
                      <img
                        src="/images/svg/cards/americaexpress.svg"
                        alt="america express"
                      />
                      <img
                        src="/images/svg/cards/discover.svg"
                        alt="discover"
                      />
                      <img src="/images/svg/cards/jcb.svg" alt="jcb" />
                      <img src="/images/svg/cards/oval.svg" alt="oval" />
                      <img
                        src="/images/svg/cards/unionpay.svg"
                        alt="unionpay"
                      />
                    </div>
                  </Col>
                  <Col md="6" className="features">
                    <h4 className="sub-title">Wallets:</h4>
                    <ul className="wallet-list">
                      <li>Alipay</li>
                      <li>Microsoft Pay</li>
                      <li>Amex Express Checkout</li>
                      <li>Masterpass by Mastercard</li>
                      <li>Apple Pay</li>
                      <li>Visa Checkout</li>
                      <li>Google Pay</li>
                      <li>WeChat Pay</li>
                    </ul>
                  </Col>
                  <Col md="6" className="features">
                    <h4 className="sub-title">Local payment methods:</h4>
                    <ul className="wallet-list">
                      <li>ACH Credit Transfer </li>
                      <li>Giropay</li>
                      <li>ACH Debit</li>
                      <li>iDEAL</li>
                      <li>Alipay</li>
                      <li>Bancontact</li>
                      <li>SEPA Direct Debit</li>
                      <li>EPS</li>
                      <li>WeChat Pay</li>
                      <li>SOFORT</li>
                    </ul>
                  </Col>
                </Row>
              </TabPanel>
              <TabPanel className="deals">
                <Row>
                  <Col md="12" className="deals__section">
                    <p className="title-book">
                      Reduced costs, reclaimed time, faster payments, more
                      visibility into your finances, less paper usage, better
                      cash flow, and greater convenience. An investment in a
                      modern payment solution drives considerable ROI — and
                      quickly.
                    </p>
                    <Row className="card-section">
                      {row1.map((item, i) => (
                        <Col key={i}>{renderDealsCard(item)}</Col>
                      ))}
                    </Row>
                  </Col>
                  <Col md="12" className="deals__section">
                    <p className="title-book">
                      Top-notch security protection, coupled with the way
                      digital systems track payments, work together to deliver
                      enhanced security.
                    </p>
                    <Row className="card-section">
                      {row2.map((item, i) => (
                        <Col key={i}>{renderDealsCard(item)}</Col>
                      ))}
                    </Row>
                  </Col>
                  <Col md="12" className="deals__section">
                    <p className="title-book">
                      More businesses are using digital payments. Modern
                      solution in place, much of the repetitive work that had to
                      be done manually is automated — giving you and your
                      employees more time to focus on other important areas of
                      operations.
                    </p>
                  </Col>
                </Row>
              </TabPanel>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

PaymentOption.propTypes = {};

export default PaymentOption;

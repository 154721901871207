import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Collapse } from "reactstrap";
import { connect } from "react-redux";
import { uiToggleModal } from "../../../../../redux/actions";

const SwitchMoreMenu = ({ handleClickAction }) => {
  const [isOpen, setIsOpen] = useState(false);
  const options = ["Unpublish"];

  const handleOnClick = event => {
    event.stopPropagation();
    const anchorEl = event.target.childNodes[0].data;
    setIsOpen(false);

    if (anchorEl) {
      switch (anchorEl) {
        case "Unpublish":
          handleClickAction();
          break;
      }
    }
  };

  const handleAction = e => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <div className="more-menu">
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
          <i className="fas fa-ellipsis-h" onClick={handleAction} />
          <div>
            <Collapse isOpen={isOpen}>
              {options.map((option, index) => (
                <div
                  key={index}
                  name={option}
                  className="more-menu__item"
                  onClick={handleOnClick}
                >
                  {option}
                </div>
              ))}
            </Collapse>
          </div>
        </OutsideClickHandler>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  uiToggleModal,
};

export default connect(null, mapDispatchToProps)(SwitchMoreMenu);

import {
  FETCH_ACTIVE_TENDER,
  FETCH_ARCHIVED_TENDER,
  FETCH_TENDER_ID,
  FETCH_TENDER_BID,
  REJECT_TENDER_BID,
  ACCEPT_TENDER_BID,
  FETCH_PURCHASING_ORDER,
  FETCH_TENDER_OFFERS,
  CREATE_BID,
  apiRequest,
  GET,
  POST,
  navigateTo,
} from "../../actions";
import { API } from "../../../_shared/defs";

const createBid = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === CREATE_BID.START) {
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.TENDER}/create/bid`,
        key: "createBid",
        onSuccess: () => {
          dispatch(
            navigateTo(`/my-office?tab=tender&view=${action.payload.tender_id}`)
          );
        },
        headerParams: false,
        payload: action.payload,
      })
    );
  }
};

const fetchActiveTender = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_ACTIVE_TENDER.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.TENDER}/active/all`,
        key: "fetchActiveTender",
        onSuccess: FETCH_ACTIVE_TENDER.SUCCESS,
        headerParams: false,
        params: action.params,
      })
    );
  }
};

const fetchTenderId = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_TENDER_ID.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.TENDER}/single/${action.id}`,
        key: "fetchTenderId",
        onSuccess: FETCH_TENDER_ID.SUCCESS,
        headerParams: false,
      })
    );
  }
};

const fetchTenderBid = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_TENDER_BID.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.TENDER}/bid/single/${action.id}`,
        key: "fetchTenderBid",
        onSuccess: FETCH_TENDER_BID.SUCCESS,
        headerParams: false,
      })
    );
  }
};

const acceptTenderBid = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === ACCEPT_TENDER_BID.START) {
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.TENDER}/bid/accept`,
        key: "acceptTenderBid",
        successMessage: "Offer accepted successfully",
        onSuccess: ACCEPT_TENDER_BID.SUCCESS,
        headerParams: false,
        payload: action.payload,
      })
    );
  }
};

const rejectTenderBid = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === REJECT_TENDER_BID.START) {
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.TENDER}/bid/reject`,
        key: "rejectTenderBid",
        successMessage: "Offer rejected successfully",
        onSuccess: REJECT_TENDER_BID.SUCCESS,
        headerParams: false,
        payload: action.payload,
      })
    );
  }
};

const fetchArchivedTender = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_ARCHIVED_TENDER.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.TENDER}/archive/all`,
        key: "fetchArchivedTender",
        onSuccess: FETCH_ARCHIVED_TENDER.SUCCESS,
        headerParams: false,
        params: action.params,
      })
    );
  }
};

const fetchTenderOffers = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_TENDER_OFFERS.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.TENDER}/bid/seller`,
        key: "fetchTenderOffers",
        onSuccess: FETCH_TENDER_OFFERS.SUCCESS,
        headerParams: false,
        params: action.params,
      })
    );
  }
};

const fetchPurchasingOrder = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_PURCHASING_ORDER.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.TENDER}/bid/accepted`,
        key: "fetchPurchasingOrder",
        onSuccess: FETCH_PURCHASING_ORDER.SUCCESS,
        headerParams: false,
        params: action.params,
      })
    );
  }
};

export default [
  createBid,
  fetchActiveTender,
  fetchTenderId,
  fetchArchivedTender,
  fetchTenderBid,
  rejectTenderBid,
  acceptTenderBid,
  fetchTenderOffers,
  fetchPurchasingOrder,
];

import {
  FETCH_CO_WORKER_EMAIL,
  FETCH_COMPANY_INFO,
  CONFIRM_COMPANY_INFO,
  CONFIRM_ORDER_LIMIT,
  VALIDATE_PROMO_CODE,
  CLEAR_PROMO_CODE_STATE,
  FETCH_ALL_INVITED_CO_WORKER,
} from "../../actions";

const defaultState = {
  email: {},
  companyInfo: {},
  hasCompanyInfo: false,
  exhaustOrderLimit: false,
  isValidPromoCode: {
    status: false,
    message: "",
    percent_off: 0
  },
  list: [],
};

const settingsReducer = (state = defaultState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_CO_WORKER_EMAIL.SUCCESS:
      return Object.assign({}, state, { email: payload });
    case FETCH_COMPANY_INFO.SUCCESS:
      return Object.assign({}, state, { companyInfo: payload });
    case FETCH_ALL_INVITED_CO_WORKER.SUCCESS:
      return Object.assign({}, state, { list: payload });
    case CONFIRM_COMPANY_INFO.SUCCESS:
      return Object.assign({}, state, { hasCompanyInfo: payload.status });
    case VALIDATE_PROMO_CODE.SUCCESS:
      return Object.assign({}, state, {
        isValidPromoCode: payload,
      });
    case CLEAR_PROMO_CODE_STATE:
      console.log("clear promo code....")
      return Object.assign({}, state, {
        isValidPromoCode: defaultState.isValidPromoCode,
      });
    case CONFIRM_ORDER_LIMIT.SUCCESS:
      console.log("payload.status :", payload);
      return Object.assign({}, state, { exhaustOrderLimit: !payload.status });
    default:
      return state;
  }
};

export default settingsReducer;

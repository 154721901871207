import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import numeral from "numeral";
import { createOrder, fetchInventoryById } from "../../../../redux/actions";
import CreateProductForm from "../../_shared/Forms/CreateProductForm";
import { stringify, truncate } from "../../../../_shared/functions/utils";
import { countries, deliveryMethods, units } from "../../../../_shared/defs";
import ProgressBar from "../../../../_shared/components/ProgressBar";

const propTypes = {
  createOrder: PropTypes.func,
  fetchInventoryById: PropTypes.func,
  isCreatingOrder: PropTypes.bool,
  isFetchingInventoryById: PropTypes.bool,
  routerLocation: PropTypes.object,
  inventoryCurrent: PropTypes.object,
};

const CreateProduct = ({
  createOrder,
  isCreatingOrder,
  isFetchingInventoryById,
  routerLocation,
  inventoryCurrent,
  fetchInventoryById,
}) => {
  const { type } = routerLocation.query;
  const { inventoryId } = routerLocation.state || {};
  const marketType = (type && type.slice(0, -1)) || "";
  const purpose =
    type === "offers" || type === "resells"
      ? "SELL"
      : type === "requests" && "BUY";

  const {
    category,
    name,
    description,
    country,
    delivery,
    amount,
    price,
    premium,
    photos,
    documents,
  } = inventoryCurrent.virtualorder || {};

  useEffect(() => {
    if (inventoryId) fetchInventoryById(inventoryId);
  }, []);

  const handleSubmit = values => {
  console.log('values :', values);
    const {
      category,
      product,
      price,
      images = [],
      docs = [],
      description,
      term,
      variant,
      location,
      currency,
      unit,
      destination,
      quantity,
      premium,
      duration,
    } = values;

    const imagesId = images.map(image => image._id);
    const docsId = docs.map(doc => doc._id);

    let payload = {
      title: product.value,
      price: numeral(price).value(),
      photos: imagesId,
      documents: docsId,
      purpose: purpose || "SELL",
      description,
      product: category.value,
      id: category.value,
      delivery: { term: term.value, variant: variant.value },
      country: location.value,
      currency: currency.value,
      destination,
      quantity: Number(quantity),
      duration: duration || null,
      premium: premium ? 1 : 0,
      amount: {
        number: 1,
        unit: unit.value,
      },
    };

    if (inventoryId) payload.virtualinventory = inventoryId;

    createOrder(payload);
  };

  const formatInitialValues = {
    currency: { label: "USD", value: "USD" },
    priceType: "fixed",
  };

  const initialStateData = {
    ...formatInitialValues,
    category: {
      label: <p>{stringify((category && category.name) || "")}</p>,
      value: category?._id,
    },
    product: {
      label: <p>{name}</p>,
      value: name,
    },
    description,
    location: countries
      .filter(({ code }) => code === country)
      .map(item => ({
        label: item.name,
        value: item.code,
      }))[0],
    variant: deliveryMethods
      .filter(({ code }) => code === delivery?.variant)
      .map(item => ({
        label: item.name,
        value: item.code,
      }))[0],
    unit: units
      .filter(({ code }) => code === amount?.unit)
      .map(item => ({
        label: item.name,
        value: item.code,
      }))[0],
    term: {
      label: delivery?.term,
      value: delivery?.term,
    },
    price: numeral(price / 100).format("0,0.00"),
    premium: premium === 0 ? false : true,
    images: photos,
    docs: documents,
  };

  return (
    <main>
      {isFetchingInventoryById ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : (
        <CreateProductForm
          onSubmit={handleSubmit}
          formLoading={isCreatingOrder}
          formType={marketType}
          initialValues={inventoryId ? initialStateData : formatInitialValues}
        />
      )}
    </main>
  );
};

CreateProduct.propTypes = propTypes;

const mapStateToProps = state => ({
  isFetchingInventoryById: state.ui.loading.fetchInventoryById,
  isCreatingOrder: state.ui.loading.createOrder,
  routerLocation: state.router.location,
  inventoryCurrent: state.inventory.current,
});

const mapDispatchToProps = {
  createOrder,
  fetchInventoryById,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProduct);

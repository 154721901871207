import _ from "lodash"
import React, { Fragment, useEffect } from "react"
import { connect } from "react-redux"
import { useParams } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import { createBid, createDeal, fetchOrder } from "../../../../redux/actions"
import ProgressBar from "../../../../_shared/components/ProgressBar"
import ProductCard from "../../_shared/components/ProductCard"
import PreOrderForm from "../../_shared/Forms/PreOrderForm/PreOrderForm"
import "./PreOrder.scss"

const defaultProps = {
  isFetchingOrder: true,
};

const PreOrder = props => {
  const {
    fetchOrder,
    createDeal,
    createBid,
    current,
    location,
    isFetchingOrder,
    isCreatingOrder,
  } = props;
  const { purpose, tender, _id } = current;
  console.log("current :", current);
  const { productId } = useParams();

  useEffect(() => {
    fetchOrder(productId);
  }, []);

  const handleSubmit = values => {
    if (purpose === "BUY") {
      const { price, variant, currency, location } = values;
      const payload = {
        price: Number(price),
        deliveryVariant: variant.value,
        location,
        currency: currency.value,
      };

      if (tender) {
        payload.tender_id = tender;
        return createBid(payload);
      }

      payload.product_id = _id;
      createDeal(payload, "request");
    } else {
      const payload = {
        ..._.omit(values, "accept_condition"),
        quantity: Number(values.quantity),
        payment: values.payment.value,
        product_id: productId,
      };

      createDeal(payload);
    }
  };

  return (
    <main className="pre-order">
      <Container>
        <Row>
          <Col md={{ size: 8, offset: 2 }} className="">
            {isFetchingOrder ? (
              <div className="d-flex justify-content-center p-5">
                <ProgressBar />
              </div>
            ) : (
              <Fragment>
                <h4 className="pre-order__title">Pre-order</h4>
                <ProductCard order={current} />
                <PreOrderForm
                  onSubmit={handleSubmit}
                  purpose={purpose}
                  formLoading={isCreatingOrder}
                />
              </Fragment>
            )}
          </Col>
        </Row>
      </Container>
    </main>
  );
};
PreOrder.propTypes = {};
PreOrder.defaultProps = defaultProps;

const mapStateToProps = state => ({
  current: state.orders.current,
  isFetchingOrder: state.ui.loading.fetchOrder,
  isCreatingOrder: state.ui.loading.createDeal,
  location: state.router.location,
});

const mapDispatchToProps = {
  fetchOrder,
  createDeal,
  createBid,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreOrder);

import { createActionType } from "../../../_shared/functions/utils";

export const CREATE_DEAL = createActionType("CREATE_DEAL", "deals");
export const FETCH_DEAL_STAT = createActionType("FETCH_DEAL_STAT", "deals");
export const PRE_ACCEPT_DEAL = createActionType("PRE_ACCEPT_DEAL", "deals");
export const CANCEL_DEAL = createActionType("CANCEL_DEAL", "deals");
export const ACCEPT_DEAL = createActionType("ACCEPT_DEAL", "deals");
export const FETCH_DEAL = createActionType("FETCH_DEAL", "deals");
export const UPDATE_DEAL = createActionType("UPDATE_DEAL", "deals");
export const UPDATE_DEAL_PRICE = createActionType("UPDATE_DEAL_PRICE", "deals");
export const FETCH_OFFERS_DEALS = createActionType(
  "FETCH_OFFERS_DEALS",
  "deals"
);
export const FETCH_REQUESTS_DEALS = createActionType(
  "FETCH_REQUESTS_DEALS",
  "deals"
);
export const DEAL_PAYMENT = createActionType("DEAL_PAYMENT", "deals");
export const FETCH_INVOICE = createActionType("FETCH_INVOICE", "invoice");
export const FETCH_DEAL_BY_STATUS = createActionType(
  "FETCH_DEAL_BY_STATUS",
  "deals"
);
export const FETCH_DEAL_CHAT_ID = createActionType(
  "FETCH_DEAL_CHAT_ID",
  "deals"
);
export const FETCH_CHAT_THREAD = createActionType("FETCH_CHAT_THREAD", "deals");
export const SEND_CHAT_MESSAGE = createActionType("SEND_CHAT_MESSAGE", "deals");
export const INVITE_LOGISTIC_PARTNER = createActionType(
  "INVITE_LOGISTIC_PARTNER",
  "deals"
);
export const FETCH_ALL_DEAL = createActionType("FETCH_ALL_DEAL", "deals");

export const createDeal = (payload, key) => ({
  type: CREATE_DEAL.START,
  meta: { payload, key },
});

export const fetchDealStat = () => ({
  type: FETCH_DEAL_STAT.START,
});

export const preAcceptDeal = payload => ({
  type: PRE_ACCEPT_DEAL.START,
  payload,
});

export const acceptDeal = payload => ({
  type: ACCEPT_DEAL.START,
  payload,
});

export const cancelDeal = payload => ({
  type: CANCEL_DEAL.START,
  payload,
});

export const fetchDeal = payload => ({
  type: FETCH_DEAL.START,
  payload,
});

export const fetchOffersDeals = payload => ({
  type: FETCH_OFFERS_DEALS.START,
  payload,
});

export const fetchRequestsDeals = payload => ({
  type: FETCH_REQUESTS_DEALS.START,
  payload,
});

export const updateDeal = payload => ({
  type: UPDATE_DEAL.START,
  payload,
});

export const updateDealPrice = (payload, callbackAction) => ({
  type: UPDATE_DEAL_PRICE.START,
  meta: { payload, callbackAction },
});

export const dealPayment = (payload, dealType) => ({
  type: DEAL_PAYMENT.START,
  meta: { payload, dealType },
});

export const fetchInvoice = payload => ({
  type: FETCH_INVOICE.START,
  payload,
});

export const fetchDealByStatus = (params, status) => ({
  type: FETCH_DEAL_BY_STATUS.START,
  meta: { params, status },
});

export const fetchDealChatId = payload => ({
  type: FETCH_DEAL_CHAT_ID.START,
  payload,
});

export const sendChatMessage = payload => ({
  type: SEND_CHAT_MESSAGE.START,
  payload,
});

export const fetchChatThread = payload => ({
  type: FETCH_CHAT_THREAD.START,
  payload,
});

export const inviteLogisticPartner = payload => ({
  type: INVITE_LOGISTIC_PARTNER.START,
  payload,
});

export const fetchAllDeal = params => ({
  type: FETCH_ALL_DEAL.START,
  params,
});

import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import _ from "lodash"
import PropTypes from "prop-types"
import qs from "query-string"
import React, { Fragment, useEffect } from "react"
import { connect } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { Col, Container, Media, Row } from "reactstrap"
import { fetchDeal, fetchTenderBid } from "../../../../redux/actions"
import ProgressBar from "../../../../_shared/components/ProgressBar"
import { priceFormat, truncate } from "../../../../_shared/functions/utils"
import CheckoutForm from "../../_shared/Forms/CheckoutForm"
import "./DealCheckout.scss"

const propTypes = {
  isFetchingDeal: PropTypes.bool,
  current: PropTypes.object,
  fetchDeal: PropTypes.func,
  fetchTenderBid: PropTypes.func,
};

const defaultProps = {
  isFetchingDeal: false,
};

const STRIPE_PUBLISHABLE_KEY = process.env.STRIPE_PUBLISHABLE_KEY;

const Checkout = ({ current, fetchDeal, isFetchingDeal, fetchTenderBid }) => {
  const { product, amount, seller, paid, order, from } = current;
  console.log("isFetchingDeal :=====>>>>>>", isFetchingDeal);
  console.log("seller :", seller);
  console.log("current :", current);
  const { company, name, photos, tender } = product || order || {};
  const { dealId } = useParams();
  const { goBack, location } = useHistory();
  const { bid } = qs.parse(location.search);

  useEffect(() => {
    if (bid) {
      fetchTenderBid(dealId);
    } else {
      const payload = { id: dealId };
      fetchDeal(payload);
    }
  }, []);

  useEffect(() => {
    if (paid) {
      goBack();
    }
  }, [paid]);

  const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
  // {
  //   stripeAccount: bid
  //     ? from && from.custom_account_id
  //     : (seller && seller.custom_account_id) || "",
  // }
  return (
    <div>
      <main className="deal-checkout">
        <Container>
          <Row>
            {isFetchingDeal ? (
              <Col
                md={{ size: 8, offset: 2 }}
                className="d-flex justify-content-center p-5"
              >
                <ProgressBar />
              </Col>
            ) : (
              <Fragment>
                <Col md={{ size: 8, offset: 2 }} className="">
                  <h4 className="deal-checkout__title">Checkout</h4>
                  <Media className="deals-detail__header__media">
                    <Media className="deals-detail__header__media__image" left>
                      {!_.isEmpty(photos) && photos[0] && (
                        <img key={photos[0]._id} src={photos[0].cdn_link} />
                      )}
                    </Media>
                    <Media
                      className="deals-detail__header__media__body body"
                      body
                    >
                      <Media className="body__heading" heading>
                        {name ? truncate(name, 50) : "---"}
                      </Media>
                      <p className="body__content">
                        {company && company.verified && (
                          <img
                            className="body__content__verified"
                            src="/images/svg/icons/verify.svg"
                            alt="chat icon"
                          />
                        )}
                        <span className="body__content__company">
                          {company && company.profile && company.profile.name
                            ? company.profile.name
                            : "---"}
                        </span>
                      </p>
                      <h4 className="body__content__amount">
                        Total: <span>{priceFormat(amount)}</span>
                      </h4>
                    </Media>
                  </Media>
                </Col>
                <Col md={{ size: 8, offset: 2 }} className="">
                  <Elements stripe={stripePromise}>
                    <CheckoutForm tenderId={tender} />
                  </Elements>
                </Col>
              </Fragment>
            )}
          </Row>
        </Container>
      </main>
    </div>
  );
};

Checkout.propTypes = propTypes;
Checkout.defaultProps = defaultProps;

const mapStateToProps = state => ({
  current: state.deals.current,
  isFetchingDeal:
    state.ui.loading.fetchDeal ||
    state.deals.current.paid ||
    state.ui.loading.fetchTenderBid,
});

const mapDispatchToProps = {
  fetchDeal,
  fetchTenderBid,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);

import React from "react";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import CustomButton from "../../../../../_shared/components/Button";
import validateFunc from "../../../../Authentication/_shared/forms/SignUpForm/registerValidation";
import { TextInputField } from "../../../../../_shared/components/ReduxFormFields/index";

const propTypes = {
  isLoading: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  submit: PropTypes.func,
};

const defaultProps = {
  isLoading: false,
};

const InviteLogisticForm = ({
  handleSubmit,
  submitting,
  invalid,
  pristine,
  isLoading,
  submit,
}) => {
  return (
    <form onSubmit={handleSubmit(submit)}>
      <Row>
        <Col md={10}>
          <Field
            name="email"
            id="email address"
            placeholder="Email address"
            component={TextInputField}
            type="text"
          />
        </Col>
        <Col md={2}>
          <CustomButton
            name="send"
            type="submit"
            classes="regular"
            loading={isLoading}
          />
        </Col>
      </Row>
    </form>
  );
};

InviteLogisticForm.propTypes = propTypes;
InviteLogisticForm.defaultProps = defaultProps;

const mapStateToProps = state => ({
  isLoading: state.ui.loading.inviteLogisticPartner,
});

const formName = "InviteLogisticForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  validate: validateFunc,
})(InviteLogisticForm);

export default connect(mapStateToProps, null)(connectedForm);

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { change, getFormValues, reduxForm, reset } from "redux-form";
import { TextInputField } from "../../../../_shared/components/ReduxFormFields";
import CompanyField from "../_shared/forms/companyFields";
import "./Profile.scss";

const Profile = ({ onSave, handleSubmit, tariff }) => {
  const renderFields = (
    name,
    label,
    placeholder,
    type = "text",
    component = TextInputField
  ) => (
    <CompanyField
      name={name}
      label={label}
      placeholder={placeholder}
      type={type}
      component={component}
      onclick={handleSubmit(onSave)}
      formType="profileForm"
    />
  );

  return (
    <div className="profile">
      <div className="profile__form">
        {renderFields("ful_name", "Full name", "Full name not specified")}
        {renderFields(
          "user_email",
          "Corporate email",
          "Corporate email not specified",
          "email"
        )}
        {renderFields("user_phone", "Phone", "Phone not specified", "tel")}
        {renderFields("position", "Position", "Position not specified")}
      </div>
      {tariff && tariff === "BASIC" ? (
        <React.Fragment>
          <hr />
          <p className="d-flex justify-content-center p-5">
            With a <Link to="/pricing"> Business </Link> account, you can invite
            your co-workers
          </p>
        </React.Fragment>
      ) : null}
    </div>
  );
};

// Profile.propTypes = propTypes;
// Profile.defaultProps = defaultProps;

const formName = "profileForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  //   validate: PreOrderValidationFunc,
})(Profile);

// export default connectedForm;
export default connect(
  state => ({
    formData: getFormValues(formName)(state),
    tariff: state.settings.companyInfo.tariff,
  }),
  {
    change,
    reset,
  }
)(connectedForm);

import { FETCH_COUNTRIES, apiRequest, GET, POST, LOGIN, navigateTo } from '../../actions';
import { API } from '../../../_shared/defs';

const fetchCountries = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_COUNTRIES.START) {
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.COUNTRY}`,
        key: 'fetchCountries',
        onSuccess: FETCH_COUNTRIES.SUCCESS
      })
    );
  }
};

export default [ fetchCountries ];

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { change, getFormValues, reduxForm } from "redux-form";
import { Container, Col, Form, Row } from "reactstrap";
import AddProductUploads from "./AddProductUploads";
import CustomButton from "../../../../../../_shared/components/Button";
import { fetchCategories, uploadMedia } from "../../../../../../redux/actions";
import OrderFormValidation from "../../../../../Workspace/_shared/Forms/CreateProductForm/OrderFormValidation";
import AddProductForm from "./AddProductForm";
import "./AddProduct.scss";

const propTypes = {
  formLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  formData: PropTypes.object,
  categories: PropTypes.array,
  uploadMedia: PropTypes.func,
  previousStep: PropTypes.func,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  nextStep: PropTypes.func,
  isUploadingImage: PropTypes.bool,
  isUploadingDoc: PropTypes.bool,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  purpose: PropTypes.string.isRequired,
};

const defaultProps = {
  formLoading: false,
  isUploadingImage: false,
  isUploadingDoc: false,
  formData: {},
  categories: {},
};

const AddProduct = props => {
  const {
    formData,
    uploadMedia,
    isUploadingDoc,
    isUploadingImage,
    handleSubmit,
    change,
    previousStep,
    categories,
    formLoading,
    submitting,
    pristine,
    invalid,
    purpose,
  } = props;

  const { category, images, docs } = formData;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Form className="offer-form" onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col md="12">
            <h5 className="form-title text-center">
              Place your {purpose === "BUY" ? "request" : "offer"}
            </h5>
          </Col>
          <AddProductForm
            categories={categories}
            category={category}
            purpose={purpose}
            change={change}
          />
          <AddProductUploads
            images={images}
            docs={docs}
            isUploadingImage={isUploadingImage}
            isUploadingDoc={isUploadingDoc}
            change={change}
            uploadMedia={uploadMedia}
          />
          <Col>
            <div className="offer-form__cta">
              <CustomButton
                name="PREVIOUS"
                btnType="no-border"
                action={() => previousStep()}
              />
              <CustomButton
                loading={formLoading}
                name="FINISH"
                type="submit"
                disabled={(formLoading, submitting || pristine || invalid)}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

AddProduct.propTypes = propTypes;
AddProduct.defaultProps = defaultProps;

const formName = "offerForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  // enableReinitialize: true,
  // @ts-ignore
  validate: OrderFormValidation,
})(AddProduct);

// export default connectedForm;
export default connect(
  state => ({
    // initialValues: { currency: { label: "USD", value: "USD" } },
    formData: getFormValues(formName)(state),
    categoryList: state.categories.list,
    isUploadingImage: state.ui.loading["uploadMedia-img"],
    isUploadingDoc: state.ui.loading["uploadMedia-doc"],
  }),
  {
    change,
    fetchCategories,
    uploadMedia,
  }
)(connectedForm);

export const Affiliates = [
  {
    img: "easy-start",
    heading: "Easy to Start",
    text:
      "Joining our affiliate program gets you an easy-to-manage personal account, which allows you to track and withdraw money seamlessly.",
  },
  {
    isEmpty: true,
  },
  {
    isEmpty: true,
  },
  {
    img: "expiry-date",
    heading: "Simple to Promote",
    text:
      "The OPN Platform is a secure and user-friendly digital platform with a wide network and dedicated support. Your audience will love it.",
  },
  {
    img: "simple-promote",
    heading: "No expiry date",
    text: "Once your affiliate signs up, their account will never expire.",
  },
  {
    isEmpty: true,
  },
  {
    isEmpty: true,
  },
  {
    img: "payout",
    heading: "Payout each 7 working days",
    text: "Expanding your activity is in our interest.",
  },
  {
    img: "win-win",
    heading: "Win-win situation",
    text:
      "It is beneficial to all parties! You receive a reward, we receive a customer, the customer receives credits.",
  },
];

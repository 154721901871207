import category from "./category";
import country from "./country";
import orders from "./orders";
import deals from "./deals";
import settings from "./settings";
import office from "./office";
import tender from "./tender";
import notifications from "./notifications";
import virtualInventory from "./virtualInventory";

export default [
  ...category,
  ...country,
  ...orders,
  ...deals,
  ...settings,
  ...office,
  ...tender,
  ...notifications,
  ...virtualInventory,
];

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import ProductCard from "../../../../_shared/components/ProductCard";
import {
  fetchMyDraftOrders,
  deleteMyDraftOrder,
} from "../../../../../../redux/actions";
import ProgressBar from "../../../../../../_shared/components/ProgressBar";
import DeleteDialog from "../../../../../../_shared/components/partials/DeleteDialog";

const propTypes = {
  fetchMyDraftOrders: PropTypes.func,
  deleteMyDraftOrder: PropTypes.func,
  myDraftOrders: PropTypes.array,
  isFetchMyDraftOrders: PropTypes.bool,
  location: PropTypes.object,
};

const Draft = ({
  myDraftOrders,
  fetchMyDraftOrders,
  isFetchMyDraftOrders,
  deleteMyDraftOrder,
  location,
}) => {
  const history = useHistory();
  const { query } = location;
  const { tab } = query;
  const queryValue = tab || "offers";
  const purpose =
    queryValue === "offers" ? "SELL" : queryValue === "requests" && "BUY";
  const previousLabel =
    queryValue === "offers"
      ? "my offers"
      : queryValue === "requests" && "my requests";

  const [productId, setProductId] = useState("");

  const handleRedirectView = (e, id, name) => {
    const productName = name.split(" ").join("-");
    history.push({
      pathname: `market/${productName}/${id}/view`,
      state: { previousLabel },
    });
  };
  useEffect(() => {
    const payload = {
      count: 250,
      offset: 0,
      purpose: purpose,
    };

    fetchMyDraftOrders(payload);
  }, []);

  const callToAction = () => {
    deleteMyDraftOrder(productId, purpose);
  };

  return (
    <React.Fragment>
      {isFetchMyDraftOrders ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : isEmpty(myDraftOrders) ? (
        <div className="d-flex justify-content-center p-5">
          <img src="/images/svg/icons/no-data.svg" alt="No data icon" />
        </div>
      ) : (
        <div className="product__list">
          {myDraftOrders &&
            myDraftOrders.map(order => (
              <ProductCard
                key={order._id}
                order={order}
                setProductId={setProductId}
                action={handleRedirectView}
                showMoreBtn={true}
              />
            ))}
        </div>
      )}

      <DeleteDialog
        title="Delete Product"
        actionName="Delete"
        message="Are you sure you want to delete this Product"
        action={() => callToAction()}
      />
    </React.Fragment>
  );
};
Draft.propTypes = propTypes;

const mapStateToProps = state => ({
  location: state.router.location,
  myDraftOrders: state.orders.draftList,
  isFetchMyDraftOrders: state.ui.loading.fetchMyDraftOrders,
});

const mapDispatchToProps = {
  fetchMyDraftOrders,
  deleteMyDraftOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(Draft);

import get from "lodash/get"
import {
  SET_TAB_INDEX, UI_DECREMENT_STEP, UI_DIALOG_TOGGLE, UI_ERROR, UI_INCREMENT_STEP, UI_INITIALIZING_ONBOARDING, UI_LOADING,
  UI_MODAL_CUSTOM_TOGGLE, UI_MODAL_TOGGLE, UI_PAYMENT_SUCCESS_TOGGLE, UI_SIGNUP_MODAL_TOGGLE
} from "../actions"

const defaultState = {
  
  route: get(window.location, "pathname"),
  errors: {},
  loading: {},

  modal: {
    isOpen: false,
    isOpenCustom: false,
    isOpenPaymentSuccess: false,
    isShowDialog: false,
    isShowSignupModal: false,
  },
  step: 1,
  initializeOnboarding: false,
  tabIndex: 0,
};
const uiReducer = (state = defaultState, action) => {
  switch (action.type) {
    case UI_LOADING.START:
      return getNewLoadingState(state, action, true);
    case UI_LOADING.END:
      return getNewLoadingState(state, action, false);
    case UI_ERROR:
      return Object.assign({}, state, {
        errors: { ...state.errors, [action.key]: action.value },
      });
    case UI_MODAL_TOGGLE:
      return Object.assign({}, state, {
        modal: {
          isOpen: !state.modal.isOpen,
          isOpenCustom: false,
        },
      });
    case UI_MODAL_CUSTOM_TOGGLE:
      return Object.assign({}, state, {
        modal: {
          isOpen: false,
          isOpenCustom: !state.modal.isOpenCustom,
        },
      });
    case UI_PAYMENT_SUCCESS_TOGGLE:
      return Object.assign({}, state, {
        modal: {
          isOpenPaymentSuccess: !state.modal.isOpenPaymentSuccess,
        },
      });
    case UI_DIALOG_TOGGLE:
      return Object.assign({}, state, {
        modal: {
          isShowDialog: !state.modal.isShowDialog,
        },
      });
    case UI_SIGNUP_MODAL_TOGGLE:
      return Object.assign({}, state, {
        modal: {
          isShowSignupModal: !state.modal.isShowSignupModal,
        },
      });
    case UI_INCREMENT_STEP:
      return Object.assign({}, state, {
        step: state.step + 1,
      });
    case UI_DECREMENT_STEP:
      return Object.assign({}, state, {
        step: state.step - 1,
      });
    case UI_INITIALIZING_ONBOARDING:
      let resetStep = state.step;

      if (state.initializeOnboarding) {
        resetStep = 1;
      }
      return Object.assign({}, state, {
        initializeOnboarding: !state.initializeOnboarding,
        step: resetStep,
      });
    case SET_TAB_INDEX:
      return Object.assign({}, state, {
        tabIndex: action.value,
      });
    default:
      return state;
  }
};

export default uiReducer;

function getNewLoadingState(currentState = {}, action, value) {
  const { key } = action;
  return Object.assign({}, currentState, {
    loading: { ...currentState.loading, [key]: value },
  });
}

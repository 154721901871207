import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Row, Col } from "reactstrap";

const Payment = props => {
  return (
    <Row>
      <Col md="12" className="faq-accordion">
        <Accordion allowZeroExpanded={true} preExpanded={["open"]}>
          <AccordionItem uuid="open">
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                How can I pay my counterparty?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                You can pay immediately without leaving the platform via Stripe
                or other integrated Providers in real time.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                How can I receive the invoice for the deal?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                A Seller can share a paper invoice with you after the deal
                directly in chat. If your software is integrated to the OPN
                Platform, an invoice will be generated automatically.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                Where can I see my company OPN platform usage?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>You can see all usage in SETTINGS > BILLING page.</p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                Where can I find a payment receipt?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Payment receipts are generated automatically by the selected
                payment provider.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                How can I use OPN credit?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                At the end of the month, you can choose to pay for OPN Platform
                services with your credits or by credit card or wire transfer.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                Are payments secure on the OPN Platform?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Payments are secured by global payment leader Stripe, which has
                been audited by a PCI-certified auditor and is certified as a
                PCI Service Provider Level 1. This is the most stringent level
                of certification available in the payments industry. For more
                information visit https://stripe.com/docs/security/stripe
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </Col>
    </Row>
  );
};

Payment.propTypes = {};

export default Payment;

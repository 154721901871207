import React from "react";
import { change, Field, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import validateFunc from "../SignUpForm/registerValidation";
import { TextInputField } from "../../../../../_shared/components/ReduxFormFields/index";
import CustomButton from "../../../../../_shared/components/Button";
import "../ResetPassForm/ResetPassForm.scss";

const propTypes = {
  isLoading: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  isLoading: false,
};

const NewPassForm = ({
  handleSubmit,
  isLoading,
  submitting,
  pristine,
  invalid,
}) => {
  return (
    <div className="reset_password-wrapper">
      <h5 className="reset_password-wrapper_title">New Password</h5>
      <form onSubmit={handleSubmit}>
        <Field
          name="password"
          label="Password"
          placeholder="Password"
          id="password"
          component={TextInputField}
          type="password"
        />
        <Field
          name="confirmPassword"
          label="Confirm password"
          id="confirmPassword"
          placeholder="Confirm password"
          component={TextInputField}
          type="password"
        />
        <CustomButton
          name="CONFIRM"
          type="submit"
          classes="w-100"
          loading={isLoading}
          disabled={submitting || pristine || invalid}
        />
      </form>
    </div>
  );
};

NewPassForm.propTypes = propTypes;
NewPassForm.defaultProps = defaultProps;

const mapStateToProps = state => ({
  fields: getFormValues(formName)(state),
  isLoading: state.ui.loading.changePassword,
});

const formName = "NewPasswordForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  validate: validateFunc,
})(NewPassForm);

// export default connectedForm;
export default connect(mapStateToProps, null)(connectedForm);

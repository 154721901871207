import React from "react";
import { Container, Row, Col } from "reactstrap";
import "./OfflinePage.scss";
import OfflineImage from "../../../../../public/offline.svg";

const OfflinePage = () => {
  return (
    <main className="offline">
      <Container>
        <Row>
          <Col md={{ size: 6, offset: 3 }} className="offline__inner">
            <div className="offline__inner__message">
              <h4 className="md-title">There’s no internet connection.</h4>
              <p>Check your connection or try again.</p>
            </div>
            <img className="img-fluid" src={OfflineImage} alt="" />
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default OfflinePage;

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Tooltip } from "reactstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { truncate } from "../../../../../_shared/functions/utils";
import ProgressBar from "../../../../../_shared/components/ProgressBar";
import { fetchIntegrationKey } from "../../../../../redux/actions";
import "./Integrations.scss";

const propTypes = {
  isFetchingAllDeal: PropTypes.bool,
  fetchIntegrationKey: PropTypes.func,
};

const defaultProps = {
  isFetchingAllDeal: true,
};

const IntegrationContent = ({
  isFetchingAllDeal,
  fetchIntegrationKey,
  api_key,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const integrationKey = api_key || {};
  const isMobileView = window.innerWidth < 767;
  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    fetchIntegrationKey();
  }, []);

  return (
    <main className="integration">
      <h5>OPN API key</h5>
      <p>
        Use this API access key to integrate OPN Platform with your own site or
        app. This key gives full access to all your OPN Platform data. Treat
        this just like your password!
      </p>
      {isFetchingAllDeal ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : isEmpty(api_key) ? (
        <React.Fragment>
          <p>
            <em className="d-flex justify-content-center p-5">
              Here will be displayed additional information about the
              integration
            </em>
          </p>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            autohide={false}
            target="DisabledAutoHideExample"
            toggle={toggle}
          >
            {isCopied ? "copied" : "copy"}
          </Tooltip>
          <div className="api-key">
            <p>
              {integrationKey.APIKey &&
                truncate(integrationKey.APIKey, isMobileView ? 20 : 50)}
            </p>

            <CopyToClipboard
              text={`${integrationKey.APIKey}`}
              onCopy={() => setIsCopied(true)}
            >
              <img src="/images/copy.png" id="DisabledAutoHideExample" />
            </CopyToClipboard>
          </div>
        </React.Fragment>
      )}
    </main>
  );
};

IntegrationContent.propTypes = propTypes;
IntegrationContent.defaultProps = defaultProps;

const mapStateToProps = state => ({
  api_key: state.office.apiKey,
  isFetchingAllDeal: state.ui.loading.fetchIntegrationKey,
});
const mapDispatchToProps = { fetchIntegrationKey };

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationContent);

import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import "./CompanyGoal.scss";
import CustomButton from "../../../../../../_shared/components/Button";

const propTypes = {
  purpose: PropTypes.string,
  setPurpose: PropTypes.func,
  nextStep: PropTypes.func,
};

const CompanyGoal = ({ setPurpose, nextStep, purpose }) => {
  return (
    <div className="company-goal">
      <h5 className="form-title text-center">What is your company goal</h5>
      <div className="company-goal__purpose-cta">
        <CustomButton
          action={() => setPurpose("SELL")}
          name="SELL"
          btnType="outlined"
          classes={`${purpose === "SELL" ? "active" : ""}`}
        />
        <CustomButton
          action={() => setPurpose("BUY")}
          name="BUY"
          btnType="outlined"
          classes={`${purpose === "BUY" ? "active" : ""}`}
        />
      </div>
      <div className="next-cta">
        <CustomButton
          disabled={isEmpty(purpose)}
          action={() => nextStep()}
          name="CONTINUE"
          type="submit"
        />
      </div>
    </div>
  );
};

CompanyGoal.propTypes = propTypes;

export default CompanyGoal;

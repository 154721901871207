import _ from "lodash";
import { push } from "connected-react-router";
import { API_REQUEST } from "../actions";
import { startUILoading, stopUILoading, updateUIError } from "../actions";
import { createAPIRequest } from "../../services/axios";
import {
  formatMessagesFromError,
  formatSuccessMessage,
} from "../../_shared/functions/utils";
import Toaster from "../../_shared/components/Toaster";

export const processApiError = error => {
  let response = "";
  if (!error) {
    response = "An error occurred, please try again!";
  } else if (error.message) {
    response = error.message;
  } else if (error.messages) {
    Object.values(error.messages).forEach(message => {
      response += `${message}\n`;
    });
  }
  return response || "An error occurred";
};
// @ts-ignore
const apiRequest = ({ dispatch, getState }) => next => action => {
  if (action.type === API_REQUEST.START) {
    const {
      method,
      url,
      key,
      payload = {},
      onError,
      successMessage,
      params,
      onSuccess,
      nextRoute,
      errorMessage,
      noSuccessToast,
      noErrorToast,
      requestType = "private",
      headerParams = true,
    } = action.meta;

    const config = { method, url, requestType, headerParams, dispatch };
    const { auth } = getState();

    if (headerParams) {
      if (auth.clientId) {
        payload.clientId = auth.clientId;
      }

      if (requestType === "private") {
        payload.access_token = auth.accessToken && auth.accessToken.token;
      }
    }

    if (payload instanceof FormData) {
      payload.append(
        "access_token",
        auth.accessToken && auth.accessToken.token
      );
      payload.append("clientId", auth.clientId);
    }

    if (payload && (!_.isEmpty(payload) || payload instanceof FormData)) {
      config.data = payload;
    }
    if (params && !_.isEmpty(params)) {
      config.params = params;
    }
    dispatch(updateUIError(key, null));
    dispatch(startUILoading(key));
    createAPIRequest(config)
      .then(apiResponse => {
        console.log("::===ApiResponse===::", apiResponse.data);
        const { result: payload, _meta } = apiResponse.data;
        if (onSuccess) {
          if (typeof onSuccess === "function") {
            onSuccess(payload);
          } else {
            dispatch({ type: onSuccess, payload: payload });
          }
        }
        if (nextRoute) {
          dispatch(push(nextRoute));
        }
        dispatch(stopUILoading(key));
        const toastMessage =
          (successMessage && formatSuccessMessage(successMessage)) ||
          (_meta && _meta.message);

        if (!noSuccessToast && toastMessage) {
          Toaster.dismiss();
          Toaster.success(toastMessage);
        }
      })
      .catch(apiError => {
        console.log("::===ApiError===::", apiError);
        const showErrorMessage = message => {
          if (!noErrorToast && message) {
            Toaster.error(message);
          }
        };
        if (onError) {
          if (typeof onError === "function") {
            onError(apiError);
          } else {
            const message = formatMessagesFromError(apiError);
            dispatch(updateUIError(key, message));
            showErrorMessage(message);
          }
        } else {
          const error =
            apiError && apiError.data && apiError.data.error_message;
          const message = errorMessage || formatMessagesFromError(error);

          dispatch(updateUIError(key, message));
          showErrorMessage(message);
        }
        dispatch(stopUILoading(key));
      });
  }
  return next(action);
};

export default [apiRequest];

import React from "react";
import PropTypes from "prop-types";
import "./UpcomingFeature.scss";

const UpcomingFeature = props => {
  return (
    <main className="upcoming-feature">
      <div className="upcoming-feature__inner">
        <img
          src={`${process.env.IMAGE_URL}/assets/images/svg/icons/comingsoon.svg`}
          alt="Coming Soon"
          className="img-fluid"
        />
        <h4 className="md-title">Coming soon! 🙌</h4>
        <p>We will notify you once this feature is ready.</p>
      </div>
    </main>
  );
};

UpcomingFeature.propTypes = {};

export default UpcomingFeature;

import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import ProgressBar from "../../../../../_shared/components/ProgressBar";
import { fetchEcoImpactStats } from "../../../../../redux/actions";
import "./EcoImpact.scss";

const propTypes = {
  isFetchEcoImpactStats: PropTypes.bool,
  fetchEcoImpactStats: PropTypes.func,
};

const defaultProps = {
  isFetchEcoImpactStats: true,
};

const EcoImpact = ({ fetchEcoImpactStats, isFetchEcoImpactStats, stats }) => {
  useEffect(() => {
    fetchEcoImpactStats();
  }, []);
  const ecoData = [
    { img: "water-drop", number: "12", type: "Gallons of Water" },
    { img: "co2", number: "22", type: "Pounds of Carbon" },
    { img: "trash", number: "14", type: "Pounds of Waste" },
  ];
  return (
    <main className="eco-impact">
      <h5>By using OPN, You’ve helped save:</h5>
      {isFetchEcoImpactStats ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : (
        <React.Fragment>
          <div className="eco-impact__features">
            <div className="eco-impact__features__card">
              <img src="/images/tree.png" />
              <p className="eco-impact__features__card__info">
                <span>{stats.tree_planted} </span> Tree
              </p>
            </div>
            {ecoData.map((item, i) => (
              <div key={i} className="eco-impact__features__card">
                <img src={`/images/${item.img}.png`} />
                <p className="eco-impact__features__card__info">
                  <span>{item.number} </span> {item.type}
                </p>
              </div>
            ))}
          </div>
        </React.Fragment>
      )}

      <p>
        This data is calculated based on offers, requests you’ve posted and
        deals you’ve closed
      </p>
      <p>Help the Planet and Your Bottom Line!</p>
    </main>
  );
};

EcoImpact.propTypes = propTypes;
EcoImpact.defaultProps = defaultProps;

const mapStateToProps = state => ({
  stats: state.office.stats,
  isFetchEcoImpactStats: state.ui.loading.fetchEcoImpactStats,
});
const mapDispatchToProps = { fetchEcoImpactStats };

export default connect(mapStateToProps, mapDispatchToProps)(EcoImpact);

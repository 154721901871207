import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Row, Col } from "reactstrap";

const Shipment = props => {
  return (
    <Row>
      <Col md="12" className="faq-accordion">
        <Accordion allowZeroExpanded={true} preExpanded={["open"]}>
          <AccordionItem uuid="open">
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                How can I deliver the product?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                After confirming the transaction, you will see the window with a
                list of integrated logistic services. You will be invited to use
                one of the services or send an invitation to your current
                logistics partner to join the OPN Platform for FREE to deliver
                your Product. Agree on a cost, if it is not included in the
                price and payment schedule.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                How can I track the delivery?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Tracking is provided by OPN logistics partners, so you would be
                linked to their system once the deal is closed.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                How can I check product quality?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Digitally enabled, paperless OPN quality control uses advanced
                real-time data analytics and ongoing process verification to
                track manufacturers, feedback and reviews and prevent deviations
                or out-of-specifications, and optimize the digital quality
                control process.
              </p>
              <p>
                Digitization and automation will also ensure better quality and
                compliance by reducing manual errors and variability, as well as
                allowing faster and effective resolution of problems.
              </p>
              <p>
                You can also request sample pictures from the Seller via chat.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </Col>
    </Row>
  );
};

Shipment.propTypes = {};

export default Shipment;

import { isEmpty } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"
import {
  fetchMyOrders, fetchOrders, uiToggleShowDialog
} from "../../../../../redux/actions"
import CustomButton from "../../../../../_shared/components/Button"
import Pagination from "../../../../../_shared/components/Pagination"
import ProgressBar from "../../../../../_shared/components/ProgressBar"
import "./ProductContainer.scss"
import ProductList from "./ProductList"
import qs from "query-string"

const propTypes = {
  fetchMyOrders: PropTypes.func,
  uiToggleShowDialog: PropTypes.func,
  fetchOrders: PropTypes.func,
  isFetchingOrders: PropTypes.bool,
  hasCompanyInfo: PropTypes.bool,
  isFetchingMyOrders: PropTypes.bool,
  orders: PropTypes.object,
  myOrders: PropTypes.object,
  location: PropTypes.object,
};

const PRODUCT_LIST_TABS = ["all", "my"];
const DEFAULT_SECTION_INDEX = 0;

const ProductContainer = props => {
  const {
    fetchMyOrders,
    fetchOrders,
    orders,
    myOrders,
    location,
    isFetchingOrders,
    isFetchingMyOrders,
    hasCompanyInfo,
    uiToggleShowDialog,
  } = props;
  const { push, replace } = useHistory();
  const { query } = location;
  const { productList, market } = query;
  const currentTabIndex =
    PRODUCT_LIST_TABS.indexOf(productList) > 0 &&
    PRODUCT_LIST_TABS.indexOf(productList) < PRODUCT_LIST_TABS.length
      ? PRODUCT_LIST_TABS.indexOf(productList)
      : DEFAULT_SECTION_INDEX;
  const [tabIndex, setTabIndex] = useState(currentTabIndex);
  const queryValue = market || "offers";
  const setQueryParam = paramValue =>
    replace({
      search: `market=${queryValue}&productList=${PRODUCT_LIST_TABS[paramValue]}`,
    });

  const purpose =
    queryValue === "offers" ? "SELL" : queryValue === "requests" && "BUY";

  const listData =
    PRODUCT_LIST_TABS[tabIndex] === "all"
      ? orders.data
      : PRODUCT_LIST_TABS[tabIndex] === "my" && myOrders.data;

  useEffect(() => {
    const payload = {
      count: 20,
      offset: 0,
      purpose,
    };

    const querystring = qs.parse(location.search);

    querystring.filters ? 
      payload["filter"] = querystring.filters.split(',')
      : 
      false;
    
    querystring.countries ? 
      payload["country"] = querystring.countries.split(',')
      : 
      false;

    fetchOrders(payload);
    fetchMyOrders(payload);
  }, []);

  const createOrder = () => {
    push(`/market/create?type=${queryValue}`);
  };

  return (
    <Tabs
      className="product-container__tabs"
      selectedIndex={tabIndex}
      onSelect={nextTabIndex => {
        setQueryParam(`${nextTabIndex}`);
        setTabIndex(nextTabIndex);
      }}
    >
      <TabList className="product-container__tabs__list">
        <div className="tab-wrap">
          <Tab>All {queryValue}</Tab>
          <Tab>My {queryValue}</Tab>
        </div>
        <div className="offer-cta-wrap">
          <CustomButton
            action={hasCompanyInfo ? createOrder : () => uiToggleShowDialog()}
            name={
              queryValue === "offers"
                ? "Place an Offer"
                : queryValue === "requests" && "Place a Request"
            }
          />
        </div>
      </TabList>

      <TabPanel>
        {isFetchingOrders ? (
          <div className="d-flex justify-content-center p-5">
            <ProgressBar />
          </div>
        ) : isEmpty(orders.data) ? (
          <div className="no-data">
            <img src="/images/svg/icons/no-data.svg" alt="No data icon" />
          </div>
        ) : (
          <ProductList orders={orders.data} />
        )}
      </TabPanel>
      <TabPanel>
        {isFetchingMyOrders ? (
          <div className="d-flex justify-content-center p-5">
            <ProgressBar />
          </div>
        ) : isEmpty(myOrders.data) ? (
          <div className="no-data">
            <img src="/images/svg/icons/no-data.svg" alt="No data icon" />
          </div>
        ) : (
          <ProductList orders={myOrders.data} />
        )}
      </TabPanel>
      {(!isFetchingMyOrders || !isFetchingOrders) && !isEmpty(listData) && (
        <Pagination
          fetchData={tabIndex === 0 ? fetchOrders : fetchMyOrders}
          pagination={tabIndex === 0 ? orders.pagination : myOrders.pagination}
          purpose={purpose}
        />
      )}
    </Tabs>
  );
};

ProductContainer.propTypes = propTypes;

const mapStateToProps = state => ({
  isFetchingOrders:
    state.ui.loading.fetchOrders || state.ui.loading.searchOrders,
  isFetchingMyOrders:
    state.ui.loading.fetchMyOrders || state.ui.loading.searchOrders,
  orders: state.orders.list,
  myOrders: state.orders.myList,
  location: state.router.location,
  hasCompanyInfo: state.settings.hasCompanyInfo,
});

const mapDispatchToProps = {
  fetchOrders,
  fetchMyOrders,
  uiToggleShowDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Published from "./Published";
import Draft from "./Draft";
import CustomButton from "../../../../../_shared/components/Button";

const propTypes = {};

const OfferContent = () => {
  const renderTab = (title, disabled) => <Tab disabled={disabled}>{title}</Tab>;
  const { push } = useHistory();
  const renderTabPanel = ContentPanel => (
    <TabPanel className="billing__tabs__panel">
      <ContentPanel />
    </TabPanel>
  );

  return (
    <div className="office-content-panel">
      <Tabs className="tabs billing__tabs">
        <div className="tab-wrap">
          <TabList>
            {renderTab("Published")}
            {renderTab("Draft")}
          </TabList>
          <div>
            <p>
              PUBLISHED:
              <span className="tab-wrap__published"> {/*11/10*/}</span>
            </p>
            <CustomButton
              name="place an offer"
              action={() => push(`/market/create?type=offers`)}
            />
          </div>
        </div>
        <div>
          {renderTabPanel(Published)}
          {renderTabPanel(Draft)}
        </div>
      </Tabs>
    </div>
  );
};

OfferContent.propTypes = propTypes;

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(OfferContent);

import {
  FETCH_ALL_SUBSCRIPTION,
  FETCH_INTEGRATION_KEY,
  UPLOAD_MY_DOCUMENT,
  FETCH_MY_DOCUMENTS,
  DELETE_MY_DOCUMENT,
  FETCH_ECO_IMPACT_STATS,
  FETCH_ANALYTICS,
  apiRequest,
  GET,
  POST,
  DELETE,
} from "../../actions";
import { API } from "../../../_shared/defs";

const fetchAllSubscription = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_ALL_SUBSCRIPTION.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.COMPANY}/subcription/trans?offset=0&count=10`,
        key: "fetchAllSubscription",
        onSuccess: FETCH_ALL_SUBSCRIPTION.SUCCESS,
        headerParams: false,
      })
    );
  }
};

const fetchIntegrationKey = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_INTEGRATION_KEY.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.COMPANY}/apikey`,
        key: "fetchIntegrationKey",
        onSuccess: FETCH_INTEGRATION_KEY.SUCCESS,
        headerParams: false,
      })
    );
  }
};

const fetchMydocuments = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_MY_DOCUMENTS.START) {
    const { params } = action;
    dispatch(
      apiRequest({
        method: GET,
        url: `/file/my/docs`,
        key: "fetchMydocuments",
        onSuccess: FETCH_MY_DOCUMENTS.SUCCESS,
        headerParams: false,
        params: params,
      })
    );
  }
};

const uploadMydocument = ({ dispatch, getState }) => (next) => (action) => {
  const { form } = getState();
  next(action);
  if (action.type === UPLOAD_MY_DOCUMENT.START) {
    const {
      payload: { files, mediaType },
      nextAction,
    } = action.meta;
    const formData = new FormData();
    formData.append(mediaType, files[0]);
    formData.append("name", files[0].name);

    dispatch(
      apiRequest({
        method: POST,
        url: `/file/my/doc`,
        key: `uploadMydocument`,
        successMessage: "Media uploaded successfully",
        onSuccess: (data) => {
          if (nextAction) {
            const params = {
              count: 15,
              offset: 0,
            };

            nextAction(params);
          }
        },
        payload: formData,
      })
    );
  }
};

const deleteMydocument = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === DELETE_MY_DOCUMENT.START) {
    const { payload, nextAction } = action.meta;
    dispatch(
      apiRequest({
        method: DELETE,
        url: `/file/my/doc`,
        key: "deleteMydocument",
        onSuccess: () => {
          if (nextAction) {
            const params = {
              count: 15,
              offset: 0,
            };

            nextAction(params);
          }
        },
        successMessage: "Document deleted successfully ",
        headerParams: false,
        payload,
      })
    );
  }
};

const fetchEcoImpactStats = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_ECO_IMPACT_STATS.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `/ecoimpact/stats`,
        key: "fetchEcoImpactStats",
        onSuccess: FETCH_ECO_IMPACT_STATS.SUCCESS,
        headerParams: false,
      })
    );
  }
};

const fetchAnalytics = ({ dispatch }) => (next) => (action) => {
  next(action);
  if (action.type === FETCH_ANALYTICS.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `/analytics/total`,
        key: "fetchAnalytics",
        onSuccess: FETCH_ANALYTICS.SUCCESS,
        headerParams: false,
      })
    );
  }
};

export default [
  fetchAllSubscription,
  fetchIntegrationKey,
  uploadMydocument,
  fetchMydocuments,
  deleteMydocument,
  fetchEcoImpactStats,
  fetchAnalytics,
];

import axios from "axios";
import _ from "lodash";
import AuthService from "./auth";
import { navigateTo } from "../redux/actions";
// Default config options
let isDev =
  /dev.opnplatform/.test(window.location.host) ||
  /localhost/.test(window.location.host);
const apiBase = isDev ? process.env.API_URL_DEV : process.env.API_URL;

const defaultOptions = {
  baseURL: apiBase,
  headers: {},
};

// Update instance
const instance = axios.create(defaultOptions);

// Set the AUTH token for any request
instance.interceptors.request.use(
  config => {
    if (!config.headerParams) {
      const access_token = AuthService.getUserToken();
      const clientId = AuthService.getClientId();
      const isLoggedIn = AuthService.isLoggedIn();

      if (clientId) config.headers["client-id"] = clientId;

      if (!isLoggedIn && config.requestType === "private") {
        return config.dispatch(navigateTo("/login"));
      }

      if (config.requestType === "private")
        config.headers["access-token"] = access_token;
    }

    return _.omit(config, ["requestType", "headerParams"]);
  },
  error => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  response => {
    // Do something with response data
    return response;
  },
  error => {
    // Do something with response error
    return Promise.reject(error.response);
  }
);
export default instance;

export const createAPIRequest = config => instance(config);

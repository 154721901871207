import React from "react";
import { change, Field, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import CustomButton from "../../../../../_shared/components/Button";
import { Col, Form, FormGroup, Row } from "reactstrap";
import {
  TextInputField,
  CheckBoxField,
} from "../../../../../_shared/components/ReduxFormFields/index";
import { login } from "../../../../../redux/actions";
import validateFunc from "./loginValidation";
import "./LoginForm.scss";

const propTypes = {
  isLoading: PropTypes.bool,
};

const defaultProps = {
  isLoading: false,
};

const LoginForm = props => {
  const {
    handleSubmit,
    isLoading,
    submitting,
    pristine,
    invalid,
    login,
  } = props;

  const onFormSubmit = values => {
    const payload = {
      email: values.email,
      password: values.password,
    };

    login(payload);
  };

  return (
    <Form className="login-form" onSubmit={handleSubmit(onFormSubmit)}>
      <Row>
        <Col md="12">
          <FormGroup>
            <Field
              name="email"
              label="Email"
              id="email"
              type="email"
              disabled={isLoading}
              component={TextInputField}
              placeholder="Business email address"
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="password"
              label="Password"
              id="password"
              type="password"
              disabled={isLoading}
              component={TextInputField}
              placeholder="Password"
            />
          </FormGroup>
          <FormGroup className="forgot-section">
            <Field
              name="remember"
              label="Remember me"
              type="checkbox"
              color="blue"
              id="remember"
              className="forgot-section__item"
              disabled={isLoading}
              component={CheckBoxField}
            />
            <Link
              className="forgot-section__item forgot-link"
              to="/reset-password"
            >
              Forgot Password?
            </Link>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md="12" className="login-form__cta">
          <CustomButton
            name="Log In"
            type="submit"
            loading={isLoading}
            classes="w-100"
            disabled={invalid || isLoading || pristine || submitting}
          />
        </Col>
      </Row>
    </Form>
  );
};

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

const formName = "loginForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  validate: validateFunc,
})(LoginForm);

// export default connectedForm;
export default connect(
  state => ({
    formData: getFormValues(formName)(state),
    isLoading: state.ui.loading.login,
  }),
  {
    change,
    login,
  }
)(connectedForm);

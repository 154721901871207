import React, { useEffect } from "react"
import { Col, Row } from "reactstrap"
import Toaster from "../../../_shared/components/Toaster"
import { formatSuccessMessage } from "../../../_shared/functions/utils"
import FeedbackForm from "../_shared/form/FeedbackForm"
import ContactMap from "./ContactMap"
import "./ContactUs.scss"


const contacts = [
  { name: "General:", email: "info@opnplatform.com" },
  { name: "Investors: ", email: "CEO@opnplatform.com" },
  { name: "Partners:", email: "info@opnplatform.com" },
  { name: "Media center: ", email: "pr@opnplatform.com" },
];

const ContactUs = ({ reset }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submit = values => {
    if (window.location.host === "opnplatform.com") {
      window.Intercom("update", { email: values.email, name: values.name });
      window.Intercom("showNewMessage", values.message);
    }

    Toaster.dismiss();
    Toaster.error(formatSuccessMessage("Message successfully sent."));
    reset("FeedbackForm");
  };
  return (
    <main className="contact-us">
      <h1>Contact Us</h1>
      <p className="contact-us__caption">
        Feel free to contact us and we’ll get back to you as soon as we can
      </p>
      <div className="contact-us__contact">
        <Row>
          <Col md={6}>
            {contacts.map((contact, index) => (
              <div key={index} className="contact-us__contact__info">
                <h5>{contact.name}</h5>
                <a href={`mailto:${contact.email}`}> {contact.email} </a>
              </div>
            ))}
          </Col>
          <Col md={6}>
            <div className="contact-us__contact__form">
              <FeedbackForm onSubmit={submit} />
            </div>
          </Col>
        </Row>
      </div>
      <div className="contact-us__map">
        <ContactMap />
      </div>
    </main>
  );
};

export default ContactUs;

import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import { isEmpty } from "lodash";
import CustomButton from "../../../_shared/components/Button";
import { Affiliates } from "./Affiliates";
import "./Referral.scss";

const Referral = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const link =
    "https://opnplatform.postaffiliatepro.com/affiliates/signup.php#SignupForm";

  const getId = url => {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[2].length == 11) {
      return match[2];
    } else {
      return "error";
    }
  };

  return (
    <main className="referral">
      <div className="referral__top-content">
        <h1>
          Earn Lifetime Commission up to <span>$100 per lead</span>
        </h1>
        <p className="referral__top-content__text">
          A monthly recurring revenue. Interested?
        </p>
        <div className="referral__top-content__btn">
          <a href={link} target="_blank">
            <CustomButton name="i'm in" classes="regular" type="submit" />
          </a>
        </div>
      </div>
      <img src="images/partners/sectionBorder.svg" style={{ width: "100%" }} />
      <div className="referral__second-content">
        <h4 className="referral__second-content__title">How to Apply?</h4>
        <div className="referral__second-content__video">
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${getId(
              "https://www.youtube.com/watch?v=OpF0K2Qq_AI&t=56s"
            )}`}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
        <div className="referral__top-content__btn">
          <a href={link} target="_blank">
            <CustomButton name="i'm in" classes="regular" type="submit" />
          </a>
        </div>
      </div>
      <img src="images/partners/sectionBorder.svg" style={{ width: "100%" }} />
      <div className="referral__third-content">
        <h5 className="referral__second-content__title">
          Why Affiliates Trust OPN?
        </h5>
        <Row>
          {Affiliates.map((affilate, index) => (
            <Col lg={6} md={6} xl={6}>
              <div className="referral__third-content__card" key={index}>
                {!isEmpty(affilate.img) && (
                  <img src={`/images/Referral/${affilate.img}.png`} />
                )}
                <div className="referral__third-content__card__content">
                  <h6>{affilate.heading}</h6>
                  <p className="referral__third-content__card__content__text">
                    {affilate.text}
                  </p>
                </div>
                {affilate.isEmpty && <div />}
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div className="referral__fourth-content">
        <h5 className="referral__second-content__title">Eligibility</h5>
        <ul className="custom-list">
          <li>
            You receive 50 USD/mo recurring for the Business Subscription and
            100 USD/mo recurring for an Enterprise Subscription.
          </li>
          <li>
            Once a customer joins OPN via your link, they will receive 1000 USD
            credit in advance to pay for platform services.
          </li>
          <li>The OPN credits can be used for OPN Platform services.</li>
        </ul>

        <div className="referral__top-content__btn">
          <a href={link} target="_blank">
            <CustomButton name="i'm in" classes="regular" type="submit" />
          </a>
        </div>
      </div>
      <div className="referral__fifth-content">
        <div className="referral__fifth-content__caption">
          <img src="/images/Referral/trumpent.png" />
          <h1>Spread the word and earn right now</h1>
        </div>
        <div className="referral__top-content__btn">
          <a href={link} target="_blank">
            <CustomButton name="i'm in" type="submit" btnType="outlined" />
          </a>
        </div>
      </div>
    </main>
  );
};

export default Referral;

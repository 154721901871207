import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Row, Col } from "reactstrap";

const Started = props => {
  return (
    <Row>
      <Col md="12" className="faq">
        <Accordion allowZeroExpanded={true} preExpanded={["open"]}>
          <AccordionItem uuid="open">
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                What is OPN's mission?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Our mission is what drives us to keep our customers and
                stakeholders happy. It gives us a long-term view and perspective
                of what we want to achieve over time. Our mission is:
              </p>
              <p>
                "To help the global packaging industry navigate online and give
                companies of all sizes the digital platform they need to trade
                quickly and easily, and create experiences their users love."
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                Where is OPN headquartered?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                The principal executive office of our main operations is located
                in the Zug, Switzerland.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                Who are OPN's users?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                OPN welcomes Companies operating in the packaging industry and
                has at least one product in their portfolio:
              </p>
              <ul>
                <li>Packaging Commodities Manufacturer</li>
                <li>Plastic Film Manufacturer</li>
                <li>Paper & Paperboard Manufacturer</li>
                <li>Printing Company</li>
                <li>Label Converter</li>
                <li>Packaging Converter</li>
                <li>Packaging Producer</li>
                <li>Packaging Consumer</li>
                <li>Packaging Machinery</li>
                <li>Printing & Labeling Machinery</li>
                <li>Plastic Film Manufacturer</li>
              </ul>
              <p>All other companies involved in the packaging industry.</p>
              <p>
                Industries: <br />
                Food, Beverage, Cosmetics, Pharma, New Materials
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                How do I register my company on OPN?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Registration is simple and only requires a login and a password
                or company social accounts, such as Linkedin or Google. By
                clicking Sign Up, you are transferred to the registration page
                where you are asked to complete required initial information.
              </p>
              <p>Go to Sign Up and follow the simple steps.</p>
              <p>Click on Sell or Buy to continue.</p>
              <p>
                Choose a Product from the packaging categories provided or
                create new product Confirm selection and enjoy!
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                What challenges can I resolve with OPN?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>OPN can assist you with:</p>
              <ol>
                <li>24/7 access to the global market.</li>
                <li>Reduce packaging waste.</li>
                <li>Trusted vendors.</li>
                <li>Integrated</li>
                <li>technological innovation.</li>
                <li>Transparency in the supply chain.</li>
                <li>Cost savings in procurement.</li>
                <li>Quicker sales cycle.</li>
              </ol>
              <p>
                Conducting effectively sales and procurement on OPN Platform
                constantly can potentially increase in annual revenue generated
                after one year of using OPN's platform.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                What services are available on the OPN platform?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                On OPN Platform you can trade if you have at least one Product
                in your portfolio.
              </p>
              <p>
                Benefit from services like: Artwork, Branding, Packaging Design
                from our Partners Ecosystem.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                Why is the company called "OPN"?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                OPN stands for Open Packaging Network. We aim to unite companies
                in the packaging industry and for the OPN Digital Platform to be
                their preferred technology infrastructure.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                What is OPN’s core business?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                OPN provides the technology infrastructure and marketing reach
                to help merchants, brands and other businesses leverage the
                power of new technology to engage with their users and customers
                and operate in a more efficient way.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                Why should I use the OPN digital solution instead of our current
                procurement process?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                OPN is a B2B online digital platform for the global packaging
                market that connects buyers and sellers in real time, no
                intermediaries, facilitates interactions, and offers solutions
                to most industry-related problems. As per our research, OPN can
                save you up to 35% in your procurement costs, automate your
                manual work and save enormous amounts of time. Depending on the
                size of your company, you can save anything from hundred
                thousands to millions of US Dollars. OPN supports instant Global
                Business Verification, so you always buy from trusted suppliers.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                Why should I move my current offline sales process to the OPN
                digital solution?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                We know how difficult it is to sell in a low margin packaging
                market especially for SME companies wanting to promote new
                materials. We built our digital platform to help companies just
                entering the market on a local or global level and provide them
                with a digital platform to manage separate pipelines in one
                place. You can increase sales with OPN’s automated workflow from
                sourcing to vetting and closing in just a few clicks.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                Is there a free subscription?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Yes, the <strong>OPN BASIC</strong> plan is{" "}
                <strong>FREE FOREVER</strong>. You can upgrade your account and
                receive more services or stay <strong>FREE</strong> for as long
                as you like. For Corporates, OPN offers a tailor-made ENTERPRISE
                Plan.
              </p>
              <p>
                For more information, please contact{" "}
                <em>enterprise@opnplatform.com</em>
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                What kind of subscription do I have?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                By default you are on a free Basic plan. You can switch to paid
                plans to enjoy advanced features. You can find your subscription
                plan status in the Setting> Billing page.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                What is the difference between Free and Paid Subscriptions?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                The main differences between free and paid subscription plans
                are described on the Pricing Page.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                Is your business sustainable?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                OPN is a purpose driven business and aims to make an honest and
                fair contribution to the planet and humankind. With a dedicated
                space for biodegradable, renewable and recyclable materials and
                the model to reduce inventory, you can reduce packaging waste or
                cut CO2 with manufacturing localization, freight emissions and
                costs of transit.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </Col>
    </Row>
  );
};

Started.propTypes = {};

export default Started;

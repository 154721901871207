import { change, reset } from "redux-form"
import AuthService from "../../../services/auth"
import { API } from "../../../_shared/defs"
import {
  apiRequest, CHECK_IF_MY_ORDERS_IS_ALREADY_TRACKED, CREATE_ORDER, DELETE, DELETE_MY_DRAFT_ORDER, DRAFT_MY_ORDER, FETCH_MY_DRAFT_ORDERS, FETCH_MY_ORDERS, FETCH_ORDER,
  FETCH_ORDERS, GET, navigateTo, POST, PUBLISH_MY__DRAFT_ORDER, PUT, SEARCH_ALL_ORDERS, SEARCH_MY_ORDERS, SEARCH_ORDERS, TRACK_MY_ORDERS, UPDATE_MY_DRAFT_ORDER, UPLOAD_MEDIA
} from "../../actions"

const createOrder = ({ dispatch, getState }) => next => action => {
  next(action);
  if (action.type === CREATE_ORDER.START) {
    const { form } = getState();
    const { payload, nextStep } = action.meta;
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.ORDERS}`,
        key: "createOrder",
        onSuccess: data => {
          const isPlaceAnother = form.offerForm.values["place-another"];

          dispatch({
            type: CREATE_ORDER.SUCCESS,
            payload: data,
          });

          if (isPlaceAnother) {
            dispatch(reset("offerForm"));
          } else {
            const previousLabel = `my ${
              data.purpose === "SELL"
                ? "offers"
                : data.purpose === "BUY"
                ? "requests"
                : ""
            }`;

            if (nextStep) {
              nextStep();
            } else {
              dispatch(
                navigateTo({
                  pathname: `/market/${data.name}/${data._id}/view`,
                  state: { previousLabel },
                })
              );
            }
          }
        },
        payload,
      })
    );
  }
};

const fetchOrder = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_ORDER.START) {
    const { productId } = action;
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.ORDERS}/${productId}`,
        key: "fetchOrder",
        onSuccess: FETCH_ORDER.SUCCESS,
        requestType: `${AuthService.isLoggedIn() ? "private" : "public"}`,
        headerParams: false,
      })
    );
  }
};

const fetchOrders = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_ORDERS.START) {
    const { payload, key } = action.meta;
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.ORDERS}/get/${AuthService.isLoggedIn() ? 1 : 0}/all`,
        key: key || "fetchOrders",
        onSuccess: FETCH_ORDERS.SUCCESS,
        requestType: `${AuthService.isLoggedIn ? "private" : "public"}`,
        payload,
      })
    );
  }
};

const fetchMyOrders = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_MY_ORDERS.START) {
    const { payload, key } = action.meta;
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.ORDERS}/get/${AuthService.isLoggedIn() ? 1 : 0}/my`,
        key: key || "fetchMyOrders",
        onSuccess: FETCH_MY_ORDERS.SUCCESS,
        requestType: `${AuthService.isLoggedIn ? "private" : "public"}`,
        payload,
      })
    );
  }
};

const searchOrders = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === SEARCH_ORDERS.START) {
    const { productList, ...rest } = action.payload;

    dispatch(
      apiRequest({
        method: POST,
        url: `${API.ORDERS}/search/${productList}`,
        key: "searchOrders",
        onSuccess:
          action.payload.purpose === "any"
            ? SEARCH_ALL_ORDERS
            : productList === "all"
            ? SEARCH_ORDERS.SUCCESS
            : productList === "my" && SEARCH_MY_ORDERS,
        payload: rest,
        requestType: `${productList === "my" ? "private" : "public"}`,
        headerParams: `${productList === "my" ? false : true}`,
      })
    );
  }
};

const uploadMedia = ({ dispatch, getState }) => next => action => {
  const { form } = getState();
  next(action);
  if (action.type === UPLOAD_MEDIA.START) {
    const {
      payload: { files, mediaType, uploadFrom },
      nextAction,
      key,
    } = action.meta;
    const formData = new FormData();
    formData.append(mediaType, files[0]);
    formData.append("name", files[0].name);

    dispatch(
      apiRequest({
        method: POST,
        url: `${API.UPLOAD}/${mediaType}`,
        key: key || `uploadMedia-${mediaType}`,
        successMessage: "Media uploaded successfully",
        onSuccess: data => {
          if (uploadFrom === "chat") {
            return nextAction({ data });
          }

          if (nextAction) {
            nextAction({ imageId: data._id });
          }
          if (mediaType === "img") {
            const imageData = form.offerForm.values.images
              ? form.offerForm.values.images
              : [];
            dispatch(change("offerForm", "images", [...imageData, data]));
          }
          if (mediaType === "doc") {
            const docData = form.offerForm.values.docs
              ? form.offerForm.values.docs
              : [];
            dispatch(change("offerForm", "docs", [...docData, data]));
          }
        },
        payload: formData,
      })
    );
  }
};

const trackMyOrders = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === TRACK_MY_ORDERS.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: POST,
        url: `/logistic/track`,
        key: "trackMyOrders",
        onSuccess: TRACK_MY_ORDERS.SUCCESS,
        requestType: "private",
        payload,
      })
    );
  }
};

const checkIfMyOrdersIsAlreadyTracked = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === CHECK_IF_MY_ORDERS_IS_ALREADY_TRACKED.START) {
    const { payload } = action;

    dispatch(
      apiRequest({
        method: GET,
        url: `/logistic/track/deal/${payload._id}`,
        key: "checkIfMyOrdersIsAlreadyTracked",
        onSuccess: CHECK_IF_MY_ORDERS_IS_ALREADY_TRACKED.SUCCESS,
        requestType: "private",
        headerParams: false,
        payload,
      })
    );
  }
};

const draftMyOrder = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === DRAFT_MY_ORDER.START) {
    const { productId } = action;
    dispatch(
      apiRequest({
        method: PUT,
        url: `${API.ORDERS}/draft/${productId}`,
        key: "draftMyOrder",
        onSuccess: DRAFT_MY_ORDER.SUCCESS,
        successMessage: "order drafted successfully",
        requestType: "private",
      })
    );
  }
};

const publishMyDraftOrder = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === PUBLISH_MY__DRAFT_ORDER.START) {
    const { productId } = action;
    dispatch(
      apiRequest({
        method: PUT,
        url: `${API.ORDERS}/publish/${productId}`,
        key: "publishMyDraftOrder",
        onSuccess: PUBLISH_MY__DRAFT_ORDER.SUCCESS,
        successMessage: "order published successfully",
        requestType: "private",
        /* onSuccess: () => {
          dispatch(
            navigateTo({
              pathname: `/my-office`,
            })
          );
        },*/
      })
    );
  }
};

const fetchMyDraftOrders = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_MY_DRAFT_ORDERS.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.ORDERS}/drafts`,
        key: "fetchMyDraftOrders",
        onSuccess: FETCH_MY_DRAFT_ORDERS.SUCCESS,
        requestType: "private",
        payload,
      })
    );
  }
};

const deleteMyDraftOrder = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === DELETE_MY_DRAFT_ORDER.START) {
    const { meta } = action;
    const { productId, purpose } = meta;
    dispatch(
      apiRequest({
        method: DELETE,
        url: `${API.ORDERS}/${productId}`,
        key: "deleteMyDraftOrder",
        onSuccess: () => {
          const payload = {
            count: 250,
            offset: 0,
            purpose: purpose,
          };
          dispatch({
            type: FETCH_MY_DRAFT_ORDERS.START,
            payload,
          });
        },
        successMessage: "drafted order deleted successfully",
        requestType: "private",
      })
    );
  }
};

const updateMyDraftOrder = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === UPDATE_MY_DRAFT_ORDER.START) {
    const { meta } = action;
    const { payload, productId } = meta;
    dispatch(
      apiRequest({
        method: PUT,
        url: `${API.ORDERS}/edit/${productId}`,
        key: "updateMyDraftOrder",
        onSuccess: () => {
          dispatch(
            navigateTo({
              pathname: `/my-office`,
            })
          );
        },
        payload,
        successMessage: "drafted order edited successfully",
        requestType: "private",
      })
    );
  }
};

export default [
  createOrder,
  fetchOrder,
  fetchOrders,
  fetchMyOrders,
  searchOrders,
  uploadMedia,
  trackMyOrders,
  checkIfMyOrdersIsAlreadyTracked,
  draftMyOrder,
  fetchMyDraftOrders,
  publishMyDraftOrder,
  deleteMyDraftOrder,
  updateMyDraftOrder,
];

import {
  CREATE_INVENTORY,
  FETCH_INVENTORY_BY_ID,
  FETCH_INVENTORY,
  apiRequest,
  GET,
  POST,
  navigateTo,
} from "../../actions";
import { API } from "../../../_shared/defs";

const createInventory = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === CREATE_INVENTORY.START) {
    const { payload, callBack } = action.meta;
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.INVENTORY}`,
        key: "createInventory",
        onSuccess: data => {
          console.log("data :", data);
          callBack();
        },
        payload,
        headerParams: false,
      })
    );
  }
};

const fetchInventory = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_INVENTORY.START) {
    const { params } = action;
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.INVENTORY}/all`,
        key: "fetchInventory",
        onSuccess: FETCH_INVENTORY.SUCCESS,
        params,
        headerParams: false,
      })
    );
  }
};

const fetchInventoryById = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_INVENTORY_BY_ID.START) {
    const { id } = action;
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.INVENTORY}/single/${id}`,
        key: "fetchInventoryById",
        onSuccess: FETCH_INVENTORY_BY_ID.SUCCESS,
        headerParams: false,
      })
    );
  }
};

export default [createInventory, fetchInventory, fetchInventoryById];

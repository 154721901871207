import { createActionType } from "../../../_shared/functions/utils";

export const FETCH_USE_CASES = createActionType("FETCH_USE_CASES", "useCase");
export const FETCH_USE_CASE = createActionType("FETCH_USE_CASE", "useCase");
export const CLEAR_USE_CASE_STATE = createActionType(
  "CLEAR_USE_CASE_STATE",
  "useCase"
);

export const fetchCaseStudies = params => ({
  type: FETCH_USE_CASES.START,
  params,
});

export const fetchCaseStudy = payload => ({
  type: FETCH_USE_CASE.START,
  payload,
});

export const clearCaseStudyState = payload => ({
  type: CLEAR_USE_CASE_STATE.START,
  payload,
});

import {
  FETCH_USE_CASE,
  FETCH_USE_CASES,
  apiRequest,
  GET,
  POST,
  LOGIN,
  navigateTo,
} from "../../actions";
import { API } from "../../../_shared/defs";

const fetchCaseStudy = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_USE_CASE.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: GET,
        url: `/usecase/title_link/${payload}`,
        key: "fetchCaseStudy",
        onSuccess: FETCH_USE_CASE.SUCCESS,
      })
    );
  }
};

const fetchCaseStudies = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_USE_CASES.START) {
    const { params } = action;
    dispatch(
      apiRequest({
        method: GET,
        url: `usecase/all`,
        key: "fetchCaseStudies",
        onSuccess: FETCH_USE_CASES.SUCCESS,
        params,
      })
    );
  }
};

export default [fetchCaseStudies, fetchCaseStudy];

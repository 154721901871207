export const trustedData = [
  "trusted/3dneopac",
  "trusted/3M",
  "trusted/johnson&johnson",
  "trusted/unilever",
  "trusted/alterna",
  "trusted/antalis",
  "trusted/berry",
  "trusted/omur",
  "trusted/dyna-corp",
  "trusted/caterpillar",
];
export const integrateData = [
  "/integrate/alibaba",
  "/integrate/china",
  "/integrate/amazon",
  "/integrate/mic",
  "/integrate/diy",
  "/integrate/ec21",
  "/integrate/indiamart",
];
export const enterpriseData = [
  "enterprise/sap",
  "enterprise/oracle",
  "enterprise/salesforce",
  "enterprise/zoho",
  "enterprise/hotspot",
  "enterprise/microsoft",
  "enterprise/xero",
];

export const meterCardData = [
  {
    count: "user_count",
    label: "Network members",
    link:
      "https://join.slack.com/t/openpackaging/shared_invite/zt-ctg9uvro-OabkJHjfko9ZsuQKZx_C5w",
    btnName: "Join community",
  },
  {
    count: "company_count",
    label: "Companies joined",
    btnName: "Join platform",
  },
  {
    count: "product_count",
    label: "Offers confirmed",
    btnName: "Place offer",
  },
];

export const solutionCardData = [
  {
    title: "Boost Revenue",
    content:
      "Find more customers to double your revenue quickly. Increase sales, find suppliers and close deals worldwide in a matter of a few clicks by OPN data-driven engine.",
    imgName: "money",
  },
  {
    title: "Connect Enterprise Software",
    content: "Automate your buyer journey, connect company ERP by flexible API",
    imgName: "artificial-intelligence",
  },
  {
    title: "Know Your Business",
    content:
      "Deal with thousands  of businesses worldwide seamlessly and safely with OPN online business verification.",
    imgName: "drive",
  },
  {
    title: "Get Market Insights",
    content:
      "Get the market insights by checking the products’ performance around the world.",
    imgName: "collaboration",
  },
  {
    title: "Capture best price",
    content: "Receive price alerts and trends feeds to be always updated.",
    imgName: "tag",
  },
];

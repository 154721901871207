import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ProgressBar from "../../../../../_shared/components/ProgressBar";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  navigateTo,
  fetchAllDeal,
  fetchDealByStatus,
} from "../../../../../redux/actions";
import DealList from "./DealList";

const propTypes = {
  fetchAllDeal: PropTypes.func,
  dealList: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  dealList: [],
  dealByStatus: [],
};

const DEALS_TABS = ["all", "pending", "accepted", "closed", "cancelled"];

const DealHistoryContent = props => {
  const {
    fetchAllDeal,
    dealList,
    dealByStatus,
    fetchDealByStatus,
    loading,
  } = props;

  useEffect(() => {
    const params = {
      count: 250,
      offset: 0,
    };
    fetchAllDeal(params);
  }, []);

  const renderTab = title => <Tab>{title}</Tab>;

  const renderTabPanel = (ContentPanel, data) => (
    <TabPanel className="billing__tabs__panel">
      {loading ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : (
        <ContentPanel data={data} />
      )}
    </TabPanel>
  );

  return (
    <div className="office-content-panel">
      <Tabs
        className="tabs billing__tabs"
        onSelect={nextTabIndex => {
          const params = {
            count: 250,
            offset: 0,
          };

          if (nextTabIndex !== 0)
            fetchDealByStatus(params, DEALS_TABS[nextTabIndex]);
        }}
      >
        <TabList>
          {renderTab("All")}
          {renderTab("Pending")}
          {renderTab("Accepted")}
          {renderTab("Closed")}
          {renderTab("Cancelled")}
        </TabList>

        <div>
          {renderTabPanel(DealList, dealList)}
          {renderTabPanel(DealList, dealByStatus)}
          {renderTabPanel(DealList, dealByStatus)}
          {renderTabPanel(DealList, dealByStatus)}
          {renderTabPanel(DealList, dealByStatus)}
        </div>
      </Tabs>
    </div>
  );
};

DealHistoryContent.propTypes = propTypes;
DealHistoryContent.defaultProps = defaultProps;

const mapStateToProps = state => ({
  location: state.router.location,
  dealList: state.deals.list.data,
  dealByStatus: state.deals.dealByStatus.data,
  loading: state.ui.loading.fetchAllDeal || state.ui.loading.fetchDealByStatus,
});

const mapDispatchToProps = { navigateTo, fetchAllDeal, fetchDealByStatus };

export default connect(mapStateToProps, mapDispatchToProps)(DealHistoryContent);

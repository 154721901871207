import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import moment from "moment";
import ClassNames from "classnames";
import ReactHtmlParser from "react-html-parser";
import AuthService from "../../../../../services/auth";

const propTypes = {
  data: PropTypes.object,
  isSameTime: PropTypes.bool,
};
const ChatItem = ({ data, isSameTime }) => {
  const { sender, text, attachments } = data;
  console.log("data :====>>>>", data);
  const companyId = AuthService.getCompanyId();

  const getFile = ({ name, _id, type, cdn_link }) => {
    const icon =
      name.split(".")[1] === "pdf" ? (
        <img src="/images/svg/icons/pdf.svg" alt="pdf" />
      ) : (
        <img src="/images/svg/icons/doc.svg" alt="doc" />
      );

    switch (type) {
      case "IMAGE":
        return (
          <div key={_id} className="file-img">
            <div className="img">
              <img src={cdn_link} alt="pdf" />
            </div>
          </div>
        );

      case "DOC":
        return (
          <div
            key={_id}
            className={ClassNames("file-doc", {
              doc: !name.split(".")[1] === "pdf",
              pdf: name.split(".")[1] === "pdf",
            })}
          >
            {icon} {name}
          </div>
        );

      default:
        break;
    }

    return elm;
  };

  return (
    <Fragment>
      {!isEmpty(sender) && (
        <Fragment>
          {isSameTime && (
            <p className="chat__thread__date">
              {moment(data.created_at).format("Do MMMM")}
            </p>
          )}
          <div
            className={`chat__thread__${
              sender._id === companyId ? "receiver" : "sender"
            } chat__thread__item`}
          >
            {sender._id !== companyId && (
              <h5 className="user-name">{sender.profile.name}</h5>
            )}
            <div className="message-wrap new-chat">
              {ReactHtmlParser(text)}
              <div className="timestamp">
                {moment(data.created_at).format("hh: mm")}
              </div>
              {!isEmpty(attachments) && (
                <div className="files">
                  {attachments.map((item) => getFile(item))}
                </div>
              )}
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

ChatItem.propTypes = propTypes;

export default ChatItem;

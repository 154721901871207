import React from "react";
import PropTypes from "prop-types";
import "./RegisterProgress.scss";

const propTypes = {
  stepCount: PropTypes.number,
};

const RegisterProgress = ({ stepCount }) => {
  const stepLabel = [
    "Create account",
    "Company goal",
    "Choose product",
    "Place Offer",
  ];

  const renderProgressItems = (index, label) => (
    <div key={index} className="step">
      <div
        className={`step__count ${stepCount > index ? "step__done" : ""} ${
          stepCount === index ? "step__active" : ""
        }`}
      >
        {stepCount > index ? (
          <img src="images/svg/icons/check-mark.svg" />
        ) : (
          index
        )}
      </div>
      <div className="step__label">{label}</div>
    </div>
  );

  return (
    <div className="progress-step">
      {stepLabel.map((label, index) => renderProgressItems(index + 1, label))}
    </div>
  );
};

RegisterProgress.propTypes = propTypes;

export default RegisterProgress;

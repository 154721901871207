import React, { useState } from "react";
import { Map, GoogleApiWrapper, InfoWindow, Marker } from "google-maps-react";
import apiServices from "../../../../_shared/defs/apiServices";

const TrackingMap = props => {
  const { GOOGLE_MAPS_SETTINGS, GOOGLE_MAPS_KEY } = apiServices;
  const { center } = GOOGLE_MAPS_SETTINGS;

  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});

  const onMarkerClick = (props, marker, e) => {
    setSelectedPlace(props);
    setActiveMarker(marker);
    setShowingInfoWindow(true);
  };

  const onClose = props => {
    if (showingInfoWindow) {
      setShowingInfoWindow(true);
      setActiveMarker(null);
    }
  };
  const mapStyles = {
    width: "100%",
    height: "100%",
    borderRadius: "12px",
  };

  return (
    <div style={{ height: "50vh", width: "100%", position: "relative" }}>
      <Map
        google={props.google}
        zoom={4}
        style={mapStyles}
        initialCenter={{
          lat: center.lat,
          lng: center.lng,
        }}
      >
        <Marker
          onClick={onMarkerClick}
          name={"2021 Fillmore Street #69 San Francisco, CA 94115 United States"}
        />
        <InfoWindow
          marker={activeMarker}
          visible={showingInfoWindow}
          onClose={onClose}
        >
          <div>
            <h4>{selectedPlace.name}</h4>
          </div>
        </InfoWindow>
      </Map>
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: apiServices.GOOGLE_MAPS_KEY,
})(TrackingMap);

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Col, Row } from "reactstrap";
import LoginForm from "../_shared/forms/LoginForm";
import SocialAuthForm from "../_shared/forms/SocialAuthForm";
import { login, incrementStep } from "../../../redux/actions";
import "./Login.scss";
import LoginInner from "./LoginInner";
import authService from "../../../services/auth";

const propTypes = {
  login: PropTypes.func,
  isLoggingIn: PropTypes.bool,
  incrementStep: PropTypes.func,
};

const Login = ({ incrementStep }) => {
  const isLoggedIn = authService.isLoggedIn();
  const { push } = useHistory();
  useEffect(() => {
    if (isLoggedIn) push(`/market`);
  }, [isLoggedIn]);

  return (
    <main className="login">
      <Container>
        <Row>
          <Col md={{ size: 4, offset: 4 }}>
            <h5 className="login-form__title">Welcome to OPN</h5>
            <LoginInner nextStep={incrementStep} />
          </Col>
        </Row>
      </Container>
    </main>
  );
};

Login.propTypes = propTypes;

const mapDispatchToProps = { login, incrementStep };

const mapStateToProps = state => ({
  isLoggingIn: state.ui.loading.login,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React, { useState } from "react";
import { Collapse } from "reactstrap";
import OutsideClickHandler from "react-outside-click-handler";

const CoWorker = ({
  uiToggleModal,
  employee,
  seCoWorkertId,
  changeInvitedCoWorkerAccess,
  fetchCompanyInfo,
  seIsCoWorker,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { name, company_permission, mail } = employee || {};
  const access = [
    { type: "Primary contact" },
    { type: "Restricted" },
    { type: "Full" },
  ];

  const handleOnClick = (event, id) => {
    event.stopPropagation();
    const anchorEl = event.target.childNodes[0].data;
    setIsOpen(false);

    const payload = {
      user: id,
      permission: anchorEl,
    };

    changeInvitedCoWorkerAccess(payload, fetchCompanyInfo);
  };

  const handleDeleteCoWorker = (event, id) => {
    event.stopPropagation();
    uiToggleModal();
    setIsOpen(false);
    seCoWorkertId(id);
    seIsCoWorker(true);
  };

  return (
    <React.Fragment>
      <div className="points-of-contacts__employee">
        <div>
          <p className="name">{name || mail.id}</p>
          <p className="position">{company_permission}</p>
          <p className="position">completed</p>
        </div>
        <div className="more">
          <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
            <i
              className="fas fa-ellipsis-h"
              id="restrictAccess"
              onClick={() => setIsOpen(!isOpen)}
            />
            <Collapse isOpen={isOpen}>
              {access.map((option, index) => {
                if (option.type != company_permission)
                  return (
                    <div
                      key={index}
                      name={option.type}
                      className="more__item"
                      onClick={e => handleOnClick(e, employee._id)}
                    >
                      {option.type}
                    </div>
                  );
              })}
              <div
                className="delete-co-worker"
                onClick={e => handleDeleteCoWorker(e, employee._id)}
              >
                <i className="fas fa-trash" />
                <p>Delete</p>
              </div>
            </Collapse>
          </OutsideClickHandler>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CoWorker;

import AccountContent from "./Account/index"
import AnalyticsContent from "./Analytics"
import BillingContent from "./Billings/index"
import "./ContentPanel.scss"
import DealHistory from "./DealHistory/index"
import DocumentsContent from "./Documents/index"
import EcoImpact from "./EcoImpact/index"
import IntegrationContent from "./Integrations/index"
import InventoryContent from "./Inventory/index"
import OfferContent from "./Offers/index"
import RequestContent from "./Requests/index"
import TenderContent from "./Tender/index"
import UpcomingFeature from "./UpcomingFeature/index"

export {
  BillingContent,
  RequestContent,
  OfferContent,
  DocumentsContent,
  AccountContent,
  DealHistory,
  IntegrationContent,
  TenderContent,
  UpcomingFeature,
  InventoryContent,
  EcoImpact,
  AnalyticsContent,
}


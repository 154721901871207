import React, { Fragment } from 'react';
import './Tags.scss';

const Tags = ({ filters, deleteFilter, clearFilter }) => {
  return (
    <div className="search-tag">
      {
        filters && filters.length > 0 ? <Fragment>
          {filters.map((filter, i) => (
            <div className="search-tag__item" key={i}>
              <span>{filter.name.toLowerCase()}</span>
              <i className="far fa-times-circle" onClick={() => deleteFilter(filter.id)} />
            </div>
          ))}
          <div className="search-tag__item clear">
            <i className="fas fa-times-circle" onClick={() => clearFilter()} />
          </div>
        </Fragment> :
        null}
    </div>
  );
};

export default Tags;

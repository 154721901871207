import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import moment from "moment";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { truncate } from "../../../../../../_shared/functions/utils";
import ProgressBar from "../../../../../../_shared/components/ProgressBar";
import "./AllDeal.scss";

const propTypes = {
  isFetchingAllDeal: PropTypes.bool,
  dealList: PropTypes.array,
};

const defaultProps = {
  isFetchingAllDeal: true,
};

const AllDeals = ({ dealList, isFetchingAllDeal }) => {
  const history = useHistory();
  const handleClick = id => {
    history.push(`/deals/${id}`);
  };

  const renderTitle = () => (
    <Row className="all-deals__render-title">
      <Col md={1}>
        <p>Date</p>
      </Col>
      <Col md={2}>
        <p>Code</p>
      </Col>
      <Col md={6}>
        <p>Deals</p>
      </Col>
      <Col md={2}>
        <p>Amount</p>
      </Col>
      <Col md={1} />
    </Row>
  );

  return (
    <main>
      {isFetchingAllDeal ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : isEmpty(dealList) ? (
        <React.Fragment>
          <div className="no-data d-flex justify-content-center p-5">
            <img src="/images/svg/icons/no-data.svg" alt="No data icon" />
          </div>
        </React.Fragment>
      ) : (
        <section>
          {renderTitle()}
          {dealList &&
            dealList.map((deal, index) => {
              let isSameTime = true;
              const prevItem = dealList[index - 1];

              if (!isEmpty(prevItem)) {
                isSameTime = !moment(deal.created_at).isSame(
                  prevItem.created_at,
                  "day"
                );
              }

              return (
                <div key={index} className="all-deals">
                  {isSameTime && (
                    <div className="all-deals__title">
                      <h5>{moment(deal.created_at).format("Do MMMM")}</h5>
                    </div>
                  )}
                  <div
                    className="all-deals__each-deal"
                    onClick={() => handleClick(deal._id)}
                  >
                    <Row>
                      <Col md={1} sm={1} className="over-right">
                        <p>{moment(deal.created_at).format("hh: mm")}</p>
                      </Col>
                      <Col md={2} sm={2}>
                        <p>{deal.deal_code}</p>
                      </Col>
                      <Col md={6} sm={6}>
                        <p>{truncate(deal.product.name, 30)}</p>
                      </Col>
                      <Col md={2} sm={2}>
                        {!deal.paid ? (
                          <p>Pending</p>
                        ) : (
                          <React.Fragment>
                            {deal.isBuyer ? (
                              <p className="all-deals__each-deal__amount-buy ">
                                {`-$${deal.amount / 100}`}
                              </p>
                            ) : (
                              <p className="all-deals__each-deal__amount-sell ">
                                {`+$${deal.amount / 100}`}
                              </p>
                            )}
                          </React.Fragment>
                        )}
                      </Col>
                      <Col md={1} />
                    </Row>
                  </div>
                </div>
              );
            })}
        </section>
      )}
    </main>
  );
};

AllDeals.propTypes = propTypes;
AllDeals.defaultProps = defaultProps;

const mapStateToProps = state => ({
  dealList: state.deals.list.data,
  isFetchingAllDeal: state.ui.loading.fetchAllDeal,
});

export default connect(mapStateToProps, null)(AllDeals);

import React from "react";
import { change, Field, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import validateFunc from "../../../../Authentication/_shared/forms/SignUpForm/registerValidation";
import { TextInputField } from "../../../../../_shared/components/ReduxFormFields/index";
import CustomButton from "../../../../../_shared/components/Button";

const propTypes = {
  isLoading: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  submitting: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  isLoading: false,
};

const FeedbackForm = ({
  handleSubmit,
  isLoading,
  submitting,
  pristine,
  invalid,
}) => {
  return (
    <div className="">
      <form onSubmit={handleSubmit}>
        <Field
          name="contactFullName"
          label="Name"
          id="name"
          type="name"
          component={TextInputField}
          placeholder="Name"
        />
        <Field
          name="email"
          label="Email"
          id="email"
          type="email"
          component={TextInputField}
          placeholder="Email"
        />
        <Field
          name="message"
          label="Message"
          placeholder="message"
          id="message"
          type="textarea"
          component={TextInputField}
          multiline
          rows={4}
        />
        <CustomButton
          name="SEND MESSAGE"
          type="submit"
          classes="w-100"
          loading={isLoading}
          disabled={submitting || pristine || invalid}
        />
      </form>
    </div>
  );
};

FeedbackForm.propTypes = propTypes;
FeedbackForm.defaultProps = defaultProps;

const mapStateToProps = state => ({
  fields: getFormValues(formName)(state),
  isLoading: state.ui.loading.changePassword,
});

const formName = "FeedbackForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  validate: validateFunc,
})(FeedbackForm);

// export default connectedForm;
export default connect(mapStateToProps, null)(connectedForm);

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import AllDocuments from "./AllDocuments";
import { formatMessagesFromError } from "../../../../../_shared/functions/utils";
import Toaster from "../../../../../_shared/components/Toaster";
import ProgressBar from "../../../../../_shared/components/ProgressBar";
import {
  uploadMydocument,
  fetchMydocuments,
} from "../../../../../redux/actions";

const propTypes = {
  uploadMydocument: PropTypes.func,
  fetchMydocuments: PropTypes.func,
  isUploadMydocument: PropTypes.bool,
};

const DocumentsContent = ({
  uploadMydocument,
  fetchMydocuments,
  isUploadMydocument,
}) => {
  useEffect(() => {
    const params = {
      count: 15,
      offset: 0,
    };

    fetchMydocuments(params);
  }, []);

  const handleUpload = (event, mediaType) => {
    const file = event.target.files;

    if (!file.length) return;

    if (file[0].type.split("/")[0] !== "application" && mediaType === "doc") {
      Toaster.dismiss();
      Toaster.error(
        formatMessagesFromError("Invalid file type, check and try again.")
      );

      return;
    }

    const payload = {
      files: file,
      mediaType,
    };

    uploadMydocument(payload, fetchMydocuments);
  };

  const renderTab = (title, disabled) => <Tab disabled={disabled}>{title}</Tab>;

  const renderTabPanel = ContentPanel => (
    <TabPanel className="billing__tabs__panel">
      <ContentPanel />
    </TabPanel>
  );

  return (
    <div className="office-content-panel">
      <Tabs className="tabs billing__tabs">
        <div className="tab-wrap">
          <TabList>{renderTab("All documents")}</TabList>
          <div>
            <label className="upload-btn">
              {isUploadMydocument ? (
                <div className="loading">
                  <ProgressBar spinType="tiny" /> ...LOADING
                </div>
              ) : (
                <span>
                  <img src="/images/upload.png" />
                  UPLOAD NEW
                </span>
              )}

              <input
                className="d-none"
                type="file"
                id="doc"
                onChange={event => handleUpload(event, "doc")}
              />
            </label>
          </div>
        </div>
        <div>{renderTabPanel(AllDocuments)}</div>
      </Tabs>
    </div>
  );
};

DocumentsContent.propTypes = propTypes;

const mapStateToProps = state => ({
  isUploadMydocument: state.ui.loading.uploadMydocument,
});

const mapDispatchToProps = { uploadMydocument, fetchMydocuments };

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsContent);

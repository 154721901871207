import React, { Fragment } from "react"
import { useParams } from "react-router-dom"
import "./ContentPanel.scss"
import ProductContainer from "./ProductContainer"
import ProductView from "./ProductView"
import Search from "./Search"

const ContentPanel = props => {
  const { productId } = useParams();
  console.log('SONO IN CONTENT PANEL')

  return (
    <div className="content-panel">
      {productId ? (
        <ProductView />
      ) : (
        <Fragment>
          <Search />
          <ProductContainer />
        </Fragment>
      )}
    </div>
  );
};

ContentPanel.propTypes = {};

export default ContentPanel;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { change, Field, getFormValues, reduxForm } from "redux-form";
import { useParams, useHistory } from "react-router-dom";
import { Container, Row, Col, InputGroup, Input, Collapse } from "reactstrap";
import { CheckBoxField } from "../../../_shared/components/ReduxFormFields";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  fetchDeal,
  fetchOffersDeals,
  fetchRequestsDeals,
} from "../../../redux/actions";
import "react-tabs/style/react-tabs.css";
import "./Deals.scss";
import DealList from "./DealList";
import DealsDetails from "./DealsDetails";
import Chat from "./Chat";
import ShipmentTracking from "./ShipmentTracking";

const propTypes = {
  fetchDeal: PropTypes.func,
  fetchOffersDeals: PropTypes.func,
  fetchRequestsDeals: PropTypes.func,
  change: PropTypes.func,
  isFetchingOrder: PropTypes.bool,
  isFetchingOffersDeals: PropTypes.bool,
  isFetchingRequestsDeals: PropTypes.bool,
  offerDeals: PropTypes.arrayOf(PropTypes.object),
  requestDeals: PropTypes.arrayOf(PropTypes.object),
  current: PropTypes.object,
  location: PropTypes.object,
};

const filters = ["open", "close"];
const DEFAULT_SECTION_INDEX = 0;

const Deals = props => {
  const { dealId } = useParams();
  const { push } = useHistory();
  const {
    fetchDeal,
    fetchOffersDeals,
    isFetchingOffersDeals,
    isFetchingRequestsDeals,
    fetchRequestsDeals,
    offerDeals,
    requestDeals,
    current,
    location,
    change,
  } = props;
  const { chat, orderTracking } = location.query;

  const [currentDealId, setCurrentDealId] = useState(dealId);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownFilters, setDropdownFilters] = useState([]);
  const [tabIndex, setTabIndex] = useState(DEFAULT_SECTION_INDEX);

  useEffect(() => {
    const params = {
      count: 10,
      offset: 0,
    };

    fetchOffersDeals(params);
    fetchRequestsDeals(params);
    if (currentDealId) {
      const payload = { id: currentDealId };
      fetchDeal(payload);
    }
  }, []);

  useEffect(() => {
    if (offerDeals[0] && !dealId) {
      setCurrentDealId(offerDeals[0]._id);
    } else if (requestDeals[0] && !dealId) {
      setCurrentDealId(requestDeals[0]._id);
    }
  }, [offerDeals, requestDeals]);

  useEffect(() => {
    if (currentDealId) {
      const payload = { id: currentDealId };
      fetchDeal(payload);
    }
  }, [currentDealId]);

  const showDeal = id => {
    push(`/deals/${id}`);
    setCurrentDealId(id);
  };

  const handleCheckBoxOnChange = selected => {
    const currentIndex = dropdownFilters.indexOf(selected);
    const newDropdownFilters = [...dropdownFilters];

    if (currentIndex === -1) {
      newDropdownFilters.push(selected);
      change(`filter-${selected}`, true);
    } else {
      newDropdownFilters.splice(currentIndex, 1);
      change(`filter-${selected}`, false);
    }

    setDropdownFilters(newDropdownFilters);

    const params = {
      count: 10,
      offset: 0,
      filter: newDropdownFilters.join(),
    };

    if (tabIndex === 0) fetchOffersDeals(params);
    if (tabIndex === 1) fetchRequestsDeals(params);
  };

  return (
    <main className="deals">
      <Container fluid>
        <Row>
          <Col xs="12" md="3">
            <div className="deals-sidebar">
              <InputGroup>
                <Input placeholder="Type to search" />
              </InputGroup>

              <Tabs
                className="deals-sidebar__tabs mt-4"
                selectedIndex={tabIndex}
                onSelect={nextTabIndex => {
                  setTabIndex(nextTabIndex);
                }}
              >
                <TabList className="deals-sidebar__tabs__list">
                  <div className="deals-sidebar__tabs__list__inner">
                    <Tab>Offers</Tab>
                    <Tab>Requests</Tab>
                  </div>
                  <div className="deals-sidebar__tabs__list__filter">
                    <div
                      className="filter-btn"
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      <img
                        className="filter-icon"
                        src="/images/svg/icons/filter.svg"
                        alt=""
                      />
                      <span className="filter-text">Filter</span>
                    </div>
                    <Collapse isOpen={isDropdownOpen}>
                      <div className="filter-dropdown">
                        {filters.map((itm, idx) => (
                          <Field
                            name={`filter-${itm}`}
                            label={itm}
                            id={itm}
                            color="blue"
                            type="checkbox"
                            component={CheckBoxField}
                            key={idx}
                            onChange={() => handleCheckBoxOnChange(itm)}
                          />
                        ))}
                      </div>
                    </Collapse>
                  </div>
                </TabList>

                <TabPanel className="deals-sidebar__tabs__tab-panel">
                  <DealList
                    deals={offerDeals}
                    setDeal={showDeal}
                    isLoading={isFetchingOffersDeals}
                    currentId={currentDealId}
                  />
                </TabPanel>
                <TabPanel className="deals-sidebar__tabs__tab-panel">
                  <DealList
                    deals={requestDeals}
                    setDeal={showDeal}
                    isLoading={isFetchingRequestsDeals}
                    currentId={currentDealId}
                  />
                </TabPanel>
              </Tabs>
            </div>
          </Col>
          <Col xs="12" md="7">
            {chat ? (
              <Chat />
            ) : orderTracking ? (
              <ShipmentTracking deal={current} />
            ) : (
              <DealsDetails deal={current} />
            )}
          </Col>
        </Row>
      </Container>
    </main>
  );
};

Deals.propTypes = propTypes;

const formName = "filter";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  //   validate: PreOrderValidationFunc,
})(Deals);

const mapStateToProps = state => ({
  formData: getFormValues(formName)(state),
  offerDeals: state.deals.offerList,
  requestDeals: state.deals.requestList,
  isFetchingOffersDeals: state.ui.loading.fetchOffersDeals,
  isFetchingRequestsDeals: state.ui.loading.fetchRequestsDeals,
  current: state.deals.current,
  location: state.router.location,
});

const mapDispatchToProps = {
  fetchDeal,
  fetchOffersDeals,
  fetchRequestsDeals,
  change,
};

export default connect(mapStateToProps, mapDispatchToProps)(connectedForm);

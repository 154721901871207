import PropTypes from "prop-types"
import qs from "query-string"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Col, Container, Row } from "reactstrap"
import { connectStripe, navigateTo } from "../../../redux/actions"
import ProgressBar from "../../../_shared/components/ProgressBar"
import "./StripeResponse.scss"

const propTypes = {
  connectStripe: PropTypes.func,
  navigateTo: PropTypes.func,
  location: PropTypes.object,
  isConnectingStripe: PropTypes.bool,
};

const defaultProps = {
  isConnectingStripe: true,
};

const StripeResponse = ({
  connectStripe,
  location,
  isConnectingStripe,
  navigateTo,
}) => {
  const { code } = qs.parse(location.search);

  useEffect(() => {
    const payload = {
      refresh_url: `${process.env.BASE_URL}/stripe-response`,
      return_url: `${process.env.BASE_URL}/my-office?tab=accounts`,
    };

    // if (code) connectStripe(payload);
     connectStripe(payload);
  }, []);

  return (
    <main className="stripe-response">
      <Container>
        <Row>
          {isConnectingStripe ? (
            <Col className="stripe-response__loader">
              <div className="stripe-response__loader__inner">
                <ProgressBar />
              </div>
              <p>Connect to Stripe</p>
            </Col>
          ) : (
            <Col className="stripe-response__loader">
              <div className="stripe-response__loader__inner">
                <ProgressBar />
              </div>
              <p>Redirect to Stripe</p>
            </Col>
          )}
        </Row>
      </Container>
    </main>
  );
};

StripeResponse.propTypes = propTypes;
StripeResponse.defaultProps = defaultProps;

const mapStateToProps = state => ({
  location: state.router.location,
  isConnectingStripe: state.ui.loading.connectStripe,
});

const mapDispatchToProps = { connectStripe, navigateTo };

export default connect(mapStateToProps, mapDispatchToProps)(StripeResponse);

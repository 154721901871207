export const AddOn = [
  {
    title: "OPN SmartTender",
    text1:
      "Enterprise smart tendering system based on Cloud and Blockchain technology.",
    text2:
      " Make sure your sourcing process is completely transparent and secure in just a few clicks.",
    hasButton: true,
  },
  {
    Img: "future-plan",
  },
  {
    Img: "plant",
  },
  {
    title: "OPN Full Cycle",
    text1:
      " Blockchain powered module to optimal packaging waste disposal, recycling, and re-entry into the packaging production cycle.",
    text2:
      " Track the location and condition of the raw material using those unique identifiers. This system guarantees the effective location of after-use packaging and prevention of it’s disposal into landfills or oceans.",
    hasButton: true,
  },
  {
    title: "OPN OptPack",
    text1:
      " Packaging optimization module - reduction of the price of your packaging",
    text2:
      "  Reduce materials costs and packaging waste, be more competitive in the market and receive recommendations about the sort and choice of packaging materials.",
    hasButton: true,
  },
  {
    Img: "opt-pack",
  },
  {
    Img: "art-work",
  },
  {
    title: "OPN Artwork",
    text1:
      "  Packaging Design, Prepress and Management - easy to use smart automation tool.",
    text2:
      " Enforce brand identity guidelines, ensure regulatory compliance, and reduce design problems. Preflight the artwork live and visualize the final packaged product in 3D. Use smart templates to create ready-for-repro output files.",
    hasButton: true,
  },
];

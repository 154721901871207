import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import moment from "moment";
import {
  fetchAllNotifications,
  markAsViewedNotification,
} from "../../../redux/actions";
import ProgressBar from "../../../_shared/components/ProgressBar";
import "./Notifications.scss";

const propTypes = {
  fetchAllNotifications: PropTypes.func,
  notifications: PropTypes.array,
  isFetchAllNotifications: PropTypes.bool,
  markAsViewedNotification: PropTypes.func,
};

const Notifications = ({
  fetchAllNotifications,
  notifications,
  isFetchAllNotifications,
  markAsViewedNotification,
}) => {
  const history = useHistory();

  useEffect(() => {
    const params = {
      count: 100,
      offset: 0,
    };

    fetchAllNotifications(params);
  }, []);

  const handleClickAction = (link, view, _id) => {
    history.push(link);
    if (!view) markAsViewedNotification(_id);
  };

  return (
    <main div className="all-notifications">
      {isFetchAllNotifications ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : isEmpty(notifications) ? (
        <div className="d-flex justify-content-center p-5">
          <em>You have no Notification </em>
        </div>
      ) : (
        <React.Fragment>
          <h5>Notifications</h5>
          {notifications.map((notification, index) => {
            const { from, link, created_at, message, view, _id } = notification;
            const { profile } = from;
            const { logo_url } = profile;
            return (
              <div
                key={index}
                className={`${view ? "" : "viewed"} notification`}
                onClick={() => handleClickAction(link, view, _id)}
              >
                <div className="default-company-logo">
                  {logo_url && <img src={logo_url} />}
                </div>
                <div className="notification__info">
                  <p>{message}</p>
                  <p className="notification__info__time">
                    {moment(created_at).fromNow()}
                  </p>
                </div>
              </div>
            );
          })}
        </React.Fragment>
      )}
    </main>
  );
};

Notifications.propTypes = propTypes;

const mapStateToProps = state => ({
  notifications: state.notifications.list,
  isFetchAllNotifications: state.ui.loading.fetchAllNotifications,
});

const mapDispatchToProps = {
  fetchAllNotifications,
  markAsViewedNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);

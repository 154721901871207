import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Row, Col } from "reactstrap";

const QaInfo = () => {
  return (
    <Row className="faq">
      <Col md={{ size: 10, offset: 1 }} className="faq-accordion">
        <h2 className="form-title text-center">FAQ</h2>
        <Accordion allowZeroExpanded={true} preExpanded={["open"]}>
          <AccordionItem uuid="open">
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                Can I change subscription plan?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                You can switch plans at any time. Simply pick the plan that’s
                right for you. Check our Pricing page for more information on
                subscription options.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                Can I get a refund?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                You can cancel your subscription at any time. Unfortunately, we
                do not issue refunds for the time left on your subscription.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                What if I exceed API calls?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>We charge low fees for continuous:</p>
              <ul
                style={{
                  marginBottom: 0,
                  marginTop: 10,
                  marginLeft: 0,
                  paddingLeft: 20,
                }}
              >
                <li>Download – $0,01 per call</li>
                <li>Upload – $0,05 per call</li>
              </ul>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                How do I pay my counterparty on the platform?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                You can pay your counterparty quickly and securely via our
                trusted fintech partners at a 1% lower market fee than other
                marketplaces.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                What is my Return on Investment (ROI)?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Below is an OPN case study of an economic value estimation of
                cloud-based collaborative technology for value-based pricing for
                a global B2B company versus conventional methods.
              </p>
              <img className="img-fluid" src="/images/ROI.png" />
              <p>
                Conducting sales and procurement activities on the OPN Platform
                regularly over the course of a year can increase your annual
                revenue.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </Col>
    </Row>
  );
};

QaInfo.propTypes = {};

export default QaInfo;

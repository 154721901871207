import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import qs from "query-string";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Chat from "../Deals/Chat";
import "./Office.scss";

import {
  BillingContent,
  DealHistory,
  RequestContent,
  DocumentsContent,
  OfferContent,
  AccountContent,
  IntegrationContent,
  TenderContent,
  UpcomingFeature,
  InventoryContent,
  EcoImpact,
  AnalyticsContent,
} from "./ContentPanel";

import {
  navigateTo,
  fetchSubscriptionStatus,
  fetchCompanyInfo,
  setTabIndex,
} from "../../../redux/actions";

import "./ContentPanel/ContentPanel.scss";
import ProductView from "../Market/ContentPanel/ProductView";
import DealsDetails from "../Deals/DealsDetails";

const propTypes = {
  navigateTo: PropTypes.func,
  fetchCompanyInfo: PropTypes.func,
  fetchSubscriptionStatus: PropTypes.func,
  setTabIndex: PropTypes.func,
  tabIndex: PropTypes.number,
};

const OFFICE_TABS = [
  "offers",
  "requests",
  "deal_history",
  "tenders",
  "documents",
  "accounts",
  "billings",
  "integrations",
  "shipment",
  "financing",
  "eco-impact",
  "analytics",
  "addon",
  "inventory",
];

const DEFAULT_SECTION_INDEX = 0;

const Office = (props) => {
  const {
    navigateTo,
    fetchSubscriptionStatus,
    fetchCompanyInfo,
    setTabIndex,
    tabIndex,
  } = props;
  const history = useHistory();
  const { tab, view, tenderOffer, chat } = qs.parse(history.location.search);

  const currentTabIndex =
    OFFICE_TABS.indexOf(tab) > 0 &&
    OFFICE_TABS.indexOf(tab) < OFFICE_TABS.length
      ? OFFICE_TABS.indexOf(tab)
      : DEFAULT_SECTION_INDEX;
  //const [tabIndex, setTabIndex] = useState(currentTabIndex);

  useEffect(() => {
    fetchSubscriptionStatus();
    fetchCompanyInfo();
    setTabIndex(currentTabIndex);
  }, []);

  useEffect(() => {
    setTabIndex(currentTabIndex);
  }, [currentTabIndex]);

  const setQueryParam = (paramValue) =>
    navigateTo(`/my-office?tab=${OFFICE_TABS[paramValue]}`);

  const renderTab = (title, imageName, disabled = false) => (
    <Tab disabled={disabled}>
      <img
        className="icon"
        src={`${process.env.IMAGE_URL}/assets/images/svg/icons/${imageName}.svg`}
        alt=""
      />
      {title}
    </Tab>
  );

  const renderTabPanel = (ContentPanel) => (
    <TabPanel className="office__tabs__panel">
      <ContentPanel />
    </TabPanel>
  );

  const renderPanels = () => (
    <Fragment>
      {renderTabPanel(OfferContent)}
      {renderTabPanel(RequestContent)}
      {renderTabPanel(DealHistory)}
      {renderTabPanel(TenderContent)}
      {renderTabPanel(DocumentsContent)}
      {renderTabPanel(AccountContent)}
      {renderTabPanel(BillingContent)}
      {renderTabPanel(IntegrationContent)}
      {renderTabPanel(UpcomingFeature)}
      {renderTabPanel(UpcomingFeature)}
      {renderTabPanel(EcoImpact)}
      {renderTabPanel(AnalyticsContent)}
      {renderTabPanel(UpcomingFeature)}
      {renderTabPanel(InventoryContent)}
    </Fragment>
  );

  return (
    <main className="office">
      <Container fluid>
        <Row>
          <Col>
            <Tabs
              className="office__tabs"
              selectedIndex={tabIndex}
              onSelect={(nextTabIndex) => {
                setQueryParam(`${nextTabIndex}`);
                setTabIndex(nextTabIndex);
              }}
            >
              <Row>
                <Col md="2" className="tab-list-wrap">
                  <TabList className="office__tabs__sidebar">
                    {renderTab("Offers", "brief")}
                    {renderTab("Requests", "request")}
                    {renderTab("Deal History", "paper")}
                    {renderTab("Tenders", "gravel")}
                    {renderTab("Documents", "file")}
                    {renderTab("Accounts", "card")}
                    {renderTab("Billings", "history")}
                    {renderTab("Integrations", "integration")}
                    {renderTab("Shipment", "shipment")}
                    {renderTab("Financing", "financial")}
                    {renderTab("ECO Impact", "eco")}
                    {renderTab("Analytics", "analytics")}
                    {renderTab("Add On", "addon")}
                    {renderTab("Virtual Inventory", "inventory")}
                  </TabList>
                </Col>

                <Col md="8">
                  {view ? (
                    <ProductView />
                  ) : tenderOffer ? (
                    <DealsDetails />
                  ) : chat ? (
                    <Chat />
                  ) : (
                    renderPanels()
                  )}
                </Col>
              </Row>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

Office.propTypes = propTypes;

const mapStateToProps = (state) => ({
  location: state.router.location,
  tabIndex: state.ui.tabIndex,
});

const mapDispatchToProps = {
  navigateTo,
  fetchSubscriptionStatus,
  fetchCompanyInfo,
  setTabIndex,
};

export default connect(mapStateToProps, mapDispatchToProps)(Office);

const number = value =>
  value && !/^-?\d*\.?\d*$/i.test(value) ? "Invalid data" : undefined;

const OrderFormValidation = values => {
  const errors = {};

  if (!values || !values.category) {
    errors["category"] = "Category field is required";
  }

  if (!values || !values.product) {
    errors["product"] = "Product name field is required";
  } else if (
    values.product.value &&
    values.product.value.replace(/ /g, "").length < 2
  ) {
    errors["product"] = "Product name length must be at least 2 characters";
  }

  if (!values || !values.location) {
    errors["location"] = "Location field is required";
  }

  if (!values || !values.destination) {
    errors["destination"] = "Destination field is required";
  }

  if (!values || !values.description) {
    errors["description"] = "Description field is required";
  } else if (values.description.replace(/ /g, "").length < 3) {
    errors["description"] = "Description length must be at least 3 characters";
  }

  if (!values || !values.variant) {
    errors["variant"] = "Delivery variants field is required";
  }

  if (!values || !values.quantity) {
    errors["quantity"] = "Quantity field is required";
  }

  if (!values || !values.price) {
    errors["price"] = "Price terms field is required";
  }

  if (!values || !values.term) {
    errors["term"] = "Delivery terms field is required";
  }

  // if (!values || !values.ship) {
  //   errors["ship"] = "Please confirm if the offer is ready for shipment";
  // }

  if (!values || !values.unit) {
    errors["unit"] = "Unit field is required";
  }

  /* optional field */
  if (values.price) {
    const validPrice = values.price.match(
      /^[-+]?(?:[0-9]+,)*[0-9]+(?:\.[0-9]{1,2})?$/
    );
    if (!validPrice) errors["price"] = "Price set is Invalid";
  }

  if (values.terms) {
    const validTerms = number(values.terms);
    if (validTerms) errors["terms"] = validTerms;
  }

  return errors;
};

export default OrderFormValidation;

const VerificationVal = values => {
  const errors = {};

  if (!values || !values.reqistration_number) {
    errors["reqistration_number"] = "Reqistration number field is required";
  }

  if (!values || !values.jurisdiction) {
    errors["jurisdiction"] = "Jurisdiction field is required";
  }

  if (!values || !values.date) {
    errors["date"] = "Date field is required";
  }

  return errors;
};

export default VerificationVal;

import React from "react";
import { change, Field, getFormValues, reduxForm } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import CustomButton from "../../../../../../_shared/components/Button";
import { Col, Form, FormGroup, Row } from "reactstrap";
import {
  TextInputField,
  CheckBoxField,
} from "../../../../../../_shared/components/ReduxFormFields";
import {
  setIbanForBank,
  uiToggleShowDialog,
} from "../../../../../../redux/actions";
// import validateFunc from "./loginValidation";
import "../Stripe/Stripe.scss";

const propTypes = {
  isLoading: PropTypes.bool,
};

const defaultProps = {
  isLoading: false,
};

const PlaidForm = props => {
  const {
    handleSubmit,
    isLoading,
    submitting,
    pristine,
    invalid,
    setIbanForBank,
    formData,
    hasCompanyInfo,
    uiToggleShowDialog,
  } = props;

  console.log("formData :", formData);

  const onFormSubmit = values => {
    if (!hasCompanyInfo) return uiToggleShowDialog();

    const { iban, account_name } = values;
    const payload = {
      iban: "GB33BUKB20201555555555",
      account_name: "Harry Potter",
      postal_code: "11111",
    };

    setIbanForBank(payload);
  };

  return (
    <Form className="login-form" onSubmit={handleSubmit(onFormSubmit)}>
      <Row>
        <Col md="12">
          <FormGroup>
            <Field
              name="iban"
              label="IBAN"
              type="text"
              disabled={isLoading}
              component={TextInputField}
              placeholder="IBAN"
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="account_name"
              label="Account name"
              type="text"
              disabled={isLoading}
              component={TextInputField}
              placeholder="Account name"
            />
          </FormGroup>
          <FormGroup>
            <Field
              name="postal_code"
              label="Postal code"
              type="text"
              disabled={isLoading}
              component={TextInputField}
              placeholder="Postal code"
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md="12" className="login-form__cta">
          <CustomButton
            name="Connect"
            type="submit"
            loading={isLoading}
            classes="w-100"
            disabled={
              invalid ||
              isLoading ||
              pristine ||
              submitting ||
              (formData && formData.iban.length === 0)
            }
          />
        </Col>
      </Row>
    </Form>
  );
};

PlaidForm.propTypes = propTypes;
PlaidForm.defaultProps = defaultProps;

const formName = "plaidForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  // validate: validateFunc,
})(PlaidForm);

// export default connectedForm;
export default connect(
  state => ({
    formData: getFormValues(formName)(state),
    isLoading: state.ui.loading.setIbanForBank,
    hasCompanyInfo: state.settings.hasCompanyInfo,
  }),
  {
    change,
    setIbanForBank,
    uiToggleShowDialog,
  }
)(connectedForm);

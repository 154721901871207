import { omit } from "lodash";
import {
  FETCH_ACTIVE_TENDER,
  FETCH_ARCHIVED_TENDER,
  FETCH_PURCHASING_ORDER,
  FETCH_TENDER_OFFERS,
} from "../../actions";

const defaultState = {
  activeList: {},
  archivedList: {},
  purchasingList: {},
  offersList: {},
};

const tenderReducer = (state = defaultState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_ACTIVE_TENDER.SUCCESS:
      return Object.assign({}, state, {
        activeList: {
          data: payload.data,
          pagination: omit(payload, ["data"]),
        },
      });
    case FETCH_PURCHASING_ORDER.SUCCESS:
      return Object.assign({}, state, {
        purchasingList: {
          data: payload.data,
          pagination: omit(payload, ["data"]),
        },
      });
    case FETCH_ARCHIVED_TENDER.SUCCESS:
      return Object.assign({}, state, {
        archivedList: {
          data: payload.data,
          pagination: omit(payload, ["data"]),
        },
      });
    case FETCH_TENDER_OFFERS.SUCCESS:
      return Object.assign({}, state, {
        offersList: {
          data: payload.data,
          pagination: omit(payload, ["data"]),
        },
      });
    default:
      return state;
  }
};

export default tenderReducer;

import { omit, isEmpty } from "lodash";
import {
  CREATE_DEAL,
  FETCH_DEAL_STAT,
  FETCH_DEAL,
  UPDATE_DEAL,
  FETCH_OFFERS_DEALS,
  FETCH_REQUESTS_DEALS,
  PRE_ACCEPT_DEAL,
  ACCEPT_DEAL,
  CANCEL_DEAL,
  DEAL_PAYMENT,
  FETCH_CHAT_THREAD,
  FETCH_DEAL_CHAT_ID,
  SEND_CHAT_MESSAGE,
  FETCH_ALL_DEAL,
  FETCH_DEAL_BY_STATUS,
  UPDATE_DEAL_PRICE,
  FETCH_TENDER_BID,
  REJECT_TENDER_BID,
  ACCEPT_TENDER_BID,
} from "../../actions";

const defaultState = {
  list: {},
  offerList: [],
  requestList: [],
  dealByStatus: {},
  stats: {},
  current: {},
  chat: {},
};

const categoriesReducer = (state = defaultState, action) => {
  const { payload } = action;
  switch (action.type) {
    case UPDATE_DEAL.SUCCESS:
    case FETCH_DEAL.SUCCESS:
    case PRE_ACCEPT_DEAL.SUCCESS:
    case ACCEPT_DEAL.SUCCESS:
    case CREATE_DEAL.SUCCESS:
    case FETCH_TENDER_BID.SUCCESS:
    case CANCEL_DEAL.SUCCESS:
    case DEAL_PAYMENT.SUCCESS:
    case REJECT_TENDER_BID:
    case ACCEPT_TENDER_BID:
      return Object.assign({}, state, {
        current: payload,
      });
    case UPDATE_DEAL_PRICE.SUCCESS: {
      const isOfferOrigin = isEmpty(payload.originOffer);
      let updatedData, updatedState;

      if (isOfferOrigin) {
        updatedData = state.offerList.map((item) => {
          if (item._id === payload._id) return payload;
          return item;
        });

        updatedState = {
          offerList: updatedData,
          current: payload,
        };
      } else {
        updatedData = state.requestList.map((item) => {
          if (item._id === payload._id) return payload;
          return item;
        });

        updatedState = {
          requestList: updatedData,
          current: payload,
        };
      }
      return Object.assign({}, state, { ...updatedState });
    }
    case FETCH_DEAL_BY_STATUS.SUCCESS:
      return Object.assign({}, state, {
        dealByStatus: {
          data: payload.data,
          pagination: omit(payload, ["data"]),
        },
      });
    case FETCH_OFFERS_DEALS.SUCCESS:
      return Object.assign({}, state, {
        offerList: payload.data,
        pagination: omit(payload, ["data"]),
      });
    case FETCH_REQUESTS_DEALS.SUCCESS:
      return Object.assign({}, state, {
        requestList: payload.data,
        pagination: omit(payload, ["data"]),
      });
    case FETCH_DEAL_CHAT_ID.SUCCESS:
      return Object.assign({}, state, {
        chat: payload,
      });
    case FETCH_DEAL_STAT.SUCCESS:
      return Object.assign({}, state, {
        stats: payload,
      });
    case FETCH_CHAT_THREAD.SUCCESS:
      return Object.assign({}, state, {
        chat: {
          ...state.chat,
          thread: { ...payload, data: payload.data.reverse() },
        },
      });
    case SEND_CHAT_MESSAGE.SUCCESS:
      return Object.assign({}, state, {
        chat: {
          ...state.chat,
          thread: {
            ...state.chat.thread,
            data: [...state.chat.thread.data, payload],
          },
        },
      });

    case FETCH_ALL_DEAL.SUCCESS:
      return Object.assign({}, state, {
        list: {
          data: payload.data,
          pagination: omit(payload, ["data"]),
        },
      });
    default:
      return state;
  }
};

export default categoriesReducer;

import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import ReactCountryFlag from "react-country-flag";
import { Helmet } from "react-helmet";
import { isEmpty } from "lodash";
import { getName } from "country-list";
import moment from "moment";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Container, Row, Col } from "reactstrap";
import Countdown from "react-countdown";
import qs from "query-string";
import {
  fetchOrder,
  uiToggleShowDialog,
  uiToggleShowSignupModal,
  fetchTenderId,
  rejectTenderBid,
} from "../../../../../redux/actions";
import CustomButton from "../../../../../_shared/components/Button";
import {
  priceFormat,
  stringify,
  truncate,
} from "../../../../../_shared/functions/utils";
import ProgressBar from "../../../../../_shared/components/ProgressBar";
import AuthService from "../../../../../services/auth";
import "./ProductView.scss";

const propTypes = {
  fetchOrder: PropTypes.func,
  fetchTenderId: PropTypes.func,
  uiToggleShowDialog: PropTypes.func,
  uiToggleShowSignupModal: PropTypes.func,
  rejectTenderBid: PropTypes.func,
  isFetchingOrder: PropTypes.bool,
  isRejectingBid: PropTypes.bool,
  hasCompanyInfo: PropTypes.bool,
  current: PropTypes.object,
  location: PropTypes.object,
  clientId: PropTypes.string,
  userPlan: PropTypes.string,
};

const ProductView = props => {
  const isLoggedIn = AuthService.isLoggedIn();
  const {
    fetchOrder,
    fetchTenderId,
    current,
    location,
    isFetchingOrder,
    clientId,
    uiToggleShowDialog,
    hasCompanyInfo,
    uiToggleShowSignupModal,
    rejectTenderBid,
    isRejectingBid,
    userPlan,
  } = props;
  const { productId } = useParams();
  const history = useHistory();
  const { view, tab } = qs.parse(history.location.search);

  const data = view ? current.order : current;
  const {
    category,
    name,
    description,
    company,
    country,
    delivery,
    status,
    price,
    amount,
    photos,
    quantity,
    destination,
    documents,
    isOwner,
    purpose,
    duration,
    tender,
  } = data || {};
  console.log("data :", current);

  const { previousLabel } = location.state || {};
  const productType =
    previousLabel || (purpose === "BUY" ? "all offers" : "all requests");
  const [displayImage, setDisplayImage] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [showTenderCounter, setShowTenderCounter] = useState(true);
  const [toLoad, setToLoad] = useState("");

  useEffect(() => {
    fetchOrderRequest();
  }, []);

  useEffect(() => {
    if (!isEmpty(photos)) setDisplayImage(photos[0] && photos[0].cdn_link);
  }, [current]);

  useEffect(() => {
    fetchOrderRequest();
  }, [clientId]);

  const fetchOrderRequest = () => {
    if (view) return fetchTenderId(view);

    fetchOrder(productId);
  };

  const handleGoBack = () => {
    if (view) {
      history.push("/my-office?tab=tenders");
    } else {
      history.push(
        `/market?market=${productType.split(" ")[1]}&productList=${
          productType.split(" ")[0]
        }`
      );
    }
  };

  const handleRedirectToPreOrder = () =>
    history.push(`/market/${productId}/pre-order`);

  const handleRedirectView = (e, id) => {
    history.push({
      search: `tab=${tab}&tenderOffer=${id}`,
    });
  };

  const toShareLink = `${window.location.protocol}//${
    window.location.hostname == "localhost"
      ? "localhost:3000"
      : window.location.hostname
  }${location.pathname}`;

  const getCountdown = ({ days, hours, minutes, seconds, completed }) => {
    console.log("hours :", hours);
    setShowTenderCounter(true);
    if (completed) {
      // Render a completed state
      setShowTenderCounter(false);
      return <div />;
    } else {
      // Render a countdown
      return (
        <span className="tender-countdown">
          <span className="count">{days}Days</span>
          <span className="count">{hours}h</span>
          <span className="count">{minutes}m</span>
          <span className="count">{seconds}s</span>
        </span>
      );
    }
  };

  const renderPremiumLabel = () => (
    <div>
      {userPlan !== "ENTERPRISE" && tender !== null && (
        <div className="premium-label">
          <div className="premium-label__title">
            Tender{" "}
            <span
              className="premium-label__title__tag"
              onClick={() => history.push("/pricing")}
            >
              enterprise
            </span>
          </div>
          <div className="premium-label__note">
            With an <span className="plan">Enterprise</span> account, you can
            apply for tenders.
          </div>
        </div>
      )}
      <CustomButton
        action={
          isLoggedIn
            ? hasCompanyInfo
              ? handleRedirectToPreOrder
              : uiToggleShowDialog
            : uiToggleShowSignupModal
        }
        faName="fa-tag"
        disabled={userPlan !== "ENTERPRISE" && tender !== null}
        color={purpose === "BUY" ? "green" : "blue"}
        name={purpose === "BUY" ? "make an offer" : "make a request"}
      />
    </div>
  );

  return (
    <Container className="product-view">
      <Helmet>
        <title>{`OPN Platform - ${name} (${priceFormat(
          price,
          true
        )})/${amount && amount.unit}`}</title>
        <meta property="og:title" content={name} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={photos && photos[0] && photos[0].cdn_link}
        />
        <meta property="og:url" content={toShareLink} />
        <meta name="twitter:card" content={`${name} image`} />

        <meta property="og:site_name" content="OPN Platform" />
        <meta name="twitter:image:alt" content={`${name} image`} />
      </Helmet>
      <Row>
        <Col md="12">
          <div className="product-view__header">
            <CustomButton
              btnType="no-border"
              action={handleGoBack}
              faName="fa-angle-left"
              name={view ? "My active tender" : `${productType}`}
            />
          </div>
        </Col>
        <Col md="12">
          {isFetchingOrder ? (
            <div className="d-flex justify-content-center p-5">
              <ProgressBar />
            </div>
          ) : (
            <div className="product-view__content">
              <p className="product-view__content__category">
                {category &&
                  (category.stringified
                    ? stringify(category.stringified.split("///")[0])
                    : category.name
                    ? category.name
                    : "---")}
              </p>
              <Row>
                <Col md="8">
                  <div className="product-view__content__body">
                    <h4 className="body__title">{name ? name : "---"}</h4>
                    <p className="body__description">
                      {description ? description : "---"}
                    </p>
                    <ul className="body__details">
                      <li>
                        {company && company.verified && (
                          <strong>verified company</strong>
                        )}
                        <p>
                          Company:{" "}
                          <span className="body__details__company">
                            {company && company.verified && (
                              <img
                                className="body__details__company__verified"
                                src="/images/svg/icons/verify.svg"
                                alt="chat icon"
                              />
                            )}
                            {company && company.profile && company.profile.name
                              ? company.profile.name
                              : "---"}
                          </span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Location:{" "}
                          {country ? (
                            <span>
                              <ReactCountryFlag
                                countryCode={country}
                                className="body__details__flag"
                                svg
                              />
                              {getName(country)}
                            </span>
                          ) : (
                            <span>{"---"}</span>
                          )}
                        </p>
                      </li>
                      <li>
                        <p>
                          Delivery variant:{" "}
                          <span>{delivery ? delivery.variant : "---"}</span>
                        </p>
                      </li>
                      <li>
                        <p>
                          Delivery terms (days):{" "}
                          <span>{delivery ? `${delivery.term}` : "---"}</span>
                        </p>
                      </li>
                      {quantity && (
                        <li>
                          <p>
                            Quantity: <span>{quantity}</span>
                          </p>
                        </li>
                      )}
                      {destination && (
                        <li>
                          <p>
                            Destination: <span>{destination}</span>
                          </p>
                        </li>
                      )}
                      <li>
                        <p>
                          Status: <span>{status ? status : "---"}</span>
                        </p>
                      </li>
                      {duration && showTenderCounter && (
                        <li>
                          <p>
                            Time left:{" "}
                            <Countdown
                              date={new Date(moment(duration || 0))}
                              renderer={getCountdown}
                            />
                          </p>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="product-view__content__price-cta">
                    <p className="price">
                      {price ? priceFormat(price) : "---"}
                      {amount && (
                        <span className="unit">per {amount.unit}</span>
                      )}
                    </p>
                    {!isOwner &&
                      !view &&
                      showTenderCounter &&
                      renderPremiumLabel()}
                  </div>
                </Col>
                <Col md="4">
                  {displayImage && (
                    <div className="photo-display">
                      <img src={displayImage} />
                    </div>
                  )}
                  <div className="photo-gallery">
                    {photos &&
                      photos.map((photo, i) => (
                        <div
                          className="photo-gallery__item"
                          key={i}
                          onClick={() => setDisplayImage(photo.cdn_link)}
                        >
                          <img src={photo.cdn_link} />
                        </div>
                      ))}
                  </div>
                </Col>
                {isOwner && (
                  <Col md="8">
                    <p className="share-link__title">Get a sharable link</p>
                    <div className="share-link">
                      <div className="icon">
                        <i className="fas fa-link" />
                      </div>
                      <p title={toShareLink} className="link">
                        {truncate(toShareLink, 50)}
                      </p>
                      <div>
                        <CopyToClipboard
                          text={`${toShareLink}`}
                          onCopy={() => setIsCopied(true)}
                        >
                          <div>
                            <CustomButton name={isCopied ? "copied" : "Copy"} />
                          </div>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </Col>
                )}
                <Col md="8" className="doc-display">
                  <h4 className="form-title">Product documents</h4>
                  {!isEmpty(documents) ? (
                    documents.map((doc, i) => (
                      <div className="doc-display__item" key={i}>
                        {doc.name.split(".")[1] === "pdf" ? (
                          <img src="/images/svg/icons/pdf.svg" alt="pdf" />
                        ) : (
                          <img src="/images/svg/icons/doc.svg" alt="doc" />
                        )}
                        <a
                          href={doc.cdn_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {truncate(doc.name, 20)}
                        </a>
                      </div>
                    ))
                  ) : (
                    <p>
                      <em>No document to display</em>
                    </p>
                  )}
                </Col>

                {view && (
                  <Col md="12">
                    <div className="bid">
                      <h4 className="sm-title">BIDS</h4>
                      <div className="bid-list">
                        {(current.offer || []).map(item => {
                          const { from, price, _id } = item;
                          console.log("item :", item);

                          return (
                            <div key={_id} className="bid-list-item">
                              <div className="bid-list-item__container">
                                <div className="bid-timeline__header">
                                  {from && from.verified && (
                                    <img
                                      className="bid-timeline__header__verified"
                                      src="/images/svg/icons/verify.svg"
                                      alt="chat icon"
                                    />
                                  )}
                                  <span className="bid-timeline__header__company">
                                    {from && from.profile && from.profile.name
                                      ? from.profile.name
                                      : "---"}
                                  </span>
                                  <span className="bid-timeline__header__rating">
                                    <i className="fas fa-star deals-sidebar__notification__card__status--active" />
                                    <i className="fas fa-star deals-sidebar__notification__card__status--active" />
                                    <i className="fas fa-star deals-sidebar__notification__card__status--active" />
                                    <i className="fas fa-star deals-sidebar__notification__card__status--active" />
                                    <i className="fas fa-star deals-sidebar__notification__card__status--active" />
                                  </span>
                                </div>
                                <div className="bid-timeline__body">
                                  <div className="bid-timeline__body__price">
                                    {price ? priceFormat(price) : "---"}
                                  </div>
                                  <div className="bid-timeline__body__country">
                                    {country ? (
                                      <Fragment>
                                        <ReactCountryFlag
                                          countryCode={country}
                                          className="bid-timeline__body__country__flag"
                                          svg
                                        />
                                        <span>{getName(country)}</span>
                                      </Fragment>
                                    ) : (
                                      <span>{"---"}</span>
                                    )}
                                  </div>
                                  <div className="bid-timeline__body__dot">
                                    .
                                  </div>
                                  <div className="bid-timeline__body__variant">
                                    {delivery ? delivery.variant : "---"}
                                  </div>
                                </div>
                              </div>
                              <div className="bid-list-item__cta">
                                <CustomButton
                                  action={e => handleRedirectView(e, _id)}
                                  btnType="no-border"
                                  name="View"
                                />
                                <CustomButton
                                  loading={
                                    toLoad === _id ? isRejectingBid : false
                                  }
                                  action={() => {
                                    setToLoad(_id);
                                    rejectTenderBid({
                                      tender_id: view,
                                      offer_id: _id,
                                    });
                                  }}
                                  btnType="no-border"
                                  name="Reject"
                                />
                                <CustomButton btnType="no-border" name="Chat" />
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

ProductView.propTypes = propTypes;

const mapStateToProps = state => ({
  current: state.orders.current,
  isFetchingOrder:
    state.ui.loading.fetchOrder || state.ui.loading.fetchTenderId,
  location: state.router.location,
  clientId: state.auth.clientId,
  hasCompanyInfo: state.settings.hasCompanyInfo,
  isRejectingBid: state.ui.loading.rejectTenderBid,
  userPlan: state.auth.user && state.auth.user.subscription?.tariff,
});

const mapDispatchToProps = {
  fetchOrder,
  uiToggleShowDialog,
  uiToggleShowSignupModal,
  fetchTenderId,
  rejectTenderBid,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductView);

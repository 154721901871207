import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import ProgressBar from "../ProgressBar";
import "./Button.scss";

const propTypes = {
  action: PropTypes.func,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  classes: PropTypes.string,
  color: PropTypes.string,
  btnType: PropTypes.string,
  faName: PropTypes.string,
  imgPath: PropTypes.string,
  imgSrc: PropTypes.string,
  loading: PropTypes.bool,
};

const CustomButton = ({
  action,
  loading,
  btnType = "regular",
  name,
  faName,
  imgSrc,
  color = "blue",
  classes,
  ...rest
}) => {
  const handleOnClick = () => {
    action && action();
  };

  const renderIcon = (faName, imgSrc) => {
    if (faName) return <i className={`icon fas ${faName}`} />;
    if (imgSrc) return <img className="icon" src={`${imgSrc}`} />;
  };

  return (
    <Button
      {...rest}
      color="link"
      onClick={() => handleOnClick()}
      className={`custom-btn ${color} ${btnType} ${classes ? classes : ""}`}
    >
      {loading ? (
        <div className="loading">
          <ProgressBar spinType="tiny" /> Loading
        </div>
      ) : (
        <span>
          {renderIcon(faName, imgSrc)}
          {name}
        </span>
      )}
    </Button>
  );
};

CustomButton.propTypes = propTypes;

export default CustomButton;

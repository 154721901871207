import React from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { Col } from "reactstrap"
import authService from "../../../../services/auth"
import CustomButton from "../../../../_shared/components/Button"
import "./PriceCard.scss"


const PriceCard = props => {
  const {
    id,
    title,
    content,
    bonus,
    plan,
    monthPrice,
    yearPrice,
    accountSwitch,
    totalPrice,
    businessPlan,
    loggedIn,
    basicPlan,
    currentPlan,
    order,
  } = props;
  const isLoggedIn = authService.isLoggedIn();

  // console.log('ORDINE', order, currentPlan.order)
 

  const buttonLabel = isLoggedIn && currentPlan
    ? currentPlan.order < order
      ? "Upgrade now"
      : currentPlan.order === order
      ? "Your plan"
      : "Downgrade now"
    : "Get started";


  const { push } = useHistory();
  return (
    <Col xs={12} sm={12} md={4} className="">
      <div
        className={`price-card ${id == 2 ? "pricing_popularTariffBox" : null}`}
      >
        {id === 2 ? <div className="price-card__ribbon"> Popular </div> : null}
        <h5 className="price-card__title">{plan}</h5>
        <div className="price-card__limitsContainer">
          <div className="limitsName">{id === 3 ? "Unlimited" : "Limits"} </div>
          <div className="price-circle-wrap">
            <div className="price-circle-wrap__inner">
              {id === 3 ? <p>from</p> : null}
              <h3
                className={`${(yearPrice || monthPrice) !== "FREE" &&
                  "currency"}`}
              >
                {accountSwitch ? yearPrice : monthPrice}
              </h3>
              <p>/month</p>
            </div>
          </div>
          <div className="bonus__wrap">
            {id === 3 || id === 2 || id === 1 ? (
              <p className="bonus__wrap__caption">
                When you pay yearly{" "}
                <span style={{ color: "#558EFD" }}> SAVE 20% </span> <br />
                {id === 3 ? <span>From </span> : null}
              </p>
            ) : (
              <p className="bonus__wrap__caption">Soft-limited</p>
            )}
            {accountSwitch && (id === 3 || id === 2 || id === 1) ? (
              <p className="bonus__wrap__total">
                Saving total: <span>${totalPrice}</span>
              </p>
            ) : null}
            {id === 3 || id === 2 || id === 1 ? (
              <p className="bonus__wrap__price">
                ${accountSwitch ? monthPrice : yearPrice}
                <span>/month</span>
              </p>
            ) : (
              <p className="bonus__wrap__price free">Freemium Plan</p>
            )}
            {!accountSwitch && (id === 3 || id === 2 || id === 1) ? (
              <p className="bonus__wrap__total">
                Saving total: <span>${totalPrice}</span>
              </p>
            ) : null}
          </div>
          <div className="price-card__cta">
            <CustomButton
              action={() =>
                push({
                  pathname: `/pricing/checkout`,
                  state: {
                    plan: `${!accountSwitch ? "monthly" : "yearly"}`,
                    tariff: plan && plan,
                  },
                })
              }
              btnType={id !== 2 ? `outlined` : `regular`}
              name={buttonLabel}
            />
          </div>
        </div>
      </div>
      
        <p className="price-card__repost">
           <br />
        
        </p>
   
    </Col>
  );
};

const mapStateToProps = state => {
  return {
    clientId: state.clientId
  };
};

export default connect(mapStateToProps)(PriceCard);

{/* <a
href="mailto:enterprise@opnplatform.com"
onClick={() => {
  if (window.location.host === "opnplatform.com") {
   
    window.Intercom("update", {
      app_id: "ulueqf5y",
      name: loggedIn.data.result.name,
      email: loggedIn.data.result.mail.id,
      "Plan type": "Enterprise",
    });
  }
}}
> 
<CustomButton btnType="outlined" name="contact sales" 
 

/>
</a> */}
import React, { Fragment } from "react";
import { FormGroup, Col } from "reactstrap";
import PropTypes from "prop-types";
import {
  formatMessagesFromError,
  truncate,
} from "../../../../../../../_shared/functions/utils";
import Toaster from "../../../../../../../_shared/components/Toaster";
import ProgressBar from "../../../../../../../_shared/components/ProgressBar";
import "./AddProductUploads.scss";

const propTypes = {
  nextStep: PropTypes.func,
  change: PropTypes.func,
  uploadMedia: PropTypes.func,
  isUploadingImage: PropTypes.bool,
  isUploadingDoc: PropTypes.bool,
  images: PropTypes.array,
  docs: PropTypes.array,
};

const AddProductUploads = props => {
  const {
    images,
    docs,
    isUploadingImage,
    isUploadingDoc,
    change,
    uploadMedia,
  } = props;

  const handleUpload = (event, mediaType) => {
    const file = event.target.files;
    if (!file.length) return;

    if (file[0].type.split("/")[0] !== "application" && mediaType === "doc") {
      Toaster.dismiss();
      Toaster.error(
        formatMessagesFromError("Invalid file type, check and try again.")
      );

      return;
    }

    if (mediaType === "img" && file[0].type.split("/")[0] !== "image") {
      Toaster.dismiss();
      Toaster.error(
        formatMessagesFromError("Invalid file type, check and try again.")
      );

      return;
    }

    const payload = {
      files: file,
      mediaType,
    };

    uploadMedia(payload);
  };

  return (
    <Fragment>
      <Col md={{ size: 10, offset: 1 }} className="uploads__image">
        <FormGroup>
          <div className="uploads__image__grid">
            {images &&
              images.map((image, i) => (
                <div className="uploads__image__grid__item" key={i}>
                  <img src={image.url} />
                </div>
              ))}
          </div>
          <label htmlFor="img" className="uploads__image__input">
            <img src="/images/svg/icons/upload.svg" alt="" />
            <input
              className="d-none"
              type="file"
              id="img"
              disabled={isUploadingImage}
              onChange={event => handleUpload(event, "img")}
            />{" "}
            Upload image
            {isUploadingImage && (
              <div className="loading">
                <ProgressBar spinType="tiny" />
              </div>
            )}
          </label>
        </FormGroup>
      </Col>
      <Col md={{ size: 10, offset: 1 }} className="uploads__doc">
        <FormGroup>
          <div className="uploads__doc__list">
            <div className="optional">
              <em>Optional</em>
            </div>
            {docs &&
              docs.map((doc, i) => (
                <div className="uploads__doc__list__item" key={i}>
                  {doc.name.split(".")[1] === "pdf" ? (
                    <img src="/images/svg/icons/pdf.svg" alt="pdf" />
                  ) : (
                    <img src="/images/svg/icons/doc.svg" alt="doc" />
                  )}
                  {truncate(doc.name, 20)}
                </div>
              ))}
            <label htmlFor="doc" className="uploads__doc__list__item input">
              <img src="/images/svg/icons/upload.svg" />
              Upload document
              <input
                className="d-none"
                type="file"
                disabled={isUploadingDoc}
                id="doc"
                onChange={event => handleUpload(event, "doc")}
              />
              {isUploadingDoc && (
                <div className="loading">
                  <ProgressBar spinType="tiny" />
                </div>
              )}
            </label>
          </div>
        </FormGroup>
      </Col>
    </Fragment>
  );
};

AddProductUploads.propTypes = propTypes;

export default AddProductUploads;

import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { Collapse } from "reactstrap";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { publishMyDraftOrder } from "../../../../../redux/actions";
import "./MoreMenu.scss";

const MoreMenu = ({ id, publishMyDraftOrder, handleClickAction, cardType }) => {
  const [isOpen, setIsOpen] = useState(false);
  const options =
    cardType === "inventory"
      ? ["Resell", "Shipment"]
      : ["Publish", "Edit", "Delete"];
  const { push } = useHistory();

  const handleOnClick = event => {
    event.stopPropagation();
    const anchorEl = event.target.childNodes[0].data;
    setIsOpen(false);

    if (anchorEl) {
      switch (anchorEl) {
        case "Publish":
          publishMyDraftOrder(id);
          break;
        case "Edit":
          push(`/office/draft/${id}`);
          break;
        case "Shipment":
          push(`/my-office?tab=shipment`);
          break;
        case "Resell":
          push({
            pathname: `/market/create`,
            search: `?type=resells`,
            state: { inventoryId: id },
          });
          break;
        case "Delete":
          handleClickAction();
          break;
      }
    }
  };

  const handleAction = e => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <React.Fragment>
      <div className="more-menu">
        <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
          <i className="fas fa-ellipsis-h" onClick={handleAction} />

          <Collapse isOpen={isOpen}>
            {options.map((option, index) => (
              <div
                key={index}
                name={option}
                className="more-menu__item"
                onClick={handleOnClick}
              >
                {option}
              </div>
            ))}
          </Collapse>
        </OutsideClickHandler>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  publishMyDraftOrder,
};

export default connect(null, mapDispatchToProps)(MoreMenu);

import React from "react";
import PropTypes from "prop-types";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { Row, Col } from "reactstrap";

const Account = props => {
  return (
    <Row>
      <Col md="12" className="faq-accordion">
        <Accordion allowZeroExpanded={true} preExpanded={["open"]}>
          <AccordionItem uuid="open">
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                How do I delete my account?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                If you deactivate/cancel/delete your OPN account, you will lose
                all of your contacts and messages on opnplatform.com. Please
                send your request to security@opnplatform.com for further
                checking. You will get a reply within 10 business days.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                What can I do if my account was deactivated?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                Please send your request to security@opnplatform.com for further
                checking. You will get a reply within 1 business day.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                How can I invite my teammates or colleagues?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                You can invite your teammates from the Settings menu with the
                admin account.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton className="accordion__title">
                What can I do if it says I already have an account?
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p>
                If it says you've already registered, it's usually because the
                email address you filled in has been registered under another
                account. Since only one email address can be used to register,
                please choose another one or contact security@opnplatform.com
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </Col>
    </Row>
  );
};

Account.propTypes = {};

export default Account;

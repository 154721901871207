import { FETCH_STATISTICS, apiRequest, GET, POST, LOGIN, navigateTo } from '../../actions';
import { API } from '../../../_shared/defs';

const fetchStatistics = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_STATISTICS.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.HOME}/stats`,
        key: 'fetchStatistics',
        onSuccess: FETCH_STATISTICS.SUCCESS
      })
    );
  }
};

export default [ fetchStatistics ];

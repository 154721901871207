import { getName } from "country-list"
import _ from "lodash"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Countdown from "react-countdown"
import ReactCountryFlag from "react-country-flag"
import { connect } from "react-redux"
import { Media } from "reactstrap"
import { uiToggleModal } from "../../../../../redux/actions"
import { priceFormat, truncate } from "../../../../../_shared/functions/utils"
import MoreMenu from "../MoreMenu"
import SwitchMoreMenu from "../SwitchMoreMenu"
import "./ProductCard.scss"

const propTypes = {
  action: PropTypes.func,
  uiToggleModal: PropTypes.func,
  order: PropTypes.object,
  showSwitchBtn: PropTypes.bool,
  showMoreBtn: PropTypes.bool,
  setProductId: PropTypes.func,
  cardType: PropTypes.string,
};

const ProductCard = ({
  order,
  action,
  showSwitchBtn,
  showMoreBtn,
  setProductId,
  uiToggleModal,
  cardType,
}) => {
  const {
    _id,
    company,
    name,
    country,
    price,
    photos,
    created_at,
    premium,
    duration,
  } = order;
  const handleClick = e => action && action(e, _id, name);
  const handleClickAction = () => {
    uiToggleModal();
    setProductId(_id);
  };


  const start = moment();
  const end = moment(created_at);
  const timeDiff = start.diff(end, "hours");
  const [showTenderCounter, setShowTenderCounter] = useState(true);

  const getCountdown = ({ days, hours, minutes, completed }) => {
    if (completed) {
      // Render a completed state
      setShowTenderCounter(false);
      return <div />;
    } else {
      // Render a countdown
      return (
        <span className="tender-countdown">
          <span className="count">{days}DAYS</span>
          <span className="count">{hours}H</span>
          <span className="count">{minutes}M</span>
        </span>
      );
    }
  };

  return (
    <Media className="product-card" onClick={e => handleClick(e)}>
      {timeDiff < 24 && (
        <img
          className="product-card__tag"
          src="images/svg/icons/new-tag.svg"
          alt=""
        />
      )}
      <Media className="product-card__image" left>
        {premium === 1 && <span className="premium">PREMIUM</span>}
        {!_.isEmpty(photos) && photos[0] && (
          <img key={photos[0]._id} src={photos[0].cdn_link} />
        )}
      </Media>
      <Media className="product-card__body" body>
        <p className="product-card__body__company">
          {company && company.verified && (
            <img
              className="product-card__body__company__verified"
              src={`${process.env.IMAGE_URL}/assets/images/svg/icons/verify.svg`}
              alt="chat icon"
            />
          )}
          <span className="product-card__body__company__name">
            {company && company.profile.name ? company.profile.name : "---"}
          </span>
        </p>

        <Media className="product-card__body__title" heading>
          {name ? truncate(name, 50) : "---"}{" "}
          {premium === 1 && <span className="premium">PREMIUM</span>}
        </Media>

        {country && (
          <p className="product-card__body__country">
            <ReactCountryFlag
              countryCode={country}
              className="product-card__body__country__flag"
              svg
            />
            <span className="product-card__body__country__name">
              {getName(country)}
            </span>
          </p>
        )}
        <Media className="product-card__body__price" heading>
          {priceFormat(price)}
        </Media>
        {showTenderCounter && duration && (
          <Media className="tender-countdown-container">
            <img
              className="product-card__body__company__verified"
              src={`${process.env.IMAGE_URL}/assets/images/svg/icons/gravel.svg`}
              alt="chat icon"
            />
            <Countdown
              date={new Date(moment(duration || 0))}
              renderer={getCountdown}
            />{" "}
            <span className="label">LEFT</span>
          </Media>
        )}
      </Media>
      {showMoreBtn && (
        <MoreMenu
          cardType={cardType}
          id={_id}
          handleClickAction={handleClickAction}
        />
      )}
      {showSwitchBtn && (
        <div className="mt-4 mr-4">
          <SwitchMoreMenu handleClickAction={handleClickAction} />
        </div>
      )}
    </Media>
  );
};

ProductCard.propTypes = propTypes;

const mapDispatchToProps = {
  uiToggleModal,
};

export default connect(null, mapDispatchToProps)(ProductCard);

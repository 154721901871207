import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";
import { isEmpty, omit } from "lodash";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Company from "./Company";
import { Container, Row, Col } from "reactstrap";
import Profile from "./Profile";
import InviteCoWorkers from "./InviteCoWorkers";
import qs from "query-string";
import numeral from "numeral";
import {
  navigateTo,
  fetchCompanyInfo,
  updateUser,
  updateCompanyInfo,
} from "../../../redux/actions";
import { countries } from "../../../_shared/defs";
import "./Settings.scss";

const SETTINGS_TABS = ["company", "profile", "Points-of-contact"];
const DEFAULT_SECTION_INDEX = 0;

const Settings = props => {
  const history = useHistory();
  const { tab } = qs.parse(history.location.search);
  const currentTabIndex =
    SETTINGS_TABS.indexOf(tab) > 0 &&
    SETTINGS_TABS.indexOf(tab) < SETTINGS_TABS.length
      ? SETTINGS_TABS.indexOf(tab)
      : DEFAULT_SECTION_INDEX;
  const [tabIndex, setTabIndex] = useState(currentTabIndex);

  const {
    fetchCompanyInfo,
    companyInfo,
    updateUser,
    updateCompanyInfo,
  } = props;
  const { tariff } = companyInfo || {};

  useEffect(() => {
    fetchCompanyInfo();
  }, []);
  const setQueryParam = paramValue => {
    history.replace({ search: `?tab=${SETTINGS_TABS[paramValue]}` });
  };

  const getCountry = code => {
    let country = countries.find(item => {
      return item.code === code;
    });
    if (country) {
      return country.name;
    }
    return code;
  };

  const formatInitialValues = companyInfo => {
    const { profile, userInfo } = companyInfo;

    let credit_limit_number = numeral(profile.credit_limit).format("$0,0.00");

    return {
      company_name: profile.name,
      company_registration_number: profile.company_registration_number,
      description: profile.description,
      country: {
        value: profile.location.country,
        label: getCountry(profile.location.country),
      },
      city: profile.location.city,
      address: profile.location.address,
      email: profile.contacts.email,
      phone: profile.contacts.phone1,
      website: profile.contacts.site,
      user_email: userInfo.admin.mail.id,
      user_phone: userInfo.admin.mobile_phone.number,
      position: userInfo.admin.position,
      ful_name: userInfo.admin.name,
      credit_limit: credit_limit_number,
    };
  };

  const handleCompanyOnSave = values => {
    // console.log('%cLANCIO IL SUBMIT', 'background-color:red; padding:4px', values)
    const {
      company_name,
      description,
      country,
      city,
      address,
      email,
      phone,
      website,
      jurisdiction,
      company_registration_number,
      credit_limit,
    } = values;

    let credit_limit_number = numeral(credit_limit).format("$0,0.00");
   

    let payload = {
      profile: {
        name: company_name,
        company_registration_number: company_registration_number,
        credit_limit: credit_limit_number,
        description,
        contacts: { email, phone1: phone, site: website },
        location: {
          country: country && country.value,
          city,
          JurisdictionOfIncorporation: jurisdiction || null,
          address,
        },
      },
    };

    if (!address) payload = omit(payload, "address");

    updateCompanyInfo(payload);
  };

  const handleUserOnSave = values => {
    const { user_email, user_phone, position, ful_name } = values;

    let payload = {
      name: ful_name,
      email: user_email,
      mobile_phone: user_phone,
      position,
    };

    if (!position) payload = omit(payload, "position");

    updateUser(payload);
  };

  const renderTab = (title, imageName, disabled = false) => (
    <Tab disabled={disabled}>
      <img className="icon" src={`images/svg/icons/${imageName}.png`} alt="" />
      {title}
    </Tab>
  );

  console.log("tariff===========================>", tariff);
  return (
    <main className="settings">
      <Container fluid>
        <Row>
          <Col>
            <Tabs
              className="office__tabs"
              selectedIndex={tabIndex}
              onSelect={nextTabIndex => {
                setQueryParam(`${nextTabIndex}`);
                setTabIndex(nextTabIndex);
              }}
            >
              <Row>
                <Col md="2" className="tab-list-wrap">
                  <TabList className="office__tabs__sidebar">
                    {renderTab("Company", "line-chart")}
                    {renderTab("Profile", "profile")}
                    {tariff === "BASIC"
                      ? null
                      : renderTab("Points of contact", "integration")}
                  </TabList>
                </Col>

                <Col md="8">
                  <TabPanel>
                    <Company
                      initialValues={
                        !isEmpty(companyInfo)
                          ? formatInitialValues(companyInfo)
                          : {}
                      }
                      onSave={handleCompanyOnSave}
                      logo={
                        !isEmpty(companyInfo) && companyInfo.profile.logo_url
                      }
                    />
                  </TabPanel>
                  <TabPanel>
                    <Profile
                      initialValues={
                        !isEmpty(companyInfo)
                          ? formatInitialValues(companyInfo)
                          : {}
                      }
                      onSave={handleUserOnSave}
                    />
                  </TabPanel>

                  {tariff === "BASIC" ? null : (
                    <TabPanel>
                      <InviteCoWorkers />
                    </TabPanel>
                  )}
                </Col>
              </Row>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

// Settings.propTypes = propTypes;

const mapStateToProps = state => ({
  location: state.router.location,
  companyInfo: state.settings.companyInfo,
  user: state.auth.user,
});

const mapDispatchToProps = {
  navigateTo,
  fetchCompanyInfo,
  updateUser,
  updateCompanyInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

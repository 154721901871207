import React, { Fragment } from "react";
import PropTypes from "prop-types";
import SocialAuthForm from "../../_shared/forms/SocialAuthForm";
import LoginForm from "../../_shared/forms/LoginForm";

const propTypes = {
  onFormSubmit: PropTypes.func,
  isLoggingIn: PropTypes.bool,
  nextStep: PropTypes.func,
};

const LoginInner = ({ nextStep }) => {
  return (
    <Fragment>
      <LoginForm />
      <div className="divider-both mt-4">OR</div>
      <SocialAuthForm nextStep={nextStep} />
    </Fragment>
  );
};

LoginInner.propTypes = propTypes;

export default LoginInner;

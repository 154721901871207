import {
  FETCH_UNREAD_NOTIFICATIONS_COUNT,
  FETCH_ALL_NOTIFICATIONS,
  FETCH_CURRENT_NOTIFICATIONS,
} from "../../actions";

const defaultState = {
  count: 0,
  list: [],
  current: [],
};

const notificationsReducer = (state = defaultState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_UNREAD_NOTIFICATIONS_COUNT.SUCCESS:
      return Object.assign({}, state, { count: payload.unread });
    case FETCH_CURRENT_NOTIFICATIONS.SUCCESS:
      return Object.assign({}, state, { current: payload.data });
    case FETCH_ALL_NOTIFICATIONS.SUCCESS:
      return Object.assign({}, state, { list: payload.data });
    default:
      return state;
  }
};

export default notificationsReducer;

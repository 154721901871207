import React from "react";
import NewPassForm from "../_shared/forms/NewPassForm";
import { connect } from "react-redux";
import { changePassword } from "../../../redux/actions";

const NewPassword = ({ match, changePassword }) => {
  const submit = values => {
    const payload = {
      id: match.params.id,
      password: values.password,
      c_password: values.confirmPassword,
    };

    changePassword(payload);
  };
  return (
    <React.Fragment>
      <NewPassForm onSubmit={submit} />
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  changePassword,
};

export default connect(null, mapDispatchToProps)(NewPassword);

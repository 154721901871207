import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import qs from "query-string";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  navigateTo,
  fetchInvoice,
  confirmCompanyInfo,
} from "../../../../../redux/actions";
import Active from "./Active";
import Archive from "./Archive";
import PurchasingOrders from "./PurchasingOrders";
import OfferedTender from "./OfferedTender";

const propTypes = {
  customAccountId: PropTypes.string,
  confirmCompanyInfo: PropTypes.func,
};

const TENDER_TABS = ["active", "archived", "purchase", "offers"];
const DEFAULT_SECTION_INDEX = 0;

const TenderContent = () => {
  const history = useHistory();
  const { tab } = qs.parse(history.location.search);
  const currentTabIndex =
    TENDER_TABS.indexOf(tab) > 0 &&
    TENDER_TABS.indexOf(tab) < TENDER_TABS.length
      ? TENDER_TABS.indexOf(tab)
      : DEFAULT_SECTION_INDEX;
  const [tabIndex, setTabIndex] = useState(currentTabIndex);

  useEffect(() => {
    // confirmCompanyInfo();
  });

  const setQueryParam = paramValue =>
    navigateTo(`/my-office?tab=${TENDER_TABS[paramValue]}`);

  const renderTab = (title, disabled = false) => (
    <Tab disabled={disabled}>{title}</Tab>
  );

  const renderTabPanel = (ContentPanel, customAccountId) => (
    <TabPanel className="account__tabs__panel">
      <ContentPanel customAccountId={customAccountId} />
    </TabPanel>
  );

  return (
    <div className="account-content-panel">
      <Tabs
        className="tabs account__tabs"
        selectedIndex={tabIndex}
        onSelect={nextTabIndex => {
          setQueryParam(`${nextTabIndex}`);
          setTabIndex(nextTabIndex);
        }}
      >
        <TabList>
          {renderTab("Active")}
          {renderTab("Archived")}
          {renderTab("Purchasing orders")}
          {renderTab("Offered tenders")}
        </TabList>

        <div>
          {renderTabPanel(Active)}
          {renderTabPanel(Archive)}
          {renderTabPanel(PurchasingOrders)}
          {renderTabPanel(OfferedTender)}
        </div>
      </Tabs>
    </div>
  );
};

TenderContent.propTypes = propTypes;

const mapStateToProps = state => ({
  location: state.router.location,
  customAccountId: state.settings.companyInfo.customAccountId,
});

const mapDispatchToProps = { navigateTo, fetchInvoice, confirmCompanyInfo };

export default connect(mapStateToProps, mapDispatchToProps)(TenderContent);

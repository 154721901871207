import _, { omit } from "lodash";
import {
  CHANGE_PASSWORD,
  SUBSCRIPTION_STATUS,
  LOGIN,
  LOGOUT,
  REGISTER,
  RESEND_VERIFY,
  SOCIAL,
  FETCH_CLIENT_ID,
  VERIFY,
  TOGGLE_RESET_PASSWORD,
  CLEAR_AUTH_STATE,
  TOGGLE_FROM_REGISTER,
  CONNECT_SOCKET_CLIENT,
  SUBSCRIBE_USER,
} from "../actions";

const defaultState = {
  clientId: undefined,
  user: undefined,
  accessToken: undefined,
  refreshToken: undefined,
  successfulReset: false,
  isLoggedIn: false,
  fromRegister: false,
  socketClient: undefined,
};

const authReducer = (state = defaultState, action) => {
  const { payload } = action;

  switch (action.type) {
    case CONNECT_SOCKET_CLIENT:
      return Object.assign({}, state, { socketClient: () => payload });
    case FETCH_CLIENT_ID.SUCCESS:
      return Object.assign({}, state, { clientId: payload.clientId });
    case SOCIAL.SUCCESS:
    case LOGIN.SUCCESS:
      return Object.assign({}, state, {
        accessToken: {
          token: payload.access_token.token,
          exp: payload.access_token.expires,
        },
        refreshToken: {
          token: payload.refresh_token.token,
          exp: payload.refresh_token.expires,
        },
        user: _.omit(payload, ["refresh_token", "access_token"]),
        isLoggedIn: true,
      });
    case SUBSCRIPTION_STATUS.SUCCESS:
      return Object.assign({}, state, {
        user: { ...state.user, subscription: payload },
      });
    case REGISTER.SUCCESS:
      return Object.assign({}, state, action.payload);
    case RESEND_VERIFY.SUCCESS:
    case CHANGE_PASSWORD.SUCCESS:
    case VERIFY.SUCCESS:
      return Object.assign({}, state, {
        ...state,
        user: action.payload,
      });
    case LOGOUT.SUCCESS:
    case CLEAR_AUTH_STATE:
      return Object.assign({}, state, omit(defaultState, ["clientId"]));

    case TOGGLE_RESET_PASSWORD.START:
      return Object.assign({}, state, {
        ...state,
        successfulReset: action.payload,
      });
    case TOGGLE_FROM_REGISTER:
      return Object.assign({}, state, {
        fromRegister: !state.fromRegister,
      });
    case SUBSCRIBE_USER.SUCCESS:
      console.log('STRIPE SUCCESS PAYLOAD', payload)
      return Object.assign({}, state, {
        user: {...state.user, stripe_subscription_id: payload.stripe_subscription_id, stripe_customer_id: payload.stripe_customer_id}
      })

    default:
      return state;
  }
};

export default authReducer;

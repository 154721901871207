import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import CustomButton from "../../../../_shared/components/Button";

const Speaker = props => {
  return (
    <div className="expo-tab-panel">
      <p className="expo-tab-panel__title">
        Reach the entire packaging industry audience in one place.
      </p>
      <Row className="features-def row-eq-height">
        <Col sm={12} md={6} className="features-def__left">
          <div>
            <h5 className="md-title">Why speak at OPN IPackXPO365?</h5>
            <p>
              At OPN IPackXPO365, you’ll meet the major packaging industry
              players and the rising stars of the global packaging world, and
              discover the latest sustainable innovations destined to transform
              the industry for the better.
            </p>
          </div>
        </Col>
        <Col sm={12} md={6} className="features-def__right">
          <img className="img-fluid" src="images/svg/cards/speaker.svg" />
        </Col>
      </Row>
      <Row className="features-list">
        <Col md="6">
          <ul className="custom-list">
            <li>Schedule-friendly approach: saving precious time and money.</li>
            <li>
              Your keynotes, presentations, fireside chats, panels, interviews,
              and so on. Up to 100,000 people can view the session at a time.
            </li>
          </ul>
        </Col>
        <Col md="6">
          <ul className="custom-list">
            <li>
              Flexible for presenters or speakers as your physical presence is
              not required.
            </li>
            <li>
              Have the same networking opportunities as the other participants.
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="features-cap">
        <Col md={{ size: 10, offset: 1 }}>
          <p className="px-5 expo-tab-panel__title">
            Your digital experience (i.e. event or meeting) has never been more
            important.{" "}
            <a
              href="https://forms.gle/cbDjuxpTR4EBFpbQ7"
              target="_blank"
              rel="noopener noreferrer"
            >
              Please fill the form
            </a>
            .
          </p>
        </Col>
      </Row>
    </div>
  );
};

Speaker.propTypes = {};

export default Speaker;

import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import moment from "moment";
import { Collapse, Container, Row, Col } from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Countdown from "react-countdown";
import Iframe from "react-iframe";
import CustomButton from "../../../_shared/components/Button";
import "./Expo.scss";
import Exhibitor from "./Exhibitor";
import Attendee from "./Attendee";
import Speaker from "./Speaker";

const Expo = () => {
  const [collapse, setCollapse] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");

  const EXPOPlayBtn = useRef(null);
  const EXPOVideoBox = useRef(null);

  const togglePlayPause = (event, videoElement, controlElement) => {
    event.preventDefault();
    event.stopPropagation();
    // const videoElement = OPNVideoBox.current;
    // const controlElement = OPNPlayBtn.current;

    if (videoElement.paused) {
      controlElement.className = "button hide";
      videoElement.setAttribute("controls", true);
      videoElement.play();
    } else {
      controlElement.className = "button show";
      videoElement.removeAttribute("controls");
      videoElement.pause();
    }
  };

  const toggle = () => setCollapse(!collapse);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <Iframe url={iframeUrl} width="100%" height="450px" id="swap-card" />
      );
    } else {
      // Render a countdown
      return (
        <div className="countdown__inner">
          <div className="count">
            <h1>{days}</h1>
            <p>days</p>
          </div>
          <div className="count">
            <h1>{hours}</h1>
            <p>hours</p>
          </div>
          <div className="count">
            <h1>{minutes}</h1>
            <p>minutes</p>
          </div>
          <div className="count">
            <h1>{seconds}</h1>
            <p>seconds</p>
          </div>
        </div>
      );
    }
  };

  return (
    <main className="expo">
      <Helmet>
        <link rel="canonical" href="https://opnplatform.com/i-pack-expo" />
        <meta charset="utf-8" />
        <title>Packaging Online Trade Show | Open packaging Network</title>
        <meta
          name="description"
          content="IPackXPO365 is a packaging online trade show. Here you can enhance your business in global packaging market. Online packaging is the easiest and best way at this moment."
          data-react-helmet="true"
        />
        <meta
          name="keywords"
          content="Packaging Online, Online Packaging, Trade Show, Online Trade Show, IPackXPO365, PackEXPO, Virtual trade, Global packaging market, Exhibition online, Flexible packaging market"
          data-react-helmet="true"
        />
      </Helmet>
      <Container>
        <Row className="expo__header">
          <Col md={{ size: 8, offset: 2 }} className="mb-5">
            <h1 className="page-title">
              IPackXPO365 - №1 Packaging Online Trade Show
            </h1>
            <p className="text-center">
              <strong>The OPN IPackXPO365 starting from June 2020</strong> is
              ready to help in this situation and provide a virtual digital
              platform for packaging industry stakeholders and decision-makers
              to meet and showcase their products, and continue to drive
              business endless, and as if nothing had happened: trade show,
              network, meeting are now online! Unlike physical events
              IPackXPO365 is highly interactive and user friendly
            </p>
            <div className="text-center">
              <CustomButton
                action={toggle}
                name={`Read ${collapse ? "less" : "more"}`}
              />
            </div>

            {/* <div className="countdown">
              <p className="sm-title">Event will start in</p>
              <Countdown
              date={new Date(moment("05-01-2020", "MM-DD-YYYY"))}
              renderer={renderer}
              />
            </div> */}
            <div className="expo-iframe">
              <div className="expo-iframe__nav">
                <CustomButton
                  action={() =>
                    setIframeUrl(
                      "https://app.swapcard.com/widget/event/live-packexpo/exhibitors/RXZlbnRWaWV3XzMxNjg0?showActions=true"
                    )
                  }
                  name="Exhibitors"
                  classes="exhibit"
                />
                <CustomButton
                  action={() =>
                    setIframeUrl(
                      "https://app.swapcard.com/widget/event/live-packexpo/plannings/RXZlbnRWaWV3XzM1MDQz?showActions=true"
                    )
                  }
                  name="IPE Agenda"
                  classes="agenda"
                />
                <CustomButton
                  action={() =>
                    setIframeUrl(
                      "https://app.swapcard.com/widget/event/live-packexpo/plannings/RXZlbnRWaWV3XzM0NTU1?showActions=true"
                    )
                  }
                  name="iPackAcademy"
                  classes="academy"
                />
              </div>
              {/* <p className="sm-title">List of Exhibitors</p> */}
              {iframeUrl && (
                <Iframe
                  url={iframeUrl}
                  width="100%"
                  height="350px"
                  className="expo-iframe__inner"
                />
              )}
            </div>
            <div className="download">
              <h5 className="md-title text-center">
                Download Swapcard Event App
              </h5>
              <div className="download__cta">
                <a
                  href="https://www.swapcard.com/app/swapcard/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <CustomButton name="go to the web app" />
                </a>
                <a
                  href="https://itunes.apple.com/fr/app/swapcard/id879488719?mt=8"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src="/images/svg/icons/app-store.png" alt="" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.swapcard.apps.android&hl=fr"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <img src="/images/svg/icons/google-play.png" alt="" />
                </a>
              </div>
            </div>
          </Col>
          <Col
            md={{ size: 10, offset: 1 }}
            className="main-solution__video mt-5"
          >
            <div
              className="video__box"
              onClick={event =>
                togglePlayPause(
                  event,
                  EXPOVideoBox.current,
                  EXPOPlayBtn.current
                )
              }
            >
              <video
                className="video"
                poster={`${process.env.IMAGE_URL}/assets/expo-video-bg.png`}
                preload="none"
                src={`${process.env.IMAGE_URL}/assets/Corrected%20IPE%20video%20.mp4`}
                ref={EXPOVideoBox}
              />
              <div
                className="button show"
                ref={EXPOPlayBtn}
                onClick={event =>
                  togglePlayPause(
                    event,
                    EXPOVideoBox.current,
                    EXPOPlayBtn.current
                  )
                }
              >
                <button
                  id="play-pause"
                  aria-label="Play button"
                  className="paused"
                />
              </div>
            </div>
          </Col>
        </Row>
        <img
          src="images/partners/sectionBorder.svg"
          style={{ width: "100%" }}
          className="img-fluid"
        />
        <Collapse isOpen={collapse}>
          <Row className="expo__more">
            <Col md={{ size: 6, offset: 3 }}>
              <p data-number="1">
                The disruptive impact of COVID-19 has all but put an end to
                business as usual. Exhibitions and trade shows across the board
                have canceled one by one, including major events like{" "}
                <strong>
                  Drupa, ProPak, Chinaplast, Empack, MetPack,Cosmopack,
                  LatinPack,ALL4Pack,India Packaging Show,Foodpro and Interpack
                </strong>
                .
              </p>
              <p data-number="2">
                Virtual events are now becoming an integral part of the
                corporate marketing strategies.IPackXPO365 is an innovative way
                to increase conversion rate by reducing cost per lead and
                maximizing the ROI.
              </p>
              <p data-number="3">
                The global crisis has illustrated the need for digital
                migration, but it has also shone a light on another crisis –
                climate change. On average, around 600,000 tons of waste is
                produced at trade shows every year, caused by paper waste and
                other marketing material. By hosting trade shows and expos
                online, the packaging industry is taking a major step forward in
                reducing the carbon footprint.
              </p>
            </Col>
          </Row>
          <img
            src="images/partners/sectionBorder.svg"
            style={{ width: "100%" }}
            className="img-fluid"
          />
        </Collapse>
        <Row className="expo__content">
          <Col md={12}>
            <div
              id="ribbon-cart-checkout"
              className="d-flex justify-content-center p-5"
            ></div>

            <h1 className="page-title">I am...</h1>
            <Tabs className="expo__content__tabs">
              <TabList>
                <Tab>an exhibitor</Tab>
                <Tab>an attendee</Tab>
                <Tab>a speaker</Tab>
              </TabList>

              <TabPanel>
                <Exhibitor />
              </TabPanel>
              <TabPanel>
                <Attendee />
              </TabPanel>
              <TabPanel>
                <Speaker />
              </TabPanel>
            </Tabs>
          </Col>
        </Row>
        <Row className="sponsor">
          <Col md={{ size: 6, offset: 3 }} className="text-center">
            <h5 className="md-title">Sponsorships</h5>
            <p>
              Having the chance to engage with the IPackXPO365 audience. OPN
              IPackXPO365 can offer:
            </p>
            <ul className="custom-list">
              <li>Sponsored Event Sessions and Event Speaker</li>
              <li>Sponsored Giveaways</li>
              <li>Sponsored Virtual After Party or Happy Hour</li>
              <li>Collaborations</li>
              <li>And much more...</li>
            </ul>
            <p>
              For sponsorship opportunities, contact <br />{" "}
              <a
                href="https://expo.opnplatform.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                expo@opnplatform.com
              </a>
            </p>
          </Col>
        </Row>
        <Row className="features-about row-eq-height">
          <Col sm={12} md={6} className="features-about__left">
            <img className="img-fluid" src="images/svg/cards/about.svg" />
          </Col>
          <Col sm={12} md={6} className="features-about__right">
            <div>
              <h5 className="md-title">About OPN</h5>
              <p>
                Founded in 2018, the Open Packaging Network (OPN) is a company
                headquartered in Zug, Switzerland.
              </p>
              <p>
                The Open Packaging Network and next-generation OPN Platform is
                the first ever data-driven B2B online platform that simplifies
                procurement and boosts sales for packaging companies. The
                digital solution caters to anyone involved in packaging
                manufacturing, converting, and packaging-ready production
                worldwide.
              </p>
              <a
                href="https://drive.google.com/file/d/1T32MbIzTOyK8pTvrcZeC_Io7GR13R8KD/view"
                target="_blank"
                rel="noopener noreferrer"
              >
                Download our free information pack here
              </a>
              <p className="mt-4">
                For media inquiries contact{" "}
                <a
                  href="https://expo.opnplatform.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  expo@opnplatform.com
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

Expo.propTypes = {};

export default Expo;

export const API = {
  HOME: "/home",
  BLOG: "/blog/user",
  AUTH: "/user",
  CLIENT: "/client",
  CATEGORY: "/category",
  COUNTRY: "/geo/countries",
  ORDERS: "/orders",
  DEAL: "/deal",
  INVOICE: "/invoice",
  UPLOAD: "/file/public",
  INVITE: "/invite",
  JOIN: "/join",
  COMPANY: "/company",
  STRIPE_CONNECT: "/stripe_connect",
  STRIPE_DISCONNECT: "/stripe_disconnect",
  STRIPE_CHECK: "/stripe_connect/callback",
  TENDER: "/tender",
  INVENTORY: "/virtualinventory",
};

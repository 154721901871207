import React, { Fragment } from "react";
import { InputGroup, Input, FormFeedback } from "reactstrap";
import classNames from "classnames";
import Select from "react-select";
import "./ReduxFormFields.scss";

export const TextInputField = ({
  className,
  label,
  input,
  id,
  disabled,
  length,
  meta: { touched, error, warning },
  ...rest
}) => {
  if (length && Number.isInteger(length)) {
    rest.minLength = length;
    rest.maxLength = length;
  }

  const onchange = (e) => {
    let { value } = e.target;
    const charArr = value.replace(/(\.)\s/g, ".").split(".");
    console.log("lastChar :", charArr, rest.type);
    if (rest.type === "textarea") value = charArr.join(". ");
    input.onChange(value);
  };

  return (
    <div className="text-input-container">
      <InputGroup className="float-label">
        <Input
          {...input}
          {...rest}
          id={id}
          onChange={onchange}
          disabled={disabled}
          className={classNames(className, {
            "is-invalid": touched && error,
            "is-valid": !error && input.value,
          })}
        />
        {label && <label htmlFor={id}>{label}</label>}
      </InputGroup>
      {touched && error && (
        <FormFeedback className="d-block">{error}</FormFeedback>
      )}
    </div>
  );
};

export const CheckBoxField = ({
  className,
  id,
  label,
  color = "grey",
  input,
  disabled,
  length,
  meta,
  ...rest
}) => {
  const { touched, error, warning } = meta || {};
  const radioType = rest.type === "radio";
  if (length && Number.isInteger(length)) {
    rest.minLength = length;
    rest.maxLength = length;
  }

  return (
    <InputGroup>
      <label
        htmlFor={id}
        className={classNames("checkbox", {
          className: className,
          isDisabled: disabled,
        })}
      >
        <div
          className={classNames("checkbox__input", color, {
            radio: radioType,
            checked: input.checked,
          })}
        >
          <input
            disabled={disabled}
            {...input}
            {...rest}
            className="d-none"
            id={id}
          />
          {!radioType && <i className="fas fa-check" />}
        </div>
        <span className="checkbox__label">{label}</span>
      </label>
      {touched && error && (
        <FormFeedback className="d-block">{error}</FormFeedback>
      )}
    </InputGroup>
  );
};

export const SwitchField = ({
  className,
  id,
  color = "blue",
  input,
  disabled,
  length,
  meta,
  ...rest
}) => {
  const { touched, error, warning } = meta || {};

  if (length && Number.isInteger(length)) {
    rest.minLength = length;
    rest.maxLength = length;
  }

  return (
    <label htmlFor={id} className={`switch ${className ? className : ""}`}>
      <div
        className={`switch__input  ${color} ${input.value ? "checked" : ""}`}
      >
        <input
          {...input}
          {...rest}
          type="checkbox"
          className="d-none"
          id={id}
        />
      </div>
      {touched && error && (
        <FormFeedback className="d-block">{error}</FormFeedback>
      )}
    </label>
  );
};

export const SelectInputField = ({
  options,
  name,
  label,
  placeholder,
  disabled,
  isMulti,
  height,
  isSearchable = false,
  isClearable = true,
  useInput = false,
  input,
  meta: { touched, error, warning },
  className,
  children,
  ...rest
}) => {
  const handleInputChanged = (value, reason) => {
    if (
      reason.action === "set-value" ||
      reason.action === "input-blur" ||
      reason.action === "menu-close"
    ) {
      return;
    }
    input.onChange({ label: value, value });
  };

  return (
    <div className="select-box-container">
      <InputGroup className="float-label">
        <Select
          {...input}
          {...rest}
          blurInputOnSelect={false}
          isClearable={isClearable}
          openMenuOnClick={true}
          openMenuOnFocus={true}
          hideSelectedOptions={true}
          placeholder={placeholder}
          className="select-box"
          classNamePrefix="react-select"
          name={name}
          isSearchable={isSearchable}
          options={options}
          value={input.value}
          onChange={(selected) => input.onChange(selected)}
          isDisabled={disabled}
          isMulti={isMulti || false}
          isOptionDisabled={(option) => option.value === "disable"}
          // inputValue={input.value}
          onBlur={() => input.onBlur(input.value)}
          onInputChange={handleInputChanged}
          // onBlurResetsInput={false}
        />
        {/* {label && (
          <label htmlFor="react-select-2-input" className="form-label">
            {label}
          </label>
        )} */}
      </InputGroup>
      {touched && error && (
        <div className="invalid-feedback d-block">{error}</div>
      )}
    </div>
  );
};

import React, { useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchArchivedTender } from "../../../../../../redux/actions";
import ProductCard from "../../../../_shared/components/ProductCard";
import ProgressBar from "../../../../../../_shared/components/ProgressBar";

const Archive = props => {
  const history = useHistory();
  const { archivedList, fetchArchivedTender, isFetchingArchivedTender } = props;
  const { data } = archivedList;

  useEffect(() => {
    const params = {
      offset: 0,
      count: 10,
    };

    fetchArchivedTender(params);
  }, []);

  const handleRedirectView = (e, id) => {
    history.push({
      search: `${history.location.search}&view=${id}`,
    });
  };

  return (
    <Fragment>
      {isFetchingArchivedTender ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : isEmpty(data) ? (
        <div className="d-flex justify-content-center p-5">
          <img src="/images/svg/icons/no-data.svg" alt="No data icon" />
        </div>
      ) : (
        <div className="product__list">
          {data.map(item => (
            <ProductCard
              key={item._id}
              order={{ ...item.order, _id: item._id }}
              // setProductId={setProductId}
              action={handleRedirectView}
              // showMoreBtn={true}
            />
          ))}
        </div>
      )}
    </Fragment>
  );
};

Archive.propTypes = {};

const mapStateToProps = state => ({
  archivedList: state.tender.archivedList,
  isFetchingArchivedTender: state.ui.loading.fetchArchivedTender,
});

const mapDispatchToProps = {
  fetchArchivedTender,
};

export default connect(mapStateToProps, mapDispatchToProps)(Archive);

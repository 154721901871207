import React from "react";
import { Modal } from "reactstrap";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CustomButton from "../../Button";
import { uiToggleModal } from "../../../../redux/actions/ui";
import "./SuccessModal.scss";

const propTypes = {
  uiToggleModal: PropTypes.func,
  isOpen: PropTypes.bool,
  message: PropTypes.string,
  action: PropTypes.func,
  actionName: PropTypes.string,
  title: PropTypes.string,
};

const DeleteDialog = ({
  isOpen,
  uiToggleModal,
  actionName,
  action,
  message,
  title,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        uiToggleModal();
      }}
    >
      <div className="dialog-content__delete">
        <h5 className="dialog-content__delete__title">{title && title}</h5>
        <p>{message && message}?</p>
        <div className="dialog-content__delete__btn">
          <CustomButton
            name="Cancel"
            btnType="no-border"
            action={() => {
              uiToggleModal();
            }}
          />
          <CustomButton
            name={actionName && actionName}
            btnType="no-border"
            action={() => {
              uiToggleModal();
              action && action();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

DeleteDialog.propTypes = propTypes;

const mapStateToProps = state => ({
  isOpen: state.ui.modal.isOpen,
});

const mapDispatchToProps = {
  uiToggleModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDialog);

import { reset } from "redux-form"
import { API } from "../../../_shared/defs"
import {
  apiRequest, CHANGE_INVITED_CO_WORKER_ACCESS, CHECK_STRIPE_ENABLED, clearPromoCodeState, CONFIRM_COMPANY_INFO, CONFIRM_ORDER_LIMIT,
  CONNECT_STRIPE, DELETE, DELETE_CO_WORKER, DELETE_CO_WORKER_INVITE, DISCONNECT_STRIPE, fetchCompanyInfo as actionFetchCompanyInfo, FETCH_ALL_INVITED_CO_WORKER, FETCH_COMPANY_INFO, FETCH_CO_WORKER_EMAIL, GET, INVITE_CO_WORKER, navigateTo, POST,
  PUT, SET_IBAN_FOR_BANK,
  SUBSCRIBE_USER, uiToggleModal, uiTogglePaymentSuccess, UPDATE_COMPANY_INFO, UPDATE_SUBSCRIBED_USER, UPLOAD_COMPANY_LOGO, VALIDATE_PROMO_CODE,
  VERIFY_USER_BUSINESS
} from "../../actions"

const fetchCoWorkerEmail = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_CO_WORKER_EMAIL.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.INVITE}/${payload.id}`,
        key: "fetchCoWorkerEmail",
        onSuccess: FETCH_CO_WORKER_EMAIL.SUCCESS,
        headerParams: false,
        requestType: "public",
      })
    );
  }
};

const confirmCompanyInfo = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === CONFIRM_COMPANY_INFO.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.COMPANY}/has/profile`,
        key: "confirmCompanyInfo",
        onSuccess: CONFIRM_COMPANY_INFO.SUCCESS,
        headerParams: false,
      })
    );
  }
};

const confirmOrderLimit = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === CONFIRM_ORDER_LIMIT.START) {
    console.log("action.type :", action.type);
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.COMPANY}/order/limit`,
        key: "confirmOrderLimit",
        onSuccess: CONFIRM_ORDER_LIMIT.SUCCESS,
        headerParams: false,
      })
    );
  }
};

const fetchCompanyInfo = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_COMPANY_INFO.START) {
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.COMPANY}/info/all`,
        key: "fetchCompanyInfo",
        onSuccess: FETCH_COMPANY_INFO.SUCCESS,
      })
    );
  }
};

const updateCompanyInfo = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === UPDATE_COMPANY_INFO.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: PUT,
        url: `${API.COMPANY}/update`,
        key: "updateCompanyInfo",
        successMessage: "Company profile updated successful",
        onSuccess: () => {
          dispatch(actionFetchCompanyInfo());
        },
        headerParams: false,
        payload,
      })
    );
  }
};

const uploadCompanyLogo = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === UPLOAD_COMPANY_LOGO.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: PUT,
        url: `${API.COMPANY}/logo`,
        key: "uploadCompanyLogo",
        successMessage: "Company logo upload successful",
        onSuccess: () => {
          dispatch(actionFetchCompanyInfo());
        },
        headerParams: false,
        payload,
      })
    );
  }
};

const inviteCoWorker = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === INVITE_CO_WORKER.START) {
    const { payload, reset, nextAction } = action.meta;

    dispatch(
      apiRequest({
        method: POST,
        url: `${API.INVITE}/user`,
        key: "inviteCoWorker",
        successMessage: "CoWorker invited successfully",
        onSuccess: () => {
          reset("PointsOfContact");
          dispatch(nextAction());
        },
        headerParams: false,
        payload,
      })
    );
  }
};

const changeInvitedCoWorkerAccess = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === CHANGE_INVITED_CO_WORKER_ACCESS.START) {
    const { payload, nextAction } = action.meta;

    dispatch(
      apiRequest({
        method: PUT,
        url: `${API.COMPANY}/set/user/permission`,
        key: "changeInvitedCoWorkerAccess",
        successMessage: "CoWorker access changed successfully",
        onSuccess: () => {
          dispatch(nextAction());
        },
        headerParams: false,
        payload,
      })
    );
  }
};

const fetchAllInvitedCoWorker = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_ALL_INVITED_CO_WORKER.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.INVITE}/all`,
        key: "fetchAllInvitedCoWorker",
        onSuccess: FETCH_ALL_INVITED_CO_WORKER.SUCCESS,
        headerParams: false,
      })
    );
  }
};

const deleteCoWorkerInvite = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === DELETE_CO_WORKER_INVITE.START) {
    const { id, nextAction } = action.meta;
    dispatch(
      apiRequest({
        method: DELETE,
        url: `${API.INVITE}/${id}`,
        key: "deleteCoWorkerInvite",
        successMessage: "CoWorker invite successfully deleted",
        headerParams: false,
        onSuccess: () => {
          dispatch(nextAction());
        },
      })
    );
  }
};

const deleteCoWorker = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === DELETE_CO_WORKER.START) {
    const { id, nextAction } = action.meta;
    dispatch(
      apiRequest({
        method: DELETE,
        url: `${API.COMPANY}/user/${id}`,
        key: "deleteCoWorker",
        successMessage: "CoWorker successfully Removed",
        headerParams: false,
        onSuccess: () => {
          dispatch(nextAction());
        },
      })
    );
  }
};

const connectStripe = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === CONNECT_STRIPE.START) {
    const { payload } = action;

    dispatch(
      apiRequest({
        method: POST,
        url: `${API.STRIPE_CONNECT}`,
        key: "connectStripe",
        // successMessage: "CoWorker invited successfully",
        onSuccess: url => {
          window.location.href = url;
        },
        // onError: (data) => {
        //   console.log('ERROOREEE', data )
        //   dispatch(navigateTo("/my-office"));
        // },
        payload,
      })
    );
  }
};

const checkStripeEnabled = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === CHECK_STRIPE_ENABLED.START) {
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.STRIPE_CHECK}`,
        key: "checkStripeEnabled",
        // successMessage: "CoWorker invited successfully",
        onSuccess: data => {
          if (data.is_account_verified) dispatch(actionFetchCompanyInfo());
        },
        headerParams: false,
      })
    );
  }
};


const disconnectStripe = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === DISCONNECT_STRIPE.START) {
    const { payload } = action;

    dispatch(
      apiRequest({
        method: POST,
        url: `${API.STRIPE_DISCONNECT}`,
        key: "disconnectStripe",
        // successMessage: "CoWorker invited successfully",
        onSuccess: () => {
          dispatch(actionFetchCompanyInfo());
        },
        payload,
      })
    );
  }
};

const setIbanForBank = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === SET_IBAN_FOR_BANK.START) {
    const { payload } = action;

    dispatch(
      apiRequest({
        method: PUT,
        url: `${API.COMPANY}/set/iban`,
        key: "setIbanForBank",
        successMessage: "IBAN for bank set successful",
        onSuccess: () => {
          dispatch(actionFetchCompanyInfo());
          dispatch(reset("plaidForm"));
        },
        headerParams: false,
        payload,
      })
    );
  }
};

const subscribeUser = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === SUBSCRIBE_USER.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.AUTH}/subscription`,
        key: "subscribeUser",
        onSuccess: (data) => {
          dispatch({
            type: SUBSCRIBE_USER.SUCCESS,
            payload: data,
          });
          dispatch(uiTogglePaymentSuccess());
          dispatch(navigateTo("/market"));
          dispatch(clearPromoCodeState());
        },
        headerParams: false,
        payload,
      })
    );
  }
};

const updateSubscribedUser = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === UPDATE_SUBSCRIBED_USER.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.AUTH}/subscription/update`,
        key: "subscribeUser",
        onSuccess: () => {
          dispatch(uiTogglePaymentSuccess());
          dispatch(navigateTo("/market"));
          dispatch(clearPromoCodeState());
        },
        headerParams: false,
        payload,
      })
    );
  }
};

const validatePromoCode = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === VALIDATE_PROMO_CODE.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: POST,
        url: `promocode/check`,
        key: "validatePromoCode",
        onSuccess: VALIDATE_PROMO_CODE.SUCCESS,
        headerParams: false,
        payload,
      })
    );
  }
};

const verifyUserBusiness = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === VERIFY_USER_BUSINESS.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: POST,
        url: `/verify/business/info`,
        key: "verifyUserBusiness",
        successMessage: "Business Verificaton was successful",
        onSuccess: () => {
          dispatch(uiToggleModal());
        },
        headerParams: false,
        payload,
      })
    );
  }
};

export default [
  fetchCoWorkerEmail,
  inviteCoWorker,
  changeInvitedCoWorkerAccess,
  fetchAllInvitedCoWorker,
  deleteCoWorkerInvite,
  deleteCoWorker,
  confirmCompanyInfo,
  confirmOrderLimit,
  fetchCompanyInfo,
  updateCompanyInfo,
  uploadCompanyLogo,
  connectStripe,
  disconnectStripe,
  checkStripeEnabled,
  setIbanForBank,
  subscribeUser,
  updateSubscribedUser,
  validatePromoCode,
  verifyUserBusiness,
];

export const carrier = [
  { code: "USPS", name: "USPS" },
  { code: "UPS", name: "UPS" },
  { code: "FedEx", name: "FedEx" },
  { code: "DHL eCommerce", name: "DHL eCommerce" },
  { code: "DHL Express", name: "DHL Express" },
  { code: "LaserShipV2", name: "LaserShipV2" },
  { code: "OnTrac", name: "OnTrac" },
  { code: "GSO", name: "GSO" },
  { code: "AmazonMws", name: "AmazonMws" },
  { code: "APC", name: "APC" },
  { code: "Aramex", name: "Aramex" },
  { code: "ArrowXL", name: "ArrowXL" },
  { code: "Asendia", name: "Asendia" },
  { code: "Australia Post", name: "Australia Post" },
  { code: "AxlehireV3", name: "AxlehireV3" },
  { code: "BorderGuru", name: "BorderGuru" },
  { code: "Cainiao", name: "Cainiao" },
  { code: "Canada Post", name: "Canada Post" },
  { code: "Canpar", name: "Canpar" },
  { code: "CDL Last Mile Solutions", name: "CDL Last Mile Solutions" },
  { code: "Chronopost", name: "Chronopost" },
  { code: "Colis Privé", name: "Colis Privé" },
  { code: "Colissimo", name: "Colissimo" },
  { code: "CouriersPlease", name: "CouriersPlease" },
  { code: "Dai Post", name: "Dai Post" },
  { code: "Deliv", name: "Deliv" },
  { code: "Deutsche Post", name: "Deutsche Post" },
  { code: "Deutsche Post UK", name: "Deutsche Post UK" },
  { code: "DHL eCommerce Asia", name: "DHL eCommerce Asia" },
  { code: "DHL eCommerce International", name: "DHL eCommerce International" },
  { code: "DHL Freight", name: "DHL Freight" },
  { code: "DHL Germany", name: "DHL Germany" },
  { code: "Dicom", name: "Dicom" },
  { code: "Direct Link", name: "Direct Link" },
  { code: "Doorman", name: "Doorman" },
  { code: "DPD", name: "DPD" },
  { code: "DPD UK", name: "DPD UK" },
  { code: "EMS", name: "EMS" },
  { code: "Estafeta", name: "Estafeta" },
  { code: "Estes", name: "Estes" },
  { code: "Fastway", name: "Fastway" },
  { code: "FedEx Mailview", name: "FedEx Mailview" },
  { code: "FedEx SameDay City", name: "FedEx SameDay City" },
  { code: "FedEx UK", name: "FedEx UK" },
  { code: "FirstMile", name: "FirstMile" },
  { code: "Globegistics", name: "Globegistics" },
  { code: "Hermes", name: "Hermes" },
  { code: "Hong Kong Post", name: "Hong Kong Post" },
  { code: "Interlink Express", name: "Interlink Express" },
  { code: "Janco Freight", name: "Janco Freight" },
  { code: "JP Post", name: "JP Post" },
  { code: "Kuroneko Yamato", name: "Kuroneko Yamato" },
  { code: "La Poste", name: "La Poste" },
  { code: "Latvijas Pasts", name: "Latvijas Pasts" },
  { code: "Liefery", name: "Liefery" },
  { code: "Loomis Express", name: "Loomis Express" },
  { code: "LSO", name: "LSO" },
  { code: "Network4", name: "Network4" },
  { code: "Newgistics", name: "Newgistics" },
  { code: "Norco", name: "Norco" },
  { code: "OmniParcel", name: "OmniParcel" },
  { code: "Orange DS", name: "Orange DS" },
  { code: "Osm Worldwide", name: "Osm Worldwide" },
  { code: "Parcel", name: "Parcel" },
  { code: "Parcelforce", name: "Parcelforce" },
  { code: "Passport", name: "Passport" },
  { code: "Pilot", name: "Pilot" },
  { code: "Posten", name: "Posten" },
  { code: "PostNL", name: "PostNL" },
  { code: "PostNord", name: "PostNord" },
  { code: "Purolator", name: "Purolator" },
  { code: "Royal Mail", name: "Royal Mail" },
  { code: "RR Donnelley", name: "RR Donnelley" },
  { code: "Seko", name: "Seko" },
  { code: "Singapore Post", name: "Singapore Post" },
  { code: "Spee-Dee", name: "Spee-Dee" },
  { code: "SprintShip", name: "SprintShip" },
  { code: "StarTrack", name: "StarTrack" },
  { code: "Toll", name: "Toll" },
  { code: "TForce", name: "TForce" },
  { code: "UDS", name: "UDS" },
  { code: "Ukrposhta", name: "Ukrposhta" },
  { code: "UPS i-parcel", name: "UPS i-parcel" },
  { code: "Veho", name: "Veho" },
  { code: "Yanwen", name: "Yanwen" },
  { code: "Yodel", name: "Yodel" },
];

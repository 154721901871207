import React from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import "../Stripe/Stripe.scss";
import CustomButton from "../../../../../../_shared/components/Button";
import {} from "../../../../../../redux/actions";
import { Container, Row, Col } from "reactstrap";
import PlaidForm from "./PlaidForm";

const propTypes = {
  iban: PropTypes.object,
};

const defaultProps = {
  iban: {},
};

const Plaid = ({ iban }) => {
  console.log("iban :=====<<<<IBAN>>>", iban);
  const { iban: ibanNumber, account_name, postal_code } = iban;
  let initialValues = {};
  if (!isEmpty(iban))
    initialValues = {
      iban: ibanNumber,
      account_name,
      postal_code,
    };

  return (
    <div className="stripe">
      <Container>
        <Row>
          <Col md={12} className="mb-5">
            <div className="stripe__header">
              <h4 className="caption">
                What is Plaid?
                <span
                  className={`status ${
                    !isEmpty(iban) && iban.iban ? "connected" : "disconnected"
                  }`}
                >
                  {!isEmpty(iban) && iban.iban ? "Connected" : "Disconnected"}
                </span>
              </h4>
              {!isEmpty(iban) && iban.iban && (
                <CustomButton
                  classes="disconnect-btn"
                  name="Disconnect"
                  btnType="no-border"
                  action={() => {}}
                />
              )}
            </div>
            <p className="stripe__content">
              Plaid makes it easy to securely connect your bank to the apps you
              want to use. Plaid connects to 11,000 financial institutions
              across the United States, Canada, and Europe.
            </p>
            <a
              href="http://plaid.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              plaid.com
            </a>
          </Col>
          <Col md={4}>
            <PlaidForm initialValues={initialValues} />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Plaid.propTypes = propTypes;
Plaid.defaultProps = defaultProps;

const mapStateToProps = state => ({
  iban: state.settings.companyInfo.iban,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Plaid);

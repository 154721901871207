import React, { useState } from "react";
import PropTypes from "prop-types";
import qs from "query-string";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./Faq.scss";
import {
  Started,
  Managing,
  Shipment,
  Payment,
  Support,
  Account,
} from "./ContentPanel";
import { navigateTo } from "../../../redux/actions";

const propTypes = {
  navigateTo: PropTypes.func,
};

const FAQ_TABS = [
  "started",
  "managing",
  "shipment",
  "payment",
  "support",
  "account",
];
const menuData = [
  "GETTING STARTED",
  "MANAGING ORDERS AND/OR REQUESTS",
  "SHIPMENT AND QUALITY VERIFICATION",
  "PAYMENTS",
  "AFTER SALES SUPPORT",
  "ACCOUNT MANAGEMENT",
];
const DEFAULT_SECTION_INDEX = 0;

const Faq = props => {
  const { navigateTo } = props;
  const history = useHistory();
  const { tab } = qs.parse(history.location.search);
  const currentTabIndex =
    FAQ_TABS.indexOf(tab) > 0 && FAQ_TABS.indexOf(tab) < FAQ_TABS.length
      ? FAQ_TABS.indexOf(tab)
      : DEFAULT_SECTION_INDEX;
  const [tabIndex, setTabIndex] = useState(currentTabIndex);

  const setQueryParam = paramValue =>
    navigateTo(`/faq?tab=${FAQ_TABS[paramValue]}`);

  const renderTabPanel = ContentPanel => (
    <TabPanel className="office__tabs__panel">
      <ContentPanel />
    </TabPanel>
  );

  return (
    <main className="faq">
      <Container>
        <Row>
          <Col md="12">
            <h1 className="page-title">Frequently Asked Questions</h1>
          </Col>
          <Col>
            <Tabs
              className="faq__tabs"
              selectedIndex={tabIndex}
              onSelect={nextTabIndex => {
                setQueryParam(`${nextTabIndex}`);
                setTabIndex(nextTabIndex);
              }}
            >
              <Row>
                <Col md="3" className="tab-list-wrap">
                  <TabList className="faq__tabs__sidebar">
                    {menuData.map((item, index) => (
                      <Tab key={index}>{item}</Tab>
                    ))}
                  </TabList>
                </Col>

                <Col md="9">
                  {renderTabPanel(Started)}
                  {renderTabPanel(Managing)}
                  {renderTabPanel(Shipment)}
                  {renderTabPanel(Payment)}
                  {renderTabPanel(Support)}
                  {renderTabPanel(Account)}
                </Col>
              </Row>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

Faq.propTypes = propTypes;

const mapStateToProps = state => ({
  location: state.router.location,
});

const mapDispatchToProps = { navigateTo };

export default connect(mapStateToProps, mapDispatchToProps)(Faq);

import { omit } from "lodash"
import {
  CHECK_IF_MY_ORDERS_IS_ALREADY_TRACKED, CREATE_ORDER, FETCH_MY_DRAFT_ORDERS, FETCH_MY_ORDERS, FETCH_ORDER,
  FETCH_ORDERS, FETCH_TENDER_ID, SEARCH_ALL_ORDERS, SEARCH_MY_ORDERS, SEARCH_ORDERS, TRACK_MY_ORDERS
} from "../../actions"

const defaultState = {
  list: {},
  current: {},
  myList: {},
  anyList: [],
  myTrackedOrder: {},
  isTracked: {},
  draftList: [],
};

const ordersReducer = (state = defaultState, action) => {
  const { payload } = action;
  switch (action.type) {
    case CREATE_ORDER.SUCCESS:
    case FETCH_ORDER.SUCCESS:
    case FETCH_TENDER_ID.SUCCESS:
      return Object.assign({}, state, { current: payload });
    case FETCH_ORDERS.SUCCESS:
    case SEARCH_ORDERS.SUCCESS:
      return Object.assign({}, state, {
        list: { data: payload.data, pagination: omit(payload, ["data"]) },
      });
    case FETCH_MY_ORDERS.SUCCESS:
    case SEARCH_MY_ORDERS:
      return Object.assign({}, state, {
        myList: { data: payload.data, pagination: omit(payload, ["data"]) },
      });
    case SEARCH_ALL_ORDERS:
      return Object.assign({}, state, {
        anyList: payload.data,
        pagination: omit(payload, ["data"]),
      });
    case TRACK_MY_ORDERS.SUCCESS:
      return Object.assign({}, state, { myTrackedOrder: payload });
    case CHECK_IF_MY_ORDERS_IS_ALREADY_TRACKED.SUCCESS:
      return Object.assign({}, state, { isTracked: payload });
    case FETCH_MY_DRAFT_ORDERS.SUCCESS:
      return Object.assign({}, state, { draftList: payload });
    default:
      return state;
  }
};

export default ordersReducer;

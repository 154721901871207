import { isArray } from "lodash"
import moment from "moment"
import numeral from "numeral"
import React, { Fragment } from "react"
import NumberFormat from "react-number-format"
import "./Utils.scss"

export const formatSuccessMessage = message => {
  return (
    <Fragment>
      {message && (
        <span>
          <img
            src="/images/svg/icons/check-merk-green.svg"
            className="check-icon"
          />{" "}
          {message}
        </span>
      )}
    </Fragment>
  );
};

export const formatMessagesFromError = error => {
  let message = (
    <span>
      <i className="fas fa-exclamation-triangle" /> {error && error}
    </span>
  );

  if (error && typeof error === 'string') return message;
  if (error && isArray(error))
    return (
      <div className="multi-error">
        <i className="fas fa-exclamation-triangle" />
        <ul className="pl-1">
          {error.map((item, i) => {
            return <li key={i}>{item.message}</li>;
          })}
        </ul>
      </div>
    );
  if (error && typeof error === 'object')
    return (
      <div className="multi-error">
        <i className="fas fa-exclamation-triangle" />
        <ul className="pl-1">
          
           <li>{error.error}</li>;
          
        </ul>
      </div>
    );
};

export const createActionType = (type, entity = "App") => ({
  START: `@@[${entity}] ${type}`,
  SUCCESS: `@@[${entity}] ${type}_SUCCESS`,
  ERROR: `@@[${entity}] ${type}_ERROR`,
  END: `@@[${entity}] ${type}_END`,
});

export const createActionString = (type, entity = "App") =>
  `[${entity}] ${type}`;

export const truncate = (string, charLength) => {
  if (string.length > charLength) {
    return string.slice(0, charLength) + " ...";
  } else {
    return string;
  }
};

export const stringify = string => {
  return string
    .toString()
    .toLowerCase()
    .replace(/&/g, " and "); // Replace & with 'and'
  // .replace(/\s+/g, "-") // Replace spaces with -
  // .replace(/--+/g, "-") // Replace multiple - with single -
  // .replace(/^-+/, "") // Trim - from start of text
  // .replace(/-+$/, ""); // Trim - from end of text
};

export const priceFormat = (price, num) => {
  if (price !== null) {
    let number = numeral(price / 100);

    if (num) return `$ ${number.format("0.00")}`;
    let res = (
      <NumberFormat
        value={number.format("0.00")}
        displayType="text"
        thousandSeparator
        prefix="&#36;"
      />
    );
    return res;
  } else {
    return "---";
  }
};

export const dateFormat = date => moment(date).format("D MMM YYYY");

export const formatCategory = (categories = []) => {
  let data = [];
  categories.forEach(category => {
    const item = {
      label: <p>{stringify(category.name)}</p>,
      value: category._id,
    };
    data = [...data, item];
  });

  return data;
};

export const formatProduct = (categories, filter) => {
  let data = [];
  categories.forEach(category => {
    if (filter === stringify(category.name)) {
      category.children.length > 0
        ? category.children.forEach(categoryItem => {
            if (categoryItem.children.length !== 0) {
              categoryItem.children.forEach(child => {
                const item = {
                  label: <p>{stringify(child.name)}</p>,
                  value: child.name,
                };

                data = [...data, item];
              });
            } else {
              const item = {
                label: <p>{stringify(categoryItem.name)}</p>,
                value: categoryItem.name,
              };

              data = [...data, item];
            }
          })
        : null;
    }
  });

  return data;
};

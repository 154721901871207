import Robots from "../public/robots.txt"
import SiteMap from "../public/sitemap.xml"
import CoWorkerInvited from "./containers/Authentication/CoWorkerInvited"
// Authentication
import Login from "./containers/Authentication/Login"
import NewPassword from "./containers/Authentication/NewPassword"
import Register from "./containers/Authentication/Register"
import ResetPassword from "./containers/Authentication/ResetPassword"
// Errors
import Page404 from "./containers/Errors/Page404"
import AboutUs from "./containers/Statics/AboutUs"
import Addon from "./containers/Statics/AddOn"
import Blog from "./containers/Statics/Blog"
import BlogView from "./containers/Statics/Blog/View"
import CaseStudies from "./containers/Statics/CaseStudies"
import CaseStudiesView from "./containers/Statics/CaseStudies/View"
import ContactUs from "./containers/Statics/ContactUs"
import Expo from "./containers/Statics/Expo"
import Faq from "./containers/Statics/Faq"
// Static
import Home from "./containers/Statics/Home"
// import Operator from "./containers/Statics/Operator"
// import Partners from "./containers/Statics/Partners"
import PaymentOption from "./containers/Statics/PaymentOption"
import Pricing from "./containers/Statics/Pricing"
import SubscriptionCheckOut from "./containers/Statics/Pricing/SubscriptionCheckOut"
import Referral from "./containers/Statics/Referral"
import StripeResponse from "./containers/Success/StripeResponse"
import Deals from "./containers/Workspace/Deals"
import DealCheckout from "./containers/Workspace/Deals/DealCheckout"
import LogisticInvite from "./containers/Workspace/Deals/LogisticInvite"
// Workspace
import Market from "./containers/Workspace/Market"
import Create from "./containers/Workspace/Market/CreateProduct"
import PreOrder from "./containers/Workspace/Market/PreOrder"
import Notifications from "./containers/Workspace/Notifications"
import Office from "./containers/Workspace/Office"
import EditDraftOrder from "./containers/Workspace/Office/EditDraftOrder"
import Settings from "./containers/Workspace/Settings"
import { PATHS } from "./_shared/defs"







// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  // STATIC PAGES
  { path: "/", exact: true, name: "Home", component: Home },
  {
    path: "/payment-option",
    exact: true,
    name: "Payment Option",
    component: PaymentOption,
  },
  // {
  //   path: `${PATHS.PARTNERS}`,
  //   exact: true,
  //   name: "Partners",
  //   component: Partners,
  // },
  {
    path: `${PATHS.PRICING}`,
    exact: true,
    name: "Pricing",
    component: Pricing,
  },
  {
    path: `${PATHS.PRICING_CHECKOUT}`,
    exact: true,
    name: "Subscription CheckOut",
    component: SubscriptionCheckOut,
  },
  {
    path: `${PATHS.FAQ}`,
    exact: true,
    name: "FAQ",
    component: Faq,
  },
  {
    path: `${PATHS.ABOUT_US}`,
    exact: true,
    name: "About Us",
    component: AboutUs,
  },
  {
    path: `${PATHS.CONTACT_US}`,
    exact: true,
    name: "ContactUs",
    component: ContactUs,
  },
  {
    path: `${PATHS.BLOG}`,
    exact: true,
    name: "Blog",
    component: Blog,
  },
  {
    path: `${PATHS.BLOG_VIEW}`,
    exact: true,
    name: "Blog View",
    component: BlogView,
  },
  {
    path: `${PATHS.REFERRAL}`,
    exact: true,
    name: "Referral",
    component: Referral,
  },
  {
    path: `${PATHS.CASE_STUDIES}`,
    exact: true,
    name: "Case Studies",
    component: CaseStudies,
  },
  {
    path: `${PATHS.CASE_STUDIES_VIEW}`,
    exact: true,
    name: "Case Studies view",
    component: CaseStudiesView,
  },

  {
    path: `${PATHS.EXPO}`,
    exact: true,
    name: "Expo",
    component: Expo,
  },

  {
    path: `${PATHS.ADD_ON}`,
    exact: true,
    name: "Addon",
    component: Addon,
  },

  // {
  //   path: `${PATHS.OPERATOR}`,
  //   exact: true,
  //   name: "Operator",
  //   component: Operator,
  // },

  // AUTHENTICATION PAGES
  {
    path: `${PATHS.LOGIN}`,
    exact: true,
    name: "Login",
    component: Login,
    noRef: true,
  },
  {
    path: `${PATHS.REGISTER}`,
    exact: true,
    name: "Register",
    component: Register,
    noRef: true,
  },
  {
    path: `${PATHS.CHANGE_PASSWORD}`,
    exact: true,
    name: "NewPassword",
    component: NewPassword,
    noRef: true,
  },
  {
    path: `${PATHS.RESET_PASSWORD}`,
    exact: true,
    name: "ResetPassword",
    component: ResetPassword,
    noRef: true,
  },

  {
    path: `${PATHS.CO_WORKER_INVITED}`,
    exact: true,
    name: "CoWorkerInvited",
    component: CoWorkerInvited,
    noRef: true,
  },

  // ERROR PAGES
  {
    path: "/404",
    exact: true,
    name: "Not found",
    component: Page404,
    noRef: true,
  },

  // SEARCH ENGINE PAGES
  {
    path: "/sitemap.xml",
    name: "SiteMap",
    component: SiteMap,
  },
  {
    path: "/robots.txt",
    name: "Robots",
    component: Robots,
  },

  // WORKSPACE PAGES
  {
    path: "/market",
    isPrivate: true,
    exact: true,
    name: "Market",
    component: Market,
  },
  {
    path: `${PATHS.MARKET_VIEW}`,
    exact: true,
    name: "Product View",
    component: Market,
  },
  {
    path: `${PATHS.MARKET_CREATE}`,
    isPrivate: true,
    isMandatorySubscription: true,
    exact: true,
    name: "Create Product",
    component: Create,
  },
  {
    path: `${PATHS.DEALS}`,
    isPrivate: true,
    exact: true,
    name: "Deals",
    component: Deals,
  },
  {
    path: `${PATHS.DEAL_VIEW}`,
    isPrivate: true,
    exact: true,
    name: "View Deal",
    component: Deals,
  },
  {
    path: `${PATHS.PRE_ORDER}`,
    isPrivate: true,
    isMandatorySubscription: true,
    exact: true,
    name: "Create Deal",
    component: PreOrder,
  },
  {
    path: `${PATHS.OFFICE}`,
    isPrivate: true,
    exact: true,
    name: "My Office",
    component: Office,
  },
  {
    path: `${PATHS.STRIPE_RESPONSE}`,
    isPrivate: true,
    exact: true,
    name: "Stripe Response",
    component: StripeResponse,
  },
  {
    path: `${PATHS.LOGISTIC_INVITE}`,
    isPrivate: true,
    exact: true,
    name: "Logistic Invite",
    component: LogisticInvite,
  },
  {
    path: `${PATHS.SETTINGS}`,
    isPrivate: true,
    exact: true,
    name: "Settings",
    component: Settings,
  },
  {
    path: `${PATHS.DEAL_CHECKOUT}`,
    isPrivate: true,
    exact: true,
    name: "Deal Checkout",
    component: DealCheckout,
  },
  {
    path: `${PATHS.EDIT_DRAFT_ORDER}`,
    isPrivate: true,
    exact: true,
    name: "Edit Draft Order",
    component: EditDraftOrder,
  },
  {
    path: `${PATHS.NOTIFICATIONS}`,
    isPrivate: true,
    exact: true,
    name: "Notifications",
    component: Notifications,
  },
];

export default routes;

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import moment from "moment";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import { fetchAllSubscription } from "../../../../../../redux/actions";
import ProgressBar from "../../../../../../_shared/components/ProgressBar";
import CustomButton from "../../../../../../_shared/components/Button";
import "./Subscription.scss";
import { useHistory } from "react-router-dom";

const propTypes = {
  isFetchAllSubscription: PropTypes.bool,
  all_subscription: PropTypes.array,
  fetchAllSubscription: PropTypes.func,
};

const defaultProps = {
  isFetchAllSubscription: true,
};

const Subscription = ({
  fetchAllSubscription,
  all_subscription,
  isFetchAllSubscription,
}) => {
  useEffect(() => {
    const payload = {
      count: 10,
      offset: 0,
    };

    fetchAllSubscription(payload);
  }, []);

  const history = useHistory();
  const currentSubscription = (all_subscription && all_subscription[0]) || {};
  const renderSubscriptionCard = currentSubscription => {
    const { plan, subscription_end_date, amount } = currentSubscription || {};

    return (
      <div className="subcription-card">
        <div className="subcription-card__inner">
          <div className="subcription-card__inner__left">
            <p className="subcription-card__inner__left__plan">current plan</p>
            <h5 className="subcription-card__inner__left__tariff">
              {plan == "BUSINESS"
                ? "BUSINESS"
                : plan == "ENTERPRISE"
                ? "ENTERPRISE"
                : "BASIC"}
            </h5>
          </div>
          {plan == "ENTERPRISE" ? null : (
            <CustomButton
              name="update plan"
              action={() => history.push(`/pricing`)}
              classes="regular"
            />
          )}
        </div>
        <div className="subcription-card__inner">
          <p className="subcription-card__time">
            {plan == "ENTERPRISE"
              ? `Next Payment: $${amount / 100} on : ${moment(
                  subscription_end_date
                ).format("MMM D YYYY")}`
              : plan == "BUSINESS"
              ? `Next Payment: $${amount / 100} on : ${moment(
                  subscription_end_date
                ).format("MMM D YYYY")}`
              : "FREE"}
          </p>
          {plan == "BUSINESS" || plan == "ENTERPRISE" ? (
            <CustomButton name="cancel plan" btnType="no-border" disabled />
          ) : null}
        </div>
      </div>
    );
  };
  const renderTitle = () => (
    <Row className="all-subscription__render-title">
      <Col md={2}>
        <p>Date</p>
      </Col>
      <Col md={7}>
        <p>Operation</p>
      </Col>
      <Col md={2}>
        <p>Amount</p>
      </Col>
      <Col md={1} />
    </Row>
  );

  return (
    <main className="subcription">
      {isFetchAllSubscription ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : isEmpty(all_subscription) ? (
        <React.Fragment>
          {renderSubscriptionCard(currentSubscription)}
          <p className="d-flex justify-content-center p-5">
            <em> You have no active subscription on this plan </em>
          </p>
        </React.Fragment>
      ) : (
        <section>
          {renderSubscriptionCard(currentSubscription)}
          {renderTitle()}
          {all_subscription &&
            all_subscription.map((subscription, index) => {
              let isSameTime = true;
              const prevItem = all_subscription[index - 1];

              if (!isEmpty(prevItem)) {
                isSameTime = !moment(subscription.created_at).isSame(
                  prevItem.created_at,
                  "day"
                );
              }

              return (
                <div key={index} className="all-subscription">
                  {isSameTime && (
                    <div className="all-subscription__title">
                      <h5>
                        {moment(subscription.created_at).format("Do MMMM")}
                      </h5>
                    </div>
                  )}
                  <div className="all-subscription__each-sub">
                    <Row>
                      <Col md={2}>
                        <p>
                          {moment(subscription.created_at).format("hh: mm")}
                        </p>
                      </Col>
                      <Col md={7}>
                        <p>{subscription.plan}</p>
                      </Col>
                      <Col md={2}>
                        <p className="all-subscription__each-sub__amount">
                          {`-$${subscription.amount / 100}`}
                        </p>
                      </Col>
                      <Col md={1}>
                        <img src="/images/svg/icons/paper.svg" />
                      </Col>
                    </Row>
                  </div>
                </div>
              );
            })}
        </section>
      )}
    </main>
  );
};

Subscription.propTypes = propTypes;
Subscription.defaultProps = defaultProps;

const mapStateToProps = state => ({
  all_subscription: state.office.list.data,
  isFetchAllSubscription: state.ui.loading.fetchAllSubscription,
});

const mapDispatchToProps = {
  fetchAllSubscription,
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);

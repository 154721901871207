import React from "react";
import PropTypes from "prop-types";

import {
  truncate,
  priceFormat,
  dateFormat,
} from "../../../../../_shared/functions/utils";
import "./DealCard.scss";

const DealCard = ({ deal, active, action }) => {
  const { _id, amount, quantity, product, created_at } = deal;
  return (
    <div
      className={`deals-sidebar__notification__card ${active ? "active" : ""}`}
      onClick={() => action(_id)}
    >
      <p className="deals-sidebar__notification__card__date">
        {dateFormat(created_at)}
      </p>
      <p className="deals-sidebar__notification__card__title">
        {product && product.name ? truncate(product.name, 50) : "---"}
      </p>
      <p className="deals-sidebar__notification__card__quantity">{quantity}</p>
      <p className="deals-sidebar__notification__card__price">
        {priceFormat(amount)}
      </p>
      <span className="deals-sidebar__notification__card__status">
        {true ? (
          <i className="fas fa-star deals-sidebar__notification__card__status--active" />
        ) : (
          <i className="far fa-star deals-sidebar__notification__card__status--in-active" />
        )}
      </span>
    </div>
  );
};

DealCard.propTypes = {};

export default DealCard;

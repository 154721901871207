import React from "react";
import { Switch } from "react-router-dom";
import Loadable from "react-loadable";
import { Slide, ToastContainer } from "react-toastify";
import Route from "./_shared/components/Extends/Route";
import "react-toastify/dist/ReactToastify.min.css";
import { Offline, Online } from "react-detect-offline";
import OfflinePage from "./_shared/components/partials/OfflinePage";

const loading = () => (
  <div className="animated fadeIn pt-5 text-center">Loading...</div>
);

const Layout = Loadable({
  loader: () => import("./containers/DefaultLayout"),
  loading,
});

const App = () => {
  return (
    <>
      <Offline polling={false}>
        <OfflinePage />
      </Offline>
      <Online polling={false}>
        <ToastContainer
          containerId={1}
          transition={Slide}
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          enableMultiContainer={false}
          closeOnClick={true}
          rtl={false}
          draggable={false}
          pauseOnHover={true}
          position="bottom-right"
        />
        <Switch>
          <Route path="/" name="OPN Platform" component={Layout} />
        </Switch>
      </Online>
    </>
  );
};

export default App;

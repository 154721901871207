import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "reactstrap";
import CustomButton from "../../../../_shared/components/Button";

const Attendee = () => {
  return (
    <div className="expo-tab-panel">
      <p className="expo-tab-panel__title">
        Find all the information you need about your trade show participation.
      </p>
      <div className="text-center">
        <a
          href="https://expo.opnplatform.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <CustomButton name="buy access" />
        </a>
      </div>
      <Row className="features-def row-eq-height">
        <Col sm={12} md={6} className="features-def__left">
          <div>
            <h5 className="md-title">Why attend?</h5>
            <p>
              At OPN IPackXPO365, you’ll meet the major packaging industry
              players and the rising stars of the global packaging world, and
              discover the latest sustainable innovations destined to transform
              the industry for the better.
            </p>
          </div>
        </Col>
        <Col sm={12} md={6} className="features-def__right">
          <img className="img-fluid" src="images/svg/cards/attendee.svg" />
        </Col>
      </Row>
      <Row className="features-list">
        <Col md="6">
          <ul className="custom-list">
            <li>
              <strong>The EXPO area:</strong> 365/24/7 discover new products,
              trends, partners, and sustainable innovations to diversify and
              scale your packaging business.
            </li>
            <li>
              <strong>The Sessions area:</strong> attend multi-track
              conferences, training workshops, hackathons, breakout rooms,
              webinars, seminars.
            </li>
            <li>
              <strong>The Networking area:</strong> automate the discovery of
              new connections in a place where people meet one-on-one.
            </li>
          </ul>
        </Col>
        <Col md="6">
          <ul className="custom-list">
            <li>
              <strong>Mobile App:</strong> chat the right people before, due or
              even after the event.
            </li>
            <li>
              <strong>Online communication:</strong> communicate in real time
              with exhibitors, speakers and other participants.
            </li>
            <li>
              <strong>Industry trends & insights</strong> are at your
              fingertips: bring top trends and insights right at your doorstep.
            </li>
          </ul>
        </Col>
      </Row>
      <Row className="features-cap">
        <Col md={{ size: 10, offset: 1 }}>
          <p className="expo-tab-panel__title">
            IPackXPO365 increases your participation dramatically. You don't
            need to travel to attend the event. It is extremely cost effective
            for attendees in terms of traveling cost, accommodation cost. This
            enables more C -level executives to attend the event.
          </p>
          <p className="expo-tab-panel-p">
            Attending OPN IPackXPO365 can be one of the most interesting ways to
            stay on top of market news, expand your network and find
            technologies that are revolutionizing the industry. And, of course,
            do business, both as an exhibitor or an attendee.
          </p>
        </Col>
        <Col sm={12} className="text-center ticket">
          <a
            href="https://expo.opnplatform.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="img-fluid"
              src={`${process.env.IMAGE_URL}/assets/images/svg/cards/ticket_attendee.svg`}
            />
          </a>
        </Col>
      </Row>
    </div>
  );
};

Attendee.propTypes = {};

export default Attendee;

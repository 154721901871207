export const tariffPlans = [
  {
    id: 3,
    titleMain: "Enterprise",
    plan: "Enterprise",
    monthPrice: "990",
    yearPrice: "792",
    totalPrice: "2376",
    order: 3,
  },
  {
    id: 2,
    titleMain: "Business",
    plan: "Business",
    monthPrice: "290",
    yearPrice: "232",
    totalPrice: "696",
    order: 2,
  },
  {
    id: 1,
    titleMain: "Basic",
    plan: "Basic",
    monthPrice: "19",
    yearPrice: "15",
    totalPrice: "45",
    order:1,
  },
];

import React, { useEffect } from "react";
import { connect } from "react-redux";
import InviteForm from "../_shared/forms/InviteForm";
import { register, fetchCoWorkerEmail } from "../../../redux/actions";

const CoWorkerInvited = ({ register, fetchCoWorkerEmail, match }) => {
  useEffect(() => {
    const id = match.params.id;
    const payload = {
      id: id,
    };

    fetchCoWorkerEmail(payload);
  }, []);

  const submit = values => {
    const id = match.params.id;
    const payload = {
      email: values.email,
      password: values.password,
      c_password: values.confirmPassword,
      invitation: id,
    };
    register(payload);
  };

  return (
    <React.Fragment>
      <InviteForm onSubmit={submit} />
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  fetchCoWorkerEmail,
  register,
};

export default connect(null, mapDispatchToProps)(CoWorkerInvited);

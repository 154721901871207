import { FETCH_CATEGORIES } from '../../actions';

const defaultState = {
  list: []
};

const categoriesReducer = (state = defaultState, action) => {
  const { payload } = action;
  switch (action.type) {
    case FETCH_CATEGORIES.SUCCESS:
      return Object.assign({}, state, { list: payload });
    default:
      return state;
  }
};

export default categoriesReducer;

import { isEmpty } from "lodash"
import numeral from "numeral"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import { Col, Container, Form, FormGroup, Row } from "reactstrap"
import { change, Field, getFormValues, reduxForm } from "redux-form"
import { fetchCategories, uploadMedia } from "../../../../../redux/actions"
import CustomButton from "../../../../../_shared/components/Button"
import ProgressBar from "../../../../../_shared/components/ProgressBar"
import {
  CheckBoxField, SelectInputField, TextInputField
} from "../../../../../_shared/components/ReduxFormFields"
import DatePickr from "../../../../../_shared/components/ReduxFormFields/ReactDatePicker"
import Toaster from "../../../../../_shared/components/Toaster"
import { countries, deliveryMethods, units } from "../../../../../_shared/defs"
import {
  formatCategory, formatMessagesFromError, formatProduct,
  truncate
} from "../../../../../_shared/functions/utils"
import "./CreateProductForm.scss"
import OrderFormValidation from "./OrderFormValidation"

const propTypes = {
  formLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  formData: PropTypes.object,
  categoryList: PropTypes.array,
  uploadMedia: PropTypes.func,
  fetchCategories: PropTypes.func,
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
  isUploadingImage: PropTypes.bool,
  isUploadingDoc: PropTypes.bool,
  submitting: PropTypes.bool,
  pristine: PropTypes.bool,
  invalid: PropTypes.bool,
  formType: PropTypes.string.isRequired,
};

const defaultProps = {
  formLoading: false,
  isUploadingImage: false,
  isUploadingDoc: false,
  formData: {},
  categoryList: {},
};

const CreateProductForm = (props) => {
  const {
    formData,
    uploadMedia,
    isUploadingDoc,
    isUploadingImage,
    handleSubmit,
    change,
    fetchCategories,
    categoryList,
    formLoading,
    submitting,
    pristine,
    invalid,
    formType,
    userPlan,
  } = props;
  const { goBack } = useHistory();

  const { category, images, docs, priceType } = formData;
  console.log("imagesdocs :", images, docs);

  const [displayImage, setDisplayImage] = useState("");

  const isRequest = formType === "request";

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (!isEmpty(images)) {
      setDisplayImage(images[0].cdn_link);
    } else {
      setDisplayImage("");
    }
  }, [images]);

  const handleRemoveFile = (id, type) => {
    console.log("id, type :====>>>>", id, type);
    const data = type === "images" ? images : docs;

    const filteredData = data.filter((item) => item._id !== id);
    console.log("filteredData :", filteredData);

    change(type, filteredData);
  };

  const countryDropdown = () => {
    return countries.map(({ name, code }) => ({ label: name, value: code }));
  };

  const variantDropdown = () => {
    return deliveryMethods.map(({ name, code }) => ({
      label: name,
      value: code,
    }));
  };

  const unitsDropdown = () => {
    return units.map(({ name, code }) => ({
      label: name,
      value: code,
    }));
  };

  const termsDropdown = () => [
    {
      label: "immediately",
      value: "immediately",
    },
    {
      label: "30 days",
      value: "30 days",
    },
    {
      label: "60 days",
      value: "60 days",
    },
    {
      label: "90 days",
      value: "90 days",
    },
  ];

  const currencyDropdown = () => [
    {
      label: "USD",
      value: "USD",
    },
  ];

  const handleCategoryChange = () => {
    change("product", "");
  };

  const handleUpload = (event, mediaType) => {
    const file = event.target.files;
    if (!file.length) return;

    if (file[0].type.split("/")[0] !== "application" && mediaType === "doc") {
      Toaster.dismiss();
      Toaster.error(
        formatMessagesFromError("Invalid file type, check and try again.")
      );

      return;
    }

    if (mediaType === "img" && file[0].type.split("/")[0] !== "image") {
      Toaster.dismiss();
      Toaster.error(
        formatMessagesFromError("Invalid file type, check and try again.")
      );

      return;
    }

    const payload = {
      files: file,
      formAction: change,
      mediaType,
    };

    uploadMedia(payload);
  };

  const renderPremiumLabel = () => (
    <div className="premium-label">
      <div className="premium-label__title">
        Premium listing{" "}
        {userPlan !== "ENTERPRISE" && (
          <span className="premium-label__title__tag">enterprise</span>
        )}
      </div>
      <div className="premium-label__note">
        Your Offer will be on the top list.{" "}
        {userPlan !== "ENTERPRISE" && (
          <span>
            Available only for <span className="plan">Enterprise</span> plan.
          </span>
        )}
      </div>
    </div>
  );

  const renderTenderLabel = () => (
    <div className="premium-label">
      <div className="premium-label__title">
        Tender{" "}
        {userPlan !== "ENTERPRISE" && (
          <span className="premium-label__title__tag">enterprise</span>
        )}
      </div>
    </div>
  );

  const handlePriceChange = (e) => {
    e.preventDefault();

    const priceValue = e.currentTarget.value;
    let number = numeral(priceValue).format("0,0.00");

    // setDecimal(number);
    change("price", number);
  };

  return (
    <Form className="offer-form" onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col md={{ size: 8, offset: 2 }}>
            <Row>
              <Col>
                <h4 className="form-title">
                  New {formType || "Offer"} details
                </h4>
              </Col>
            </Row>
            <Row>
              <Col className="offer-form__left">
                <FormGroup>
                  <Field
                    name="category"
                    label="Category"
                    id="category"
                    options={formatCategory(categoryList)}
                    onChange={handleCategoryChange}
                    component={SelectInputField}
                    placeholder="Category"
                  />
                </FormGroup>
                <FormGroup>
                  <Field
                    name="product"
                    label="Product Name"
                    id="product"
                    disabled={isEmpty(category)}
                    options={formatProduct(
                      categoryList,
                      category && category.label.props.children
                    )}
                    component={SelectInputField}
                    isSearchable={true}
                    placeholder="Product Name"
                  />
                </FormGroup>
                <FormGroup>
                  <Field
                    name="description"
                    label="Description"
                    id="description"
                    type="textarea"
                    className="textarea"
                    component={TextInputField}
                    placeholder="Description"
                  />
                </FormGroup>
                <FormGroup>
                  <Field
                    name="location"
                    label="Location"
                    id="location"
                    options={countryDropdown()}
                    className="textarea"
                    component={SelectInputField}
                    isSearchable={true}
                    placeholder="Location"
                  />
                </FormGroup>
                {isRequest && (
                  <FormGroup>
                    <Field
                      name="destination"
                      label="Destination"
                      id="destination"
                      type="text"
                      disabled={formLoading}
                      component={TextInputField}
                      placeholder="Destination"
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  <Field
                    name="variant"
                    label="Delivery Variant"
                    id="variant"
                    options={variantDropdown()}
                    className="textarea"
                    component={SelectInputField}
                    isSearchable={true}
                    placeholder="Delivery Variant"
                  />
                </FormGroup>
                <FormGroup>
                  <Field
                    name="term"
                    label="Delivery Terms"
                    id="term"
                    options={termsDropdown()}
                    className="textarea"
                    component={SelectInputField}
                    isSearchable={true}
                    placeholder="Delivery Terms"
                  />
                </FormGroup>
                {isRequest && (
                  <FormGroup>
                    <Field
                      name="quantity"
                      label="Quantity"
                      id="quantity"
                      type="number"
                      disabled={formLoading}
                      component={TextInputField}
                      placeholder="Quantity"
                    />
                  </FormGroup>
                )}
                {isRequest && (
                  <div className="tender-container">
                    <label className="price-title">Price</label>
                    <FormGroup className="price-type">
                      <Field
                        name="priceType"
                        label="Fixed price"
                        type="radio"
                        id="fixed"
                        value="fixed"
                        color="blue"
                        disabled={userPlan !== "ENTERPRISE"}
                        component={CheckBoxField}
                      />
                    </FormGroup>
                    <FormGroup className="price-type">
                      <Field
                        name="priceType"
                        label={renderTenderLabel()}
                        type="radio"
                        id="tender"
                        value="tender"
                        color="blue"
                        disabled={userPlan !== "ENTERPRISE"}
                        component={CheckBoxField}
                      />
                    </FormGroup>
                  </div>
                )}
                <div className="price-unit">
                  <FormGroup className="price-form-control">
                    <Field
                      name="currency"
                      label="currency"
                      id="currency"
                      disabled
                      options={currencyDropdown()}
                      component={SelectInputField}
                      placeholder="currency"
                      isClearable={false}
                    />
                    <Field
                      name="price"
                      label="Price"
                      id="price"
                      type="text"
                      onBlur={e => handlePriceChange(e)}
                      className="amount"
                      component={TextInputField}
                      placeholder={
                        priceType === "tender" ? "Max price" : "Price"
                      }
                    />
                  </FormGroup>
                  <FormGroup className="unit">
                    <Field
                      name="unit"
                      label="unit"
                      id="unit"
                      options={unitsDropdown()}
                      component={SelectInputField}
                      placeholder="unit"
                      isClearable={false}
                    />
                  </FormGroup>
                </div>
                {priceType === "tender" && (
                  <FormGroup>
                    <Field
                      name="duration"
                      label="Tender duration"
                      type="text"
                      id="duration"
                      withTime={true}
                      component={DatePickr}
                    />
                  </FormGroup>
                )}
                <FormGroup className="ship">
                  <Field
                    name="ship"
                    label="Ready for shipment"
                    type="checkbox"
                    color="blue"
                    component={CheckBoxField}
                  />
                </FormGroup>
                <FormGroup className="ship">
                  <Field
                    name="premium"
                    label={renderPremiumLabel()}
                    type="checkbox"
                    color="blue"
                    disabled={userPlan !== "ENTERPRISE"}
                    component={CheckBoxField}
                  />
                </FormGroup>
              </Col>
              <Col className="offer-form__right">
                <div className="uploads">
                  <div className="uploads__display">
                    {displayImage && <img src={displayImage} />}
                  </div>
                  <div className="uploads__image-grid">
                    <label
                      htmlFor="img"
                      className="uploads__image-grid__list input"
                    >
                      <img src="/images/svg/icons/upload.svg" alt="" />
                      <input
                        className="d-none"
                        type="file"
                        id="img"
                        disabled={isUploadingImage}
                        onChange={event => handleUpload(event, "img")}
                        accept="image/png, image/jpeg"
                      />
                      {isUploadingImage && (
                        <div className="loading">
                          <ProgressBar spinType="tiny" />
                        </div>
                      )}
                    </label>
                    {!isEmpty(images) &&
                      images.map((image, i) => (
                        <div className="uploads__image-grid__list" key={i}>
                          <i
                            onClick={() =>
                              handleRemoveFile(image._id, "images")
                            }
                            className="fas remove-icon fa-times-circle"
                          />
                          <img
                            onClick={() =>
                              setDisplayImage(image.url || image.cdn_link)
                            }
                            src={image.url || image.cdn_link}
                          />
                        </div>
                      ))}
                  </div>
                  <div className="uploads__doc-list">
                    <label>
                      <em>Optional</em>
                    </label>
                    {docs &&
                      docs.map((doc, i) => (
                        <div className="uploads__doc-list__item" key={i}>
                          <i
                            onClick={() => handleRemoveFile(doc._id, "docs")}
                            className="fas remove-icon fa-times-circle"
                          />
                          {doc.name.split(".")[1] === "pdf" ? (
                            <img src="/images/svg/icons/pdf.svg" alt="pdf" />
                          ) : (
                            <img src="/images/svg/icons/doc.svg" alt="doc" />
                          )}
                          {truncate(doc.name, 20)}
                        </div>
                      ))}
                    <label
                      htmlFor="doc"
                      className="uploads__doc-list__item input"
                    >
                      <img src="/images/svg/icons/upload.svg" />
                      Upload document
                      <input
                        className="d-none"
                        type="file"
                        disabled={isUploadingDoc}
                        id="doc"
                        onChange={event => handleUpload(event, "doc")}
                      />
                      {isUploadingDoc && (
                        <div className="loading">
                          <ProgressBar spinType="tiny" />
                        </div>
                      )}
                    </label>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md="12" className="offer__cta">
                <FormGroup className="ship">
                  <Field
                    name="place-another"
                    label="Place and create another"
                    type="checkbox"
                    color="blue"
                    component={CheckBoxField}
                  />
                </FormGroup>
                <div>
                  <CustomButton
                    name="Cancel"
                    btnType="no-border"
                    action={goBack}
                  />
                  <CustomButton
                    name={`Place the ${formType || "Offer"}`}
                    type="submit"
                    loading={formLoading}
                    disabled={formLoading || submitting || pristine || invalid}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col></Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

CreateProductForm.propTypes = propTypes;
CreateProductForm.defaultProps = defaultProps;

const formName = "offerForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  validate: OrderFormValidation,
})(CreateProductForm);

// export default connectedForm;
export default connect(
  (state) => ({
    formData: getFormValues(formName)(state),
    categoryList: state.categories.list,
    userPlan: state.auth.user.subscription.tariff,
    isUploadingImage: state.ui.loading["uploadMedia-img"],
    isUploadingDoc: state.ui.loading["uploadMedia-doc"],
  }),
  {
    change,
    fetchCategories,
    uploadMedia,
  }
)(connectedForm);

import {
  FETCH_UNREAD_NOTIFICATIONS_COUNT,
  FETCH_CURRENT_NOTIFICATIONS,
  FETCH_ALL_NOTIFICATIONS,
  MARK_AS_VIEWED_NOTIFICATION,
  apiRequest,
  GET,
  PUT,
  POST,
  DELETE,
} from "../../actions";
import { API } from "../../../_shared/defs";

const fetchUnreadNotificationsCount = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_UNREAD_NOTIFICATIONS_COUNT.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `/notification/unread`,
        key: "fetchUnreadNotificationsCount",
        onSuccess: FETCH_UNREAD_NOTIFICATIONS_COUNT.SUCCESS,
        headerParams: false,
      })
    );
  }
};

const fetchCurrentNotifications = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_CURRENT_NOTIFICATIONS.START) {
    const { params } = action;
    dispatch(
      apiRequest({
        method: GET,
        url: `/notification/all`,
        key: "fetchCurrentNotifications",
        onSuccess: FETCH_CURRENT_NOTIFICATIONS.SUCCESS,
        headerParams: false,
        params: params,
      })
    );
  }
};

const fetchAllNotifications = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_ALL_NOTIFICATIONS.START) {
    const { params } = action;
    dispatch(
      apiRequest({
        method: GET,
        url: `/notification/all`,
        key: "fetchAllNotifications",
        onSuccess: FETCH_ALL_NOTIFICATIONS.SUCCESS,
        headerParams: false,
        params: params,
      })
    );
  }
};

const markAsViewedNotification = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === MARK_AS_VIEWED_NOTIFICATION.START) {
    const { Id } = action;
    dispatch(
      apiRequest({
        method: PUT,
        url: `/notification/view/${Id}`,
        key: "markAsViewedNotification",
        onSuccess: MARK_AS_VIEWED_NOTIFICATION.SUCCESS,
        headerParams: false,
      })
    );
  }
};

export default [
  fetchUnreadNotificationsCount,
  fetchAllNotifications,
  markAsViewedNotification,
  fetchCurrentNotifications,
];

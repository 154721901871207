const dealValidationFunc = values => {
  const errors = {};

  if (!values || !values.quantity) {
    errors['quantity'] = 'Quantity field is required';
  }

  if (!values || !values.destination) {
    errors['destination'] = 'Destination field is required';
  }

  if (!values || !values.due_date) {
    errors['due_date'] = 'Due date field is required';
  }

  return errors;
};

export default dealValidationFunc;

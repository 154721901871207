import { API } from "../../../_shared/defs"
import {
  ACCEPT_DEAL, apiRequest, CANCEL_DEAL, CREATE_DEAL, DEAL_PAYMENT, FETCH_ALL_DEAL, FETCH_CHAT_THREAD, FETCH_DEAL, FETCH_DEAL_BY_STATUS,
  FETCH_DEAL_CHAT_ID, FETCH_DEAL_STAT, FETCH_INVOICE, FETCH_OFFERS_DEALS,
  FETCH_REQUESTS_DEALS, GET, INVITE_LOGISTIC_PARTNER, navigateTo, POST, PRE_ACCEPT_DEAL, PUT, SEND_CHAT_MESSAGE, uiToggleModal,
  uiTogglePaymentSuccess, UPDATE_DEAL, UPDATE_DEAL_PRICE
} from "../../actions"

const createDeal = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === CREATE_DEAL.START) {
    const { payload, key } = action.meta;
    let url = `${API.DEAL}`;
    if (key === "request") url = `${API.DEAL}/offer`;
    dispatch(
      apiRequest({
        method: POST,
        url,
        key: "createDeal",
        onSuccess: data => {
          dispatch({
            type: CREATE_DEAL.SUCCESS,
            payload: data,
          });

          dispatch(navigateTo(`/deals/${data._id}`));
        },
        payload,
        headerParams: false,
      })
    );
  }
};

const fetchDealStat = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_DEAL_STAT.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.DEAL}-stats`,
        key: "fetchDealStat",
        onSuccess: FETCH_DEAL_STAT.SUCCESS,
        headerParams: false,
      })
    );
  }
};

const fetchDeal = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_DEAL.START) {
    const { id } = action.payload;
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.DEAL}/single/${id}`,
        key: "fetchDeal",
        onSuccess: FETCH_DEAL.SUCCESS,
        headerParams: false,
      })
    );
  }
};

const preAcceptDeal = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === PRE_ACCEPT_DEAL.START) {
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.DEAL}/pre-accept`,
        key: "preAcceptDeal",
        onSuccess: PRE_ACCEPT_DEAL.SUCCESS,
        successMessage: "Deal accepted successfully",
        headerParams: false,
        payload: action.payload,
      })
    );
  }
};

const acceptDeal = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === ACCEPT_DEAL.START) {
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.DEAL}/accept`,
        key: "acceptDeal",
        onSuccess: ACCEPT_DEAL.SUCCESS,
        headerParams: false,
        payload: action.payload,
      })
    );
  }
};

const cancelDeal = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === CANCEL_DEAL.START) {
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.DEAL}/cancel`,
        key: "cancelDeal",
        onSuccess: CANCEL_DEAL.SUCCESS,
        headerParams: false,
        payload: action.payload,
      })
    );
  }
};

const fetchOffersDeals = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_OFFERS_DEALS.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.DEAL}/offer/all`,
        key: "fetchOffersDeals",
        onSuccess: FETCH_OFFERS_DEALS.SUCCESS,
        headerParams: false,
        params: action.payload,
      })
    );
  }
};

const fetchRequestsDeals = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_REQUESTS_DEALS.START) {
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.DEAL}/request/all`,
        key: "fetchRequestsDeals",
        onSuccess: FETCH_REQUESTS_DEALS.SUCCESS,
        headerParams: false,
        params: action.payload,
      })
    );
  }
};

const updateDeal = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === UPDATE_DEAL.START) {
    dispatch(
      apiRequest({
        method: PUT,
        url: `${API.DEAL}`,
        key: "updateDeal",
        onSuccess: data => {
          dispatch({
            type: UPDATE_DEAL.SUCCESS,
            payload: data,
          });

          dispatch(uiToggleModal());
        },
        successMessage: "Deal updated successfully",
        payload: action.payload,
        headerParams: false,
      })
    );
  }
};

const updateDealPrice = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === UPDATE_DEAL_PRICE.START) {
    const { payload, callbackAction } = action.meta;
    dispatch(
      apiRequest({
        method: PUT,
        url: `${API.DEAL}/price`,
        key: "updateDealPrice",
        onSuccess: data => {
          dispatch({
            type: UPDATE_DEAL_PRICE.SUCCESS,
            payload: data,
          });

          callbackAction(false);
        },
        successMessage: "product price updated successfully",
        payload: payload,
        headerParams: false,
      })
    );
  }
};

const dealPayment = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === DEAL_PAYMENT.START) {
    const { dealType, payload } = action.meta;
    let url = `${API.DEAL}/pay`;

    if (dealType === "tender") url = `${API.TENDER}/pay`;

    dispatch(
      apiRequest({
        method: POST,
        url,
        key: "dealPayment",
        onSuccess: () => {
          dispatch(navigateTo(`/deals/${payload.id}`));
          dispatch(uiTogglePaymentSuccess());
        },
        successMessage: "payment completed successfully",
        payload,
        headerParams: false,
      })
    );
  }
};

const fetchInvoice = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_INVOICE.START) {
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.INVOICE}/my`,
        key: "fetchInvoice",
        onSuccess: FETCH_INVOICE.SUCCESS,
        payload: action.payload,
        headerParams: false,
      })
    );
  }
};

const fetchDealByStatus = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_DEAL_BY_STATUS.START) {
    const { status, params } = action.meta;
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.DEAL}/status/all/${status}`,
        key: "fetchDealByStatus",
        onSuccess: FETCH_DEAL_BY_STATUS.SUCCESS,
        params,
        headerParams: false,
      })
    );
  }
};

const fetchDealChatId = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_DEAL_CHAT_ID.START) {
    const { id } = action.payload;
    dispatch(
      apiRequest({
        method: GET,
        url: `/msg/chat/reference/${id}`,
        key: "fetchDealChatId",
        onSuccess: data => {
          dispatch({
            type: FETCH_DEAL_CHAT_ID.SUCCESS,
            payload: data,
          });

          const payload = {
            chatId: data._id,
            offset: 0,
            count: 10,
          };
          dispatch({
            type: FETCH_CHAT_THREAD.START,
            payload,
          });
        },
        headerParams: false,
      })
    );
  }
};

const fetchChatThread = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_CHAT_THREAD.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: POST,
        url: `/msg/chat/thread`,
        key: "fetchChatThread",
        onSuccess: FETCH_CHAT_THREAD.SUCCESS,
        headerParams: false,
        payload,
      })
    );
  }
};

const sendChatMessage = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === SEND_CHAT_MESSAGE.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: POST,
        url: `/msg/send`,
        key: "sendChatMessage",
        onSuccess: SEND_CHAT_MESSAGE.SUCCESS,
        headerParams: false,
        payload,
      })
    );
  }
};

const inviteLogisticPartner = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === INVITE_LOGISTIC_PARTNER.START) {
    const { payload } = action;
    dispatch(
      apiRequest({
        method: POST,
        url: `${API.AUTH}/invite/logistic`,
        key: "inviteLogisticPartner",
        onSuccess: () => {
          dispatch(navigateTo("/deals"));
        },
        successMessage: "Logistic partner successfully invited",
        headerParams: false,
        payload,
      })
    );
  }
};

const fetchAllDeal = ({ dispatch }) => next => action => {
  next(action);
  if (action.type === FETCH_ALL_DEAL.START) {
    const { params } = action;
    dispatch(
      apiRequest({
        method: GET,
        url: `${API.DEAL}/all`,
        key: "fetchAllDeal",
        onSuccess: FETCH_ALL_DEAL.SUCCESS,
        headerParams: false,
        params: params,
      })
    );
  }
};

export default [
  acceptDeal,
  preAcceptDeal,
  createDeal,
  fetchDealStat,
  fetchDeal,
  updateDeal,
  fetchOffersDeals,
  fetchRequestsDeals,
  cancelDeal,
  dealPayment,
  fetchInvoice,
  fetchDealByStatus,
  fetchDealChatId,
  fetchChatThread,
  sendChatMessage,
  inviteLogisticPartner,
  fetchAllDeal,
  updateDealPrice,
];

export default {
  GOOGLE_MAPS_KEY: "AIzaSyCsBa0Nb3AZz_8fcX4ShBDusN74GmlGt-c",
  GOOGLE_MAPS_SETTINGS: {
    center: {
      lat: 45.451283,
      lng: 9.1716502,
    },
    zoom: 12,
  },
};

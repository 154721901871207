import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import moment from "moment";
import "./DealList.scss";
import {
  truncate,
  priceFormat,
} from "../../../../../../_shared/functions/utils";

const propTypes = {
  data: PropTypes.array,
};

const DealList = ({ data }) => {
  const history = useHistory();
  const handleClick = (id) => {
    history.push(`/deals/${id}`);
  };
  const renderDealCard = (deal, index) => {
    const { _id, status, product, created_at, amount } = deal;

    return (
      <div key={index} className="deal-card" onClick={() => handleClick(_id)}>
        <div className="deal-card__inner">
          <p className={`status ${status.toLowerCase()}`}>{status}</p>
          <p className="time">{moment(created_at).format("L hh: mm a")}</p>
        </div>
        <div className="deal-card__inner">
          <p>{product.name ? truncate(product.name, 50) : "---"}</p>
          <p className="price">{priceFormat(amount)}</p>
        </div>
      </div>
    );
  };
  return (
    <div className="deal-list">
      {isEmpty(data) ? (
        <div className="no-deal">
          <p>
            <em>
              Here will be displayed additional information about the deal
            </em>
          </p>
        </div>
      ) : (
        data.map((deal, index) => renderDealCard(deal, index))
      )}
    </div>
  );
};

DealList.propTypes = propTypes;

export default DealList;

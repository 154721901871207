import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Col, Container, Row } from "reactstrap";
import ProgressBar from "../../../_shared/components/ProgressBar";
import {
  fetchCaseStudies,
  clearCaseStudiesState,
} from "../../../redux/actions";
import CaseStudiesCard from "./CaseStudiesCard";
import CustomButton from "../../../_shared/components/Button";

import "./CaseStudies.scss";

const propTypes = {
  isFetchingCaseStudies: PropTypes.bool,
  case_studies: PropTypes.arrayOf(PropTypes.object),
  fetchCaseStudies: PropTypes.func,
};

const defaultProps = {
  isFetchingCaseStudies: true,
};

const CaseStudies = ({
  history,
  case_studies,
  isFetchingCaseStudies,
  fetchCaseStudies,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    const params = {
      offset: 0,
      count: 20,
    };

    fetchCaseStudies(params);

    return () => {
      //  clearCaseStudiesState();
    };
  }, []);

  return (
    <main className="case-studies">
      <h1>Case Studies</h1>
      <React.Fragment>
        {isFetchingCaseStudies ? (
          <div className="d-flex justify-content-center p-5">
            <ProgressBar />
          </div>
        ) : isEmpty(case_studies) ? (
          <Container>
            <Row>
              <Col>
                <p className="justify-content-center p-5">
                  No Case Studies to display
                </p>
              </Col>
            </Row>
          </Container>
        ) : (
          <Row>
            {case_studies &&
              case_studies.map((item, index) => (
                <CaseStudiesCard key={index} eachCase={item} />
              ))}
          </Row>
        )}
      </React.Fragment>
      <div className="case-studies__get-started">
        <div className="case-studies__get-started__caption">
          <img src="/images/ready-to-start.png" />
          <h2>Ready to Get Started</h2>
        </div>
        <CustomButton
          name="sign up"
          action={() => history.push("./register")}
          btnType="outlined"
        />
      </div>
    </main>
  );
};

CaseStudies.propTypes = propTypes;
CaseStudies.defaultProps = defaultProps;

const mapStateToProps = state => ({
  case_studies: state.useCase.list,
  isFetchingCaseStudies: state.ui.loading.fetchCaseStudies,
});

const mapDispatchToProps = {
  fetchCaseStudies,
  clearCaseStudiesState,
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseStudies);

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import { Elements, StripeProvider } from "react-stripe-elements";
import SubscriptionForm from "./SubscriptionForm";
import "./SubscriptionCheckOut.scss";

const propTypes = {
  location: PropTypes.object,
};

const defaultProps = {};

const isDev =
  /dev.opnplatform/.test(window.location.host) ||
  /localhost/.test(window.location.host);
const STRIPE_PUBLISHABLE_KEY = isDev
  ? process.env.STRIPE_PUBLISHABLE_KEY_TEST
  : process.env.STRIPE_PUBLISHABLE_KEY;

const SubscriptionCheckOut = ({ location }) => {
  const { state } = location;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <main className="deal-checkout">
        <Container>
          <Row>
            <Col md={{ size: 8, offset: 2 }} className="">
              <StripeProvider apiKey={STRIPE_PUBLISHABLE_KEY}>
                <Elements>
                  <SubscriptionForm plan={state && state.plan} />
                </Elements>
              </StripeProvider>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
};

SubscriptionCheckOut.propTypes = propTypes;
SubscriptionCheckOut.defaultProps = defaultProps;

const mapStateToProps = state => ({
  location: state.router.location,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionCheckOut);

const TrackingValFunc = values => {
  const errors = {};

  if (!values || !values.carrier) {
    errors["carrier"] = "Carrier field is required";
  }

  if (!values || !values.tracking_code) {
    errors["tracking_code"] = "Tracking code field is required";
  }

  return errors;
};

export default TrackingValFunc;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Col, CardImg, Card, CardBody } from "reactstrap";
import CustomButton from "../../../../_shared/components/Button";
import { truncate } from "../../../../_shared/functions/utils";

const BlogCard = ({ article }) => {
  const { main_image, title, description, title_link } = article || {};

  return (
    <Col lg={4} md={4} xl={4} style={{ marginBottom: "52px" }}>
      <Card style={{ border: "0px", borderRadius: "8px" }}>
        <CardImg
          top
          src={main_image}
          width="100%"
          alt="Card image"
          className="blog-card-image"
        />
        <CardBody>
          <h5 className="card-text" title={title}>
            {truncate(title, 60)}
          </h5>
          <p className="card-text_p">{truncate(description, 110)}</p>
          <Link to={`/blog/${title_link}`}>
            <CustomButton btnType="no-border" name="READ MORE" />
          </Link>
        </CardBody>
      </Card>
    </Col>
  );
};

BlogCard.propTypes = {};

export default BlogCard;

import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { reducer as toastrReducer } from "react-redux-toastr";
import { connectRouter } from "connected-react-router";
import ui from "./ui";
import auth from "./auth";
import websiteReducers from "./website";
import workspaceReducers from "./workspace";

const appReducer = history =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    toastr: toastrReducer,
    ui,
    auth,
    ...workspaceReducers,
    ...websiteReducers,
  });

export default appReducer;

import React, { useState, Fragment, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ProductCard from "../../../../_shared/components/ProductCard";
import "./ProductList.scss";

const propTypes = {
  fetchOrder: PropTypes.func,
  isFetchingOrder: PropTypes.bool,
  orders: PropTypes.arrayOf(PropTypes.object),
  location: PropTypes.object,
};

const ProductList = ({ orders, location }) => {
  const history = useHistory();
  const { query } = location;
  const { productList, market } = query;
  const previousLabel = `${productList || "all"} ${market || "offers"}`;
  const [layout, setLayout] = useState("product__grid");
  const isMobileView = window.innerWidth < 426;

  useEffect(() => {
    if (isMobileView) setLayout("product__list");
  }, []);

  const handleRedirectView = (e, id, name) => {
    const productName = name.split(" ").join("-");
    history.push({
      pathname: `market/${productName}/${id}/view`,
      state: { previousLabel },
    });
  };
  return (
    <Fragment>
      {!isMobileView && (
        <div className="layout-cta">
          <img
            className={`${layout === "product__grid" ? "active" : ""}`}
            src="/images/svg/icons/grid.svg"
            onClick={() => setLayout("product__grid")}
          />
          <img
            className={`${layout === "product__list" ? "active" : ""}`}
            src="/images/svg/icons/list.svg"
            onClick={() => setLayout("product__list")}
          />
        </div>
      )}
      <div className={`${layout}`}>
        {orders &&
          orders.map(order => (
            <ProductCard
              key={order._id}
              order={order}
              action={handleRedirectView}
            />
          ))}
      </div>
    </Fragment>
  );
};

ProductList.propTypes = propTypes;

const mapStateToProps = state => ({
  location: state.router.location,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);

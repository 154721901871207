import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import CreateProductForm from "../../_shared/Forms/CreateProductForm";
import { fetchOrder, updateMyDraftOrder } from "../../../../redux/actions";
import ProgressBar from "../../../../_shared/components/ProgressBar";
import { units, countries } from "../../../../_shared/defs/";

const propTypes = {
  fetchOrder: PropTypes.func,
  updateMyDraftOrder: PropTypes.func,
  isFetchingOrder: PropTypes.bool,
  isUpdateMyDraftOrder: PropTypes.bool,
  current: PropTypes.object,
};

const EditDraftOrder = ({
  fetchOrder,
  current,
  isFetchingOrder,
  isUpdateMyDraftOrder,
  updateMyDraftOrder,
}) => {
  const { slug } = useParams();
  const productId = slug;
  const {
    category,
    name,
    description,
    company,
    country,
    delivery,
    status,
    price,
    amount,
    photos,
    quantity,
    destination,
    documents,
    isOwner,
    purpose,
  } = current || {};

  const { variant, term } = delivery || {};
  const { unit } = amount || {};

  const formType =
    purpose === "SELL" ? "offer" : purpose === "BUY" && "request";

  useEffect(() => {
    fetchOrder(productId);
  }, []);

  const getCountryName = name => {
    let country = countries.find(item => item.code === name);
    if (country) {
      return country.name;
    }
    return name;
  };

  const getUnitName = name => {
    let unit = units.find(item => item.code === name);

    if (unit) {
      return unit.name;
    }
    return name;
  };

  const formatInitialValues = {
    category: {
      value: !isEmpty(category) ? category.json.id : "",
      label: <p>{!isEmpty(category) ? category.json.name : ""}</p>,
    },
    product: {
      value: !isEmpty(name) && name,
      label: <p>{!isEmpty(name) && name}</p>,
    },
    currency: { label: "USD", value: "USD" },
    description: description,
    variant: {
      value: !isEmpty(variant) ? variant : "",
      label: !isEmpty(variant) ? <p>{variant}</p> : "",
    },
    term: {
      value: !isEmpty(term) ? term : "",
      label: !isEmpty(term) ? <p>{term}</p> : "",
    },
    location: {
      value: country,
      label: getCountryName(country),
    },

    unit: {
      value: unit,
      label: getUnitName(unit),
    },
    price: String(price / 100),

    images: photos,
    docs: documents,
    //for request
    quantity: quantity,
    destination: destination,
  };

  const handleSubmit = values => {
    const {
      category,
      product,
      price,
      images = [],
      docs = [],
      description,
      term,
      variant,
      location,
      currency,
      unit,
      destination,
      quantity,
    } = values;

    const imagesId = images.map(image => image._id);
    const docsId = docs.map(doc => doc._id);

    const payload = {
      title: product.value,
      price: Number(price),
      photos: imagesId,
      documents: docsId,
      purpose: purpose || "SELL",
      description,
      product: category.value,
      id: category.value,
      delivery: { term: term.value, variant: variant.value },
      country: location.value,
      currency: currency.value,
      destination,
      quantity: Number(quantity),
      amount: {
        number: 1,
        unit: unit.value,
      },
    };

    updateMyDraftOrder(payload, productId);
  };

  return (
    <main>
      {isFetchingOrder ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : (
        <CreateProductForm
          onSubmit={handleSubmit}
          formLoading={isUpdateMyDraftOrder}
          formType={formType}
          initialValues={formatInitialValues}
        />
      )}
    </main>
  );
};

EditDraftOrder.propTypes = propTypes;

const mapStateToProps = state => ({
  current: state.orders.current,
  isUpdateMyDraftOrder: state.ui.loading.updateMyDraftOrder,
  isFetchingOrder: state.ui.loading.fetchOrder,
});

const mapDispatchToProps = {
  fetchOrder,
  updateMyDraftOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDraftOrder);

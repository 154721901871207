import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Col, Container, Row } from "reactstrap"
import CustomButton from "../../../_shared/components/Button"
import "./AboutUs.scss"
import { OurValuesData } from "./fixtures"

const AboutUs = () => {
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="about">
      <Container>
        <Row>
          <Col md="12">
            <div className="page-title">About Us</div>
          </Col>
          <Col md="6" sm={12}>
            <h5 className="about__caption">
              Our vision is to transform the packaging industry in a sustainable
              way, and usher in a new era of digital innovation.
            </h5>
          </Col>
          <Col md="6" sm={12}>
            <p>
              Founded in 2018 by packaging industry veterans in Switzerland and
              then expanded to the US market in 2020, currently managed by
              Polimex Group (Italy), the Open Packaging Network powered by OPN
              Platform is a B2B packaging business automation and optimization
              digital platform. Our unique digital solutions are used daily by
              sales, marketing, procurement, packaging design and development
              managers, individuals and teams around the world to automate{" "}
              <a href="https://www.opnplatform.com/case-studies">
                OUTSIDE BUSINESS PROCESSES!
              </a>
              It is inevitable switch to digital{" "}
              <a href="https://www.opnplatform.com/register">TODAY</a> to stay
              competitive and keep market share.
            </p>
            <p>
              Armed with more than a decade’s experience in packaging
              procurement, sales, production, and distribution, the OPN team set
              out with a vision to transform the packaging industry in a
              sustainable way and to help offline businesses grow quickly by
              digitizing their processes and moving online.
            </p>
            <p>
              Our Fintech-enabled B2B marketplace offers an incredible
              opportunity to access a vast new market and customer base and
              reinvents the way sellers reach business customers. Today, the OPN
              Platform services thousands of companies across the globe and is
              trusted by brands such as Unilever, 3M, Caterpillar and, L'Oréal.
            </p>
          </Col>
          <Col>
            <h4 className="md-title trust__title">Trusted by</h4>
            <div className="trust-group">
              <img src="images/partners/trusted/3M.png" />
              <img src="images/partners/trusted/unilever.png" />
              <img src="images/partners/trusted/caterpillar.png" />
              <img src="images/aboutUs/Trusted/L'oreal.png" />
            </div>
          </Col>
        </Row>
        <Row className="platform row-eq-height">
          <Col sm={12} md={6}>
            <h5 className="md-title">The OPN Platform</h5>
            <p className="top_content_right">
              The packaging industry has long suffered from challenges such as
              lengthy deal negotiation, lack of trust, quality of raw materials,
              non-transparent pricing, fragmented and slow distribution, and
              high CO2 emissions.
            </p>
            <p className="top_content_right">
              The OPN Platform is a data-driven B2B online platform that
              connects buyers, sellers and related services including:
            </p>
            <ul className="custom-list">
              <li>Logistics</li>
              <li>Payments</li>
              <li>Trade finance</li>
              <li>Insurance</li>
              <li>Recycling packaging waste</li>
            </ul>
          </Col>
          <Col sm={12} md={6} className="platform__hero">
            <img src="images/aboutUs/B2B.png" />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <div className="platform__card">
              <p>
                <span>Buyers</span> can access new global and local markets,
                source new products or suppliers in a few clicks, match with
                verified sellers, and find better, more dynamic pricing.
              </p>
            </div>
          </Col>
          <Col sm={12} md={6}>
            <div className="platform__card">
              <p>
                <span>Sellers</span> can introduce new materials to a worldwide
                market and SMEs have the opportunity to enter the marketplace
                easily and affordably.
              </p>
            </div>
          </Col>
        </Row>
        <Row className="platform__greener">
          <Col sm={12} md={6} className="platform__greener__image">
            <img src="images/aboutUs/plant.png" />
          </Col>
          <Col sm={12} md={6}>
            <h5 className="md-title">Moving toward a greener future</h5>
            <p>
              Sustainability is part of our DNA and our team is dedicated to
              helping our customers lower their carbon footprint and reduce CO2
              emissions by offering tips, advice, and access to alternative
              local vendors and a range of innovative materials like
              biodegradable films, alternative inks, renewable materials,
              forest-friendly paper pulp, recycled materials, and plastic-free
              products.
            </p>
            <p>
              In 2020, OPN was included in the World Business Angels Investment
              Forum’s list of Top 100 most promising startups for industrial
              transformation.
            </p>
          </Col>
        </Row>
        <Row className="basic-card">
          <Col md="12">
            <h5 className="md-title basic-card__title">Our values</h5>
          </Col>
          {OurValuesData.map((item, index) => (
            <Col lg={4} md={4} xl={4} key={index}>
              <div className="basic-card__item" key={index}>
                <img
                  src={`/images/aboutUs/OurValues/${item.img}.png`}
                  alt="our values icon"
                />
                <h4>{item.heading}</h4>
                <p>{item.text}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
      
      <Container>
        <Row>
          <Col md="12">
            <div className="team__cta">
              <CustomButton
                name="Contact Us"
                classes="regular"
                action={() => history.push("./contacts")}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default AboutUs;

import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { Col, Container, FormGroup, Row } from "reactstrap"
import { change, Field, getFormValues, reduxForm } from "redux-form"
import CustomButton from "../../../_shared/components/Button"
import { SwitchField } from "../../../_shared/components/ReduxFormFields"
import FeaturesTable from "./FeaturesTable"
import { tariffPlans } from "./fixtures"
import PriceCard from "./PriceCard"
// import { Container } from '@material-ui/core';
import "./Pricing.scss"
import QaInfo from "./QaInfo"

const propTypes = {
  formData: PropTypes.object,
};

const defaultProps = {
  formData: {},
  authUser: {}
};

const Pricing = ({ formData, authUser }) => {
  const { accountSwitch } = formData;

  let currPlan = tariffPlans.find(el => el.plan == "Basic");
  if (authUser.isLoggedIn) {
    const subscription = authUser.user.subscription;
    const stripeSubscriptionId = authUser.user.stripe_subscription_id
    console.log('SUBSCRIPTION', subscription)
    if (subscription && stripeSubscriptionId) {
      currPlan = tariffPlans.find(el => {
        return el.plan.toUpperCase() == subscription.tariff;
      });
    } else {
      currPlan = null;
    }
    
  }

  
  
  

  return (
    <main className="pricing">
      <Container>
        <div className="page-section container" style={{ paddingBottom: 0 }}>
          <div className="text-center">
            <Row>
              <Col xs="12">
                <h1 className="page-title">Pricing Page</h1>
                <p>
                  {!currPlan || currPlan.plan === "Basic"
                    ? `BY DEFAULT, YOU ARE IN BASIC PLAN. No credit card to try. No
                  contracts. Cancel anytime.`
                    : `You are in ${currPlan.plan} Plan. `}
                </p>
                <div className="pricing__switch">
                  <FormGroup>
                    <span className={!accountSwitch ? "active" : ""}>
                      Bill Monthly
                    </span>
                    <Field name="accountSwitch" component={SwitchField} />
                    <span className={accountSwitch ? "active" : ""}>
                      Bill Yearly
                    </span>
                  </FormGroup>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Row className="row-eq-height pricing__card">
          {tariffPlans.map(tariff => (
            <PriceCard
              key={tariff.id}
              id={tariff.id}
              title={tariff.titleMain}
              content={tariff.content}
              bonus={tariff.bonus}
              plan={tariff.plan}
              accountSwitch={accountSwitch}
              monthPrice={tariff.monthPrice}
              yearPrice={tariff.yearPrice}
              totalPrice={tariff.totalPrice}
              currentPlan={currPlan}
              order={tariff.order}
              // basicPlan={this.basicPlan}
              // businessPlan={this.businessPlan}
            />
          ))}
        </Row>

        <FeaturesTable />
        <Row>
          <Col className="p-5 label-coming-soon">
            <span className="text-danger">*</span> -{" "}
            <span className="badge">Coming soon</span>
          </Col>
        </Row>

        <Row>
          <Col xs="12" sm="12" md={{ size: 8, offset: 2 }}>
            <p className="pricing__disclaim">
              <strong>The disclaimer:</strong> “These discounted yearly offers
              are available only when paid by credit card. At the end of the
              introductory period, your subscription will automatically renew
              and your credit card will automatically be charged every 4-weeks
              in advance at the then prevailing rate for your service area until
              you notify us by e-mail of your decision to terminate your
              subscription.”
            </p>
          </Col>
        </Row>

        <QaInfo />

        <Row className="pricing__Interest">
          <Col xs="12" sm="12">
            <CustomButton name="Interested" />
          </Col>
        </Row>
      </Container>
    </main>
  );
};

Pricing.propTypes = propTypes;
Pricing.defaultProps = defaultProps;


const formName = "pricingSwitchForm";
const connectedForm = reduxForm({
  // a unique name for the form
  form: formName,
  enableReinitialize: true,
  // @ts-ignore
  //   validate: PreOrderValidationFunc,
})(Pricing);

// export default connectedForm;
export default connect(
  state => ({
    initialValues: {
      accountSwitch: false,
    },
    formData: getFormValues(formName)(state),
    authUser: state.auth,
  }),
  {
    change,
  }
)(connectedForm);

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Container, Row, Col, CardImg, Card, CardBody } from "reactstrap";
import CustomButton from "../../../_shared/components/Button";
import { fetchArticles, clearBlogState } from "../../../redux/actions";
import "./Blog.scss";
import { truncate } from "../../../_shared/functions/utils";
import ProgressBar from "../../../_shared/components/ProgressBar";
import BlogCard from "./BlogCard";

const propTypes = {
  isFetchingArticles: PropTypes.bool,
};

const defaultProps = {
  isFetchingArticles: true,
};

const Blog = props => {
  const { fetchArticles, articles, isFetchingArticles, clearBlogState } = props;
  const featuredArticle = articles[0];
  useEffect(() => {
    window.scrollTo(0, 0);
    const params = {
      offset: 0,
      count: 20,
    };

    fetchArticles(params);

    return () => {
      clearBlogState();
    };
  }, []);

  const renderFeaturedArticle = article => {
    const { main_image, title, description, title_link } = article || {};

    return (
      <Row className="blog-wrapper">
        <Col md={8}>
          <Card style={{ border: "none" }}>
            <div>
              <CardImg
                top
                className="top-card-img"
                src={main_image}
                alt="Card image"
              />
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <div className="top-card-text">
            <CardBody>
              <h5 className="card-text" title={title}>
                {truncate(title, 45)}
              </h5>
              <p className="card-text_p" title={description}>
                {truncate(description, 145)}
              </p>
              <Link to={`/blog/${title_link}`}>
                <CustomButton name="READ MORE" />
              </Link>
            </CardBody>
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <main>
      <Helmet>
        <link rel="canonical" href="https://opnplatform.com/blog" />
      </Helmet>
      {isFetchingArticles ? (
        <div className="d-flex justify-content-center p-5">
          <ProgressBar />
        </div>
      ) : isEmpty(articles) ? (
        <Container>
          <Row>
            <Col>
              <p>No article to display</p>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container>
          {renderFeaturedArticle(featuredArticle)}
          <Row>
            {articles.map((item, index) => {
              if (index != 0) return <BlogCard key={item._id} article={item} />;
            })}
          </Row>
        </Container>
      )}
    </main>
  );
};

Blog.propTypes = propTypes;
Blog.defaultProps = defaultProps;

const mapStateToProps = state => ({
  articles: state.blogs.list,
  isFetchingArticles: state.ui.loading.fetchArticles,
});

const mapDispatchToProps = {
  fetchArticles,
  clearBlogState,
};

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
